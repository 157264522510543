export const ZIP_CODE_VALIDATOR = {
  minLength: (value) => {
    const clearedValue = value.replace(' ', '');

    return /^\d{5}$/.test(clearedValue) || 'Incorrect Zip Code';
  },
};

export const ZIP_CODE_VALIDATOR_LOCALE = (msg) => ({
  minLength: (value) => {
    const clearedValue = value.replace(' ', '');

    return /^\d{5}$/.test(clearedValue) || msg;
  },
});
