import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import CompanyCard from '../../../../components/CompanyCard/CompanyCard';
import {
  COMPANY_SPEC,
  COMPANY_TYPE,
} from '../../../../constants/company-type.const';
import { useDispatch, useSelector } from 'react-redux';
import { getCompany } from '../../../../store/slice/companySlice';
import Icon from '../../../../components/ui/Icon';
import LoadedContent from '../../../../components/LoadedContent';
import { AlertMessengerContext } from '../../../../provider/Alert';
import Pagination from '../../../../components/Pagination';
import { useSearchParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

const MaterialSuppliersCompaniesPage = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const isListLoading = useSelector((state) => state.company.isListLoading);
  const companyList = useSelector((state) => state.company.companyList);
  const componentMounted = useRef(true);
  const companyPagination = useSelector(
    (state) => state.company.paginationMeta
  );
  const [searchParams] = useSearchParams();
  const pageQuery = searchParams.get('page') || 1;

  const { addErrorMessage, addSuccessMessage } = useContext(
    AlertMessengerContext
  );

  useEffect(() => {
    return () => {
      componentMounted.current = false;
    };
  }, []);

  useEffect(() => {
    fetchList(pageQuery);
  }, [pageQuery]);

  const fetchList = () => {
    dispatch(
      getCompany(
        `?type=${COMPANY_TYPE.SUPPLIER}&spec=${COMPANY_SPEC.FACTORY}&page=${pageQuery}&per_page=10`
      )
    )
      .unwrap()
      .then((res) => {})
      .catch(({ errors }) => {
        console.log(errors);
      });
  };

  const successDeleteHandler = () => {
    addSuccessMessage({
      message: intl.formatMessage({
        id: 'page.sa.allCompanies.deleteSuccess',
        defaultMessage: 'The company deleted successfull!',
      }),
    });
    fetchList();
  };

  const failDeleteHandler = (err) => {
    addErrorMessage({
      message: err
        ? err.message
        : intl.formatMessage({
            id: 'page.sa.allCompanies.deleteError',
            defaultMessage: 'The company not deleted!',
          }),
    });
  };

  useEffect(() => {
    document.title = `${intl.formatMessage({
      id: 'page.sa.allCompanies.title',
      defaultMessage: 'All Companies',
    })} - ${intl.formatMessage({
      id: 'page.sa.allCompanies.tab.material',
      defaultMessage: 'Material supplier',
    })}`;
  }, []);

  return (
    <div className="company-page customer-page">
      <LoadedContent loader={isListLoading} />
      <div className="company-page__inner">
        <div className="company-page__list">
          {!isListLoading &&
            companyList &&
            companyList.map((item) => (
              <div key={item.id} className="company-page__list-item">
                <CompanyCard
                  data={item}
                  onSuccess={successDeleteHandler}
                  onError={failDeleteHandler}
                />
              </div>
            ))}
          {!isListLoading && companyList && !companyList.length ? (
            <div className="empty-list">
              <div className="empty-list__icon">
                <Icon icon="users" size="52px" />
              </div>
              <span className="empty-list__title">
                <FormattedMessage
                  id="page.sa.allCompanies.tab.material.empty"
                  defaultMessage="You have no material supplier companies"
                />
              </span>
            </div>
          ) : (
            ''
          )}
        </div>
        {!isListLoading && companyList && companyList.length ? (
          <div className="company-page__pagination">
            <Pagination
              currentPage={companyPagination.current_page}
              totalCount={companyPagination.last_page}
              onPageChange={() => {}}
            />
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

MaterialSuppliersCompaniesPage.propTypes = {
  props: PropTypes.object,
};

export default MaterialSuppliersCompaniesPage;
