/* eslint-disable prefer-regex-literals */
/* eslint-disable no-unused-vars */
import { useSearchParams, useParams, useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getMyOrder,
  getMyOrderOffer,
  setClearOrder,
} from '../../../../store/slice/orderCustomerSASlice';
import Order from '../../../../components/Order';
import Title from '../../../../components/ui/Title';
import useIsMobile from '../../../../utils/useMobile';
import Tab from '../../../../components/ui/Tab';
import Accordion from '../../../../components/Accordion';
import Icon from '../../../../components/ui/Icon';
import { get, debounce } from 'lodash';
import LoadedContent from '../../../../components/LoadedContent';
import Status from '../../../../components/ui/Status/Status';
import { resolveTabPath } from '../../../../utils/resolveTabPath';
import moment from 'moment';
import { orderViewIdFormat } from '../../../../utils/order-viewid.format';
import { FormattedMessage, useIntl } from 'react-intl';
import { localizeOrderInfo } from '../../../../utils/localizeOrderInfo';

const HomeOrders = () => {
  const dispatch = useDispatch();
  const isListLoading = useSelector(
    (state) => state.orderCustomerSA.isListLoading
  );
  const isProjectLoading = useSelector(
    (state) => state.orderCustomerSA.isProjectLoading
  );
  const isErrorProject = useSelector(
    (state) => state.orderCustomerSA.isErrorProject
  );

  const orderList = useSelector((state) => state.orderCustomerSA.data);
  const intl = useIntl();
  const localeMsg = (key, defVal) =>
    intl.formatMessage({
      id: `order.${key}`,
      defaultMessage: defVal,
    });

  const isDictionaryLoading = useSelector(
    (state) => state.dictionary.isDictionaryLoading
  );
  const dictionary = useSelector((state) => state.dictionary.mappedDictionary);

  const contentRowsAccordion = {
    1: {
      title: localeMsg('project', 'Project'),
      row: [
        {
          text: localeMsg('project.name', 'Project name'),
          id: 'project.name',
          link: true,
          id_project: true,
        },
        {
          text: localeMsg('project.marking', 'Marking'),
          id: 'project.marking',
        },
        {
          text: localeMsg('project.address', 'Adress'),
          id: 'project.address',
        },
        {
          text: localeMsg('project.zipCode', 'ZIP'),
          id: 'project.zipCode',
          regexp: { first: /(.{3})/, second: '$1 ' },
        },
        {
          text: localeMsg('project.city', 'City'),
          id: 'project.city',
        },
        {
          text: localeMsg('project.comment', 'Comment'),
          id: 'project.comment',
        },
      ],
    },
    2: {
      title: localeMsg('step.1', 'Project info'),
      row: [
        {
          text: localeMsg('dateOfExecution', 'Date of execution'),
          id: 'dateOfExecution',
          itsFullDate: true,
        },
        {
          text: localeMsg('contactPerson', 'Contact person'),
          id: 'project.contactPerson',
        },
        {
          text: localeMsg('phone', 'Phone'),
          id: 'project.phone',
          link: true,
          phone: true,
        },
        {
          text: localeMsg('email', 'Email'),
          id: 'project.email',
          link: true,
          email: true,
        },
        {
          text: localeMsg('comment', 'Comment'),
          id: 'orderDetailFirstComment',
        },
      ],
    },
    3: {
      title: localeMsg('step.2', 'Area/Volym'),
      row: [
        {
          text: localeMsg('location', 'Läge'),
          id: 'location',
        },
        {
          text: localeMsg('pump', 'Pump'),
          id: 'pump',
        },
        {
          text: localeMsg('surfaceType', 'Yttyp'),
          id: 'surfaceType',
        },
        {
          text: localeMsg('area', 'Area, m²'),
          id: 'area',
        },
        {
          text: localeMsg('volume', 'Volume, m³'),
          id: 'volume',
        },
        {
          text: localeMsg('slopeOrFall', 'Lutning/fall'),
          id: 'slopeOrFall',
        },
        {
          text: localeMsg('comment', 'Comment'),
          id: 'orderDetailSecondComment',
        },
      ],
    },
    4: {
      title: localeMsg('step.3', 'Concrete'),
      row: [
        {
          text: localeMsg('variety', 'Sort'),
          id: 'variety',
        },
        {
          text: localeMsg('exposureClass', 'Exposure class'),
          id: 'exposureClass',
        },
        {
          text: localeMsg('vct', 'Vct'),
          id: 'vct',
        },
        {
          text: localeMsg('dmax', 'Dmax'),
          id: 'dmax',
        },
        {
          text: localeMsg('seatDimensions', 'Sättmått'),
          id: 'seatDimensions',
        },
        {
          text: localeMsg('additive', 'Tillsats'),
          id: 'additive',
        },
        {
          text: localeMsg('cementVariety', 'Cementsort'),
          id: 'cementVariety',
        },
        {
          text: localeMsg('heat', 'Värme'),
          id: 'heat',
        },
        {
          text: localeMsg('comment', 'Comment'),
          id: 'orderDetailSecondComment',
        },
      ],
    },
    5: {
      title: localeMsg('step.4', 'Time'),
      row: [
        {
          text: localeMsg('timeOfStart', 'Start time'),
          id: 'timeOfStart',
        },
        {
          text: localeMsg('interval', 'Interval, min'),
          id: 'interval',
        },
        {
          text: localeMsg('comment', 'Comment'),
          id: 'orderDetailThirdComment',
        },
      ],
    },
    6: {
      title: localeMsg('step.5', 'Control'),
      row: [
        {
          text: localeMsg('orderClass', 'Class'),
          id: 'orderClass',
        },
        {
          text: localeMsg('coverage', 'Coverage'),
          id: 'coverage',
        },
        {
          text: localeMsg('comment', 'Comment'),
          id: 'orderDetailFourthComment',
        },
      ],
    },
  };

  const orderMeta = useSelector((state) => state.orderCustomerSA.meta);

  const orderProject = useSelector(
    (state) => state.orderCustomerSA.dataProject
  );

  const [searchParams] = useSearchParams();
  const pageQuery = searchParams.get('page') || 1;
  const specQuery = searchParams.get('spec') || 'factory';
  const queryParams = useParams();

  const fetchList = async () => {
    dispatch(
      getMyOrderOffer({
        id: queryParams.id_order,
        companyId: queryParams.id_company,
        query: {
          page: pageQuery,
          spec: specQuery,
        },
      })
    );
  };

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(
      getMyOrder({
        id: queryParams.id_order,
        companyId: queryParams.id_company,
      })
    );
    dispatch(setClearOrder());
    document.title = intl.formatMessage({
      id: `page.orderDetail.title`,
      defaultMessage: 'Order',
    });
  }, []);

  const debouncedFetchList = debounce(fetchList, 25, { maxWait: 25 });

  useEffect(() => {
    if (orderProject.info) {
      if (orderProject.status === 'in_progress') {
        if (orderProject.info.materialSupplier.hasAccept) {
          onTabChange(1);
        } else if (orderProject.info.serviceProvider.hasAccept) {
          onTabChange(2);
        } else if (orderProject.info.qualityAssurance.hasAccept) {
          onTabChange(3);
        }
        debouncedFetchList();
      } else {
        const params = new URLSearchParams();
        if (!specQuery) {
          params.append('spec', 'factory');
        } else {
          params.append('spec', specQuery);
        }
        if (!pageQuery) {
          params.append('page', 1);
        } else {
          params.append('page', pageQuery);
        }

        navigate(
          {
            search: params.toString(),
          },
          {
            replace: true,
          }
        );

        debouncedFetchList();
      }
    }
  }, [orderProject.info]);

  useEffect(() => {
    if (orderProject.info) {
      if (orderProject.status.length && orderProject.status !== 'in_progress') {
        debouncedFetchList();
      }
    }
  }, [pageQuery, specQuery]);

  const isMobile = useIsMobile();

  const activeDefault =
    specQuery === 'factory' ? 1 : specQuery === 'service' ? 2 : 3;

  const onTabChange = (id) => {
    const params = new URLSearchParams();

    switch (id) {
      case 1:
        params.append('spec', 'factory');
        params.append('page', 1);

        break;
      case 2:
        params.append('spec', 'service');
        params.append('page', 1);

        break;
      case 3:
        params.append('spec', 'quality-control');
        params.append('page', 1);

        break;
      default:
        break;
    }

    navigate(
      {
        search: params.toString(),
      },
      {
        replace: true,
      }
    );
  };

  const tabs = [
    {
      title: intl.formatMessage({
        id: `page.orderDetail.tab.ms`,
        defaultMessage: 'Material supplier',
      }),
      id: 1,
      countActive: orderProject.info?.materialSupplier?.count || 0,
      itsDone: orderProject.info?.materialSupplier?.hasAccept,
      page: (
        <Order
          currentPage={orderMeta.current_page}
          totalCount={orderMeta.last_page}
          isListLoading={isListLoading}
          orderList={orderList}
          description={intl.formatMessage({
            id: `page.orderDetail.tab.ms`,
            defaultMessage: 'Material supplier',
          })}
          // itsInProgress={true}
        />
      ),
    },
    {
      title: intl.formatMessage({
        id: `page.orderDetail.tab.sp`,
        defaultMessage: 'Service provider',
      }),
      id: 2,
      countActive: orderProject.info?.serviceProvider?.count || 0,
      itsDone: orderProject.info?.serviceProvider?.hasAccept,
      page: (
        <Order
          currentPage={orderMeta.current_page}
          totalCount={orderMeta.last_page}
          isListLoading={isListLoading}
          orderList={orderList}
          description={intl.formatMessage({
            id: `page.orderDetail.tab.sp`,
            defaultMessage: 'Service provider',
          })}
        />
      ),
    },
    {
      title: intl.formatMessage({
        id: `page.orderDetail.tab.qa`,
        defaultMessage: 'Quality assurance',
      }),
      id: 3,
      countActive: orderProject.info?.qualityAssurance?.count || 0,
      itsDone: orderProject.info?.qualityAssurance?.hasAccept,
      page: (
        <Order
          currentPage={orderMeta.current_page}
          totalCount={orderMeta.last_page}
          isListLoading={isListLoading}
          orderList={orderList}
          description={intl.formatMessage({
            id: `page.orderDetail.tab.qa`,
            defaultMessage: 'Quality assurance',
          })}
        />
      ),
    },
  ];

  const checkIsAccepted = () => {
    if (orderProject && orderProject.info) {
      for (const key of Object.keys(orderProject.info)) {
        if (orderProject.info[key] && orderProject.info[key].hasAccept) {
          return true;
        }
      }
    }

    return false;
  };

  const localize = (item) => {
    if (!isDictionaryLoading && dictionary) {
      const localize = localizeOrderInfo(
        item.id,
        get(orderProject, item.id),
        dictionary
      );

      return localize || get(orderProject, item.id);
    }

    return get(orderProject, item.id);
  };

  return (
    <div className="order-page">
      <div className="order-page__content">
        {!isMobile ? (
          <div className="order-page__content-header">
            <Title
              infoContent={
                <div>
                  <div className="order-page__content-viewid">
                    {orderViewIdFormat(orderProject.viewId)}
                  </div>
                  <div className="order-page__content-project">
                    {orderProject.project?.name}
                  </div>
                  <div className="status-container">
                    <Status type={orderProject && orderProject.status} />
                    {orderProject &&
                      (orderProject.edited || orderProject.inEditing) && (
                        <span className="edited-label">
                          <FormattedMessage
                            id={`status.${
                              !orderProject.inEditing && orderProject.edited
                                ? 'edited'
                                : 'editing'
                            }`}
                            defaultMessage={
                              !orderProject.inEditing && orderProject.edited
                                ? 'Edited'
                                : 'Editing'
                            }
                          />
                        </span>
                      )}
                  </div>
                </div>
              }
              subTitle={
                isMobile
                  ? intl.formatMessage({
                      id: `order.mainName_2`,
                      defaultMessage: 'Concrete casting',
                    })
                  : intl.formatMessage({
                      id: `page.orderDetail.title`,
                      defaultMessage: 'Order',
                    })
              }
            >
              {isMobile
                ? intl.formatMessage({
                    id: `page.orderDetail.title`,
                    defaultMessage: 'Order',
                  })
                : intl.formatMessage({
                    id: `order.mainName_2`,
                    defaultMessage: 'Concrete casting',
                  })}
            </Title>
            {orderProject.status === 'in_progress' ? null : (
              <div className="order-page__content-header-count">
                {orderProject.tenderTotal}{' '}
                <FormattedMessage
                  id="page.orderDetail.tenderRec"
                  defaultMessage="tenders received"
                />
              </div>
            )}
          </div>
        ) : null}

        {!isListLoading && !isProjectLoading ? (
          orderProject.status !== 'in_progress' ? (
            <Tab
              onTabChange={(id) => onTabChange(id)}
              tabs={
                orderProject.orderClass !== 'no' ? tabs : [tabs[0], tabs[1]]
              }
              activeDefault={activeDefault}
            />
          ) : orderProject.status ? (
            <Order
              currentPage={orderMeta.current_page}
              orderProject={orderProject}
              totalCount={orderMeta.last_page}
              isListLoading={isListLoading}
              orderList={orderList}
              itsInProgress={true}
              description={
                orderProject.info.materialSupplier.hasAccept
                  ? intl.formatMessage({
                      id: `company.spec.material.full`,
                      defaultMessage: 'Material supplier',
                    })
                  : orderProject.info.materialSupplier.hasAccept
                  ? intl.formatMessage({
                      id: `company.spec.service.full`,
                      defaultMessage: 'Service provider',
                    })
                  : intl.formatMessage({
                      id: `company.spec.qa.full`,
                      defaultMessage: 'Quality assurance',
                    })
              }
            />
          ) : null
        ) : (
          <LoadedContent loader={true} />
        )}
      </div>

      {orderProject.status !== 'in_progress' && isMobile ? (
        <div className="order-page__tenders">
          {orderProject.tenderTotal}{' '}
          <FormattedMessage
            id="page.orderDetail.tenderRec"
            defaultMessage="tenders received"
          />
        </div>
      ) : null}

      <div className="order-page__info">
        {!isProjectLoading && isErrorProject ? (
          <div className="empty-list">
            <div className="empty-list__icon">
              <Icon icon="alert" size="32px" />
            </div>
            <span className="empty-list__title">
              <FormattedMessage
                id="page.orderDetail.noDetailMsg"
                defaultMessage="You have no detail information"
              />
            </span>
          </div>
        ) : (
          <div className="relative">
            <LoadedContent
              loader={isProjectLoading && !isListLoading && orderProject.status}
            />
            <div className="order-page__info-title">
              <div className="title">
                <FormattedMessage
                  id="page.orderDetail.infoTitle"
                  defaultMessage="Detailed information"
                />
              </div>
              <div className="row mb-8">
                <div className="row-title">
                  <div className="icon_cyrcle">
                    <Icon icon="calendar" stroke={'#4446B0'} size="20px" />
                  </div>
                  <FormattedMessage
                    id="order.dateOfExecution"
                    defaultMessage="Date of execution"
                  />
                </div>
                <div className="bold">
                  {moment(orderProject.dateOfExecution).format('YYYY.MM.DD')}
                </div>
              </div>

              <div className="row mb-16">
                <div className="row-title">
                  <div className="icon_cyrcle">
                    <Icon icon="time" stroke={'#4446B0'} size="20px" />
                  </div>
                  <FormattedMessage
                    id="order.timeOfStart"
                    defaultMessage="Start time"
                  />
                </div>
                <div className="bold">
                  {orderProject.timeOfStart &&
                    moment(orderProject.timeOfStart, 'hh:mm:ss').format(
                      'HH:mm'
                    )}
                </div>
              </div>

              <div className="row">
                <div className="row-title">
                  <FormattedMessage id="company.city" defaultMessage="City" />
                </div>
                <div className="row-description">
                  {orderProject.project?.city}
                </div>
              </div>
              <div className="row">
                <div className="row-title">
                  <FormattedMessage id="order.area" defaultMessage="Area, m²" />
                </div>
                <div className="row-description">{orderProject.area}</div>
              </div>
              <div className="row">
                <div className="row-title">
                  <FormattedMessage
                    id="order.volume"
                    defaultMessage="Volume, m³"
                  />
                </div>
                <div className="row-description">{orderProject.volume}</div>
              </div>
            </div>

            {isMobile ? (
              <div
                onClick={() => {
                  navigate('detail-information');
                }}
                className="order-page__info-detail"
              >
                <FormattedMessage
                  id="page.orderDetail.infoTitle"
                  defaultMessage="Detailed information"
                />{' '}
                &#62;
              </div>
            ) : null}
            {Object.keys(contentRowsAccordion).map((item) => {
              return (
                <Accordion
                  key={contentRowsAccordion[item]}
                  title={contentRowsAccordion[item].title}
                >
                  {contentRowsAccordion[item].row.map((item) => {
                    if (!get(orderProject, item.id)) {
                      return '';
                    }
                    return (
                      <div key={item.id} className="row">
                        <div className={'row-title'}>{item.text}</div>
                        <div
                          onClick={() => {
                            if (item.link) {
                              if (item.id_project) {
                                const tabName = resolveTabPath();
                                navigate(
                                  `/all-companies/${tabName}/company/${get(
                                    orderProject,
                                    'project.company.id'
                                  )}/projects/${get(
                                    orderProject,
                                    'project.id'
                                  )}`
                                );
                              } else if (item.phone) {
                                document.location.href = `tel:${get(
                                  orderProject,
                                  item.id
                                )}`;
                              } else if (item.email) {
                                window.location.href = `mailto:${get(
                                  orderProject,
                                  item.id
                                )}`;
                              }
                            }
                          }}
                          className={
                            item.link
                              ? 'row-description-link link'
                              : 'row-description'
                          }
                        >
                          {/* {item.regexp} */}
                          {item?.itsFullDate
                            ? moment(get(orderProject, item.id)).format(
                                'YYYY.MM.DD'
                              )
                            : item?.regexp?.first
                            ? get(orderProject, item.id)?.replace(
                                item.regexp.first,
                                item.regexp.second
                              )
                            : localize(item)}
                        </div>
                      </div>
                    );
                  })}
                </Accordion>
              );
            })}
          </div>
        )}
      </div>
      {isMobile ? (
        <div>
          <Title
            infoContent={
              <div>
                <div className="order-page__content-viewid">
                  {orderViewIdFormat(orderProject.viewId)}
                </div>
                <div className="order-page__content-project">
                  {orderProject.project?.name}
                </div>
                <div className="status-container">
                  {' '}
                  <Status type={orderProject && orderProject.status} />
                  {orderProject &&
                    (orderProject.edited || orderProject.inEditing) && (
                      <span className="edited-label">
                        <FormattedMessage
                          id={`status.${
                            !orderProject.inEditing && orderProject.edited
                              ? 'edited'
                              : 'editing'
                          }`}
                          defaultMessage={
                            !orderProject.inEditing && orderProject.edited
                              ? 'Edited'
                              : 'Editing'
                          }
                        />
                      </span>
                    )}
                </div>
              </div>
            }
            subTitle={
              !isMobile
                ? intl.formatMessage({
                    id: `order.mainName_2`,
                    defaultMessage: 'Concrete casting',
                  })
                : intl.formatMessage({
                    id: `page.orderDetail.title`,
                    defaultMessage: 'Order',
                  })
            }
          >
            {!isMobile
              ? intl.formatMessage({
                  id: `page.orderDetail.title`,
                  defaultMessage: 'Order',
                })
              : intl.formatMessage({
                  id: `order.mainName_2`,
                  defaultMessage: 'Concrete casting',
                })}
          </Title>
        </div>
      ) : null}
    </div>
  );
};

HomeOrders.defaultProps = {};

HomeOrders.propTypes = {};

export default HomeOrders;
