/* eslint-disable no-unused-vars */
import { useParams, useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMyOrder } from '../../../../store/slice/orderCustomerSASlice';
import Title from '../../../../components/ui/Title';
import useIsMobile from '../../../../utils/useMobile';
import Accordion from '../../../../components/Accordion';
import Icon from '../../../../components/ui/Icon';
import { get } from 'lodash';
import LoadedContent from '../../../../components/LoadedContent';
import Status from '../../../../components/ui/Status/Status';
import { resolveTabPath } from '../../../../utils/resolveTabPath';
import moment from 'moment';
import { FormattedMessage, useIntl } from 'react-intl';
import { localizeOrderInfo } from '../../../../utils/localizeOrderInfo';

const HomeOrders = () => {
  const dispatch = useDispatch();
  const isProjectLoading = useSelector(
    (state) => state.orderCustomerSA.isProjectLoading
  );
  const isErrorProject = useSelector(
    (state) => state.orderCustomerSA.isErrorProject
  );
  const navigate = useNavigate();
  const intl = useIntl();

  const orderProject = useSelector(
    (state) => state.orderCustomerSA.dataProject
  );

  const queryParams = useParams();

  const localeMsg = (key, defVal) =>
    intl.formatMessage({
      id: `order.${key}`,
      defaultMessage: defVal,
    });

  const isDictionaryLoading = useSelector(
    (state) => state.dictionary.isDictionaryLoading
  );
  const dictionary = useSelector((state) => state.dictionary.mappedDictionary);

  const contentRowsAccordion = {
    1: {
      title: localeMsg('project', 'Project'),
      row: [
        {
          text: localeMsg('project.name', 'Project name'),
          id: 'project.name',
          link: true,
          id_project: true,
        },
        {
          text: localeMsg('project.marking', 'Marking'),
          id: 'project.marking',
        },
        {
          text: localeMsg('project.address', 'Adress'),
          id: 'project.address',
        },
        {
          text: localeMsg('project.zipCode', 'ZIP'),
          id: 'project.zipCode',
          regexp: { first: /(.{3})/, second: '$1 ' },
        },
        {
          text: localeMsg('project.city', 'City'),
          id: 'project.city',
        },
        {
          text: localeMsg('project.comment', 'Comment'),
          id: 'project.comment',
        },
      ],
    },
    2: {
      title: localeMsg('step.1', 'Project info'),
      row: [
        {
          text: localeMsg('dateOfExecution', 'Date of execution'),
          id: 'dateOfExecution',
          itsFullDate: true,
        },
        {
          text: localeMsg('contactPerson', 'Contact person'),
          id: 'project.contactPerson',
        },
        {
          text: localeMsg('phone', 'Phone'),
          id: 'project.phone',
          link: true,
          phone: true,
        },
        {
          text: localeMsg('email', 'Email'),
          id: 'project.email',
          link: true,
          email: true,
        },
        {
          text: localeMsg('comment', 'Comment'),
          id: 'orderDetailFirstComment',
        },
      ],
    },
    3: {
      title: localeMsg('step.2', 'Area/Volym'),
      row: [
        {
          text: localeMsg('location', 'Läge'),
          id: 'location',
        },
        {
          text: localeMsg('pump', 'Pump'),
          id: 'pump',
        },
        {
          text: localeMsg('surfaceType', 'Yttyp'),
          id: 'surfaceType',
        },
        {
          text: localeMsg('area', 'Area, m²'),
          id: 'area',
        },
        {
          text: localeMsg('volume', 'Volume, m³'),
          id: 'volume',
        },
        {
          text: localeMsg('slopeOrFall', 'Lutning/fall'),
          id: 'slopeOrFall',
        },
        {
          text: localeMsg('comment', 'Comment'),
          id: 'orderDetailSecondComment',
        },
      ],
    },
    4: {
      title: localeMsg('step.3', 'Concrete'),
      row: [
        {
          text: localeMsg('variety', 'Sort'),
          id: 'variety',
        },
        {
          text: localeMsg('exposureClass', 'Exposure class'),
          id: 'exposureClass',
        },
        {
          text: localeMsg('vct', 'Vct'),
          id: 'vct',
        },
        {
          text: localeMsg('dmax', 'Dmax'),
          id: 'dmax',
        },
        {
          text: localeMsg('seatDimensions', 'Sättmått'),
          id: 'seatDimensions',
        },
        {
          text: localeMsg('additive', 'Tillsats'),
          id: 'additive',
        },
        {
          text: localeMsg('cementVariety', 'Cementsort'),
          id: 'cementVariety',
        },
        {
          text: localeMsg('heat', 'Värme'),
          id: 'heat',
        },
        {
          text: localeMsg('comment', 'Comment'),
          id: 'orderDetailSecondComment',
        },
      ],
    },
    5: {
      title: localeMsg('step.4', 'Time'),
      row: [
        {
          text: localeMsg('timeOfStart', 'Start time'),
          id: 'timeOfStart',
        },
        {
          text: localeMsg('interval', 'Interval, min'),
          id: 'interval',
        },
        {
          text: localeMsg('comment', 'Comment'),
          id: 'orderDetailThirdComment',
        },
      ],
    },
    6: {
      title: localeMsg('step.5', 'Control'),
      row: [
        {
          text: localeMsg('orderClass', 'Class'),
          id: 'orderClass',
        },
        {
          text: localeMsg('coverage', 'Coverage'),
          id: 'coverage',
        },
        {
          text: localeMsg('comment', 'Comment'),
          id: 'orderDetailFourthComment',
        },
      ],
    },
  };

  useEffect(() => {
    dispatch(
      getMyOrder({
        id: queryParams.id_order,
        companyId: queryParams.id_company,
      })
    );
  }, []);

  useEffect(() => {
    document.title = intl.formatMessage({
      id: `page.orderDetail.title`,
      defaultMessage: 'Order',
    });
  });

  const localize = (item) => {
    if (!isDictionaryLoading && dictionary) {
      const localize = localizeOrderInfo(
        item.id,
        get(orderProject, item.id),
        dictionary
      );

      return localize || get(orderProject, item.id);
    }

    return get(orderProject, item.id);
  };
  const isMobile = useIsMobile();

  return (
    <div className="order-page">
      <div className="order-page__info mobile">
        {!isProjectLoading && isErrorProject ? (
          <div className="empty-list">
            <div className="empty-list__icon">
              <Icon icon="alert" size="32px" />
            </div>
            <span className="empty-list__title">
              <FormattedMessage
                id="page.orderDetail.noDetailMsg"
                defaultMessage="You have no detail information"
              />
            </span>
          </div>
        ) : (
          <div className="relative">
            <LoadedContent loader={isProjectLoading} />
            <div>
              <Title
                infoContent={
                  <div className="infoContent">
                    <div className="order-page__content-project">
                      {orderProject.project?.name}
                    </div>
                    <div>
                      <Status type={orderProject && orderProject.status} />
                    </div>
                  </div>
                }
                subTitle={
                  !isMobile ? (
                    <FormattedMessage
                      id="order.mainName_2"
                      defaultMessage="Concrete casting"
                    />
                  ) : (
                    <FormattedMessage
                      id="page.orderDetail.infoTitle"
                      defaultMessage="Detailed information"
                    />
                  )
                }
              >
                {!isMobile ? (
                  <FormattedMessage
                    id="page.orderDetail.infoTitle"
                    defaultMessage="Detailed information"
                  />
                ) : (
                  <FormattedMessage
                    id="order.mainName_2"
                    defaultMessage="Concrete casting"
                  />
                )}
              </Title>
            </div>

            {Object.keys(contentRowsAccordion).map((item) => {
              return (
                <Accordion
                  key={contentRowsAccordion[item]}
                  title={contentRowsAccordion[item].title}
                >
                  {contentRowsAccordion[item].row.map((item) => {
                    if (!get(orderProject, item.id)) {
                      return '';
                    }
                    return (
                      <div key={item.id} className="row">
                        <div className={'row-title'}>{item.text}</div>
                        <div
                          onClick={() => {
                            if (item.link) {
                              if (item.id_project) {
                                const tabName = resolveTabPath();
                                navigate(
                                  `/all-companies/${tabName}/company/${get(
                                    orderProject,
                                    'project.company.id'
                                  )}/projects/${get(
                                    orderProject,
                                    'project.id'
                                  )}`
                                );
                              } else if (item.phone) {
                                document.location.href = `tel:${get(
                                  orderProject,
                                  item.id
                                )}`;
                              } else if (item.email) {
                                window.location.href = `mailto:${get(
                                  orderProject,
                                  item.id
                                )}`;
                              }
                            }
                          }}
                          className={
                            item.link
                              ? 'row-description-link link'
                              : 'row-description'
                          }
                        >
                          {item?.itsFullDate
                            ? moment(get(orderProject, item.id)).format(
                                'YYYY.MM.DD'
                              )
                            : item?.regexp?.first
                            ? get(orderProject, item.id)?.replace(
                                item.regexp.first,
                                item.regexp.second
                              )
                            : localize(item)}
                        </div>
                      </div>
                    );
                  })}
                </Accordion>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

HomeOrders.defaultProps = {};

HomeOrders.propTypes = {};

export default HomeOrders;
