/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { AlertMessengerContext } from '../../../../provider/Alert';
import { validationErrorParser } from '../../../../utils/validations.error.parser';
import LoadedContent from '../../../../components/LoadedContent';
import StepsComponent, {
  NextStepButton,
  StepPage,
} from '../../../../components/ui/Steps/StepsComponent';
import FormInput from '../../../../components/ui/FormInput/FormInput';
import FormInputMask from '../../../../components/ui/FormInputMask';
import Button from '../../../../components/ui/Button';
import {
  BUTTON_SIZE,
  BUTTON_TYPE,
} from '../../../../components/ui/Button/Button';
import FormTextArea from '../../../../components/ui/FormTextArea';
import ConfirmPopup from '../../../../components/ConfirmPopup/ConfirmPopup';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { projectEdit, customerProjectShow } from '../../../../api/project';
import { FormattedMessage, useIntl } from 'react-intl';

const phoneRegExp = /(^[+]46\d{6,11}$)|(^[+]46\s\d{6,11}$)/;

const EditProject = () => {
  const [open, setOpen] = useState(false);
  const [cancelPopupOpen, setCancelPopupOpen] = useState(false);

  const [isSubmiting, setIsSubmiting] = useState(false);
  const [isProjectInfoLoading, setProjectInfoLoading] = useState(false);
  const [projectData, setProjectData] = useState();

  const [step, setStep] = useState(0);
  const navigate = useNavigate();
  const params = useParams();
  const intl = useIntl();

  const steps = [
    intl.formatMessage({
      id: 'page.addProject.step.1',
      defaultMessage: 'Project details',
    }),
    intl.formatMessage({
      id: 'page.addProject.step.2',
      defaultMessage: 'Contact person',
    }),
  ];

  const schema = yup.object().shape({
    name: yup
      .string()
      .required(
        intl.formatMessage({
          id: 'input.error.requiredMsg',
          defaultMessage: 'Field is required',
        })
      )
      .min(
        3,
        intl.formatMessage({
          id: 'input.error.projectName',
          defaultMessage: 'Project name must be at least 3 characters',
        })
      ),
    address: yup.string().required(
      intl.formatMessage({
        id: 'input.error.requiredMsg',
        defaultMessage: 'Field is required',
      })
    ),
    zipCode: yup
      .string()
      .required(
        intl.formatMessage({
          id: 'input.error.requiredMsg',
          defaultMessage: 'Field is required',
        })
      )
      .matches(
        /^\d{3}\s\d{2}$/,
        intl.formatMessage({
          id: 'input.error.incorrectZip',
          defaultMessage: 'Incorrect Zip Code',
        })
      ),
    city: yup.string().required(
      intl.formatMessage({
        id: 'input.error.requiredMsg',
        defaultMessage: 'Field is required',
      })
    ),
    contactPerson: yup
      .string()
      .required(
        intl.formatMessage({
          id: 'input.error.requiredMsg',
          defaultMessage: 'Field is required',
        })
      )
      .min(
        3,
        intl.formatMessage({
          id: 'input.error.minChar',
          defaultMessage: 'Name must be at least 3 characters',
        })
      ),
    phone: yup
      .string()
      .required(
        intl.formatMessage({
          id: 'input.error.requiredMsg',
          defaultMessage: 'Field is required',
        })
      )
      .matches(
        phoneRegExp,
        intl.formatMessage({
          id: 'input.error.incorrectPhone',
          defaultMessage: 'Phone number is not valid',
        })
      ),
    email: yup
      .string()
      .required(
        intl.formatMessage({
          id: 'input.error.requiredMsg',
          defaultMessage: 'Field is required',
        })
      )
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, 'Incorrect E-mail address'),
  });

  const { addErrorMessage, addSuccessMessage } = useContext(
    AlertMessengerContext
  );

  const {
    control,
    getValues,
    trigger,
    handleSubmit,
    setError,
    reset,
    clearErrors,
    formState,
  } = useForm({
    reValidateMode: 'onChange',
    mode: 'onTouched',
    defaultValues: {
      name: '',
      marking: '',
      address: '',
      zipCode: '',
      city: '',
      comment: '',
      contactPerson: '',
      phone: '',
      email: '',
    },
    resolver: yupResolver(schema),
  });
  const { errors } = formState;

  const toStepTwo = async () => {
    const res = await trigger(['name', 'address', 'zipCode', 'city']);

    if (res) {
      clearErrors();
      setStep(step + 1);
    }
  };

  const onNextStepLinkClick = async (index) => {
    const res = await trigger(['name', 'address', 'zipCode', 'city']);

    if (res) {
      clearErrors();
      setStep(index);
    }
  };

  const fetchProjectInfo = () => {
    setProjectInfoLoading(true);
    customerProjectShow(params.id_project)
      .then((res) => {
        if (res && res.data && res.data.data) {
          const info = res.data.data;
          setProjectData(res.data.data);
          reset({
            name: info.name,
            marking: info.marking,
            address: info.address,
            zipCode: info.zipCode,
            city: info.city,
            comment: info.comment,
            contactPerson: info.contactPerson,
            phone: info.phone,
            email: info.email,
          });
        }
        setProjectInfoLoading(false);
      })
      .catch((err) => {
        setErrors(err);
        setProjectInfoLoading(false);
      });
  };

  const onSubmit = async () => {
    setOpen(true);
  };

  const sendData = () => {
    setOpen(false);
    const val = getValues();
    const phone = val.phone.replace(/[()_\s-]/g, '');
    const postData = {
      ...val,
      phone,
    };
    setIsSubmiting(true);
    projectEdit(params.id_project, postData)
      .then(() => {
        setIsSubmiting(false);
        addSuccessMessage({
          message: intl.formatMessage({
            id: 'page.editProject.successMsg',
            defaultMessage: 'Company information has been successfully edited',
          }),
        });
        navigate('/my-projects');
      })
      .catch((err) => {
        setErrors(err);
        setIsSubmiting(false);
      });
  };

  const setErrors = (err) => {
    if (err && err.errors) {
      const parsed = validationErrorParser(err.errors);
      let errorStr = '';

      for (const key of Object.keys(parsed)) {
        setError(key, { type: 'custom', message: parsed[key] });
        errorStr += `${key}: ${parsed[key]} `;
      }

      addErrorMessage({
        message: errorStr,
      });

      return;
    }

    addErrorMessage({
      message: err && err.message ? err.message : err,
    });
  };

  useEffect(() => {
    fetchProjectInfo();
  }, []);

  useEffect(() => {
    if (projectData) {
      document.title = projectData.name;
    } else {
      document.title = '...';
    }
  }, [projectData]);

  return (
    <div className="company-page add-project">
      <LoadedContent loader={isSubmiting || isProjectInfoLoading} />
      <div className="company-page__inner">
        <div className="company-page__header">
          <span className="company-page__header-title">
            <FormattedMessage
              id="page.editProject.title"
              defaultMessage="Edit Project"
            />
          </span>
        </div>
        <div className="company-page__body">
          <div className="new-company__steps">
            <form onSubmit={handleSubmit(onSubmit)}>
              <StepsComponent
                steps={steps}
                activeStep={step}
                clickOnPrevStep={(index) => {
                  setStep(index);
                }}
                clickOnNextStep={(index) => {
                  onNextStepLinkClick(index);
                }}
              >
                <StepPage>
                  <div className="col-2">
                    <FormInput
                      control={control}
                      label={intl.formatMessage({
                        id: 'input.label.projectName',
                        defaultMessage: 'Project name',
                      })}
                      name="name"
                      id="name"
                      type="text"
                      errors={errors.name}
                      placeholder={intl.formatMessage({
                        id: 'input.placeholder.projectName',
                        defaultMessage: 'Enter project name',
                      })}
                      disabled={isSubmiting || isProjectInfoLoading}
                      rules={{
                        required: 'Field is required',
                      }}
                    />
                  </div>
                  <div className="col-2">
                    <FormInput
                      control={control}
                      label={intl.formatMessage({
                        id: 'input.label.marking',
                        defaultMessage: 'Marking',
                      })}
                      name="marking"
                      id="marking"
                      type="text"
                      // errors={errors.marking}
                      placeholder={intl.formatMessage({
                        id: 'input.placeholder.marking',
                        defaultMessage: 'Enter marking',
                      })}
                      disabled={isSubmiting || isProjectInfoLoading}
                      // rules={{
                      //   required: 'Field is required',
                      // }}
                    />
                  </div>
                  <div className="col-4">
                    <FormInput
                      control={control}
                      label={intl.formatMessage({
                        id: 'input.label.address',
                        defaultMessage: 'Address',
                      })}
                      name="address"
                      id="address"
                      type="text"
                      errors={errors.address}
                      placeholder={intl.formatMessage({
                        id: 'input.placeholder.addressPlaceholder',
                        defaultMessage: 'Enter address',
                      })}
                      disabled={isSubmiting || isProjectInfoLoading}
                      rules={{
                        required: 'Field is required',
                      }}
                    />
                  </div>
                  <div className="col-2">
                    <FormInputMask
                      control={control}
                      label={intl.formatMessage({
                        id: 'input.label.zipCode',
                        defaultMessage: 'ZIP code',
                      })}
                      name="zipCode"
                      id="zipCode"
                      type="tel"
                      inputMode="tel"
                      errors={errors.zipCode}
                      placeholder={intl.formatMessage({
                        id: 'input.placeholder.zipPlaceholder',
                        defaultMessage: 'Type ZIP code',
                      })}
                      disabled={isSubmiting || isProjectInfoLoading}
                      rules={{
                        required: 'Field is required',
                      }}
                      maskChar="_"
                      mask="999 99"
                    />
                  </div>
                  <div className="col-2">
                    <FormInput
                      control={control}
                      label={intl.formatMessage({
                        id: 'input.label.city',
                        defaultMessage: 'City',
                      })}
                      name="city"
                      id="city"
                      type="text"
                      errors={errors.city}
                      placeholder={intl.formatMessage({
                        id: 'input.placeholder.cityPlaceholder',
                        defaultMessage: 'Enter city',
                      })}
                      disabled={isSubmiting || isProjectInfoLoading}
                      rules={{
                        required: 'Field is required',
                      }}
                    />
                  </div>
                  <div className="col-4">
                    <FormTextArea
                      control={control}
                      label={intl.formatMessage({
                        id: 'input.label.comment',
                        defaultMessage: 'Comment',
                      })}
                      name="comment"
                      id="comment"
                      type="text"
                      placeholder={intl.formatMessage({
                        id: 'input.placeholder.projectDetails',
                        defaultMessage: 'Project details',
                      })}
                      disabled={isSubmiting || isProjectInfoLoading}
                    />
                  </div>

                  <NextStepButton>
                    <Button
                      disabled={isSubmiting || isProjectInfoLoading}
                      text={intl.formatMessage({
                        id: 'steps.btn.cancel',
                        defaultMessage: 'Cancel',
                      })}
                      type={BUTTON_TYPE.OUTLINE}
                      size={BUTTON_SIZE.MEDIUM}
                      onClick={(e) => {
                        e.preventDefault();
                        e.target.blur();
                        setCancelPopupOpen(true);
                      }}
                    />
                    <Button
                      size={BUTTON_SIZE.MEDIUM}
                      text={intl.formatMessage({
                        id: 'steps.btn.nextStep',
                        defaultMessage: 'Next Step',
                      })}
                      onClick={() => {
                        toStepTwo();
                      }}
                    />
                  </NextStepButton>
                </StepPage>

                <StepPage>
                  <div className="col-2">
                    <FormInput
                      control={control}
                      label={intl.formatMessage({
                        id: 'input.label.contactPerson',
                        defaultMessage: 'Contact person',
                      })}
                      name="contactPerson"
                      id="contactPerson"
                      type="text"
                      errors={errors.contactPerson}
                      placeholder={intl.formatMessage({
                        id: 'input.placeholder.contactPerson',
                        defaultMessage: 'Enter name',
                      })}
                      disabled={isSubmiting || isProjectInfoLoading}
                      rules={{
                        required: 'Field is required',
                      }}
                    />
                  </div>
                  <div className="col-2">
                    <FormInputMask
                      control={control}
                      label={intl.formatMessage({
                        id: 'input.label.phone',
                        defaultMessage: 'Phone',
                      })}
                      name="phone"
                      id="phone"
                      type="tel"
                      inputMode="tel"
                      errors={errors.phone}
                      placeholder="+46 "
                      disabled={isSubmiting || isProjectInfoLoading}
                      rules={{
                        required: 'Field is required',
                      }}
                      maskChar=""
                      mask="+46 99999999999"
                    />
                  </div>
                  <div className="col-4">
                    <FormInput
                      control={control}
                      label={intl.formatMessage({
                        id: 'input.label.mail',
                        defaultMessage: 'E-mail',
                      })}
                      name="email"
                      id="email"
                      type="email"
                      errors={errors.email}
                      placeholder={intl.formatMessage({
                        id: 'input.placeholder.mail',
                        defaultMessage: 'Enter email',
                      })}
                      disabled={isSubmiting || isProjectInfoLoading}
                      rules={{
                        required: 'Field is required',
                      }}
                    />
                  </div>

                  <NextStepButton>
                    <Button
                      disabled={isSubmiting || isProjectInfoLoading}
                      text={intl.formatMessage({
                        id: 'btn.cancel',
                        defaultMessage: 'Cancel',
                      })}
                      type={BUTTON_TYPE.OUTLINE}
                      size={BUTTON_SIZE.MEDIUM}
                      onClick={(e) => {
                        e.preventDefault();
                        e.target.blur();
                        setCancelPopupOpen(true);
                      }}
                    />
                    <Button
                      size={BUTTON_SIZE.MEDIUM}
                      text={intl.formatMessage({
                        id: 'btn.save',
                        defaultMessage: 'Save',
                      })}
                      onClick={handleSubmit(sendData)}
                    />
                  </NextStepButton>
                </StepPage>
              </StepsComponent>
            </form>
          </div>
        </div>
      </div>
      <ConfirmPopup
        open={open}
        text={intl.formatMessage({
          id: 'popup.project.editeMsg',
          defaultMessage: `Are you sure that you want to update the project?`,
        })}
        confirmButtonText={intl.formatMessage({
          id: 'popup.btn.update',
          defaultMessage: 'Yes, update',
        })}
        onSubmit={sendData}
        onCloseModal={() => setOpen(false)}
      />
      <ConfirmPopup
        open={cancelPopupOpen}
        text={intl.formatMessage({
          id: 'popup.project.cancelEditMsg',
          defaultMessage: `Are you sure that you want to cancel editing the project?`,
        })}
        confirmButtonText={intl.formatMessage({
          id: 'popup.cancel.ok',
          defaultMessage: 'Yes, cancel',
        })}
        cancelButtonText={intl.formatMessage({
          id: 'popup.cancel.no',
          defaultMessage: 'No, continue',
        })}
        onSubmit={() => {
          navigate(-1);
        }}
        onCloseModal={() => setCancelPopupOpen(false)}
      />
    </div>
  );
};

EditProject.propTypes = {
  props: PropTypes.object,
};

export default EditProject;
