import React from 'react';
import PropTypes from 'prop-types';

const RoleCheck = ({ children, roles }) => {
  const userRole = localStorage.getItem('role');
  const checkRole = roles.indexOf(userRole) > -1;

  if (checkRole) {
    return children;
  } else {
    return <></>;
  }
};

RoleCheck.defaultProps = {
  roles: [],
};

RoleCheck.propTypes = {
  roles: PropTypes.array.isRequired,
  children: PropTypes.node,
};

export default RoleCheck;
