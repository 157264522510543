// http://api.localhost/api/v1/company/companies/{companyId}/users
import API from '../api';

export const companyUsersCompany = (id, data) =>
  API.get(`/api/api/v1/company/companies/${id}/users`, {
    params: {
      ...data,
      per_page: 10,
    },
  });

export const companyUserCompanyBan = (companyId, userId) =>
  API.patch(`/api/api/v1/company/companies/${companyId}/users/${userId}/ban`);

export const companyUserCompanyUnban = (companyId, userId) =>
  API.patch(`/api/api/v1/company/companies/${companyId}/users/${userId}/unban`);

export const companyUserInviteAdmin = (companyId, data) =>
  API.post(
    `/api/api/v1/company/companies/${companyId}/users/admin/invite`,
    data
  );

export const companyUserInviteProjectLead = (companyId, data) =>
  API.post(
    `/api/api/v1/company/companies/${companyId}/users/project-lead/invite`,
    data
  );

export const companyUserEditReq = (companyId, userId, data) =>
  API.post(`/api/api/v1/company/companies/${companyId}/users/${userId}`, data);

export const companyUserShow = (userId, companyId) =>
  API.get(`/api/api/v1/company/companies/${companyId}/users/${userId}`);

export const superAdminBan = (userId) =>
  API.patch(`/api/api/v1/users/${userId}/super-admin/ban`);

export const superAdminUnBan = (userId) =>
  API.patch(`/api/api/v1/users/${userId}/super-admin/unban`);
