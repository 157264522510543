/* eslint-disable no-unused-expressions */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  customerOrdersSupplierArchive,
  customerOrdersSupplierNew,
  customerOrdersSupplierDecline,
  orderIdDecline,
  orderIdUnDecline,
  customerOrdersSupplierWait,
  customerOrdersSupplierCurrent,
} from '../../api/order';
import {
  orderMapperSA,
  orderSupplierArchiveMapper,
} from '../../utils/order-mapper';

export const ordersSupplierWait = createAsyncThunk(
  'ordersSupplier/ordersSupplierWait',
  async (creds, { rejectWithValue }) => {
    try {
      const response = await customerOrdersSupplierWait(creds.data);
      if (response.status === 200) {
        response.data.data = response.data.data.map((item) => {
          if (item.status === 'new') {
            return {
              ...item,
              status: 'waiting_for_answer',
            };
          } else {
            return item;
          }
        });
        // }) === 'new'
        //   ? (response.data.data.status = 'waiting_for_answer')
        //   : null;
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (error) {
      console.log('sliceError', error.response);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const ordersSupplierCurrent = createAsyncThunk(
  'ordersSupplier/ordersSupplierCurrent',
  async (creds, { rejectWithValue }) => {
    try {
      const response = await customerOrdersSupplierCurrent(creds.data);
      if (response.status === 200) {
        console.log(response.data.data);
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (error) {
      console.log('sliceError', error.response);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const ordersIdUnDecline = createAsyncThunk(
  'ordersSupplier/ordersIdUnDecline',
  async (creds, { rejectWithValue }) => {
    try {
      const response = await orderIdUnDecline(creds);
      if (response.status === 200 || response.status === 201) {
        console.log(response.data.data);
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (error) {
      console.log('sliceError', error.response);
      console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export function startLoading(text) {
  return {
    type: 'ADD_LOADING',
  };
}

export const ordersIdDecline = createAsyncThunk(
  'ordersSupplier/ordersSupplierDeclineId',
  async (creds, { rejectWithValue }) => {
    try {
      const response = await orderIdDecline(creds);
      return response;
    } catch (error) {
      console.log('sliceError', error.response);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const ordersSupplierDecline = createAsyncThunk(
  'ordersSupplier/ordersSupplierDecline',
  async (creds, { rejectWithValue }) => {
    try {
      const response = await customerOrdersSupplierDecline(creds.data);
      if (response.status === 200) {
        response.data.data = response.data.data.map((item) => {
          if (item.status === 'new') {
            return {
              ...item,
              status: 'new_order',
            };
          } else {
            return item;
          }
        });
        console.log(response.data.data);
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (error) {
      console.log('sliceError', error.response);
      console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const ordersSupplierArchive = createAsyncThunk(
  'ordersSupplier/ordersSupplierArchive',
  async (creds, { rejectWithValue }) => {
    try {
      const response = await customerOrdersSupplierArchive(creds.data);
      if (response.status === 200) {
        return orderSupplierArchiveMapper(response.data);
      } else {
        return rejectWithValue(response.data);
      }
    } catch (error) {
      console.log('sliceError', error.response);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const ordersSupplierNew = createAsyncThunk(
  'ordersSupplier/ordersSupplierNew',
  async (creds, { rejectWithValue }) => {
    try {
      const response = await customerOrdersSupplierNew(creds.data);
      if (response.status === 200) {
        response.data.data = response.data.data.map((item) => {
          if (item.status === 'new') {
            return {
              ...item,
              status: 'new_order',
            };
          } else {
            return item;
          }
        });
        console.log(response.data.data);
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (error) {
      console.log('sliceError', error.response);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export function changeordersSuppliertatus(status) {
  return {
    type: 'CHANDE_ORDER_STATUS',
    status: status,
  };
}

const initialState = {
  isLoading: false,
  isError: false,
  data: [],
  mappedData: [],
  meta: null,

  isLoadingDecline: false,
  isErrorDecline: false,
  dataDecline: [],
  metaDecline: null,
};

const ordersSupplierSlice = createSlice({
  name: 'ordersSupplier',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(ordersSupplierDecline.pending, (state) => {
      state.isLoadingDecline = true;
      state.isErrorDecline = false;
    });
    builder.addCase(ordersSupplierDecline.fulfilled, (state, payload) => {
      state.isLoadingDecline = false;
      state.isErrorDecline = false;
      state.dataDecline = orderMapperSA(payload.payload.data);
      state.metaDecline = payload.payload.meta;
    });
    builder.addCase(ordersSupplierDecline.rejected, (state) => {
      state.isLoadingDecline = false;
      state.isErroDecliner = true;
    });
    // base
    builder.addCase(ordersSupplierArchive.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
    });
    builder.addCase(ordersSupplierArchive.fulfilled, (state, payload) => {
      state.isLoading = false;
      state.isError = false;
      state.data = orderMapperSA(payload.payload.data);
      state.meta = payload.payload.meta;
    });
    builder.addCase(ordersSupplierArchive.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
    // base
    builder.addCase(ordersSupplierNew.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
    });
    builder.addCase(ordersSupplierNew.fulfilled, (state, payload) => {
      state.isLoading = false;
      state.isError = false;
      state.data = orderMapperSA(payload.payload.data);
      state.meta = payload.payload.meta;
    });
    builder.addCase(ordersSupplierNew.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    builder.addCase(ordersSupplierWait.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
    });
    builder.addCase(ordersSupplierWait.fulfilled, (state, payload) => {
      state.isLoading = false;
      state.isError = false;
      state.data = orderMapperSA(payload.payload.data);
      state.meta = payload.payload.meta;
    });
    builder.addCase(ordersSupplierWait.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    builder.addCase(ordersSupplierCurrent.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
    });
    builder.addCase(ordersSupplierCurrent.fulfilled, (state, payload) => {
      state.isLoading = false;
      state.isError = false;
      state.data = orderMapperSA(payload.payload.data);
      state.meta = payload.payload.meta;
    });
    builder.addCase(ordersSupplierCurrent.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
    // builder.addCase(ordersIdUnDecline.pending, (state) => {
    //   state.isLoading = true;
    // });
    // builder.addCase(ordersIdDecline.pending, (state) => {
    //   state.isLoading = true;
    // });
    builder.addCase('ADD_LOADING', (state, action) => {
      console.log('/');
      state.data = [];
      state.dataDecline = [];
      state.isLoading = true;
      state.isLoadingDecline = true;
    });
  },
});

const { reducer } = ordersSupplierSlice;

export const selectOrder = (state) => state.order;

export default reducer;
