import React from 'react';
import Title from '../../../components/ui/Title';
import Tab from '../../../components/ui/Tab';
import PropTypes from 'prop-types';
import PersonalInfo from './PersonalInfo/PersonalInfo';
import { useNavigate } from 'react-router-dom';
import Password from './Password';
import MyCompanyPage from './MyCompany/MyCompany';
import UsersPage from './Users/UsersPage';
import { FormattedMessage, useIntl } from 'react-intl';

const MyCompany = ({ activeDefault = 1 }) => {
  const navigate = useNavigate();
  const intl = useIntl();

  const tabs = [
    {
      title: intl.formatMessage({
        id: 'page.myCompany.tab.company',
        defaultMessage: 'My company',
      }),
      id: 1,
      page: <MyCompanyPage />,
    },
    {
      title: intl.formatMessage({
        id: 'page.myCompany.tab.info',
        defaultMessage: 'Personal Info',
      }),
      id: 2,
      page: <PersonalInfo />,
    },
    {
      title: intl.formatMessage({
        id: 'page.myCompany.tab.password',
        defaultMessage: 'Password',
      }),
      id: 3,
      page: <Password />,
    },
    {
      title: intl.formatMessage({
        id: 'page.myCompany.tab.users',
        defaultMessage: 'Users',
      }),
      id: 4,
      page: <UsersPage />,
    },
  ];

  const onTabChange = (id) => {
    switch (id) {
      case 1:
        navigate('/my-company/company-info');
        break;
      case 2:
        navigate('/my-company/personal-info');
        break;
      case 3:
        navigate('/my-company/password');
        break;
      case 4:
        navigate('/my-company/admins');
        break;
      default:
        break;
    }
  };

  return (
    <div className="my-company-page">
      <Title>
        <FormattedMessage
          id="page.myCompany.title"
          defaultMessage="My Company"
        />
      </Title>
      <Tab
        onTabChange={(id) => onTabChange(id)}
        activeDefault={activeDefault}
        tabs={tabs}
      />
    </div>
  );
};

MyCompany.defaultProps = {
  activeDefault: 1,
};

MyCompany.propTypes = {
  activeDefault: PropTypes.number,
};

export default MyCompany;
