import React from 'react';
import PropTypes from 'prop-types';
import iconSet from '../../../selection.json';
import IcomoonReact from 'icomoon-react';

const Icon = (props) => {
  const { color, size, className, icon } = props;

  return (
    <IcomoonReact
      iconSet={iconSet}
      className={className}
      color={color}
      size={size}
      icon={icon}
    />
  );
};

Icon.defaultProps = {
  color: '',
  size: '100%',
  className: '',
};

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

export default Icon;
