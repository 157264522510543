import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const CompanyOrderItem = ({ data, onSuccess, idCompany, description }) => {
  // const [isLoading, setIsLoading] = useState(false);

  const { company, amount } = data;
  const navigate = useNavigate();

  function numberWithSpaces(x) {
    const parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    return parts.join('.');
  }

  return (
    <div className="company-order-item">
      <div className="company-order-item__inner">
        <div
          className="company-order-item__name"
          //   onClick={() => navigate(`/all-companies/company-order/${id}`)}
        >
          <span className="company-order-item__name-name">
            <FormattedMessage
              id="component.offerCard.total"
              defaultMessage="Quotation amount (excl. VAT)"
            />
          </span>
          <div className="company-order-item__name-count">
            {numberWithSpaces(amount)} kr
          </div>
        </div>
        <div className="company-order-item__info">
          <div className="company-order-item__info-block">
            <span className="company-order-item__info-title">
              {description}
            </span>
            <span
              onClick={() =>
                navigate(
                  `/all-companies/${
                    company.spec === 'service'
                      ? 'providers'
                      : company.spec === 'quality-control'
                      ? 'quality'
                      : 'supplier'
                  }/company/${company.id}`
                )
              }
              className="company-order-item__info-desc link"
            >
              {company.name}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

CompanyOrderItem.defaultProps = {
  onSuccess: () => {},
};

CompanyOrderItem.propTypes = {
  data: PropTypes.object.isRequired,
  onSuccess: PropTypes.func,
  idCompany: PropTypes.string,
  description: PropTypes.string,
};

export default CompanyOrderItem;
