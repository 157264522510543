import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../../components/ui/Button';
import { BUTTON_SIZE, BUTTON_TYPE } from '../../../components/ui/Button/Button';
import Icon from '../../../components/ui/Icon';
import { useMediaQuery } from 'react-responsive';
import { getCompany } from '../../../api/company';
import LoadedContent from '../../../components/LoadedContent';
import Actions from '../../../components/ui/Actions';
import { useDispatch } from 'react-redux';
import { deleteCompany } from '../../../store/slice/companySlice';
import ConfirmPopup from '../../../components/ConfirmPopup/ConfirmPopup';
import { resolveTabPath } from '../../../utils/resolveTabPath';
// import { zipCodeFormat } from '../../../utils/zip-code.format';
import { FormattedMessage, useIntl } from 'react-intl';

const CompanyInfoPage = () => {
  const [isLoading, setIsLoading] = useState(true);

  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [companyInfo, setCompanyInfo] = useState({});
  const [companyInfoPerson, setCompanyInfoPerson] = useState(null);
  const params = useParams();
  const intl = useIntl();

  const isMobileOrTablet = useMediaQuery({
    query: '(max-width: 900px) ',
  });

  const fetchCompany = async (id) => {
    setIsLoading(true);

    try {
      const res = await getCompany(id);

      if (res && res.data && res.data.data) {
        setIsLoading(false);
        setCompanyInfo(res.data.data);
        const contactPerson =
          res.data.data.admins && res.data.data.admins.length
            ? res.data.data.admins[0]
            : null;

        setCompanyInfoPerson(contactPerson);
        document.title = `${res.data.data.name}`;
      }
    } catch {
      setIsLoading(false);
    }
  };

  const onSubmit = () => {
    setIsLoading(true);

    dispatch(deleteCompany(companyInfo.id))
      .unwrap()
      .then((res) => {
        setIsLoading(false);
        setOpen(false);
        navigate(-1);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const closeModal = () => setOpen(false);

  useEffect(() => {
    document.title = '...';
  }, []);

  useEffect(() => {
    if (params && params.id) {
      fetchCompany(params.id);
    }
  }, [params]);

  return (
    <div className="company-page company-detail-page">
      <LoadedContent loader={isLoading} />

      <div className="company-page__inner">
        <div className="company-page__header">
          <div className="company-page__header-type">
            <span>
              {!isMobileOrTablet && (
                <div onClick={() => navigate(-1)}>
                  <Icon icon="arrow-left" size="20px" />
                </div>
              )}
              {companyInfo && companyInfo.type
                ? intl.formatMessage({
                    id: `company.type.${companyInfo.type}`,
                    defaultMessage: companyInfo.type,
                  })
                : '...'}
            </span>

            {isMobileOrTablet && !isLoading ? (
              <div className="company-page__header-controls">
                <Actions
                  actions={[
                    {
                      text: intl.formatMessage({
                        id: 'actions.edit',
                        defaultMessage: 'Edit',
                      }),
                      icon: 'edit',
                      action: () => {
                        const tabName = resolveTabPath();
                        navigate(
                          `/all-companies/${tabName}/edit-company/${companyInfo.id}`
                        );
                      },
                    },
                    {
                      text: intl.formatMessage({
                        id: 'actions.delete',
                        defaultMessage: 'Delete',
                      }),
                      icon: 'trash',
                      action: () => setOpen(true),
                    },
                  ]}
                />
              </div>
            ) : (
              ''
            )}
            <ConfirmPopup
              open={open}
              text={intl.formatMessage(
                {
                  id: 'popup.sa.deleteCompany.text',
                  defaultMessage: `Are you sure you want to delete the company${'\u00A0'}${
                    companyInfo.name
                  }?`,
                },
                {
                  value: companyInfo.name,
                }
              )}
              isLoading={isLoading}
              onSubmit={onSubmit}
              onCloseModal={closeModal}
            />
          </div>
          {!isMobileOrTablet ? (
            <span className="company-page__header-title">
              {companyInfo && companyInfo.name ? `${companyInfo.name}` : '...'}
            </span>
          ) : (
            <span className="company-page__header-title mobile">
              {companyInfo && (
                <div onClick={() => navigate(-1)}>
                  <Icon icon="arrow-left" size="20px" />
                </div>
              )}
              {companyInfo && companyInfo.name ? `${companyInfo.name}` : '...'}
            </span>
          )}
        </div>
        <div className="company-page__content">
          {!isLoading && (
            <>
              <div
                className={
                  companyInfo && companyInfo.type === 'supplier'
                    ? 'company-page__stat supplier'
                    : 'company-page__stat '
                }
              >
                <div
                  className="company-additional-card"
                  onClick={isMobileOrTablet ? () => navigate(`users`) : null}
                >
                  <div className="company-additional-card__top">
                    <div className="company-additional-card__icon">
                      <Icon icon="user" size="20px" />
                    </div>
                    <div className="company-additional-card__text">
                      <span>{companyInfo.usersCount}</span>
                      <span>
                        <FormattedMessage
                          id="page.sa.companyDetail.users"
                          defaultMessage="Users"
                        />
                      </span>
                    </div>
                  </div>
                  <div className="company-additional-card__bottom">
                    <Button
                      text={intl.formatMessage({
                        id: 'btn.view',
                        defaultMessage: 'View',
                      })}
                      type={BUTTON_TYPE.OUTLINE}
                      size={BUTTON_SIZE.SMALL}
                      onClick={() => {
                        navigate(`users`);
                      }}
                    />
                  </div>
                </div>
                {companyInfo && companyInfo.type !== 'supplier' ? (
                  <div
                    className="company-additional-card"
                    onClick={
                      isMobileOrTablet ? () => navigate(`projects`) : null
                    }
                  >
                    <div className="company-additional-card__top">
                      <div className="company-additional-card__icon">
                        <Icon icon="project" size="20px" />
                      </div>
                      <div className="company-additional-card__text">
                        <span>{companyInfo.projectsCount}</span>
                        <span>
                          <FormattedMessage
                            id="page.sa.companyDetail.projects"
                            defaultMessage="Projects"
                          />
                        </span>
                      </div>
                    </div>
                    <div className="company-additional-card__bottom">
                      <Button
                        text={intl.formatMessage({
                          id: 'btn.view',
                          defaultMessage: 'View',
                        })}
                        type={BUTTON_TYPE.OUTLINE}
                        size={BUTTON_SIZE.SMALL}
                        onClick={() => navigate(`projects`)}
                      />
                    </div>
                  </div>
                ) : (
                  ''
                )}
                <div
                  className="company-additional-card"
                  onClick={isMobileOrTablet ? () => navigate(`orders`) : null}
                >
                  <div className="company-additional-card__top">
                    <div className="company-additional-card__icon">
                      <Icon icon="orders" size="20px" />
                    </div>
                    <div className="company-additional-card__text">
                      <span>
                        {companyInfo && companyInfo.type !== 'supplier'
                          ? companyInfo.ordersCount
                          : companyInfo.supplierOrdersCount}
                      </span>
                      <span>
                        <FormattedMessage
                          id="page.sa.companyDetail.orders"
                          defaultMessage="Orders"
                        />
                      </span>
                    </div>
                  </div>
                  <div className="company-additional-card__bottom">
                    <Button
                      text={intl.formatMessage({
                        id: 'btn.view',
                        defaultMessage: 'View',
                      })}
                      type={BUTTON_TYPE.OUTLINE}
                      size={BUTTON_SIZE.SMALL}
                      onClick={() => navigate(`orders?page=1&status=new`)}
                    />
                  </div>
                </div>
              </div>
              <div className="company-page__desc">
                <div className="company-card">
                  <div className="company-card__title">
                    <FormattedMessage
                      id="component.aboutCompanyCard.title"
                      defaultMessage="About Company"
                    />
                  </div>
                  <div className="company-card__content">
                    <div className="company-card__content-item">
                      <div className="company-card__content-name">
                        <FormattedMessage
                          id="company.orgNumber"
                          defaultMessage="Organization number"
                        />
                      </div>
                      <div className="company-card__content-desc">
                        {companyInfo.organisationNumber}
                      </div>
                    </div>
                    <div className="company-card__content-item">
                      <div className="company-card__content-name">
                        <FormattedMessage
                          id="company.address"
                          defaultMessage="Address"
                        />
                      </div>
                      <div className="company-card__content-desc">
                        {companyInfo.address}
                      </div>
                    </div>
                    <div className="company-card__content-item">
                      <div className="company-card__content-name">
                        <FormattedMessage
                          id="company.city"
                          defaultMessage="City"
                        />
                      </div>
                      <div className="company-card__content-desc">
                        {/* {zipCodeFormat(companyInfo.zipCode)}, {companyInfo.city} */}
                        {companyInfo.zipCode}, {companyInfo.city}
                      </div>
                    </div>
                    <div className="company-card__content-item">
                      <div className="company-card__content-name">
                        <FormattedMessage
                          id="company.contactPerson"
                          defaultMessage="Contact person"
                        />
                      </div>
                      <div className="company-card__content-desc">
                        {companyInfoPerson
                          ? companyInfoPerson.firstname +
                            ' ' +
                            companyInfoPerson.lastname
                          : ''}
                      </div>
                    </div>
                    <div className="company-card__content-item">
                      <div className="company-card__content-name">
                        <FormattedMessage
                          id="company.phone"
                          defaultMessage="Phone"
                        />
                      </div>
                      <div className="company-card__content-desc">
                        {companyInfoPerson && companyInfoPerson.phone}
                      </div>
                    </div>
                    <div className="company-card__content-item">
                      <div className="company-card__content-name">
                        <FormattedMessage
                          id="company.mail"
                          defaultMessage="Email"
                        />
                      </div>
                      <div className="company-card__content-desc">
                        {companyInfoPerson && companyInfoPerson.email}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

CompanyInfoPage.propTypes = {
  props: PropTypes.object,
};

export default CompanyInfoPage;
