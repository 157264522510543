/* eslint-disable no-unused-vars */
// import Button from '../../../components/ui/Button';
// import { BUTTON_SIZE } from '../../../components/ui/Button/Button';
import { useSearchParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import NewUserCard, {
  NEW_USER_CARD_TYPE,
} from '../../../../components/NewUserCard/NewUserCard';
import {
  getUsersCompany,
  getBannedUsersCompany,
} from '../../../../store/slice/usersCompanySlice';
import LoadedContent from '../../../../components/LoadedContent';
import UsersList from './UsersList';
import RoleCheck from '../../../../components/RoleCheck';
import { getProfile } from '../../../../store/slice/personalInfoSlice';
import { FormattedMessage, useIntl } from 'react-intl';

const UsersPage = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const isListLoading = useSelector(
    (state) => state.usersCompany.isListLoading
  );
  const usersList = useSelector((state) => state.usersCompany.data);
  const usersMeta = useSelector((state) => state.usersCompany.meta);

  const isListBannedLoading = useSelector(
    (state) => state.usersCompany.isListBannedLoading
  );
  const usersBannedList = useSelector((state) => state.usersCompany.dataBanned);
  const usersBannedMeta = useSelector((state) => state.usersCompany.metaBanned);

  const personalInfo = useSelector((state) => state.personalInfo.data);

  const [searchParams] = useSearchParams();
  const pageQuery = searchParams.get('page') || 1;
  const pageBannedQuery = searchParams.get('pageBanned') || 1;

  const fetchListBanned = async () => {
    dispatch(
      getBannedUsersCompany({
        id: personalInfo.companyId,
        query: { page: pageBannedQuery, status: 'banned' },
      })
    );
  };

  const fetchList = async (companyId, page) => {
    dispatch(
      getUsersCompany({
        id: companyId,
        query: { page: page, per_page: 10, status: 'active' },
      })
    );
  };

  useEffect(() => {
    if (personalInfo.companyId) {
      fetchList(personalInfo.companyId, pageQuery);
    } else {
      dispatch(getProfile())
        .unwrap()
        .then(() => {})
        .catch((err) => console.log(err));
    }
    document.title = intl.formatMessage({
      id: 'page.myCompany.users.title',
      defaultMessage: 'Users',
    });
  }, [pageQuery]);

  useEffect(() => {
    if (personalInfo.companyId) {
      fetchListBanned();
    }
  }, [pageBannedQuery]);

  useEffect(() => {}, [pageBannedQuery]);

  useEffect(() => {
    if (personalInfo.companyId) {
      fetchList(personalInfo.companyId, pageQuery);
      fetchListBanned();
    }
  }, [personalInfo]);

  return (
    <div className="admins-page">
      <div className="admins-page__inner">
        <div className="admins-page__add">
          <RoleCheck roles={['super admin']}>
            <div>
              <NewUserCard type={NEW_USER_CARD_TYPE.NEW_SUPER_ADMIN} />
            </div>
          </RoleCheck>
          <RoleCheck roles={['admin']}>
            <div>
              <NewUserCard type={NEW_USER_CARD_TYPE.NEW_ADMIN} />
              <NewUserCard type={NEW_USER_CARD_TYPE.NEW_PROJECT_LEAD} />
            </div>
          </RoleCheck>
        </div>
        <div className="admins-page__list" style={{ position: 'relative' }}>
          <span className="admins-page__list-title">
            <FormattedMessage
              id="page.myCompany.users.listTitle"
              defaultMessage="All Users"
            />
          </span>
          <LoadedContent loader={isListLoading} />
          <UsersList
            idCompany={personalInfo && personalInfo.companyId}
            currentUserId={personalInfo.id}
            currentPage={usersMeta.current_page}
            totalCount={usersMeta.last_page}
            isListLoading={isListLoading}
            list={usersList}
            itsActive={true}
            callbackAction={() => {
              fetchList(personalInfo.companyId, pageQuery);
              fetchListBanned();
            }}
            listBanned={usersBannedList}
          />

          <UsersList
            idCompany={personalInfo.companyId}
            currentPage={usersBannedMeta.current_page}
            totalCount={usersBannedMeta.last_page}
            isListLoading={isListLoading || isListBannedLoading}
            list={usersBannedList}
            itsBanned={true}
            callbackAction={() => {
              fetchList(personalInfo.companyId, pageQuery);
              fetchListBanned();
            }}
          />
        </div>
      </div>
    </div>
  );
};

UsersPage.defaultProps = {};

UsersPage.propTypes = {};

export default UsersPage;
