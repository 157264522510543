/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import {
  Routes,
  Route,
  useLocation,
  Navigate,
  useNavigate,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import NewPasswordPage from './pages/Auth/NewPassword/NewPasswordPage';
import ResetPasswordPage from './pages/Auth/ResetPassword/ResetPasswordPage';
import SignInPage from './pages/Auth/SignIn/SignInPage';
import { getProfile } from './store/slice/personalInfoSlice';
import AuthGuard from './components/AuthGuard';
import MyCompanySuperAdmin from './pages/SuperAdmin/MyCompany/MyCompany';
import MyCompanyAdmin from './pages/Admin/MyCompany/MyCompany';
import AllCompaniesPage from './pages/SuperAdmin/AllCompanies/AllCompaniesPage';
import CompanyInfoPage from './pages/SuperAdmin/CompanyInfo';
import AddCompanyPage from './pages/SuperAdmin/AddCompany/AddCompanyPage';
import EditCompanyPage from './pages/SuperAdmin/EditCompany';
import ProjectDetailPage from './pages/SuperAdmin/ProjectDetail';
import OrdersPage from './pages/SuperAdmin/OrdersCustomerSA/Orders';
import MyOrdersCustomerPage from './pages/Customer/Orders';

import ProjectHome from './pages/Admin/Projects/HomeProjects';
import ProjectHomeSuperAdmin from './pages/SuperAdmin/Projects/HomeProjects';
import UsersSuperAdmin from './pages/SuperAdmin/UsersInfo';
import OrderInfo from './pages/SuperAdmin/OrdersCustomerSA/OrderInfo';
import OrderInfoMobile from './pages/SuperAdmin/OrdersCustomerSA/OrderInfoMobile';

import OrdersSupplierSaPage from './pages/SuperAdmin/OrdersSupplierSA/OrdersSupplierSaPage';
import OrderInfoSupplierSa from './pages/SuperAdmin/OrdersSupplierSA/OrderInfo';
import OrderInfoMobileSupplierSa from './pages/SuperAdmin/OrdersSupplierSA/OrderInfoMobile';

import AddUserPage from './pages/Admin/MyCompany/Users/AddUser/AddUserPage';
import MyOrdersSupplierPage from './pages/Supplier/MyOrders';
import AddOrderPage from './pages/Admin/MyOrdersCustomer/AddOrder';
import User from './pages/SuperAdmin/MyCompany/User';
import UserCompany from './pages/SuperAdmin/UsersInfo/User';
import EditOrderPage from './pages/Admin/MyOrdersCustomer/EditOrder/EditOrderPage';
import OrderDetailCustomer from './pages/Admin/MyOrdersCustomer/OrderDetail/OrderDetailCustomer';
import OrderInfoMobileCustomer from './pages/Admin/MyOrdersCustomer/OrderInfoMobile';
import Analytics from './pages/Admin/Analytics';
import ProjectDetailCustomerPage from './pages/Admin/Projects/ProjectDetail/ProjectDetailCustomerPage';
import AddProject from './pages/Admin/Projects/AddProject';
import EditProject from './pages/Admin/Projects/EditProject';
import { getCompany } from './api/company';
import OrderInfoSupplier from './pages/Supplier/OrderInfo';
import SupplierCompanyInfoPage from './pages/Admin/SupplierCompanyInfo/SupplierCompanyInfoPage';
import { getNotificationsUnread } from './store/slice/notificationSlice';
import { LangContext } from './provider/Lang';
import { getOrderDictionary } from './store/slice/dictionarySlice';

// TODO: MOCK DATA
export const ROLES = {
  SUPER_ADMIN: 'super admin',
  CUSTOMER: 'customer admin',
  SUPPLIER: 'supplier admin',

  ADMIN: 'admin',
  PROJECT_LEADER: 'project_leader',
};

const App = () => {
  const auth = useSelector((state) => state.auth.isAuthorized);
  // const roleState = useSelectofar((state) => state.personalInfo.data.role);
  // const role = localStorage.getItem('role');
  const lang = localStorage.getItem('lang');
  if (!lang) {
    localStorage.setItem('lang', 'swe');
  }
  const [role, setRole] = useState(localStorage.getItem('role'));
  const [companyType, setCompanyType] = useState(
    localStorage.getItem('companyType')
  );
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const context = useContext(LangContext);

  // const isMobileOrTablet = useMediaQuery({
  //   query: '(max-width: 768px) ',
  // });

  useEffect(() => {
    // console.log(role);
    setRole(localStorage.getItem('role'));

    if (
      role &&
      localStorage.getItem('companyId') &&
      !localStorage.getItem('companyType')
    ) {
      getCompany(localStorage.getItem('companyId'))
        .then((res) => {
          if (res && res.data && res.data.data) {
            localStorage.setItem('companyType', res.data.data.type);
            localStorage.setItem('companySpec', res.data.data.spec);
          }
        })
        .catch(() => {});
    }
  }, [role]);

  useEffect(() => {
    if (!companyType && localStorage.getItem('companyType')) {
      setCompanyType(localStorage.getItem('companyType'));
    }
  }, [localStorage]);

  useEffect(() => {
    if (role === null && auth) {
      dispatch(getProfile());
    }
  }, []);

  useEffect(() => {
    if (auth) {
      dispatch(getOrderDictionary());
    }
  }, [localStorage, context.locale]);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
      if (document.querySelector('.page-layout__content')) {
        document.querySelector('.page-layout__content').scrollTop = 0;
      }
    }, 300);

    if (auth) {
      dispatch(getNotificationsUnread());
    }

    if (auth && location.pathname === '/' && role === 'super admin') {
      navigate('/analytics');
    }
    if (
      auth &&
      location.pathname === '/' &&
      role === 'admin' &&
      localStorage.getItem('companyType') === 'supplier'
    ) {
      navigate('/supplier/my-orders');
    }
    if (
      auth &&
      location.pathname === '/' &&
      role === 'admin' &&
      localStorage.getItem('companyType') === 'customer'
    ) {
      navigate('/customer/my-orders');
    }
  }, [location]);

  // TODO: FIX IOS ONLY. UNCOMMENT IF NECESSARY.
  // useEffect(() => {
  //   if (
  //     isMobileOrTablet &&
  //     /iPad|iPhone|iPod/.test(navigator.userAgent) &&
  //     !window.MSStream
  //   ) {
  //     window.document.body.classList.add('ios-mobile-fix-autozoom');
  //   } else {
  //     window.document.body.classList.remove('ios-mobile-fix-autozoom');
  //   }
  // }, [isMobileOrTablet]);

  return (
    <Routes>
      <Route path="/">
        <Route path="sign-in" element={<SignInPage />} />
        <Route path="forgot-password" element={<ResetPasswordPage />} />
        <Route path="reset-password" element={<NewPasswordPage />} />
        <Route
          path="invite-admin"
          element={<NewPasswordPage inviteUser={true} />}
        />
        <Route
          path="invite-project-lead"
          element={<NewPasswordPage inviteUser={true} />}
        />

        {!auth && (
          <Route
            index
            element={
              <Navigate to="sign-in" state={{ from: location }} replace />
            }
          />
        )}

        <Route element={<AuthGuard allowedRoles={[ROLES.SUPER_ADMIN]} />}>
          <Route path="analytics" element={<Analytics />} />

          <Route path="all-companies">
            <Route
              index
              element={
                <Navigate to="customers" state={{ from: location }} replace />
              }
            />
            {/* TODO: WARNING! 
                All routes that are tab scoped must be nested as shown below. 
                To get the tab name from the URL, use the resolveTabPath function */}
            <Route path="customers">
              <Route index element={<AllCompaniesPage activeDefault={1} />} />
              <Route path="add-company" element={<AddCompanyPage />} />
              <Route path="edit-company/:id" element={<EditCompanyPage />} />
              <Route path="company/:id" element={<CompanyInfoPage />} />
              <Route
                path="company/:id_company/users"
                element={<UsersSuperAdmin />}
              />
              <Route
                path="company/:id_company/user/:id_user"
                element={<UserCompany />}
              />
              <Route
                path="company/:id_company/projects"
                element={<ProjectHomeSuperAdmin />}
              />
              <Route
                path="company/:id_company/projects/:id_project"
                element={<ProjectDetailPage />}
              />
              {/* TODO:REDIRECT FROM PROJECTS LIST => PROJECT DETAIL PAGE => ORDER DETAIL */}
              <Route
                path="company/:id_company/projects/:id_project/orders/:id_order/order"
                element={<OrderInfo />}
              />
              <Route
                path="company/:id_company/projects/:id_project/orders/:id_order/order/detail-information"
                element={<OrderInfoMobile />}
              />

              <Route
                path="company/:id_company/orders"
                element={<OrdersPage activeDefault={1} />}
              />
              {/* TODO:REDIRECT FROM ORDERS LIST => ORDER DETAIL */}
              <Route
                path="company/:id_company/orders/projects/:id_project/order/:id_order"
                element={<OrderInfo />}
              />
              <Route
                path="company/:id_company/orders/projects/:id_project/order/:id_order/detail-information"
                element={<OrderInfoMobile />}
              />
            </Route>

            {/* TODO: WARNING! 
                All routes that are tab scoped must be nested as shown below. 
                To get the tab name from the URL, use the resolveTabPath function */}
            <Route path="supplier">
              <Route index element={<AllCompaniesPage activeDefault={2} />} />
              <Route path="add-company" element={<AddCompanyPage />} />
              <Route path="edit-company/:id" element={<EditCompanyPage />} />
              <Route path="company/:id" element={<CompanyInfoPage />} />
              <Route
                path="company/:id_company/users"
                element={<UsersSuperAdmin />}
              />
              <Route
                path="company/:id_company/user/:id_user"
                element={<UserCompany />}
              />
              <Route
                path="company/:id_company/projects"
                element={<ProjectHomeSuperAdmin />}
              />
              <Route
                path="company/:id_company/projects/:id_project"
                element={<ProjectDetailPage />}
              />
              <Route
                path="company/:id_company/orders"
                element={<OrdersSupplierSaPage activeDefault={1} />}
              />
              <Route
                path="company/:id_company/orders/order/:id_order"
                element={<OrderInfoSupplierSa />}
              />
              <Route
                path="company/:id_company/orders/order/:id_order/detail-information"
                element={<OrderInfoMobileSupplierSa />}
              />
            </Route>

            {/* TODO: WARNING! 
                All routes that are tab scoped must be nested as shown below. 
                To get the tab name from the URL, use the resolveTabPath function */}
            <Route path="providers">
              <Route index element={<AllCompaniesPage activeDefault={3} />} />
              <Route path="add-company" element={<AddCompanyPage />} />
              <Route path="edit-company/:id" element={<EditCompanyPage />} />
              <Route path="company/:id" element={<CompanyInfoPage />} />
              <Route
                path="company/:id_company/users"
                element={<UsersSuperAdmin />}
              />
              <Route
                path="company/:id_company/user/:id_user"
                element={<UserCompany />}
              />
              <Route
                path="company/:id_company/projects"
                element={<ProjectHomeSuperAdmin />}
              />
              <Route
                path="company/:id_company/projects/:id_project"
                element={<ProjectDetailPage />}
              />
              <Route
                path="company/:id_company/orders"
                element={<OrdersSupplierSaPage activeDefault={1} />}
              />
              <Route
                path="company/:id_company/orders/order/:id_order"
                element={<OrderInfoSupplierSa />}
              />
              <Route
                path="company/:id_company/orders/order/:id_order/detail-information"
                element={<OrderInfoMobileSupplierSa />}
              />
            </Route>

            {/* TODO: WARNING! 
                All routes that are tab scoped must be nested as shown below. 
                To get the tab name from the URL, use the resolveTabPath function */}
            <Route path="quality">
              <Route index element={<AllCompaniesPage activeDefault={4} />} />
              <Route path="add-company" element={<AddCompanyPage />} />
              <Route path="edit-company/:id" element={<EditCompanyPage />} />
              <Route path="company/:id" element={<CompanyInfoPage />} />
              <Route
                path="company/:id_company/users"
                element={<UsersSuperAdmin />}
              />
              <Route
                path="company/:id_company/user/:id_user"
                element={<UserCompany />}
              />
              <Route
                path="company/:id_company/projects"
                element={<ProjectHomeSuperAdmin />}
              />
              <Route
                path="company/:id_company/projects/:id_project"
                element={<ProjectDetailPage />}
              />
              <Route
                path="company/:id_company/orders"
                element={<OrdersSupplierSaPage activeDefault={1} />}
              />
              <Route
                path="company/:id_company/orders/order/:id_order"
                element={<OrderInfoSupplierSa />}
              />
              <Route
                path="company/:id_company/orders/order/:id_order/detail-information"
                element={<OrderInfoMobileSupplierSa />}
              />
            </Route>
          </Route>

          <Route path="my-account">
            <Route
              path="personal-info"
              element={<MyCompanySuperAdmin activeDefault={1} />}
            />
            <Route
              path="password"
              element={<MyCompanySuperAdmin activeDefault={2} />}
            />
            <Route
              path="users"
              element={<MyCompanySuperAdmin activeDefault={3} />}
            />
            <Route path="user" element={<User />} />
            <Route path="user/:id_user" element={<User />} />
          </Route>
        </Route>

        <Route
          element={
            <AuthGuard allowedRoles={[ROLES.ADMIN, ROLES.PROJECT_LEADER]} />
          }
        >
          <Route path="supplier/my-orders">
            <Route index element={<MyOrdersSupplierPage activeDefault={1} />} />
            <Route path="order/:id_order" element={<OrderInfoSupplier />} />
            <Route
              path="company/:id_company"
              element={<SupplierCompanyInfoPage />}
            />
          </Route>

          <Route path="customer/my-orders">
            <Route index element={<MyOrdersCustomerPage activeDefault={1} />} />
            <Route path="order/new-order" element={<AddOrderPage />} />
            <Route path="order/:id_order/edit" element={<EditOrderPage />} />
            <Route
              path="info/project/:id_project/order/:id_order"
              element={<OrderDetailCustomer />}
            />
            <Route
              path="info/project/:id_project/order/:id_order/detail-information"
              element={<OrderInfoMobileCustomer />}
            />
            <Route
              path="company/:id_company"
              element={<SupplierCompanyInfoPage />}
            />
          </Route>

          <Route path="my-projects">
            <Route index element={<ProjectHome />} />
            <Route path=":id_project" element={<ProjectDetailCustomerPage />} />
            <Route path="add-project" element={<AddProject />} />
            <Route path="edit-project/:id_project" element={<EditProject />} />
          </Route>

          <Route path="my-company">
            <Route
              path="company-info"
              element={<MyCompanyAdmin activeDefault={1} />}
            />
            <Route
              path="personal-info"
              element={<MyCompanyAdmin activeDefault={2} />}
            />
            <Route
              path="password"
              element={<MyCompanyAdmin activeDefault={3} />}
            />
            <Route
              path="admins"
              element={<MyCompanyAdmin activeDefault={4} />}
            />
            <Route
              path="admins/new-admin"
              element={<AddUserPage role={ROLES.ADMIN} />}
            />
            <Route
              path="admins/new-lead"
              element={<AddUserPage role={ROLES.PROJECT_LEADER} />}
            />
            <Route
              path="admins/company/:id_company/user/:id"
              element={<AddUserPage editPage={true} />}
            />
          </Route>
        </Route>
        <Route
          path="*"
          element={
            // TODO: OR DISPLAY ERROR PAGE
            // <PageLayout roles={[role]}>
            //   <ErrorPage />
            // </PageLayout>
            <Navigate to="/" state={{ from: location }} replace />
          }
        />
      </Route>
    </Routes>
  );
};

export default App;
