import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';

const TimePickerControls = ({
  houres,
  min,
  setHoures,
  setMin,
  formatValue,
  hhRef,
  mmRef,
}) => {
  return (
    <div className="timepicker__controls">
      <div className="timepicker__control">
        <div
          className="timepicker__control-left"
          tabIndex="0"
          id="timepicker-hh-dec"
          onKeyUp={(e) => {
            const code = e.keyCode ? e.keyCode : e.which;
            if (code !== 13) {
              return;
            }
            if (houres > 0) {
              setHoures(houres - 1);
            }
          }}
          onClick={() => {
            if (houres > 0) {
              setHoures(houres - 1);
            }
          }}
        >
          <Icon icon="chevron-left" size="16px" />
        </div>
        <div className="timepicker__control-input">
          <input
            id="timepicker-hh-input"
            className="control-input"
            type="text"
            inputMode="decimal"
            name="houres"
            defaultValue={''}
            ref={hhRef}
            readOnly
            value={formatValue(houres)}
            tabIndex="-1"
          />
        </div>
        <div
          className="timepicker__control-right"
          tabIndex="0"
          id="timepicker-hh-inc"
          onKeyUp={(e) => {
            const code = e.keyCode ? e.keyCode : e.which;
            if (code !== 13) {
              return;
            }
            if (houres < 23) {
              setHoures(houres + 1);
            }
          }}
          onClick={() => {
            if (houres < 23) {
              setHoures(houres + 1);
            }
          }}
        >
          <Icon icon="chevron-right" size="16px" />
        </div>
      </div>
      <div className="timepicker__control">
        <div
          className="timepicker__control-left"
          tabIndex="0"
          id="timepicker-mm-dec"
          onKeyUp={(e) => {
            const code = e.keyCode ? e.keyCode : e.which;
            if (code !== 13) {
              return;
            }
            if (min > 0) {
              setMin(min - 1);
            }
          }}
          onClick={() => {
            if (min > 0) {
              setMin(min - 1);
            }
          }}
        >
          <Icon icon="chevron-left" size="16px" />
        </div>
        <div className="timepicker__control-input">
          <input
            id="timepicker-mm-input"
            className="control-input"
            type="text"
            inputMode="decimal"
            name="minutes"
            tabIndex="-1"
            readOnly
            defaultValue={''}
            value={formatValue(min)}
            ref={mmRef}
          />
        </div>
        <div
          className="timepicker__control-right"
          tabIndex="0"
          id="timepicker-mm-inc"
          onKeyUp={(e) => {
            const code = e.keyCode ? e.keyCode : e.which;
            if (code !== 13) {
              return;
            }
            if (min < 59) {
              setMin(min + 1);
            }
          }}
          onClick={() => {
            if (min < 59) {
              setMin(min + 1);
            }
          }}
        >
          <Icon icon="chevron-right" size="16px" />
        </div>
      </div>
    </div>
  );
};

TimePickerControls.propTypes = {
  houres: PropTypes.number,
  min: PropTypes.number,
  setHoures: PropTypes.func,
  setMin: PropTypes.func,
  formatValue: PropTypes.func,
  hhRef: PropTypes.func,
  mmRef: PropTypes.func,
};

export default TimePickerControls;
