/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { AlertMessengerContext } from '../../../../provider/Alert';
import { validationErrorParser } from '../../../../utils/validations.error.parser';
import LoadedContent from '../../../../components/LoadedContent';
import StepsComponent, {
  NextStepButton,
  StepPage,
} from '../../../../components/ui/Steps/StepsComponent';
import FormInput from '../../../../components/ui/FormInput/FormInput';
import FormInputMask from '../../../../components/ui/FormInputMask';
import Button from '../../../../components/ui/Button';
import {
  BUTTON_SIZE,
  BUTTON_TYPE,
} from '../../../../components/ui/Button/Button';
import FormTextArea from '../../../../components/ui/FormTextArea';
import Icon from '../../../../components/ui/Icon';
import ConfirmPopup from '../../../../components/ConfirmPopup/ConfirmPopup';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { projectCreate } from '../../../../api/project';
import { FormattedMessage, useIntl } from 'react-intl';

const phoneRegExp = /(^[+]46\d{6,11}$)|(^[+]46\s\d{6,11}$)/;

const AddProject = () => {
  const [open, setOpen] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [cancelPopupOpen, setCancelPopupOpen] = useState(false);

  const [successMsg, setSuccessMsg] = useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);

  const [step, setStep] = useState(0);
  const [searchParams] = useSearchParams();
  const redirect = searchParams.get('redirect') || false;
  const orderId = searchParams.get('orderId') || null;
  const navigate = useNavigate();
  const intl = useIntl();

  const steps = [
    intl.formatMessage({
      id: 'page.addProject.step.1',
      defaultMessage: 'Project details',
    }),
    intl.formatMessage({
      id: 'page.addProject.step.2',
      defaultMessage: 'Contact person',
    }),
  ];

  const { addErrorMessage } = useContext(AlertMessengerContext);

  const schema = yup.object().shape({
    name: yup
      .string()
      .required(
        intl.formatMessage({
          id: 'input.error.requiredMsg',
          defaultMessage: 'Field is required',
        })
      )
      .min(
        3,
        intl.formatMessage({
          id: 'input.error.projectName',
          defaultMessage: 'Project name must be at least 3 characters',
        })
      ),
    address: yup.string().required(
      intl.formatMessage({
        id: 'input.error.requiredMsg',
        defaultMessage: 'Field is required',
      })
    ),
    zipCode: yup
      .string()
      .required(
        intl.formatMessage({
          id: 'input.error.requiredMsg',
          defaultMessage: 'Field is required',
        })
      )
      .matches(
        /^\d{3}\s\d{2}$/,
        intl.formatMessage({
          id: 'input.error.incorrectZip',
          defaultMessage: 'Incorrect Zip Code',
        })
      ),
    city: yup.string().required(
      intl.formatMessage({
        id: 'input.error.requiredMsg',
        defaultMessage: 'Field is required',
      })
    ),
    contactPerson: yup
      .string()
      .required(
        intl.formatMessage({
          id: 'input.error.requiredMsg',
          defaultMessage: 'Field is required',
        })
      )
      .min(
        3,
        intl.formatMessage({
          id: 'input.error.minChar',
          defaultMessage: 'Name must be at least 3 characters',
        })
      ),
    phone: yup
      .string()
      .required(
        intl.formatMessage({
          id: 'input.error.requiredMsg',
          defaultMessage: 'Field is required',
        })
      )
      .matches(
        phoneRegExp,
        intl.formatMessage({
          id: 'input.error.incorrectPhone',
          defaultMessage: 'Phone number is not valid',
        })
      ),
    email: yup
      .string()
      .required(
        intl.formatMessage({
          id: 'input.error.requiredMsg',
          defaultMessage: 'Field is required',
        })
      )
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, 'Incorrect E-mail address'),
  });

  const {
    control,
    getValues,
    trigger,
    handleSubmit,
    setError,
    clearErrors,
    formState,
  } = useForm({
    reValidateMode: 'onChange',
    mode: 'onTouched',
    defaultValues: {
      name: '',
      marking: '',
      address: '',
      zipCode: '',
      city: '',
      comment: '',
      contactPerson: '',
      phone: '',
      email: '',
    },
    resolver: yupResolver(schema),
  });
  const { errors } = formState;

  const toStepTwo = async () => {
    const res = await trigger(['name', 'address', 'zipCode', 'city']);

    if (res) {
      clearErrors();
      setStep(step + 1);
    }
  };

  const onNextStepLinkClick = async (index) => {
    const res = await trigger(['name', 'address', 'zipCode', 'city']);

    if (res) {
      clearErrors();
      setStep(index);
    }
  };

  const onSubmit = async () => {
    setOpen(true);
  };

  const sendData = () => {
    setOpen(false);
    const val = getValues();
    const phone = val.phone.replace(/[()_\s-]/g, '');
    const id = uuidv4();
    const name = val.name;
    const postData = {
      id,
      ...val,
      phone,
    };
    setIsSubmiting(true);
    projectCreate(postData)
      .then(() => {
        setIsSubmiting(false);
        if (redirect && orderId) {
          navigate(
            `/customer/my-orders/order/${orderId}/edit?projectId=${id}&name=${name}`
          );
        } else if (redirect) {
          navigate(
            `/customer/my-orders/order/new-order?projectId=${id}&name=${name}`
          );
        } else {
          setSuccessMsg(true);
        }
      })
      .catch((err) => {
        setErrors(err);
        setIsSubmiting(false);
      });
  };

  const setErrors = (err) => {
    if (err && err.errors) {
      const parsed = validationErrorParser(err.errors);
      let errorStr = '';

      for (const key of Object.keys(parsed)) {
        setError(key, { type: 'custom', message: parsed[key] });
        errorStr += `${key}: ${parsed[key]} `;
      }

      addErrorMessage({
        message: errorStr,
      });

      return;
    }

    addErrorMessage({
      message: err && err.message ? err.message : err,
    });
  };

  useEffect(() => {
    document.title = intl.formatMessage({
      id: 'page.addProject.title',
      defaultMessage: 'New Project',
    });
  }, []);

  return (
    <div className="company-page add-project">
      <LoadedContent loader={isSubmiting} />
      {!successMsg && (
        <div className="company-page__inner">
          <div className="company-page__header">
            <span className="company-page__header-title">
              <FormattedMessage
                id="page.addProject.title_2"
                defaultMessage="Create Project"
              />
            </span>
          </div>
          <div className="company-page__body">
            <div className="new-company__steps">
              <form onSubmit={handleSubmit(onSubmit)}>
                <StepsComponent
                  steps={steps}
                  activeStep={step}
                  clickOnPrevStep={(index) => {
                    setStep(index);
                  }}
                  clickOnNextStep={(index) => {
                    onNextStepLinkClick(index);
                  }}
                >
                  <StepPage>
                    <div className="col-2">
                      <FormInput
                        control={control}
                        label={intl.formatMessage({
                          id: 'input.label.projectName',
                          defaultMessage: 'Project name',
                        })}
                        name="name"
                        id="name"
                        type="text"
                        errors={errors.name}
                        placeholder={intl.formatMessage({
                          id: 'input.placeholder.projectName',
                          defaultMessage: 'Enter project name',
                        })}
                        disabled={isSubmiting}
                        rules={{
                          required: 'Field is required',
                        }}
                      />
                    </div>
                    <div className="col-2">
                      <FormInput
                        control={control}
                        label={intl.formatMessage({
                          id: 'input.label.marking',
                          defaultMessage: 'Marking',
                        })}
                        name="marking"
                        id="marking"
                        type="text"
                        // errors={errors.marking}
                        placeholder={intl.formatMessage({
                          id: 'input.placeholder.marking',
                          defaultMessage: 'Enter marking',
                        })}
                        disabled={isSubmiting}
                        // rules={{
                        //   required: 'Field is required',
                        // }}
                      />
                    </div>
                    <div className="col-4">
                      <FormInput
                        control={control}
                        label={intl.formatMessage({
                          id: 'input.label.address',
                          defaultMessage: 'Address',
                        })}
                        name="address"
                        id="address"
                        type="text"
                        errors={errors.address}
                        placeholder={intl.formatMessage({
                          id: 'input.placeholder.addressPlaceholder',
                          defaultMessage: 'Enter address',
                        })}
                        disabled={isSubmiting}
                        rules={{
                          required: 'Field is required',
                        }}
                      />
                    </div>
                    <div className="col-2">
                      <FormInputMask
                        control={control}
                        label={intl.formatMessage({
                          id: 'input.label.zipCode',
                          defaultMessage: 'ZIP code',
                        })}
                        name="zipCode"
                        id="zipCode"
                        type="tel"
                        inputMode="tel"
                        errors={errors.zipCode}
                        placeholder={intl.formatMessage({
                          id: 'input.placeholder.zipPlaceholder',
                          defaultMessage: 'Type ZIP code',
                        })}
                        disabled={isSubmiting}
                        rules={{
                          required: 'Field is required',
                        }}
                        maskChar="_"
                        mask="999 99"
                      />
                    </div>
                    <div className="col-2">
                      <FormInput
                        control={control}
                        label={intl.formatMessage({
                          id: 'input.label.city',
                          defaultMessage: 'City',
                        })}
                        name="city"
                        id="city"
                        type="text"
                        errors={errors.city}
                        placeholder={intl.formatMessage({
                          id: 'input.placeholder.cityPlaceholder',
                          defaultMessage: 'Enter city',
                        })}
                        disabled={isSubmiting}
                        rules={{
                          required: 'Field is required',
                        }}
                      />
                    </div>
                    <div className="col-4">
                      <FormTextArea
                        control={control}
                        label={intl.formatMessage({
                          id: 'input.label.comment',
                          defaultMessage: 'Comment',
                        })}
                        name="comment"
                        id="comment"
                        type="text"
                        placeholder={intl.formatMessage({
                          id: 'input.placeholder.projectDetails',
                          defaultMessage: 'Project details',
                        })}
                        disabled={isSubmiting}
                      />
                    </div>

                    <NextStepButton>
                      <Button
                        disabled={isSubmiting}
                        text={intl.formatMessage({
                          id: 'steps.btn.cancel',
                          defaultMessage: 'Cancel',
                        })}
                        type={BUTTON_TYPE.OUTLINE}
                        size={BUTTON_SIZE.MEDIUM}
                        onClick={(e) => {
                          e.preventDefault();
                          e.target.blur();
                          setCancelPopupOpen(true);
                        }}
                      />
                      <Button
                        size={BUTTON_SIZE.MEDIUM}
                        text={intl.formatMessage({
                          id: 'steps.btn.nextStep',
                          defaultMessage: 'Next Step',
                        })}
                        onClick={() => {
                          toStepTwo();
                        }}
                      />
                    </NextStepButton>
                  </StepPage>

                  <StepPage>
                    <div className="col-2">
                      <FormInput
                        control={control}
                        label={intl.formatMessage({
                          id: 'input.label.contactPerson',
                          defaultMessage: 'Contact person',
                        })}
                        name="contactPerson"
                        id="contactPerson"
                        type="text"
                        errors={errors.contactPerson}
                        placeholder={intl.formatMessage({
                          id: 'input.placeholder.contactPerson',
                          defaultMessage: 'Enter name',
                        })}
                        disabled={isSubmiting}
                        rules={{
                          required: 'Field is required',
                        }}
                      />
                    </div>
                    <div className="col-2">
                      <FormInputMask
                        control={control}
                        label={intl.formatMessage({
                          id: 'input.label.phone',
                          defaultMessage: 'Phone',
                        })}
                        name="phone"
                        id="phone"
                        type="tel"
                        inputMode="tel"
                        errors={errors.phone}
                        placeholder="+46 "
                        disabled={isSubmiting}
                        rules={{
                          required: 'Field is required',
                        }}
                        maskChar=""
                        mask="+46 99999999999"
                      />
                    </div>
                    <div className="col-4">
                      <FormInput
                        control={control}
                        label={intl.formatMessage({
                          id: 'input.label.mail',
                          defaultMessage: 'E-mail',
                        })}
                        name="email"
                        id="email"
                        type="email"
                        errors={errors.email}
                        placeholder={intl.formatMessage({
                          id: 'input.placeholder.mail',
                          defaultMessage: 'Enter email',
                        })}
                        disabled={isSubmiting}
                        rules={{
                          required: 'Field is required',
                        }}
                      />
                    </div>

                    <NextStepButton>
                      <Button
                        disabled={isSubmiting}
                        text={intl.formatMessage({
                          id: 'btn.cancel',
                          defaultMessage: 'Cancel',
                        })}
                        type={BUTTON_TYPE.OUTLINE}
                        size={BUTTON_SIZE.MEDIUM}
                        onClick={(e) => {
                          e.preventDefault();
                          e.target.blur();
                          setCancelPopupOpen(true);
                        }}
                      />
                      <Button
                        size={BUTTON_SIZE.MEDIUM}
                        text={intl.formatMessage({
                          id: 'btn.create',
                          defaultMessage: 'Create',
                        })}
                        onClick={handleSubmit(sendData)}
                      />
                    </NextStepButton>
                  </StepPage>
                </StepsComponent>
              </form>
            </div>
          </div>
        </div>
      )}
      <ConfirmPopup
        open={open}
        text={intl.formatMessage({
          id: 'popup.project.createMsg',
          defaultMessage: `Are you sure that you want to create the project?`,
        })}
        confirmButtonText={intl.formatMessage({
          id: 'popup.btn.create',
          defaultMessage: 'Yes, create',
        })}
        onSubmit={sendData}
        onCloseModal={() => setOpen(false)}
      />
      <ConfirmPopup
        open={cancelPopupOpen}
        text={intl.formatMessage({
          id: 'popup.project.cancelMsg',
          defaultMessage: `Are you sure that you want to cancel creating the project?`,
        })}
        confirmButtonText={intl.formatMessage({
          id: 'popup.cancel.ok',
          defaultMessage: 'Yes, cancel',
        })}
        cancelButtonText={intl.formatMessage({
          id: 'popup.cancel.no',
          defaultMessage: 'No, continue',
        })}
        onSubmit={() => {
          if (redirect && orderId) {
            navigate(`/customer/my-orders/order/${orderId}/edit`);
          } else if (redirect) {
            navigate(`/customer/my-orders/order/new-order`);
          } else {
            navigate(`/my-projects`);
          }
        }}
        onCloseModal={() => setCancelPopupOpen(false)}
      />
      {successMsg && (
        <div className="add-project__success">
          <Icon icon="check-big" size="48px" />
          <span>
            <FormattedMessage
              id="page.addProject.successMsg"
              defaultMessage="Your project has been created!"
            />
          </span>
          <div className="add-project__success-btn">
            <Button
              size={BUTTON_SIZE.MEDIUM}
              text={intl.formatMessage({
                id: 'page.order.create.title',
                defaultMessage: 'Create Order',
              })}
              onClick={() => {
                navigate('/customer/my-orders/order/new-order');
              }}
            />
            <Button
              disabled={isSubmiting}
              text={intl.formatMessage({
                id: 'page.projects.title',
                defaultMessage: 'My Projects',
              })}
              type={BUTTON_TYPE.OUTLINE}
              size={BUTTON_SIZE.MEDIUM}
              onClick={(e) => {
                navigate('/my-projects');
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

AddProject.propTypes = {
  props: PropTypes.object,
};

export default AddProject;
