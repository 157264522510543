/* eslint-disable prefer-regex-literals */
/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';

/* eslint-disable no-unused-vars */
import { useNavigate } from 'react-router-dom';
import Accordion from '../../../../../components/Accordion';
import { get } from 'lodash';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { localizeOrderInfo } from '../../../../../utils/localizeOrderInfo';

const InfoAccordion = ({ orderProject }) => {
  const navigate = useNavigate();
  const intl = useIntl();

  const localeMsg = (key, defVal) =>
    intl.formatMessage({
      id: `order.${key}`,
      defaultMessage: defVal,
    });

  const isDictionaryLoading = useSelector(
    (state) => state.dictionary.isDictionaryLoading
  );
  const dictionary = useSelector((state) => state.dictionary.mappedDictionary);

  const contentRowsAccordion = {
    1: {
      title: localeMsg('project', 'Project'),
      row: [
        {
          text: localeMsg('project.name', 'Project name'),
          id: 'project.name',
          link: true,
          id_project: true,
        },
        {
          text: localeMsg('project.marking', 'Marking'),
          id: 'project.marking',
        },
        {
          text: localeMsg('project.address', 'Adress'),
          id: 'project.address',
        },
        {
          text: localeMsg('project.zipCode', 'ZIP'),
          id: 'project.zipCode',
          regexp: { first: /(.{3})/, second: '$1 ' },
        },
        {
          text: localeMsg('project.city', 'City'),
          id: 'project.city',
        },
        {
          text: localeMsg('project.comment', 'Comment'),
          id: 'project.comment',
        },
      ],
    },
    2: {
      title: localeMsg('step.1', 'Project info'),
      row: [
        {
          text: localeMsg('dateOfExecution', 'Date of execution'),
          id: 'dateOfExecution',
          itsFullDate: true,
        },
        {
          text: localeMsg('contactPerson', 'Contact person'),
          id: 'project.contactPerson',
        },
        {
          text: localeMsg('phone', 'Phone'),
          id: 'project.phone',
          link: true,
          phone: true,
        },
        {
          text: localeMsg('email', 'Email'),
          id: 'project.email',
          link: true,
          email: true,
        },
        {
          text: localeMsg('comment', 'Comment'),
          id: 'orderDetailFirstComment',
        },
      ],
    },
    3: {
      title: localeMsg('step.2', 'Area/Volym'),
      row: [
        {
          text: localeMsg('location', 'Läge'),
          id: 'location',
        },
        {
          text: localeMsg('pump', 'Pump'),
          id: 'pump',
        },
        {
          text: localeMsg('surfaceType', 'Yttyp'),
          id: 'surfaceType',
        },
        {
          text: localeMsg('area', 'Area, m²'),
          id: 'area',
        },
        {
          text: localeMsg('volume', 'Volume, m³'),
          id: 'volume',
        },
        {
          text: localeMsg('slopeOrFall', 'Lutning/fall'),
          id: 'slopeOrFall',
        },
        {
          text: localeMsg('comment', 'Comment'),
          id: 'orderDetailSecondComment',
        },
      ],
    },
    4: {
      title: localeMsg('step.3', 'Concrete'),
      row: [
        {
          text: localeMsg('variety', 'Sort'),
          id: 'variety',
        },
        {
          text: localeMsg('exposureClass', 'Exposure class'),
          id: 'exposureClass',
        },
        {
          text: localeMsg('vct', 'Vct'),
          id: 'vct',
        },
        {
          text: localeMsg('dmax', 'Dmax'),
          id: 'dmax',
        },
        {
          text: localeMsg('seatDimensions', 'Sättmått'),
          id: 'seatDimensions',
        },
        {
          text: localeMsg('additive', 'Tillsats'),
          id: 'additive',
        },
        {
          text: localeMsg('cementVariety', 'Cementsort'),
          id: 'cementVariety',
        },
        {
          text: localeMsg('heat', 'Värme'),
          id: 'heat',
        },
        {
          text: localeMsg('comment', 'Comment'),
          id: 'orderDetailSecondComment',
        },
      ],
    },
    5: {
      title: localeMsg('step.4', 'Time'),
      row: [
        {
          text: localeMsg('timeOfStart', 'Start time'),
          id: 'timeOfStart',
        },
        {
          text: localeMsg('interval', 'Interval, min'),
          id: 'interval',
        },
        {
          text: localeMsg('comment', 'Comment'),
          id: 'orderDetailThirdComment',
        },
      ],
    },
    6: {
      title: localeMsg('step.5', 'Control'),
      row: [
        {
          text: localeMsg('orderClass', 'Class'),
          id: 'orderClass',
        },
        {
          text: localeMsg('coverage', 'Coverage'),
          id: 'coverage',
        },
        {
          text: localeMsg('comment', 'Comment'),
          id: 'orderDetailFourthComment',
        },
      ],
    },
  };

  const localize = (item) => {
    if (!isDictionaryLoading && dictionary) {
      const localize = localizeOrderInfo(
        item.id,
        get(orderProject, item.id),
        dictionary
      );

      return localize || get(orderProject, item.id);
    }

    return get(orderProject, item.id);
  };
  return (
    <>
      {Object.keys(contentRowsAccordion).map((item) => {
        return (
          <Accordion key={uuidv4()} title={contentRowsAccordion[item].title}>
            {contentRowsAccordion[item].row.map((item) => {
              if (!get(orderProject, item.id)) {
                return '';
              }
              return (
                <div key={item.id} className="row">
                  <div className={'row-title'}>{item.text}</div>
                  <div
                    onClick={() => {
                      if (item.link) {
                        if (item.id_project) {
                          navigate(
                            `/my-projects/${get(orderProject, 'project.id')}`
                          );
                        } else if (item.phone) {
                          document.location.href = `tel:${get(
                            orderProject,
                            item.id
                          )}`;
                        } else if (item.email) {
                          window.location.href = `mailto:${get(
                            orderProject,
                            item.id
                          )}`;
                        }
                      }
                    }}
                    className={
                      item.link
                        ? 'row-description-link link'
                        : 'row-description'
                    }
                  >
                    {item?.itsFullDate
                      ? moment(get(orderProject, item.id)).format('yyyy.MM.DD')
                      : item?.regexp?.first
                      ? get(orderProject, item.id)?.replace(
                          item.regexp.first,
                          item.regexp.second
                        )
                      : localize(item)}
                  </div>
                </div>
              );
            })}
          </Accordion>
        );
      })}
    </>
  );
};

InfoAccordion.propTypes = {
  orderProject: PropTypes.object,
};

export default InfoAccordion;
