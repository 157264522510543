import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import FormInput from '../../../../components/ui/FormInput/FormInput';
import {
  // editCustomerCompanyReq,
  // editCompanyUserReq,
  getMyCompany,
  editCompanyAdmin,
} from '../../../../api/company';
import LoadedContent from '../../../../components/LoadedContent';
import { AlertMessengerContext } from '../../../../provider/Alert';
import FormInputMask from '../../../../components/ui/FormInputMask/FormInputMask';
import { ORGANIZATION_NUMBER_VALIDATOR } from '../../../../validators/organization-number.validator';
import { validationErrorParser } from '../../../../utils/validations.error.parser';
import Dropdown from '../../../../components/ui/dropdown/Dropdown';
import Button from '../../../../components/ui/Button';
import { BUTTON_SIZE } from '../../../../components/ui/Button/Button';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FormTextArea from '../../../../components/ui/FormTextArea';
import { useIntl } from 'react-intl';
import { LangContext } from '../../../../provider/Lang';
// import { zipCodeFormat } from '../../../../utils/zip-code.format';

const urlRegExp =
  // eslint-disable-next-line no-useless-escape
  /(^$)|[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/;

const MyCompany = () => {
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [type, setType] = useState();
  const [spec, setSpec] = useState();
  const [isCompanyDataFetch, setIsCompanyDataFetch] = useState(false);
  const intl = useIntl();
  const context = useContext(LangContext);

  const typeOpt = [
    {
      label: intl.formatMessage({
        id: 'company.type.customer',
        defaultMessage: 'Customer',
      }),
      value: 'customer',
    },
    {
      label: intl.formatMessage({
        id: 'company.type.supplier',
        defaultMessage: 'Supplier',
      }),
      value: 'supplier',
    },
  ];

  const specOpt = [
    {
      label: intl.formatMessage({
        id: 'company.spec.material',
        defaultMessage: 'Material',
      }),
      value: 'factory',
    },
    {
      label: intl.formatMessage({
        id: 'company.spec.service',
        defaultMessage: 'Service',
      }),
      value: 'service',
    },
    {
      label: intl.formatMessage({
        id: 'company.spec.qa',
        defaultMessage: 'Quality control',
      }),
      value: 'quality-control',
    },
  ];

  const { addErrorMessage, addSuccessMessage } = useContext(
    AlertMessengerContext
  );

  const schema = yup.object().shape({
    city: yup.string().required(
      intl.formatMessage({
        id: 'input.error.requiredMsg',
        defaultMessage: 'Field is required',
      })
    ),
    zipCode: yup
      .number(
        intl.formatMessage({
          id: 'input.error.incorrectZip',
          defaultMessage: 'Incorrect Zip Code',
        })
      )
      .required(
        intl.formatMessage({
          id: 'input.error.requiredMsg',
          defaultMessage: 'Field is required',
        })
      )
      .min(
        6,
        intl.formatMessage({
          id: 'input.error.incorrectZip',
          defaultMessage: 'Incorrect Zip Code',
        })
      )
      .typeError(
        intl.formatMessage({
          id: 'input.error.incorrectZip',
          defaultMessage: 'Incorrect Zip Code',
        })
      ),
    address: yup.string().required(
      intl.formatMessage({
        id: 'input.error.requiredMsg',
        defaultMessage: 'Field is required',
      })
    ),
    website: yup
      .string()
      .nullable()
      .matches(
        urlRegExp,
        intl.formatMessage({
          id: 'input.error.website',
          defaultMessage: 'Please valide website',
        })
      ),
  });

  const { control, reset, setError, handleSubmit, setValue, formState } =
    useForm({
      reValidateMode: 'onChange',
      mode: 'onTouched',
      defaultValues: {
        firstname: '',
        lastname: '',
        name: '',
        organisationNumber: '',
        address: '',
        zipCode: '',
        city: '',
        about: '',
        website: '',
      },
      resolver: yupResolver(schema),
    });
  const { errors } = formState;

  const [role] = useState(localStorage.getItem('role'));

  const onSubmit = async (data) => {
    setIsSubmiting(true);

    const companyPayload = {
      address: data.address, // 1
      zipCode: String(data.zipCode), // 2
      city: data.city, // 3
      website: data.website,
      about: data.about,
    };

    try {
      // eslint-disable-next-line no-unused-vars
      const data = await editCompanyAdmin(companyPayload);

      setIsSubmiting(false);
      addSuccessMessage({
        message: intl.formatMessage({
          id: 'page.myCompany.successEdit',
          defaultMessage: 'Company information has been successfully edited',
        }),
      });
    } catch ({ response }) {
      const data = response && response.data;
      setErrors(data);
      addErrorMessage({
        message: data && data.message ? data.message : 'An error occured!',
      });
      setIsSubmiting(false);
    }
  };

  const fetchCompany = async () => {
    setIsCompanyDataFetch(true);

    try {
      const res = await getMyCompany();

      if (res && res.data && res.data.data) {
        const data = res.data.data;
        setType(data.type);
        setSpec(data.spec);
        reset({
          type: typeOpt.find((item) => item.value === data.type) || null,
          spec: specOpt.find((item) => item.value === data.spec) || null,
          name: data.name,
          organisationNumber: data.organisationNumber,
          address: data.address,
          zipCode: data.zipCode,
          city: data.city,
          about: data.about,
          website: data.website,
        });
        setIsCompanyDataFetch(false);
      }
    } catch (err) {
      setIsCompanyDataFetch(false);
    }
  };

  const setErrors = (err) => {
    if (err && err.errors) {
      const parsed = validationErrorParser(err.errors);

      for (const key of Object.keys(parsed)) {
        setError(key, { type: 'custom', message: parsed[key] });
      }
    }
  };

  useEffect(() => {
    document.title = intl.formatMessage({
      id: 'page.myCompany.title',
      defaultMessage: 'My company',
    });
  }, []);

  useEffect(() => {
    fetchCompany();
  }, []);

  useEffect(() => {
    if (type || spec) {
      setValue('type', typeOpt.find((item) => item.value === type) || null);
      setValue('spec', specOpt.find((item) => item.value === spec) || null);
    }
  }, [localStorage, context.locale]);

  return (
    <div className="my-company-user">
      <LoadedContent loader={isCompanyDataFetch || isSubmiting} />
      <div className="my-company-user__inner">
        <div className="my-company-user__body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Dropdown
              handleChange={(data) => console.log(data)}
              options={typeOpt}
              control={control}
              label={intl.formatMessage({
                id: 'input.label.companyType',
                defaultMessage: 'Company Type',
              })}
              name="type"
              id="type"
              className={'form-input'}
              errors={errors.type}
              disabled={true}
              rules={{
                required: 'Field is required',
              }}
            />
            {spec && (
              <Dropdown
                handleChange={(data) => console.log(data)}
                options={typeOpt}
                control={control}
                label={intl.formatMessage({
                  id: 'input.label.companySpec',
                  defaultMessage: 'Company Specialization',
                })}
                name="spec"
                id="type"
                className={'form-input'}
                errors={errors.type}
                disabled={true}
                rules={{
                  required: 'Field is required',
                }}
              />
            )}
            <FormInput
              control={control}
              label={intl.formatMessage({
                id: 'input.label.companyName',
                defaultMessage: 'Company Name',
              })}
              name="name"
              id="name"
              type="text"
              errors={errors.name}
              placeholder={intl.formatMessage({
                id: 'input.placeholder.companyNamelaceholder',
                defaultMessage: 'Type Company Name',
              })}
              disabled={true}
              rules={{
                required: 'Field is required',
              }}
            />
            <FormInputMask
              control={control}
              label={intl.formatMessage({
                id: 'input.label.orgNumber',
                defaultMessage: 'Organization Number',
              })}
              name="organisationNumber"
              id="organisationNumber"
              type="text"
              inputMode="numeric"
              errors={errors.organisationNumber}
              placeholder="______-____"
              disabled={true}
              checkSuccess={true}
              rules={{
                required: 'Field is required',
                validate: ORGANIZATION_NUMBER_VALIDATOR,
              }}
              mask="999999-9999"
            />
            <FormInput
              control={control}
              label={intl.formatMessage({
                id: 'input.label.address',
                defaultMessage: 'Address',
              })}
              name="address"
              id="address"
              type="text"
              checkSuccess={true}
              errors={errors.address}
              placeholder={intl.formatMessage({
                id: 'input.placeholder.addressPlaceholder',
                defaultMessage: 'Type Address',
              })}
              disabled={
                role === 'admin' ? isSubmiting || isCompanyDataFetch : true
              }
              rules={{
                required: 'Field is required',
              }}
            />
            <FormInputMask
              checkSuccess={true}
              control={control}
              label={intl.formatMessage({
                id: 'input.label.zipCode',
                defaultMessage: 'Zip Code',
              })}
              name="zipCode"
              id="zipCode"
              type="text"
              errors={errors.zipCode}
              placeholder={intl.formatMessage({
                id: 'input.placeholder.zipPlaceholder',
                defaultMessage: 'Type Zip Code',
              })}
              disabled={
                role === 'admin' ? isSubmiting || isCompanyDataFetch : true
              }
              rules={{
                required: 'Field is required',
              }}
              mask="999 99"
              inputMode="numeric"
            />
            <FormInput
              checkSuccess={true}
              control={control}
              label={intl.formatMessage({
                id: 'input.label.city',
                defaultMessage: 'City',
              })}
              name="city"
              id="city"
              type="text"
              errors={errors.city}
              placeholder={intl.formatMessage({
                id: 'input.placeholder.cityPlaceholder',
                defaultMessage: 'Type City',
              })}
              disabled={
                role === 'admin' ? isSubmiting || isCompanyDataFetch : true
              }
              rules={{
                required: 'Field is required',
              }}
            />

            {role === 'admin' ? (
              <>
                <FormInput
                  checkSuccess={true}
                  control={control}
                  label={intl.formatMessage({
                    id: 'input.label.website',
                    defaultMessage: 'Website',
                  })}
                  name="website"
                  id="website"
                  type="text"
                  errors={errors.website}
                  placeholder="https://"
                  disabled={isSubmiting || isCompanyDataFetch}
                  rules={{}}
                />

                <FormTextArea
                  checkSuccess={true}
                  control={control}
                  label={intl.formatMessage({
                    id: 'input.label.about',
                    defaultMessage: 'About Company',
                  })}
                  name="about"
                  id="about"
                  type="text"
                  errors={errors.about}
                  placeholder={intl.formatMessage({
                    id: 'input.placeholder.about',
                    defaultMessage: 'A few words about your company',
                  })}
                  disabled={isSubmiting || isCompanyDataFetch}
                  rules={{}}
                />
                <Button
                  size={BUTTON_SIZE.LARGE}
                  text={intl.formatMessage({
                    id: 'btn.save',
                    defaultMessage: 'Save',
                  })}
                  disabled={isSubmiting}
                  onClick={handleSubmit(onSubmit)}
                />
              </>
            ) : null}
          </form>
        </div>
      </div>
    </div>
  );
};

MyCompany.propTypes = {
  props: PropTypes.object,
};

export default MyCompany;
