import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';

import { useController } from 'react-hook-form';
import DatePickerComponent, {
  CalendarContainer,
  registerLocale,
} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import sv from 'date-fns/locale/sv';
import enGb from 'date-fns/locale/en-GB';

const localeVal = {
  swe: 'sv',
  en: 'en-gb',
};

const localeLibVal = {
  swe: sv,
  en: enGb,
};

const MyContainer = (setIsOpened) => {
  const CustomContainer = ({ children, className }) => {
    useEffect(() => {
      setIsOpened(true);
      return () => {
        setIsOpened(false);
      };
    }, []);
    return (
      <CalendarContainer className={className}>{children}</CalendarContainer>
    );
  };

  CustomContainer.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    setIsOpened: PropTypes.func,
  };

  return CustomContainer;
};

const DatePicker = (props) => {
  const { field } = useController(props);
  const [isFocus, setIsFocus] = useState(false);
  const calendarRef = useRef();
  const {
    label,
    id,
    placeholder,
    showTimeInput,
    dateFormat,
    disabled,
    onFocus,
    errors,
    rules,
    minDate,
  } = props;

  const local = localStorage.getItem('lang') || 'swe';

  const iconsWrap = () => {
    let className = 'datepicker__input-wrap';
    if (errors) {
      className += ' errors';
    }
    return className;
  };

  useEffect(
    () => registerLocale(localeVal[local], localeLibVal[local]),
    [localStorage]
  );

  return (
    <>
      <div className={!disabled ? 'datepicker' : 'datepicker disabled'}>
        <div className="datepicker__label-wrap">
          {label && (
            <label
              htmlFor={id}
              className={
                errors ? 'datepicker__label error' : 'datepicker__label'
              }
            >
              {label}
              {rules && rules.required ? (
                <span className="required">*</span>
              ) : (
                ''
              )}
            </label>
          )}
        </div>
        <div className={isFocus ? 'focused' : ''}>
          <div className={iconsWrap()}>
            <div className="calendar-icon">
              <Icon icon="calendar" size="16px" />
            </div>
            <DatePickerComponent
              {...field}
              onChange={(e) => {
                field.onChange(e);
              }}
              selected={field.value}
              disabled={disabled}
              dateFormat={dateFormat}
              showTimeInput={showTimeInput}
              placeholderText={placeholder}
              minDate={minDate}
              onFocus={() => {
                setIsFocus(true);
                if (onFocus) {
                  onFocus();
                }
              }}
              onBlur={() => {
                field.onBlur();
              }}
              calendarClassName="datapicker-component"
              ref={calendarRef}
              calendarContainer={MyContainer(setIsFocus, '')}
              locale={localeLibVal[local]}
              autoComplete="off"
            />
          </div>
        </div>
        {errors && <p className="error">{errors.message}</p>}
      </div>
    </>
  );
};

DatePicker.defaultProps = {
  minDate: null,
};

DatePicker.propTypes = {
  dateFormat: PropTypes.string,
  label: PropTypes.string.isRequired,
  id: PropTypes.string,
  showTimeInput: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  onFocus: PropTypes.func,
  errors: PropTypes.object,
  rules: PropTypes.object,
  minDate: PropTypes.object,
  control: PropTypes.object.isRequired,
};

export default DatePicker;
