/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from 'react';

import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import Tab from '../../../components/ui/Tab';
import {
  ordersSupplierArchive,
  ordersSupplierNew,
  ordersSupplierDecline,
  ordersIdDecline,
  ordersIdUnDecline,
  startLoading,
  ordersSupplierCurrent,
  ordersSupplierWait,
} from '../../../store/slice/orderSupplierSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams, useNavigate } from 'react-router-dom';
import Title from '../../../components/ui/Title';
import { AlertMessengerContext } from '../../../provider/Alert';
import OrdersSupplier from '../../../components/OrdersSupplier';
import _ from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';

const MyOrdersSupplierPage = () => {
  const { addErrorMessage, addSuccessMessage } = useContext(
    AlertMessengerContext
  );
  const intl = useIntl();
  const titles = [
    intl.formatMessage({
      id: `page.orders.tab.newOrders`,
      defaultMessage: 'New Orders',
    }),
    intl.formatMessage({
      id: `page.orders.tab.waiting`,
      defaultMessage: 'Waiting for answer Orders',
    }),
    intl.formatMessage({
      id: `page.orders.tab.current`,
      defaultMessage: 'Current Orders',
    }),
    intl.formatMessage({
      id: `page.orders.tab.archive`,
      defaultMessage: 'Archive Orders',
    }),
  ];
  const [pageTitle, setPageTitle] = useState(titles[0]);
  const [searchParams] = useSearchParams();
  const pageQuery = searchParams.get('page') || 1;
  const pageDeclinedQuery = searchParams.get('pageDeclined') || 1;
  const specQuery = searchParams.get('status') || 'new';

  const isListLoading = useSelector((state) => state.ordersSupplier.isLoading);
  const ordersList = useSelector((state) => state.ordersSupplier.data);
  const ordersMeta = useSelector((state) => state.ordersSupplier.meta);

  const isListLoadingDecline = useSelector(
    (state) => state.ordersSupplier.isLoadingDecline
  );
  const ordersListDecline = useSelector(
    (state) => state.ordersSupplier.dataDecline
  );
  const ordersMetaDecline = useSelector(
    (state) => state.ordersSupplier.metaDecline
  );

  const declineOrder = (id) => {
    dispatch(startLoading());
    dispatch(ordersIdDecline(id))
      .unwrap()
      .then(() => {
        addSuccessMessage({
          message: intl.formatMessage({
            id: `page.orders.declineOrderMsg`,
            defaultMessage: 'You have declined order successfully!',
          }),
        });
      })
      .catch((er) => {
        addErrorMessage({
          message: er?.message || er,
        });
        console.log(er);
      })
      .finally(() => {
        searchListOrder();
      });
  };

  const unDeclineOrder = (id) => {
    dispatch(startLoading());
    dispatch(ordersIdUnDecline(id))
      .unwrap()
      .then(() => {
        addSuccessMessage({
          message: intl.formatMessage({
            id: `page.orders.changeMsg`,
            defaultMessage: 'You have changed decision successfully!',
          }),
        });
        navigate(`/supplier/my-orders/order/${id}`);
      })
      .catch((er) => {
        addErrorMessage({
          message: er?.message || er,
        });
        console.log(er);
      })
      .finally(() => {
        searchListOrder();
      });
  };

  // TODO: GET ROLE, AND ADD INTO CUSTOMER/SUPPLIER ORDERS LIST;
  // const role = localStorage.get('role');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const tabs = [
    {
      title: intl.formatMessage({
        id: `page.orders.tab.newOrders`,
        defaultMessage: 'New Orders',
      }),
      id: 1,
      page: (
        <div>
          <OrdersSupplier
            meta={ordersMeta}
            isListLoading={isListLoading}
            orderList={ordersList}
            itsNewOrders={true}
            declineOrder={declineOrder}
          />
          {!isListLoadingDecline && ordersListDecline.length ? (
            <>
              <div className="order-supplier-page__line"></div>
              <Title classNameContainer={'order-supplier-page__description'}>
                <FormattedMessage
                  id="page.orders.declinedList"
                  defaultMessage="Declined"
                />
              </Title>
              <OrdersSupplier
                meta={ordersMetaDecline}
                isListLoading={isListLoadingDecline}
                orderList={ordersListDecline}
                itsDeclined={true}
                unDeclineOrder={unDeclineOrder}
              />
            </>
          ) : null}
        </div>
      ),
      name: 'new',
    },
    {
      title: intl.formatMessage({
        id: `page.orders.tab.waiting`,
        defaultMessage: 'Waiting for answer Orders',
      }),
      id: 2,
      page: (
        <OrdersSupplier
          meta={ordersMeta}
          isListLoading={isListLoading}
          orderList={ordersList}
          itsWait={true}
        />
      ),
      name: 'wait',
    },
    {
      title: intl.formatMessage({
        id: `page.orders.tab.current`,
        defaultMessage: 'Current Orders',
      }),
      id: 3,
      page: (
        <OrdersSupplier
          meta={ordersMeta}
          isListLoading={isListLoading}
          orderList={ordersList}
          itsCurrent={true}
        />
      ),
      name: 'current',
    },
    {
      title: intl.formatMessage({
        id: `page.orders.tab.archive`,
        defaultMessage: 'Archive Orders',
      }),
      id: 4,
      page: (
        <OrdersSupplier
          meta={ordersMeta}
          isListLoading={isListLoading}
          orderList={ordersList}
          itsArchive={true}
        />
      ),
      name: 'archive',
    },
  ];
  const activeDefault = _.find(tabs, { name: specQuery }).id || 1;

  useEffect(() => {
    const params = new URLSearchParams();
    if (!specQuery) {
      params.append('status', 'new');
    } else {
      params.append('status', specQuery);
    }

    if (!pageDeclinedQuery) {
      params.append('pageDeclined', 1);
    } else {
      params.append('pageDeclined', pageDeclinedQuery);
    }

    if (!pageQuery) {
      params.append('page', 1);
    } else {
      params.append('page', pageQuery);
    }
    navigate(
      {
        search: params.toString(),
      },
      {
        replace: true,
      }
    );
    searchListOrder();
  }, []);

  const isMobileOrTablet = useMediaQuery({
    query: '(max-width: 900px) ',
  });

  const searchListOrder = () => {
    if (specQuery === 'wait') {
      dispatch(
        ordersSupplierWait({
          data: { page: pageQuery },
        })
      );
    } else if (specQuery === 'archive') {
      dispatch(
        ordersSupplierArchive({
          data: { page: pageQuery },
        })
      );
    } else if (specQuery === 'current') {
      dispatch(
        ordersSupplierCurrent({
          data: { page: pageQuery },
        })
      );
    } else {
      dispatch(
        ordersSupplierDecline({
          data: {
            page: pageDeclinedQuery,
          },
        })
      );
      dispatch(
        ordersSupplierNew({
          data: { page: pageQuery },
        })
      );
    }
  };

  useEffect(() => {
    dispatch(startLoading());
    searchListOrder();
  }, [pageQuery, specQuery]);

  useEffect(() => {
    document.title = `${intl.formatMessage({
      id: `page.ad.offers.title`,
      defaultMessage: 'My Orders',
    })} - ${pageTitle}`;
  }, [pageTitle]);

  return (
    <div className="order-supplier-page">
      <div className="order-supplier-page__inner">
        <Title>
          <FormattedMessage
            id="page.ad.offers.title"
            defaultMessage="My Orders"
          />
        </Title>
        <div className="order-supplier-page__body">
          <Tab
            activeDefault={activeDefault}
            tabs={tabs}
            onTabChange={(id) => {
              const params = new URLSearchParams();
              params.append('status', tabs[id - 1].name);
              params.append('page', 1);

              params.append('pageDeclined', 1);
              navigate(
                {
                  search: params.toString(),
                },
                {
                  replace: true,
                }
              );
              setPageTitle(titles[id - 1]);
            }}
          />
        </div>
      </div>
    </div>
  );
};

MyOrdersSupplierPage.defaultProps = {
  activeDefault: 1,
};

MyOrdersSupplierPage.propTypes = {
  activeDefault: PropTypes.number,
};

export default MyOrdersSupplierPage;
