import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  companyUserInviteAdmin,
  companyUserInviteProjectLead,
  companyUsersCompany,
  companyUserEditReq,
  companyUserShow,
} from '../../api/companyUser';

// myUserGet

export const getBannedUsersCompany = createAsyncThunk(
  'user/getUserBanned',
  async (creds, { rejectWithValue }) => {
    try {
      const response = await companyUsersCompany(creds.id, {
        status: 'banned',
        ...creds.query,
      });
      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getUsersCompany = createAsyncThunk(
  'user/getUser',
  async (creds, { rejectWithValue }) => {
    try {
      const response = await companyUsersCompany(creds.id, creds.query);
      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const userShow = createAsyncThunk(
  'user/userShow',
  async (creds, { rejectWithValue }) => {
    try {
      const response = await companyUserShow(creds.userId, creds.companyId);
      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const userEdit = createAsyncThunk(
  'user/userEdit',
  async (creds, { rejectWithValue }) => {
    try {
      const response = await companyUserEditReq(
        creds.companyId,
        creds.userId,
        creds.data
      );
      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const inviteAdmin = createAsyncThunk(
  'user/inviteAdmin',
  async (creds, { rejectWithValue }) => {
    try {
      const response = await companyUserInviteAdmin(creds.id, creds.data);
      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const inviteProjectLead = createAsyncThunk(
  'user/inviteProjectLead',
  async (creds, { rejectWithValue }) => {
    try {
      const response = await companyUserInviteProjectLead(creds.id, creds.data);
      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export function startLoading(text) {
  return {
    type: 'ADD_LOADING',
  };
}

export function stopLoading(text) {
  return {
    type: 'REMOVE_LOADING',
  };
}

export function changeStatusRole(id, status) {
  return {
    type: 'CHANDE_ROLE',
    id: id,
    status: status,
  };
}

const initialState = {
  isListLoading: false,
  isListError: false,
  isInviteLoading: false,
  isUserLoading: false,
  userInfo: null,
  data: [],
  meta: {},

  metaBanned: {},
  dataBanned: [],
  isListBannedLoading: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getUsersCompany.pending, (state) => {
      state.isListLoading = true;
      state.isListError = false;
      state.data = [];
    });
    builder.addCase(getUsersCompany.fulfilled, (state, payload) => {
      state.isListLoading = false;
      state.isError = false;
      state.userInfo = null;
      state.data = payload.payload.data;
      state.meta = payload.payload.meta;
    }); // rejected
    builder.addCase(getUsersCompany.rejected, (state) => {
      state.isListLoading = false;
      state.isListError = true;
    });

    // banned
    builder.addCase(getBannedUsersCompany.pending, (state) => {
      state.isListBannedLoading = true;
      state.isListError = false;
      state.metaBanned = false;
      state.dataBanned = [];
    });
    builder.addCase(getBannedUsersCompany.fulfilled, (state, payload) => {
      state.isListBannedLoading = false;
      state.isError = false;
      state.userInfo = null;
      state.dataBanned = payload.payload.data;
      state.metaBanned = payload.payload.meta;
    }); // rejected
    builder.addCase(getBannedUsersCompany.rejected, (state) => {
      state.isListBannedLoading = false;
      state.isListError = true;
    });

    builder.addCase(userShow.pending, (state) => {
      state.isUserLoading = true;
      state.isListError = false;
    });
    builder.addCase(userShow.fulfilled, (state, payload) => {
      state.isUserLoading = false;
      state.isError = false;
      state.userInfo = payload.payload.data;
    });
    builder.addCase(userShow.rejected, (state) => {
      state.isUserLoading = false;
      state.isListError = true;
    });

    builder.addCase(userEdit.pending, (state) => {
      state.isInviteLoading = true;
      state.isListError = false;
    });
    builder.addCase(userEdit.fulfilled, (state, payload) => {
      state.isInviteLoading = false;
      state.isError = false;
    });
    builder.addCase(userEdit.rejected, (state) => {
      state.isInviteLoading = false;
      state.isListError = true;
    });

    builder.addCase(inviteAdmin.pending, (state) => {
      state.isInviteLoading = true;
      state.isListError = false;
    });
    builder.addCase(inviteAdmin.fulfilled, (state, payload) => {
      state.isInviteLoading = false;
      state.isError = false;
    });
    builder.addCase(inviteAdmin.rejected, (state) => {
      state.isInviteLoading = false;
      state.isListError = true;
    });

    builder.addCase(inviteProjectLead.pending, (state) => {
      state.isInviteLoading = true;
      state.isListError = false;
    });
    builder.addCase(inviteProjectLead.fulfilled, (state, payload) => {
      state.isInviteLoading = false;
      state.isError = false;
    }); // rejected
    builder.addCase(inviteProjectLead.rejected, (state) => {
      state.isInviteLoading = false;
      state.isListError = true;
    });

    builder.addCase('ADD_LOADING', (state, action) => {
      state.isListLoading = true;
    });
    builder.addCase('REMOVE_LOADING', (state, action) => {
      state.isListLoading = false;
    });
    builder.addCase('CHANDE_ROLE', (state, action) => {
      const data = state.data.map((item) => {
        if (item.id === action.id) {
          return {
            ...item,
            status: action.status,
          };
        }
        return item;
      });
      state.data = data;
    });
  },
});

const { reducer } = userSlice;

export const selectUser = (state) => state.user;
export default reducer;
