import React from 'react';
import PropTypes from 'prop-types';
import Button, {
  BUTTON_SIZE,
  BUTTON_TYPE,
  BUTTON_ROLE,
} from '../ui/Button/Button';
import useIsMobile from '../../utils/useMobile';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';

export const NEW_USER_CARD_TYPE = {
  NEW_ADMIN: 'admin',
  NEW_PROJECT_LEAD: 'project_lead',
  NEW_SUPER_ADMIN: 'new_super_admin',
};

const NewUserCard = ({ type }) => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const intl = useIntl();

  const getTitle = () => {
    switch (type) {
      case NEW_USER_CARD_TYPE.NEW_ADMIN:
        return intl.formatMessage({
          id: 'page.sa.addUserCard.title_ad',
          defaultMessage: 'Add New Admin',
        });
      case NEW_USER_CARD_TYPE.NEW_PROJECT_LEAD:
        return intl.formatMessage({
          id: 'page.sa.addUserCard.title_pl',
          defaultMessage: 'Add New Project Leader',
        });
      case NEW_USER_CARD_TYPE.NEW_SUPER_ADMIN:
        return intl.formatMessage({
          id: 'page.sa.addUserCard.title_sa',
          defaultMessage: 'Add New Super Admin',
        });
      default:
        return '';
    }
  };

  const getSubTitle = () => {
    switch (type) {
      case NEW_USER_CARD_TYPE.NEW_ADMIN:
        return intl.formatMessage({
          id: 'page.sa.addUserCard.subTitle_ad',
          defaultMessage:
            'Admin has access to the entire system and can create other users',
        });
      case NEW_USER_CARD_TYPE.NEW_PROJECT_LEAD:
        return intl.formatMessage({
          id: 'page.sa.addUserCard.subTitle_pl',
          defaultMessage:
            'Project leaders have access to the entire system, but can not create new users',
        });
      case NEW_USER_CARD_TYPE.NEW_SUPER_ADMIN:
        return intl.formatMessage({
          id: 'page.sa.addUserCard.subTitle_sa',
          defaultMessage:
            'Super admin has access to the entire system and can create other users and company',
        });
      default:
        return '';
    }
  };

  const isIos =
    isMobile &&
    /iPad|iPhone|iPod/.test(navigator.userAgent) &&
    !window.MSStream;

  const getButton = () => {
    switch (type) {
      case NEW_USER_CARD_TYPE.NEW_ADMIN:
        return (
          <Button
            role={BUTTON_ROLE.ADD_BUTTON}
            type={BUTTON_TYPE.OUTLINE}
            size={!isMobile ? BUTTON_SIZE.MEDIUM : BUTTON_SIZE.BIG}
            text={intl.formatMessage({
              id: 'page.sa.addUserCard.btn_ad',
              defaultMessage: 'Add Admin',
            })}
            onClick={(e) => {
              e.target.blur();
              navigate('new-admin');
            }}
          />
        );
      case NEW_USER_CARD_TYPE.NEW_PROJECT_LEAD:
        return (
          <Button
            role={BUTTON_ROLE.ADD_BUTTON}
            type={BUTTON_TYPE.OUTLINE}
            size={!isMobile ? BUTTON_SIZE.MEDIUM : BUTTON_SIZE.BIG}
            text={intl.formatMessage({
              id: 'page.sa.addUserCard.btn_pl',
              defaultMessage: 'Add Project Leader',
            })}
            onClick={(e) => {
              e.target.blur();
              navigate('new-lead');
            }}
          />
        );
      case NEW_USER_CARD_TYPE.NEW_SUPER_ADMIN:
        return (
          <Button
            role={BUTTON_ROLE.ADD_BUTTON}
            type={BUTTON_TYPE.OUTLINE}
            size={!isMobile ? BUTTON_SIZE.MEDIUM : BUTTON_SIZE.BIG}
            text={intl.formatMessage({
              id: 'page.sa.addUserCard.btn_sa',
              defaultMessage: 'Add Super Admin',
            })}
            istAdd={true}
            addButton={true}
            onClick={(e) => {
              e.target.blur();
              navigate('/my-account/user');
            }}
          />
        );
      default:
        return '';
    }
  };

  return (
    <div className="new-user-card">
      <div className="new-user-card__inner">
        <div className="new-user-card__top">
          <div className="new-user-card__title">
            <span>{getTitle()}</span>
          </div>
          <div className="new-user-card__subtitle">
            <span>{getSubTitle()}</span>
          </div>
        </div>
        <div
          className={
            isIos ? 'new-user-card__bottom safari-fix' : 'new-user-card__bottom'
          }
        >
          <div className="new-user-card__btn">{getButton()}</div>
        </div>
      </div>
    </div>
  );
};

NewUserCard.propTypes = {
  type: PropTypes.string.isRequired,
};

export default NewUserCard;
