import React, { useEffect, useState } from 'react';
import { getCompany } from '../../../api/company';
import { useParams } from 'react-router-dom';
import LoadedContent from '../../../components/LoadedContent';
import Title from '../../../components/ui/Title';
import { FormattedMessage, useIntl } from 'react-intl';

const SupplierCompanyInfoPage = () => {
  const [isLoading, setIsLoading] = useState();
  const [companyInfo, setCompanyInfo] = useState();
  const params = useParams();
  const intl = useIntl();

  const getCompanyInfo = () => {
    setIsLoading(true);

    getCompany(params.id_company)
      .then((res) => {
        setCompanyInfo(res.data.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getCompanyInfo();
  }, []);

  useEffect(() => {
    if (companyInfo) {
      document.title = companyInfo.name;
    } else {
      document.title = '...';
    }
  }, [companyInfo]);

  return (
    <div className="supplier-company-info">
      <Title
        subTitle={intl.formatMessage({
          id: 'page.supplayer.company.title',
          defaultMessage: 'About Supplier',
        })}
        infoContent={
          <a
            className="link"
            href={companyInfo && companyInfo.website}
            target="_blank"
            rel="noopener noreferrer"
          >
            {companyInfo && companyInfo.website ? companyInfo.website : ''}
          </a>
        }
      >
        {companyInfo ? companyInfo.name : '...'}
      </Title>

      <div className="supplier-company-info__body">
        <LoadedContent loader={isLoading} />
        {companyInfo && (
          <div className="supplier-company-info__list">
            <div className="supplier-company-info__list-item">
              <span>
                <FormattedMessage
                  id="company.orgNumber"
                  defaultMessage="Organization number"
                />
              </span>
              <span>{companyInfo.organisationNumber}</span>
            </div>
            <div className="supplier-company-info__list-item">
              <span>
                <FormattedMessage
                  id="company.address"
                  defaultMessage="Address"
                />
              </span>
              <span>{companyInfo.address}</span>
            </div>
            <div className="supplier-company-info__list-item">
              <span>
                <FormattedMessage id="company.city" defaultMessage="City" />
              </span>
              <span>{companyInfo.city}</span>
            </div>
            <div className="supplier-company-info__list-item">
              <span>
                <FormattedMessage
                  id="company.contactPerson"
                  defaultMessage="Contact person"
                />
              </span>
              <span>
                {companyInfo.admins[0].firstname +
                  ' ' +
                  companyInfo.admins[0].lastname}
              </span>
            </div>
            <div className="supplier-company-info__list-item">
              <span>
                <FormattedMessage id="company.phone" defaultMessage="Phone" />
              </span>
              <span>{companyInfo.admins[0].phone}</span>
            </div>
            <div className="supplier-company-info__list-item">
              <span>
                <FormattedMessage id="company.mail" defaultMessage="Email" />
              </span>
              <span>{companyInfo.admins[0].email}</span>
            </div>
            <div className="supplier-company-info__list-item">
              {companyInfo.about && (
                <>
                  <span>
                    <FormattedMessage
                      id="page.supplayer.company.about"
                      defaultMessage="About Company"
                    />
                  </span>
                  <span>{companyInfo.about}</span>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

SupplierCompanyInfoPage.defaultProps = {};

SupplierCompanyInfoPage.propTypes = {};

export default SupplierCompanyInfoPage;
