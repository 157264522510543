import React from 'react';
import PropTypes from 'prop-types';
import OrderCard, {
  CARD_TYPE,
} from '../../../../../components/OrderCard/OrderCard';
import LoadedContent from '../../../../../components/LoadedContent';
import Icon from '../../../../../components/ui/Icon';
import Pagination from '../../../../../components/Pagination';
import { FormattedMessage } from 'react-intl';

const OrdersList = ({ title, meta, isListLoading, orderList }) => {
  return (
    <>
      <LoadedContent loader={isListLoading} />
      <div className="orders-list">
        {!isListLoading &&
          orderList &&
          orderList.map((item, index) => (
            <OrderCard
              key={index}
              data={item}
              type={CARD_TYPE.HORIZONTAL}
              path={`projects/${item.project.id}/order/${item.id}`}
            />
          ))}
        {!isListLoading && meta && (
          <div className="orders-list__pagination">
            <Pagination
              totalCount={meta.last_page}
              currentPage={meta.current_page}
              onPageChange={() => {}}
            />
          </div>
        )}
        {orderList && !orderList.length && !isListLoading ? (
          <div className="empty-list">
            <div className="empty-list__icon">
              <Icon icon="orders-big" size="52px" />
            </div>
            <span className="empty-list__title">
              <FormattedMessage
                id="page.orders.noMsg"
                defaultMessage="You have no orders"
              />
            </span>
          </div>
        ) : (
          ''
        )}
      </div>
    </>
  );
};

OrdersList.propTypes = {
  title: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  isListLoading: PropTypes.bool.isRequired,
  orderList: PropTypes.array.isRequired,
};

export default OrdersList;
