import React from 'react';
import Select, { components } from 'react-select';
import PropTypes from 'prop-types';
import Icon from '../../ui/Icon';
import { useController } from 'react-hook-form';
import { useIntl } from 'react-intl';

const customStyles = {
  menu: (provided) => ({
    ...provided,
    marginTop: '0!important',
    marginBottom: '0!important',
    boxShadow: 'var(--shadow-light)',
    borderRadius: ' 0 0 8px 8px',
    top: '44px',
    zIndex: '12',
  }),
  menuList: (provided) => ({
    ...provided,
    paddingTop: '0!important',
    paddingBottom: '0!important',
    borderRadius: ' 0 0 8px 8px',
  }),
  control: (provided, state) => {
    return {
      ...provided,
      borderRadius: !state.menuIsOpen ? '8px' : '8px 8px 0 0',
      boxShadow: state.menuIsOpen ? 'var(--shadow-light)' : 'none',
      borderWidth: '1px',
      borderColor: state.menuIsOpen
        ? '#fff !important'
        : 'var(--gray-thirdy) !important',
      height: '44px',
    };
  },
  // ValueContainer: (provided) => ({
  //   ...provided,
  //   height: '44px',
  // }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  input: (provided) => ({
    ...provided,
    margin: 0,
  }),
  option: (provided, state) => ({
    ...provided,
    borderTop: '1px solid #F8F8FA',
    color: state.isSelected ? '#4446B0' : 'var(--black-primary)',
    backgroundColor: '#fff',
    padding: '12px 8px',
    height: '44px',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    fontFamily: 'Inter Regular',
    fontStyle: 'normal',
    letterSpacing: ' -0.02em',
    display: 'flex',
    alignItems: 'end',
    textTransform: 'capitalize',
    // '&:last-child': {
    //   borderBottom: 'none',
    //   borderRadius: ' 0 0 8px 8px',
    // },
    '&:hover': {
      backgroundColor: '#E8E8FF!important',
      color: '#4446B0',
    },
  }),
  placeholder: (provided, state) => ({
    color: '#A7AEBA',
    fontSize: '14px',
    fontWeight: '400',
    position: 'absolute',
    left: '8px',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    margin: 0,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    fontFamily: 'Inter Regular',
    fontStyle: 'normal',
    letterSpacing: ' -0.02em',
    textTransform: 'capitalize',
    color: state.isDisabled ? 'var(--gray-thirdy)' : 'var(--black-primary)',
  }),
  indicatorsContainer: (provided, state) => {
    return {
      ...provided,
      svg: {
        color: '#A7AEBA',
      },
    };
  },
  iconDone: {
    right: '10px',
    position: 'absolute',
  },
  iconAdd: {
    paddingRight: '10px',
  },
  iconArrow: {
    width: '40px',
    paddingLeft: '10px',
  },
};

const CustomArrow = ({ ...e }) => {
  return (
    <components.IndicatorsContainer {...e}>
      <div style={customStyles.iconArrow}>
        {e.selectProps.menuIsOpen ? (
          <Icon icon="chevron-up" size="16px" />
        ) : (
          <Icon icon="chevron-down" size="16px" />
        )}
      </div>
    </components.IndicatorsContainer>
  );
};

// eslint-disable-next-line react/prop-types
const CustomOption = ({ children, ...e }) => {
  if (e.data.itsAdd) {
    e.innerProps.onClick = () => {}; // custom
  }
  return (
    <components.Option {...e}>
      {e.data.itsAdd ? (
        <div style={customStyles.iconAdd}>
          <Icon icon="plus" size="18px" />
        </div>
      ) : null}
      {children}
      {e.isSelected ? (
        <div style={customStyles.iconDone}>
          <Icon icon="check-1" size="16px" />
        </div>
      ) : null}
    </components.Option>
  );
};
const optionsExample = [
  { value: 'Create', label: 'Create', id: 1, itsAdd: true },
  { value: 'strawberry', label: 'Strawberry', id: 2 },
  { value: 'vanilla', label: 'Vanilla', id: 3 },
];

export const Dropdown = (props) => {
  const {
    selectedOption,
    handleChange,
    options,
    label,
    errors,
    rules,
    disabled,
    className = '',
    withoutControll = false,
    defaultValue = null,
    customPlaceholder = 'Select',
    onClick,
  } = props;
  const intl = useIntl();

  if (withoutControll) {
    return (
      <div
        className={
          disabled ? `dropdown disabled ${className}` : `dropdown ${className}`
        }
      >
        <div className="label-wrap">
          {label && (
            <div className={errors ? 'label error' : 'label'}>
              {label}
              {rules && rules.required ? (
                <span className="required">*</span>
              ) : (
                ''
              )}
            </div>
          )}
        </div>
        <div className={errors ? 'select-wrap error' : 'select-wrap'}>
          <Select
            components={{
              Option: CustomOption,
              IndicatorsContainer: CustomArrow,
            }}
            styles={customStyles}
            className="dropdown_input"
            inputClassName="test1"
            defaultValue={defaultValue}
            value={selectedOption}
            onChange={(data) => {
              handleChange(data);
            }}
            // menuIsOpen={true}
            isSearchable={false}
            options={options || optionsExample}
            empty
            placeholder={customPlaceholder}
            isDisabled={disabled}
            onMenuOpen={onClick}
          />

          {errors && <p className="errorName">{errors.message}</p>}
        </div>
      </div>
    );
  } else {
    const { field } = useController(props);
    return (
      <div
        className={
          disabled ? `dropdown disabled ${className}` : `dropdown ${className}`
        }
      >
        <div className="label-wrap">
          {label && (
            <div className={errors ? 'label error' : 'label'}>
              {label}
              {rules && rules.required ? (
                <span className="required">*</span>
              ) : (
                ''
              )}
            </div>
          )}
        </div>
        <div className={errors ? 'select-wrap error' : 'select-wrap'}>
          <Select
            {...field}
            components={{
              Option: CustomOption,
              IndicatorsContainer: CustomArrow,
            }}
            styles={customStyles}
            className="dropdown_input"
            inputClassName="test1"
            defaultValue={null}
            value={selectedOption || field.value}
            onChange={(data) => {
              field.onChange(data);
              handleChange(data);
            }}
            options={options || optionsExample}
            // menuIsOpen={true}
            empty
            placeholder={intl.formatMessage({
              id: `input.select.placeholder`,
              defaultMessage: 'Select',
            })}
            isDisabled={disabled}
          />

          {errors && <p className="errorName">{errors.message}</p>}
        </div>
      </div>
    );
  }
};

Dropdown.defaultProps = {};

Dropdown.propTypes = {
  handleChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired || PropTypes.null.isRequired,
  selectedOption: PropTypes.string || PropTypes.null,
  label: PropTypes.string.isRequired,
  errors: PropTypes.object,
  rules: PropTypes.object,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  withoutControll: PropTypes.bool,
  customPlaceholder: PropTypes.bool,
  defaultValue: PropTypes.object,
  onClick: PropTypes.func,
};

export default Dropdown;
