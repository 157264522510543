import React, { useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import FormInput from '../../../../components/ui/FormInput/FormInput';
import Button, { BUTTON_SIZE } from '../../../../components/ui/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import {
  editProfile,
  getProfile,
} from '../../../../store/slice/personalInfoSlice';
import LoadedContent from '../../../../components/LoadedContent';
import { AlertMessengerContext } from '../../../../provider/Alert';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import FormInputMask from '../../../../components/ui/FormInputMask/FormInputMask';
import { useIntl } from 'react-intl';

const PersonalInfo = () => {
  const dispatch = useDispatch();
  const personalData = useSelector((state) => state.personalInfo);
  const intl = useIntl();

  const { addErrorMessage, addSuccessMessage } = useContext(
    AlertMessengerContext
  );

  const schema = yup.object().shape({
    phone: yup
      .string()
      .required(
        intl.formatMessage({
          id: 'input.error.requiredMsg',
          defaultMessage: 'Field is required',
        })
      )
      .test((value, ctx) => {
        const clearValue = value.replace(/[()_\s-]/g, '');
        if (!/^[+]\d{8,13}$/.test(clearValue)) {
          return ctx.createError({
            message: intl.formatMessage({
              id: 'input.error.incorrectPhone',
              defaultMessage: 'Incorrect phone',
            }),
          });
        }
        return true;
      }),
    email: yup
      .string()
      .email(
        intl.formatMessage({
          id: 'input.error.incorrectMail',
          defaultMessage: 'Incorrect E-mail address',
        })
      )
      .required(
        intl.formatMessage({
          id: 'input.error.requiredMsg',
          defaultMessage: 'Field is required',
        })
      ),
    firstname: yup
      .string()
      .required(
        intl.formatMessage({
          id: 'input.error.requiredMsg',
          defaultMessage: 'Field is required',
        })
      )
      .min(
        3,
        intl.formatMessage({
          id: 'input.error.incorrectFName',
          defaultMessage: 'First Name must be at least 3 characters',
        })
      ),
    lastname: yup
      .string()
      .required(
        intl.formatMessage({
          id: 'input.error.requiredMsg',
          defaultMessage: 'Field is required',
        })
      )
      .min(
        3,
        intl.formatMessage({
          id: 'input.error.incorrectLName',
          defaultMessage: 'Last Name must be at least 3 characters',
        })
      ),
  });

  const { control, handleSubmit, formState, ...formControl } = useForm({
    reValidateMode: 'onChange',
    defaultValues: {
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
    },
    resolver: yupResolver(schema),
  });

  const { errors } = formState;

  const onSubmit = (data) => {
    const phone = data.phone.replace(/[()_\s-]/g, '');
    dispatch(editProfile({ ...data, phone }))
      .unwrap()
      .then(() => {
        addSuccessMessage({
          message: intl.formatMessage({
            id: `page.sa.myAcc.info.successMsg`,
            defaultMessage: 'You update an information!',
          }),
        });
      })
      .catch(({ errors, message }) => {
        if (errors) {
          Object.keys(errors).forEach((item) => {
            formControl.setError(item, {
              type: 'custom',
              message: errors[item][0],
            });
          });
        } else if (message) {
          addErrorMessage({
            message: message,
          });
        }
      });
  };

  useEffect(() => {
    document.title = intl.formatMessage({
      id: `page.sa.myAcc.tab.info`,
      defaultMessage: 'Personal Info',
    });
    dispatch(getProfile());
  }, []);

  useEffect(() => {
    formControl.setValue('firstname', personalData.data.firstname);
    formControl.setValue('lastname', personalData.data.lastname);
    formControl.setValue('phone', personalData.data.phone);
    formControl.setValue('email', personalData.data.email);
  }, [personalData.data]);

  return (
    <div className="personal-info">
      <LoadedContent loader={personalData.isError || personalData.isLoading} />
      <form
        className="personal-info__form-inner"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="personal-info__form">
          <FormInput
            control={control}
            label={intl.formatMessage({
              id: `input.label.fname`,
              defaultMessage: 'First Name*',
            })}
            rules={{ required: true }}
            name="firstname"
            id="firstname"
            type="firstname"
            errors={errors.firstname}
            disabled={personalData.isError || personalData.isLoading}
            placeholder={intl.formatMessage({
              id: `input.placeholder.fname`,
              defaultMessage: 'Enter your First Name',
            })}
          />
          <FormInput
            control={control}
            label={intl.formatMessage({
              id: `input.label.lname`,
              defaultMessage: 'Last Name*',
            })}
            rules={{ required: true }}
            name="lastname"
            id="lastname"
            type="lastname"
            errors={errors.lastname}
            disabled={personalData.isError || personalData.isLoading}
            placeholder={intl.formatMessage({
              id: `input.placeholder.lname`,
              defaultMessage: 'Enter your Last Name',
            })}
          />

          <FormInputMask
            control={control}
            label={intl.formatMessage({
              id: `input.label.phone`,
              defaultMessage: 'Phone*',
            })}
            rules={{ required: true }}
            name="phone"
            inputMode="tel"
            disabled={personalData.isError || personalData.isLoading}
            id="phone"
            type="phone"
            errors={errors.phone}
            placeholder="+46"
            maskChar=""
            mask="+46 99999999999"
          />

          <FormInput
            control={control}
            label={intl.formatMessage({
              id: `input.label.mail`,
              defaultMessage: 'E-mail*',
            })}
            rules={{ required: true }}
            name="email"
            id="email"
            type="email"
            errors={errors.email}
            disabled={personalData.isError || personalData.isLoading}
            placeholder={intl.formatMessage({
              id: `input.placeholder.mail`,
              defaultMessage: 'Enter your email',
            })}
          />
        </div>
        <div className="personal-info__form-btn">
          <Button
            disabled={personalData.isError || personalData.isLoading}
            text={intl.formatMessage({
              id: `btn.save`,
              defaultMessage: 'Save',
            })}
            size={BUTTON_SIZE.LARGE}
          />
        </div>
      </form>
    </div>
  );
};

PersonalInfo.defaultProps = {};

PersonalInfo.propTypes = {};

export default PersonalInfo;
