import React, { useMemo } from 'react';
import Icon from '../ui/Icon';
import PropTypes from 'prop-types';
import { useNavigate, useSearchParams } from 'react-router-dom';

const range = (start, end) => {
  const length = end - start + 1;
  /*
  	Create an array of certain length and set the elements within it from
    start value to end value.
  */
  return Array.from({ length }, (_, idx) => idx + start);
};
const DOTS = 1;

export const usePagination = ({
  totalCount,
  pageSize,
  siblingCount = 1,
  currentPage,
}) => {
  const paginationRange = useMemo(() => {
    const totalPageCount = Math.ceil(totalCount / pageSize);

    // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
    const totalPageNumbers = siblingCount + 5;

    /*
      Case 1:
      If the number of pages is less than the page numbers we want to show in our
      paginationComponent, we return the range [1..totalPageCount]
    */
    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount);
    }

    /*
    	Calculate left and right sibling index and make sure they are within range 1 and totalPageCount
    */
    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(
      currentPage + siblingCount,
      totalPageCount
    );

    /*
      We do not show dots just when there is just one page number to be inserted between the extremes of sibling and the page limits i.e 1 and totalPageCount. Hence we are using leftSiblingIndex > 2 and rightSiblingIndex < totalPageCount - 2
    */
    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    /*
    	Case 2: No left dots to show, but rights dots to be shown
    */
    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount = 3 + 2 * siblingCount;
      const leftRange = range(1, leftItemCount);

      return [...leftRange, DOTS, totalPageCount];
    }

    /*
    	Case 3: No right dots to show, but left dots to be shown
    */
    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightItemCount = 3 + 2 * siblingCount;
      const rightRange = range(
        totalPageCount - rightItemCount + 1,
        totalPageCount
      );
      return [firstPageIndex, DOTS, ...rightRange];
    }

    /*
    	Case 4: Both left and right dots to be shown
    */
    if (shouldShowLeftDots && shouldShowRightDots) {
      const middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }
  }, [totalCount, pageSize, siblingCount, currentPage]);

  return paginationRange;
};

const Pagination = ({
  totalCount,
  currentPage,
  onPageChange,
  typePage = 'page',
}) => {
  const siblingCount = 0;
  const pageSize = 1;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const onPageChangeGlobal = (page) => {
    const params = new URLSearchParams(searchParams);
    params.set(typePage, page);
    navigate(
      {
        search: params.toString(),
      },
      {
        replace: true,
      }
    );

    // setSearchParams(params);
    onPageChange(page);
  };

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  // If there are less than 2 times in pagination range we shall not render the component
  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }
  const onNext = () => {
    onPageChangeGlobal(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChangeGlobal(currentPage - 1);
  };

  const lastPage = paginationRange[paginationRange.length - 1];
  return (
    <ul className={'pagination'}>
      <li
        className={
          currentPage !== 1
            ? 'pagination-icon pagination-icon_active'
            : 'pagination-icon pagination-icon_inactive'
        }
        onClick={onPrevious}
      >
        <Icon icon="arrow-left" size="16px" />
      </li>
      {paginationRange.map((pageNumber, index) => {
        // If the pageItem is a DOT, render the DOTS unicode character
        if (pageNumber === 1 && index !== 0) {
          // dots
          return <li className="pagination-item dots">&#8230;</li>;
        }

        // Render our Page Pills
        return (
          <li
            key={pageNumber}
            className={pageNumber === currentPage ? 'active' : ''}
            onClick={() => onPageChangeGlobal(pageNumber)}
          >
            {pageNumber}
          </li>
        );
      })}
      {/*  Right Navigation arrow */}
      <li
        onClick={onNext}
        className={
          currentPage !== lastPage
            ? 'pagination-icon pagination-icon_active'
            : 'pagination-icon pagination-icon_inactive'
        }
      >
        <Icon icon="arrow-right" size="16px" />
      </li>
    </ul>
  );
};

Pagination.propTypes = {
  // props: PropTypes.object,
  onPageChange: PropTypes.func,
  totalCount: PropTypes.number,
  currentPage: PropTypes.number,
  typePage: PropTypes.string,
};

export default Pagination;
