import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../components/ui/Button';
import { BUTTON_ROLE, BUTTON_SIZE } from '../../../components/ui/Button/Button';
import { useNavigate } from 'react-router-dom';
import CustomersCompaniesPage from './CustomersCompanies';
import MaterialSuppliersCompaniesPage from './MaterialSuppliersCompanies';
import QualityAssurancePage from './QualityAssurance';
import ServiceProviderCompaniesPage from './ServiceProviderCompanies';
import Tab from '../../../components/ui/Tab';
import { useMediaQuery } from 'react-responsive';
import { FormattedMessage, useIntl } from 'react-intl';

const defaultTabName = {
  customers: 'Customers',
  material: 'Material Supplier',
  service: 'Service Provider',
  qa: 'Quality Assurance',
};

const AllCompaniesPage = ({ activeDefault }) => {
  const navigate = useNavigate();
  const intl = useIntl();

  const isMobileOrTablet = useMediaQuery({
    query: '(max-width: 900px) ',
  });

  const getTabLocale = (tab) =>
    intl.formatMessage({
      id: `page.sa.allCompanies.tab.${tab}`,
      defaultMessage: defaultTabName[tab],
    });

  const tabs = [
    {
      title: getTabLocale('customers'),
      id: 1,
      page: <CustomersCompaniesPage />,
    },
    {
      title: getTabLocale('material'),
      id: 2,
      page: <MaterialSuppliersCompaniesPage />,
    },
    {
      title: getTabLocale('service'),
      id: 3,
      page: <ServiceProviderCompaniesPage />,
    },
    {
      title: getTabLocale('qa'),
      id: 4,
      page: <QualityAssurancePage />,
    },
  ];

  const onTabChange = (id) => {
    switch (id) {
      case 1:
        navigate('/all-companies/customers');
        break;
      case 2:
        navigate('/all-companies/supplier');
        break;
      case 3:
        navigate('/all-companies/providers');
        break;
      case 4:
        navigate('/all-companies/quality');
        break;
      default:
        break;
    }
  };

  return (
    <div className="company-page customer-page">
      <div className="company-page__inner">
        <div className="company-page__header">
          <span className="company-page__header-title">
            <FormattedMessage
              id="page.sa.allCompanies.title"
              defaultMessage="All Companies"
            />
          </span>
          <Button
            text={intl.formatMessage({
              id: 'page.sa.allCompanies.btn',
              defaultMessage: 'Add New Company',
            })}
            size={isMobileOrTablet ? BUTTON_SIZE.ADD_SMALL : BUTTON_SIZE.MEDIUM}
            role={BUTTON_ROLE.ADD_BUTTON}
            hideText={true}
            onClick={() => navigate('add-company')}
          />
        </div>

        <div className="company-page__body">
          <Tab
            activeDefault={activeDefault}
            tabs={tabs}
            onTabChange={(id) => onTabChange(id)}
          />
        </div>
      </div>
    </div>
  );
};

AllCompaniesPage.defaultProps = {
  activeDefault: 1,
};

AllCompaniesPage.propTypes = {
  activeDefault: PropTypes.number,
};

export default AllCompaniesPage;
