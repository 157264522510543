import React from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import Button from '../ui/Button';
import { BUTTON_SIZE, BUTTON_TYPE } from '../ui/Button/Button';
import { useNavigate } from 'react-router-dom';
import LoadedContent from '../LoadedContent';
import { FormattedMessage, useIntl } from 'react-intl';

export const CONFIRM_POPUP_TYPE = {
  DELETE: 'delete',
  SUPPLIER_ACCEPT: 'supplier_accept',
  PUBLISH_REQUEST: 'publish_request',
  ERROR_POPUP: 'error',
};

export const SupplierPopupContent = ({ data, max }) => {
  const navigate = useNavigate();

  return (
    <div className="confirm-popup__content-supplier">
      <span className="confirm-popup__content-text">
        <FormattedMessage
          id="popup.order.acceptPart"
          defaultMessage={`Are you sure want to accept only ${data.length} of ${max} suppliers?`}
          values={{
            value: data.length,
            max,
          }}
        />
      </span>
      <div className="confirm-popup__list">
        {data.map((item, index) => (
          <div key={index} className="confirm-popup__list-item">
            <span className="confirm-popup__list-title">{item.category}</span>
            <span
              className="confirm-popup__list-link link"
              onClick={() => navigate(`/customer/my-orders/company/${item.id}`)}
            >
              {item.name}
            </span>
          </div>
        ))}
      </div>
      <span className="confirm-popup__content-desc">
        <FormattedMessage
          id="popup.order.infoMsg"
          defaultMessage="The order will go into work, and you will not be able to receive offers
        from other companies for this order"
        />
      </span>
    </div>
  );
};

SupplierPopupContent.propTypes = {
  data: PropTypes.array.isRequired,
  max: PropTypes.number,
};

const ConfirmPopup = ({
  type,
  data,
  text,
  open,
  onCloseModal,
  onSubmit,
  isLoading,
  confirmButtonText,
  cancelButtonText,
  max,
}) => {
  const intl = useIntl();
  confirmButtonText =
    confirmButtonText ||
    intl.formatMessage({
      id: 'popup.btn.delete',
      defaultMessage: 'Delete',
    });
  cancelButtonText =
    cancelButtonText ||
    intl.formatMessage({
      id: 'popup.btn.cancel',
      defaultMessage: 'Cancel',
    });

  return (
    <Popup open={open} closeOnDocumentClick onClose={onCloseModal}>
      <div className="confirm-popup">
        <div className="confirm-popup__content">
          {type === CONFIRM_POPUP_TYPE.SUPPLIER_ACCEPT ? (
            <div className="confirm-popup__content-text">
              <LoadedContent loader={isLoading} />
              <SupplierPopupContent data={data} max={max} />
            </div>
          ) : (
            <div className="confirm-popup__content-text">
              <LoadedContent loader={isLoading} />
              {text}
            </div>
          )}
          <div className="confirm-popup__content-btn">
            {/* COMPONENT FOR DISABLE AUTOFOCUS ON OUTLINE BUTTON AFTER MODAL PRESENT */}
            <button style={{ display: 'none' }} autoFocus="true"></button>
            <Button
              text={cancelButtonText}
              type={BUTTON_TYPE.OUTLINE}
              size={BUTTON_SIZE.MEDIUM}
              onClick={onCloseModal}
              disabled={isLoading}
            />
            {type !== CONFIRM_POPUP_TYPE.ERROR_POPUP && (
              <Button
                text={confirmButtonText}
                size={BUTTON_SIZE.MEDIUM}
                onClick={onSubmit}
                disabled={isLoading}
              />
            )}
          </div>
        </div>
      </div>
    </Popup>
  );
};

ConfirmPopup.defaultProps = {
  type: CONFIRM_POPUP_TYPE.DELETE,
  data: [],
  max: 3,
};

ConfirmPopup.propTypes = {
  text: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  confirmButtonText: PropTypes.string.isRequired,
  cancelButtonText: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func,
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool.isRequired,
  max: PropTypes.number,
};

export default ConfirmPopup;
