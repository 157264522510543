import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';
import { useIntl } from 'react-intl';

export const STATUS_TYPE = {
  NOT_ANSWERED: 'not_answered',
  COMPLETED: 'completed',
  NULLED: 'nulled',
  CANCELED: 'canceled',
  IN_PROGRESS: 'in_progress',
  WAITING_FOR_ANSWER: 'waiting_for_answer',
  NEW_ORDER: 'new_order',
  DECLINED: 'declined',
  NEW: 'new',
};

export const STATUS_ICON = {
  not_answered: 'time',
  completed: 'check',
  nulled: 'nulled',
  canceled: 'cancel',
  in_progress: 'in-progress',
  waiting_for_answer: 'time',
  new_order: 'new',
  declined: 'cancel',
  new: 'new',
};

const Status = ({ type }) => {
  const intl = useIntl();

  const localeStatuse = (key, msg) =>
    intl.formatMessage({
      id: `status.${key}`,
      defaultMessage: msg,
    });

  const getName = (data) => {
    switch (data) {
      case STATUS_TYPE.NOT_ANSWERED:
        return localeStatuse(data, 'Not Answered');
      case STATUS_TYPE.COMPLETED:
        return localeStatuse(data, 'Completed');
      case STATUS_TYPE.NULLED:
        return localeStatuse(data, 'Nulled');
      case STATUS_TYPE.CANCELED:
        return localeStatuse(data, 'Canceled');
      case STATUS_TYPE.IN_PROGRESS:
        return localeStatuse(data, 'In Progress');
      case STATUS_TYPE.WAITING_FOR_ANSWER:
        return localeStatuse(data, 'Waiting for answer');
      case STATUS_TYPE.NEW_ORDER:
        return localeStatuse(data, 'New Order');
      case STATUS_TYPE.DECLINED:
        return localeStatuse(data, 'Declined');
      case STATUS_TYPE.NEW:
        return localeStatuse(data, 'New');
      default:
        return '';
    }
  };

  if (!getName(type)) {
    return null;
  }

  return (
    <div className={`status-wrapper`}>
      <div className={`status ${type}`}>
        <Icon icon={STATUS_ICON[type]} size="16px" />
        <span>{getName(type)}</span>
      </div>
    </div>
  );
};

Status.propTypes = {
  type: PropTypes.string.isRequired,
};

export default Status;
