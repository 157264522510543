import PropTypes from 'prop-types';
import React from 'react';
import UsersItem from '../UserItem';
import Icon from '../ui/Icon';
import LoadedContent from '../LoadedContent';
import Pagination from '../../components/Pagination';
import Title from '../ui/Title';
import { FormattedMessage } from 'react-intl';

const Users = ({
  list,
  isListLoading,
  currentPage,
  totalCount,
  idCompany,
  itsSuperAdmin,
  itsBanned,
  handleBanUser,
  callbackAction,
  listBanned,
}) => {
  if (itsBanned && list.length === 0) {
    return null;
  }
  return (
    <div
      className={`users-company-page customer-page ${
        itsBanned ? 'banned' : ''
      }`}
    >
      {itsBanned ? (
        <>
          <div className="users-company-page__banned"></div>
          <Title h2>
            <FormattedMessage
              id="page.sa.users.bannedListTitle"
              defaultMessage="Banned Users"
            />
          </Title>
        </>
      ) : null}
      <div className="users-company-page__inner">
        <div className="users-company-page__body">
          <div className="users-company-page customer-page">
            <LoadedContent loader={isListLoading} />
            <div className="users-company-page__inner">
              <div className="users-company-page__list">
                {list &&
                  list.map((item) => (
                    <div
                      key={item.id}
                      className="users-company-page__list-item"
                    >
                      <UsersItem
                        itsSuperAdmin={itsSuperAdmin}
                        idCompany={idCompany}
                        data={item}
                        itsBanned={itsBanned}
                        handleBanUser={handleBanUser}
                        callbackAction={callbackAction}
                      />
                    </div>
                  ))}
                {!isListLoading &&
                list &&
                !list.length &&
                (listBanned === undefined || listBanned.length === 0) ? (
                  <div className="empty-list">
                    <div className="empty-list__icon">
                      <Icon icon="users" size="52px" />
                    </div>
                    <span className="empty-list__title">
                      <FormattedMessage
                        id="page.users.noUsersMsg"
                        defaultMessage="You have no users"
                      />
                    </span>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <Pagination
                totalCount={totalCount}
                currentPage={currentPage}
                onPageChange={() => {}}
                typePage={itsBanned ? 'pageBanned' : 'page'}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Users.defaultProps = {
  list: [],
  totalCount: 1,
  currentPage: 1,
};

Users.propTypes = {
  isListLoading: PropTypes.bool,
  list: PropTypes.array,
  currentPage: PropTypes.number,
  totalCount: PropTypes.number,
  idCompany: PropTypes.string,
  itsSuperAdmin: PropTypes.bool,
  itsBanned: PropTypes.bool,
  handleBanUser: PropTypes.func,
  callbackAction: PropTypes.func,
  itsActive: PropTypes.func,
  listBanned: PropTypes.array,
};

export default Users;
