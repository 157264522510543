/* eslint-disable no-unused-vars */
/* eslint-disable no-unreachable */
import React from 'react';
import PropTypes from 'prop-types';
import Status from '../ui/Status/Status';
import { useNavigate } from 'react-router-dom';
import useIsMobile from '../../utils/useMobile';
import Button, {
  BUTTON_ROLE,
  BUTTON_SIZE,
  BUTTON_TYPE,
} from '../ui/Button/Button';
import _ from 'lodash';
import { orderViewIdFormat } from '../../utils/order-viewid.format';
import { FormattedMessage, useIntl } from 'react-intl';
import { resolveTabPath } from '../../utils/resolveTabPath';
import { offersSort } from '../../utils/offers.sort';

const tenderAllowedStatus = {
  not_answered: 'not_answered',
  waiting_for_answer: 'waiting_for_answer',
  new_order: 'new_order',
};

const companyType = {
  factory: 'Material Supplier',
  service: 'Service Provider',
  'quality-control': 'Quality Assurance',
};

const toSelectAllowedStatus = {
  not_answered: 'not_answered',
  waiting_for_answer: 'waiting_for_answer',
  new_order: 'new_order',
};

const canceledStatus = {
  declined: 'declined',
  canceled: 'canceled',
};

const approvedStatus = {
  completed: 'completed',
  in_progress: 'in_progress',
  new_order: 'new_order',
};

export const CARD_TYPE = {
  VERTICAL: 'vertical',
  HORIZONTAL: 'horizontal',
};

const colors = {
  reject: 'var(--black-primary)',
  complete: 'var(--green-accept)',
  new: 'var(--green-accept)',
};

const OrderSupplierCardSA = ({
  data,
  type,
  itsNewOrders,
  itsDeclined,
  itsWait,
  itsCurrent,
  itsArchive,
  declineOrder,
  unDeclineOrder,
  noneTender,
  companyId,
}) => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const intl = useIntl();

  const getCurrentSupplierName = () => {
    const tabName = resolveTabPath();

    return tabName === 'supplier'
      ? 'factory'
      : tabName === 'providers'
      ? 'service'
      : 'quality-control';
  };

  const getAcceptedOffers = (arr) => {
    if (data && data.status === 'in_progress' && data.offers) {
      return data.offers.filter((item) => item.company.id !== companyId);
    }

    if (
      data &&
      data.status !== 'in_progress' &&
      data.status !== 'new' &&
      data.status !== 'waiting_for_answer' &&
      data.otherOffers
    ) {
      return data.otherOffers.filter(
        (item) => item.status === 'accept' && item.company.id !== companyId
      );
    }

    if (data?.allOffers?.length) {
      return data.allOffers.filter((item) => {
        return item.status === 'accept' && item.company.id !== companyId;
      });
    }

    return [];
  };

  const getMyOffer = () => {
    if (data?.myNotAcceptOffer?.length) {
      return data.myNotAcceptOffer;
    }

    if (data?.offers?.length) {
      return data.offers.filter((item) => item.company.id === companyId);
    }

    if (data?.otherOffers?.length) {
      return data.otherOffers.filter((item) => item.company.id === companyId);
    }

    if (data?.allOffers?.length) {
      return data.allOffers.filter((item) => item.company.id === companyId);
    }
    return [];
  };

  return (
    <div
      className={
        !noneTender && tenderAllowedStatus[data.status]
          ? `order-card-supplier tender ${type}`
          : `order-card-supplier ${type}`
      }
    >
      <div className="order-card-supplier__card">
        <div className="order-card-supplier__content">
          <div className="order-card-supplier__header">
            {!isMobile && (
              <div className="order-card-supplier__status">
                <Status type={data && data.status} />
                {data && (data.edited || data.in_editing) && (
                  <span className="edited-label">
                    <FormattedMessage
                      id={`status.${
                        !data.in_editing && data.edited ? 'edited' : 'editing'
                      }`}
                      defaultMessage={
                        !data.in_editing && data.edited ? 'Edited' : 'Editing'
                      }
                    />
                  </span>
                )}
              </div>
            )}
            <div className="order-card-supplier__title">
              <span>{data?.project?.company?.name}</span>
              <span>
                {orderViewIdFormat(data.viewId)}
                <span className="order-card-supplier__desc">
                  <FormattedMessage
                    id="order.mainName_sm"
                    defaultMessage="Concrete casting Order"
                  />
                </span>
              </span>
              <span>{data.project.name}</span>
            </div>
            {isMobile && (
              <div className="order-card-supplier__status">
                <Status type={data && data.status} />
                {data && (data.edited || data.in_editing) && (
                  <span className="edited-label">
                    <FormattedMessage
                      id={`status.${
                        !data.in_editing && data.edited ? 'edited' : 'editing'
                      }`}
                      defaultMessage={
                        !data.in_editing && data.edited ? 'Edited' : 'Editing'
                      }
                    />
                  </span>
                )}
              </div>
            )}
          </div>
          <div className="order-card-supplier__body">
            <div className="order-card-supplier__desc-bottom">
              <div className="order-card-supplier__desc-col">
                <div className="order-card-supplier__desc-title">
                  <FormattedMessage
                    id="order.dateOfExecution"
                    defaultMessage="Date of execution"
                  />
                </div>
                <div className="order-card-supplier__desc-subtitle">
                  {data.dateOfExecution?.replaceAll('-', '.')}
                </div>
              </div>
              <div className="order-card-supplier__desc-col">
                <div className="order-card-supplier__desc-title">
                  <FormattedMessage id="company.city" defaultMessage="City" />
                </div>
                <div className="order-card-supplier__desc-subtitle">
                  {data.project.city}
                </div>
              </div>
              <div className="order-card-supplier__desc-col">
                <div className="order-card-supplier__desc-title">
                  <FormattedMessage
                    id="order.area"
                    defaultMessage="Area, m&sup2;"
                  />
                </div>
                <div className="order-card-supplier__desc-subtitle">
                  {data.area}
                </div>
              </div>
              <div className="order-card-supplier__desc-col">
                <div className="order-card-supplier__desc-title">
                  <FormattedMessage
                    id="order.volume"
                    defaultMessage="Volume, m&sup3;"
                  />
                </div>
                <div className="order-card-supplier__desc-subtitle">
                  {data.volume}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="order-card-supplier__footer">
          {itsDeclined ? (
            <div className="order-card-supplier__footer-btns">
              <Button
                role={BUTTON_ROLE.DETAIL}
                type={BUTTON_TYPE.PRIMARY}
                size={!isMobile ? BUTTON_SIZE.MEDIUM : BUTTON_SIZE.BIG}
                text={intl.formatMessage({
                  id: `btn.viewDetails`,
                  defaultMessage: 'View Details',
                })}
                onClick={() => {
                  navigate(`order/${data.id}`);
                }}
              />
            </div>
          ) : null}
          {itsArchive && data.status === 'declined' ? (
            <div
              className="declied-btn-container"
              style={{
                flex: 'none',
              }}
            >
              <Button
                role={BUTTON_ROLE.DETAIL}
                type={BUTTON_TYPE.TERTIARY}
                size={!isMobile ? BUTTON_SIZE.MEDIUM : BUTTON_SIZE.BIG}
                text={intl.formatMessage({
                  id: `btn.viewOffer`,
                  defaultMessage: 'View Your Offer',
                })}
                onClick={() => {
                  navigate(`order/${data.id}`);
                }}
              />
            </div>
          ) : null}
          {itsCurrent || (itsArchive && data.status !== 'nulled') ? (
            <div className="order-card-supplier__content">
              <div
                className="order-card-supplier__header"
                style={{
                  width: !getAcceptedOffers().length ? '100%' : '292px',
                  maxWidth: !getAcceptedOffers().length ? '100%' : '292px',
                  paddingRight: '5px',
                }}
              >
                {data.status === 'completed' ? (
                  <div
                    className="order-card-supplier__footer-accept"
                    style={{
                      color: colors[getMyOffer()[0].status],
                    }}
                  >
                    <FormattedMessage
                      id="page.orders.acceptedOfferMsg_1"
                      defaultMessage="Your offer as"
                    />{' '}
                    <span
                      style={{
                        color: colors[getMyOffer()[0].status],
                      }}
                    >
                      {intl.formatMessage({
                        id: `company.supplier.${getCurrentSupplierName()}`,
                        defaultMessage: companyType[getCurrentSupplierName()],
                      })}{' '}
                    </span>
                    <FormattedMessage
                      id={`page.orders.acceptedOfferMsg_2.${
                        getMyOffer()[0].status
                      }`}
                      defaultMessage="accepted"
                    />
                  </div>
                ) : null}
                {data.status === 'in_progress' ? (
                  <div
                    className="order-card-supplier__footer-accept"
                    style={{
                      color: colors[getMyOffer()[0].status],
                    }}
                  >
                    <FormattedMessage
                      id="page.orders.acceptedOfferMsg_1"
                      defaultMessage="Your offer as"
                    />{' '}
                    <span
                      style={{
                        color: colors[getMyOffer()[0].status],
                      }}
                    >
                      {intl.formatMessage({
                        id: `company.supplier.${getCurrentSupplierName()}`,
                        defaultMessage: companyType[getCurrentSupplierName()],
                      })}{' '}
                    </span>
                    <FormattedMessage
                      id={`page.orders.acceptedOfferMsg_2.${
                        getMyOffer()[0].status
                      }`}
                      defaultMessage="accepted"
                    />
                  </div>
                ) : null}

                {data.status === 'canceled' ? (
                  <div
                    className="order-card-supplier__footer-accept"
                    style={{
                      color: 'var(--black-primary)',
                    }}
                  >
                    <FormattedMessage
                      id="page.orders.acceptedOfferMsg_1"
                      defaultMessage="Your offer as"
                    />{' '}
                    <span
                      style={{
                        color: 'var(--black-primary)',
                      }}
                    >
                      {intl.formatMessage({
                        id: `company.supplier.${getCurrentSupplierName()}`,
                        defaultMessage: companyType[getCurrentSupplierName()],
                      })}{' '}
                    </span>
                    <FormattedMessage
                      id={`page.orders.acceptedOfferMsg_2.${
                        getMyOffer()[0].status
                      }`}
                      defaultMessage="accepted"
                    />
                  </div>
                ) : null}
                {data.status === 'in_progress' ||
                data.status === 'canceled' ||
                data.status === 'completed' ? (
                  <div className="order-card-supplier__footer-count">
                    {_.sumBy(getMyOffer(), (item) => +item.amount) || 0} kr
                  </div>
                ) : null}
              </div>
              <div className="order-card-supplier-description__body">
                {itsCurrent || itsArchive ? (
                  !canceledStatus[data.status] || data.status === 'canceled' ? (
                    <div className="order-card-supplier__desc-description">
                      <div className="order-card-supplier__selected-list">
                        {(data.status === 'canceled' ||
                          data.status === 'in_progress' ||
                          data.status === 'completed') &&
                          offersSort(getAcceptedOffers()).map((item, index) => (
                            <div
                              key={index}
                              className="order-card-supplier__desc-col"
                            >
                              <div
                                className="order-card__selected-item"
                                key={index}
                              >
                                <span>
                                  {intl.formatMessage({
                                    id: `company.supplier.${item.company.spec}`,
                                    defaultMessage:
                                      companyType[item.company.spec],
                                  })}
                                </span>
                                <a
                                  className="link"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    navigate(
                                      `/all-companies/${
                                        item.company.spec === 'service'
                                          ? 'providers'
                                          : item.company.spec ===
                                            'quality-control'
                                          ? 'quality'
                                          : 'supplier'
                                      }/company/${item.company.id}`
                                    );
                                  }}
                                >
                                  {item.company.name}
                                </a>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  ) : null
                ) : null}
              </div>
            </div>
          ) : null}
          {itsWait && data.hasMyOffer ? (
            <div className="order-card-supplier__footer-btns full">
              <Button
                role={BUTTON_ROLE.DETAIL}
                type={BUTTON_TYPE.PRIMARY}
                size={!isMobile ? BUTTON_SIZE.MEDIUM : BUTTON_SIZE.BIG}
                text={
                  data.project.numberOfOrders > 0
                    ? intl.formatMessage({
                        id: `btn.viewDetails`,
                        defaultMessage: 'View Details',
                      })
                    : intl.formatMessage({
                        id: `btn.viewOffer`,
                        defaultMessage: 'View Your Offer',
                      })
                }
                onClick={() => {
                  navigate(`order/${data.id}`);
                }}
                disabled={data.project.numberOfOrders > 0}
              />
              {data.project.numberOfOrders > 0 && (
                <div className="order-card-supplier__footer-text">
                  <FormattedMessage
                    id="page.orderDetail.makeOffer.exist"
                    defaultMessage="Your company sent an offer for this order"
                  />
                </div>
              )}
            </div>
          ) : null}
          {itsWait && !data.hasMyOffer ? (
            <div className="order-card-supplier__footer-btns full">
              <Button
                role={BUTTON_ROLE.DETAIL}
                type={BUTTON_TYPE.TERTIARY}
                size={!isMobile ? BUTTON_SIZE.MEDIUM : BUTTON_SIZE.BIG}
                text={
                  data.project.numberOfOrders > 0
                    ? intl.formatMessage({
                        id: `btn.viewDetails`,
                        defaultMessage: 'View Details',
                      })
                    : intl.formatMessage({
                        id: `btn.viewDetails`,
                        defaultMessage: 'View Details',
                      })
                }
                onClick={() => {
                  navigate(`order/${data.id}`);
                }}
                disabled={data.project.numberOfOrders > 0}
              />
              {data.project.numberOfOrders > 0 && (
                <div className="order-card-supplier__footer-text">
                  {intl.formatMessage({
                    id: `btn.viewDetails`,
                    defaultMessage: 'View Details',
                  })}
                </div>
              )}
            </div>
          ) : null}

          {itsArchive && data.status === 'nulled' ? (
            <div className="order-card-supplier__footer-btns nulled">
              <Button
                role={BUTTON_ROLE.DETAIL}
                type={BUTTON_TYPE.TERTIARY}
                size={!isMobile ? BUTTON_SIZE.MEDIUM : BUTTON_SIZE.BIG}
                text={intl.formatMessage({
                  id: `btn.viewOffer`,
                  defaultMessage: 'View Your Offer',
                })}
                onClick={() => {
                  navigate(`order/${data.id}`);
                }}
              />
            </div>
          ) : null}

          {itsNewOrders ? (
            <div className="order-card-supplier__footer-btns">
              <Button
                role={BUTTON_ROLE.DETAIL}
                type={BUTTON_TYPE.PRIMARY}
                size={!isMobile ? BUTTON_SIZE.MEDIUM : BUTTON_SIZE.BIG}
                text={intl.formatMessage({
                  id: `btn.viewDetails`,
                  defaultMessage: 'View Details',
                })}
                onClick={() => {
                  navigate(`order/${data.id}`);
                }}
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

OrderSupplierCardSA.defaultProps = {
  type: CARD_TYPE.VERTICAL,
};

OrderSupplierCardSA.propTypes = {
  data: PropTypes.object.isRequired,
  type: PropTypes.string,
  itsDeclined: PropTypes.bool,
  itsNewOrders: PropTypes.bool,
  itsWait: PropTypes.bool,
  itsCurrent: PropTypes.bool,
  itsArchive: PropTypes.bool,
  declineOrder: PropTypes.func,
  unDeclineOrder: PropTypes.func,
  noneTender: PropTypes.bool,
  companyId: PropTypes.string,
};

export default OrderSupplierCardSA;
