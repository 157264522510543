import React from 'react';
import Title from '../../../components/ui/Title';
import Tab from '../../../components/ui/Tab';
import PropTypes from 'prop-types';
import PersonalInfo from './PersonalInfo/PersonalInfo';
import { useNavigate } from 'react-router-dom';
import Password from './Password';
import Users from './Users';
import { FormattedMessage, useIntl } from 'react-intl';

const MyCompany = ({ activeDefault = 1 }) => {
  const navigate = useNavigate();
  const intl = useIntl();

  const tabs = [
    {
      title: intl.formatMessage({
        id: `page.sa.myAcc.tab.info`,
        defaultMessage: 'Personal Info',
      }),
      id: 1,
      page: <PersonalInfo />,
    },
    {
      title: intl.formatMessage({
        id: `page.sa.myAcc.tab.password`,
        defaultMessage: 'Password',
      }),
      id: 2,
      page: <Password />,
    },
    {
      title: intl.formatMessage({
        id: `page.sa.myAcc.tab.users`,
        defaultMessage: 'Users',
      }),
      id: 3,
      page: <Users />,
    },
  ];

  const onTabChange = (id) => {
    switch (id) {
      case 1:
        navigate('/my-account/personal-info');
        break;
      case 2:
        navigate('/my-account/password');
        break;
      case 3:
        navigate('/my-account/users');
        break;
      default:
        break;
    }
  };

  return (
    <div className="my-company-page">
      <Title>
        <FormattedMessage
          id="page.sa.myAcc.title"
          defaultMessage="My Account"
        />
      </Title>
      <Tab
        onTabChange={(id) => onTabChange(id)}
        activeDefault={activeDefault}
        tabs={tabs}
      />
    </div>
  );
};

MyCompany.defaultProps = {
  activeDefault: 1,
};

MyCompany.propTypes = {
  activeDefault: PropTypes.number,
};

export default MyCompany;
