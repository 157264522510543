import API from '../api';

export const oAuthToken = (data) => API.post('/api/api/v1/oauth/token', data);

export const oAuthTokenRefresh = (data) =>
  API.post('/api/api/v1/oauth/token/refresh', data);

export const userChangePassword = (data) =>
  API.post('/api/api/v1/users/change-password', data);

export const userResetPasswordRequest = (data) =>
  API.post('/api/api/v1/users/reset-password', data);

export const userResetPasswordConfirm = (data) =>
  API.post('/api/api/v1/users/reset-password/confirm', data);

export const newPassword = (data) =>
  API.post('/api/api/v1/new/password/email', data);
