/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import Button from '../ui/Button';
import { BUTTON_SIZE, BUTTON_TYPE } from '../ui/Button/Button';
import { FormattedMessage, useIntl } from 'react-intl';
import { numberFormat } from '../../utils/number.format';

const ConfirmSupplierOfferPopup = ({
  text,
  open,
  onCloseModal,
  onSubmit,
  isLoading,
  inEditing,
}) => {
  const intl = useIntl();

  const localeSpec = () =>
    intl.formatMessage({
      id: `company.supplier.${localStorage.getItem('companySpec')}`,
    });

  return (
    <Popup open={open} closeOnDocumentClick onClose={onCloseModal}>
      <div className="confirm-popup">
        <div className="confirm-popup__content">
          <div className="confirm-popup__content-text">
            {inEditing === false ? (
              <>
                <FormattedMessage
                  id="popup.sendOffer.sendTitle"
                  defaultMessage={`Send an offer as ${localeSpec()} for the amount (excl. VAT)`}
                  values={{
                    spec: localeSpec(),
                  }}
                />
                <br />
                {numberFormat(text)} kr
              </>
            ) : (
              <>
                <FormattedMessage
                  id="popup.sendOffer.noSendTitle"
                  defaultMessage="You can't send an offer because customer is editing it right now"
                />
              </>
            )}
          </div>
          <div className="confirm-popup__content-btn">
            {/* COMPONENT FOR DISABLE AUTOFOCUS ON OUTLINE BUTTON AFTER MODAL PRESENT */}
            <button style={{ display: 'none' }} autoFocus="true"></button>
            {inEditing === false ? (
              <>
                <Button
                  text={intl.formatMessage({
                    id: 'btn.cancel',
                    defaultMessage: 'Cancel',
                  })}
                  type={BUTTON_TYPE.OUTLINE}
                  size={BUTTON_SIZE.MEDIUM}
                  onClick={onCloseModal}
                  disabled={isLoading}
                />
                <Button
                  text={intl.formatMessage({
                    id: 'btn.send',
                    defaultMessage: 'Send',
                  })}
                  size={BUTTON_SIZE.MEDIUM}
                  onClick={onSubmit}
                  disabled={isLoading}
                />
              </>
            ) : (
              <Button
                text={intl.formatMessage({
                  id: 'btn.okay',
                  defaultMessage: 'Okay',
                })}
                size={BUTTON_SIZE.MEDIUM}
                type={BUTTON_TYPE.OUTLINE}
                onClick={onCloseModal}
                disabled={isLoading}
              />
            )}
          </div>
        </div>
      </div>
    </Popup>
  );
};

ConfirmSupplierOfferPopup.propTypes = {
  text: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func,
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool.isRequired,
  inEditing: PropTypes.bool,
};

export default ConfirmSupplierOfferPopup;
