import { configureStore } from '@reduxjs/toolkit';

import authReducer from './slice/authSlice';
import personalInfoReducer from './slice/personalInfoSlice';
import companyReducer from './slice/companySlice';
import projectReducer from './slice/projectSlice';
import usersCompanyReducer from './slice/usersCompanySlice';
import orderReducer from './slice/orderSlice';
import orderCustomerReducer from './slice/orderCustomerSlice';
import ordersReducer from './slice/ordersSlice';
import orderSupplierReducer from './slice/orderSupplierSlice';
import dictionaryReducer from './slice/dictionarySlice';
import usersAdminsReducer from './slice/usersAdminsSlice';
import notificationsReducer from './slice/notificationSlice';
import orderOfferSupplierReducer from './slice/orderOfferSupplierSlice';
import ordersCustomerReducer from './slice/ordersCustomerSlise';
import orderOfferSupplierSaReducer from './slice/orderOfferSupplierSaSlice';
import ordersSupplierSaReducer from './slice/ordersSupplierSaSlice';
import orderCustomerSAReducer from './slice/orderCustomerSASlice';

export default configureStore({
  reducer: {
    auth: authReducer,
    personalInfo: personalInfoReducer,
    company: companyReducer,
    project: projectReducer,
    usersCompany: usersCompanyReducer,
    order: orderReducer,
    orderCustomerSA: orderCustomerSAReducer,
    orderCustomer: orderCustomerReducer,
    orders: ordersReducer,
    // supplier list
    ordersSupplier: orderSupplierReducer,
    // customer list
    dictionary: dictionaryReducer,

    usersAdmins: usersAdminsReducer,
    notifications: notificationsReducer,
    orderSupplier: orderOfferSupplierReducer,
    ordersCustoemr: ordersCustomerReducer,

    orderSupplierSA: orderOfferSupplierSaReducer,
    ordersSupplierSA: ordersSupplierSaReducer,
  },
});
