import React from 'react';
import PropTypes from 'prop-types';
import { numberFormat } from '../../../../../utils/number.format';
import { useNavigate } from 'react-router-dom';
import { offersSort } from '../../../../../utils/offers.sort';
import { FormattedMessage, useIntl } from 'react-intl';

const companyType = {
  factory: 'Material Supplier',
  service: 'Service Provider',
  'quality-control': 'Quality Assurance',
};

const AcceptedOffersInProgress = ({ offers }) => {
  const navigate = useNavigate();
  const intl = useIntl();

  return (
    <>
      <div className="accepted-offers-inprogress-desctop">
        <div className="accepted-offers-inprogress-desctop__inner">
          <div className="accepted-offers-inprogress-desctop__title">
            <span>
              <FormattedMessage
                id="page.orderDetail.listTitle"
                defaultMessage="Selected suppliers"
              />
            </span>
          </div>
          <div className="accepted-offers-inprogress-desctop__content">
            <div className="accepted-offers-inprogress-desctop__list">
              {offersSort(offers).map((item, index) => {
                return (
                  <div
                    key={index}
                    className="accepted-offers-inprogress-desctop__list-item"
                  >
                    <div className="accepted-offers-inprogress-desctop__list-item-type">
                      <span>
                        {intl.formatMessage({
                          id: `company.supplier.${item.company.spec}`,
                          defaultMessage: companyType[item.company.spec],
                        })}
                      </span>
                    </div>
                    <div className="accepted-offers-inprogress-desctop__list-item-name">
                      <span
                        className="link"
                        onClick={() =>
                          navigate(
                            `/customer/my-orders/company/${item.company.id}`
                          )
                        }
                      >
                        {item.company.name}
                      </span>
                    </div>
                    <div className="accepted-offers-inprogress-desctop__list-item-amount">
                      <span>{numberFormat(item.amount)} kr</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

AcceptedOffersInProgress.defaultProps = {
  offers: [],
};

AcceptedOffersInProgress.propTypes = {
  offers: PropTypes.array.isRequired,
};

export default AcceptedOffersInProgress;
