import React from 'react';
import PropTypes from 'prop-types';
import Spinner from '../ui/Spinner/Spinner';

const LoadedContent = ({ loader }) => {
  return loader ? (
    <div className="container_spinner">
      <Spinner />
    </div>
  ) : null;
};

LoadedContent.propTypes = {
  loader: PropTypes.bool,
};

export default LoadedContent;
