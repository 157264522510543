/* eslint-disable no-unused-expressions */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { orderSupplier, orderOfferSupplier } from '../../api/order';
// import { orderObjectMapperSA } from '../../utils/order-mapper';
import moment from 'moment';

// orderOfferSupplier
export const getMyOrderOfferSupplier = createAsyncThunk(
  'orderSupplier/getOrderOfferSupplier',
  async (creds, { rejectWithValue }) => {
    try {
      const response = await orderOfferSupplier(creds);
      if (response.status === 200) {
        return response.data.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getMyOrderSupplier = createAsyncThunk(
  'orderSupplier/getOrderSupplier',
  async (creds, { rejectWithValue }) => {
    try {
      const response = await orderSupplier(creds);
      if (response.status === 200) {
        response.data.data.status === 'new'
          ? (response.data.data.status = 'new_order')
          : null;
        response.data.data.timeOfStart = moment(
          response.data.data.timeOfStart,
          'hh:mm:ss'
        ).format('HH:mm');
        return response.data.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const initialState = {
  isListLoading: false,
  isProjectLoading: false,
  isLoadingEdit: false,
  isErrorProject: false,
  dataProject: {
    project: {},
  },
  data: {},
  meta: {},
  isListOfferLoading: false,
  isOfferError: false,
  dataOffer: {},

  withoutOffer: null,
};

const orderSupplierSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    // setClearOrder(state) {
    //   state = {
    //     isListLoading: false,
    //     isProjectLoading: false,
    //     isLoadingEdit: false,
    //     isErrorProject: false,
    //     data: {},
    //     meta: {},
    //   };
    // },
  },
  extraReducers: (builder) => {
    builder.addCase(getMyOrderSupplier.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
      state.data = {};
    });
    builder.addCase(getMyOrderSupplier.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
      state.data = {};
    });
    builder.addCase(getMyOrderSupplier.fulfilled, (state, payload) => {
      state.isLoading = false;
      state.isError = false;
      state.data = payload.payload;
    });

    builder.addCase(getMyOrderOfferSupplier.pending, (state) => {
      state.isListOfferLoading = true;
      state.isOfferError = false;
      state.dataOffer = {};
      state.withoutOffer = null;
    });
    builder.addCase(getMyOrderOfferSupplier.rejected, (state, payload) => {
      if (payload.payload.status === 404) {
        state.withoutOffer = true;
      } else {
        state.withoutOffer = false;
      }
      state.isListOfferLoading = false;
      state.isOfferError = true;
      state.dataOffer = {};
    });
    builder.addCase(getMyOrderOfferSupplier.fulfilled, (state, payload) => {
      state.isListOfferLoading = false;
      state.isOfferError = false;
      state.dataOffer = payload.payload;
    });
  },
});

const { reducer } = orderSupplierSlice;

export const selectProject = (state) => state.orderSupplier;

export default reducer;
