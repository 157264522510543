import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { usersAdmins } from '../../api/users';

// myUserGet

export const getUsersSuperAdmins = createAsyncThunk(
  'userAdmins/getUser',
  async (creds, { rejectWithValue }) => {
    try {
      const response = await usersAdmins(creds.query);
      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getUsersSuperAdminsBanned = createAsyncThunk(
  'userAdmins/getUserBanned',
  async (creds, { rejectWithValue }) => {
    try {
      const response = await usersAdmins({
        status: 'banned',
        ...creds.query,
      });
      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export function startLoading(text) {
  return {
    type: 'ADD_LOADING',
  };
}
export function stopLoading(text) {
  return {
    type: 'REMOVE_LOADING',
  };
}
export function changeStatusRole(id, status) {
  return {
    type: 'CHANDE_ROLE',
    id: id,
    status: status,
  };
}
const initialState = {
  isListLoading: false,
  isListError: false,
  data: [],
  meta: {},

  isBannedListLoading: false,
  bannedData: [],
  bannedMeta: {},
};

const userSlice = createSlice({
  name: 'userAdmins',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getUsersSuperAdmins.pending, (state) => {
      state.isListLoading = true;
      state.isListError = false;
      state.data = [];
    });
    builder.addCase(getUsersSuperAdmins.fulfilled, (state, payload) => {
      state.isListLoading = false;
      state.isError = false;
      state.data = payload.payload.data;
      state.meta = payload.payload.meta;
    }); // rejected
    builder.addCase(getUsersSuperAdmins.rejected, (state) => {
      state.isListLoading = false;
      state.isListError = true;
    });

    builder.addCase(getUsersSuperAdminsBanned.pending, (state) => {
      state.isBannedListLoading = true;
      state.isListError = false;
      state.bannedData = [];
    });
    builder.addCase(getUsersSuperAdminsBanned.fulfilled, (state, payload) => {
      state.isBannedListLoading = false;
      state.isError = false;
      state.bannedData = payload.payload.data;
      state.bannedMeta = payload.payload.meta;
    });
    builder.addCase(getUsersSuperAdminsBanned.rejected, (state) => {
      state.isBannedListLoading = false;
      state.isListError = true;
    });

    builder.addCase('ADD_LOADING', (state, action) => {
      state.isListLoading = true;
    });
    builder.addCase('REMOVE_LOADING', (state, action) => {
      state.isListLoading = false;
    });
    builder.addCase('CHANDE_ROLE', (state, action) => {
      const data = state.data.map((item) => {
        if (item.id === action.id) {
          return {
            ...item,
            status: action.status,
          };
        }
        return item;
      });
      state.data = data;
    });
  },
});

const { reducer } = userSlice;

export const selectUser = (state) => state.userAdmins;
export default reducer;
