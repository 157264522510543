import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Status from '../ui/Status/Status';
import { useNavigate } from 'react-router-dom';
import useIsMobile from '../../utils/useMobile';
import moment from 'moment';
import { offersSort } from '../../utils/offers.sort';
import { orderViewIdFormat } from '../../utils/order-viewid.format';
import { FormattedMessage, useIntl } from 'react-intl';

const isNewOrder = {
  not_answered: 'not_answered',
  waiting_for_answer: 'waiting_for_answer',
  new_order: 'new_order',
};

const companyType = {
  factory: 'Material Supplier',
  service: 'Service Provider',
  'quality-control': 'Quality Assurance',
};

const toSelectAllowedStatus = {
  not_answered: 'not_answered',
  waiting_for_answer: 'waiting_for_answer',
  new_order: 'new_order',
};

const canceledStatus = {
  declined: 'declined',
  canceled: 'canceled',
};

const approvedStatus = {
  completed: 'completed',
  in_progress: 'in_progress',
};

export const CARD_TYPE = {
  VERTICAL: 'vertical',
  HORIZONTAL: 'horizontal',
};

const OrderCard = ({ data, type, path }) => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const ref = useRef(null);
  const role = localStorage.getItem('role');
  const intl = useIntl();

  const selectedMsg = (count, countTo) =>
    intl.formatMessage(
      {
        id: `component.orderCard.msg.selected`,
        defaultMessage: `Selected ${count} of ${countTo}`,
      },
      {
        value: count,
        countTo: countTo,
      }
    );

  console.log(data);

  const canceledMsg = () =>
    intl.formatMessage({
      id: `component.orderCard.msg.canceled`,
      defaultMessage: 'You canceled this order',
    });

  const approvedMsg = () =>
    intl.formatMessage({
      id: `component.orderCard.msg.approved`,
      defaultMessage: 'Suppliers Approved',
    });

  const nulledMsg = () =>
    intl.formatMessage({
      id: `component.orderCard.msg.nulled`,
      defaultMessage: 'You have not approved any suppliers, order was nulled',
    });

  return (
    <div
      className={
        isNewOrder[data.status]
          ? `order-card tender ${type}`
          : `order-card ${type}`
      }
      onClick={(e) => {
        if (e.target.tagName !== 'A') {
          navigate(path);
        }
      }}
    >
      <div className="order-card__inner">
        <div className="order-card__header" ref={ref}>
          {!isMobile && (
            <div className="order-card__status">
              <Status type={data && data.status} />
              {data && (data.edited || data.in_editing) && (
                <span className="edited-label">
                  <FormattedMessage
                    id={`status.${
                      !data.in_editing && data.edited ? 'edited' : 'editing'
                    }`}
                    defaultMessage={
                      !data.in_editing && data.edited ? 'Edited' : 'Editing'
                    }
                  />
                </span>
              )}
            </div>
          )}
          <div className="order-card__title">
            <span>
              <FormattedMessage
                id="order.mainName"
                defaultMessage="Concrete casting Order"
              />
            </span>
            <span>{orderViewIdFormat(data.viewId)}</span>
            <span
              className={!data.acceptOffers.length ? 'no-accept-offers' : ''}
            >
              {data.project.name}
            </span>
          </div>
          {isMobile && (
            <div className="order-card__status">
              <Status type={data && data.status} />
              {data && (data.edited || data.in_editing) && (
                <span className="edited-label">
                  <FormattedMessage
                    id={`status.${
                      !data.in_editing && data.edited ? 'edited' : 'editing'
                    }`}
                    defaultMessage={
                      !data.in_editing && data.edited ? 'Edited' : 'Editing'
                    }
                  />
                </span>
              )}
            </div>
          )}
        </div>
        <div className="order-card__body">
          <div className="order-card__desc">
            <div className="order-card__desc-col">
              <div className="order-card__desc-title">
                <FormattedMessage
                  id="order.dateOfExecution"
                  defaultMessage="Date of execution"
                />
              </div>
              <div className="order-card__desc-subtitle">
                {moment(data.dateOfExecution).format('YYYY.MM.DD')}
              </div>
            </div>
            <div className="order-card__desc-col">
              <div className="order-card__desc-title">
                <FormattedMessage id="company.city" defaultMessage="City" />
              </div>
              <div className="order-card__desc-subtitle">
                {data.project.city}
              </div>
            </div>
            <div className="order-card__desc-col">
              <div className="order-card__desc-title">
                <FormattedMessage
                  id="order.area"
                  defaultMessage="Area, m&sup2;"
                />
              </div>
              <div className="order-card__desc-subtitle">{data.area}</div>
            </div>
            <div className="order-card__desc-col">
              <div className="order-card__desc-title">
                <FormattedMessage
                  id="order.volume"
                  defaultMessage="Volume, m&sup3;"
                />
              </div>
              <div className="order-card__desc-subtitle">{data.volume}</div>
            </div>
          </div>
          {isNewOrder[data.status] || type === 'vertical' ? (
            <div
              className="order-card__selected"
              style={{
                paddingBottom:
                  data.acceptOffers.length &&
                  isNewOrder[data.status] &&
                  isMobile
                    ? '14px'
                    : '0',
              }}
            >
              <div
                className="order-card__selected-title"
                style={{
                  fontSize:
                    toSelectAllowedStatus[data.status] &&
                    data.acceptOffers.length
                      ? '12px'
                      : '14px',
                }}
              >
                {toSelectAllowedStatus[data.status] && data.acceptOffers.length
                  ? data.orderClass === 'no'
                    ? selectedMsg(data.acceptOffers.length, 2)
                    : selectedMsg(data.acceptOffers.length, 3)
                  : ''}
                {approvedStatus[data.status] && !isMobile ? approvedMsg() : ''}
                {canceledStatus[data.status] ? canceledMsg() : ''}
                {data.status === 'nulled' ? nulledMsg() : ''}
              </div>
              {data.status !== 'declined' ? (
                <div
                  className="order-card__selected-list"
                  style={{
                    display:
                      data.acceptOffers && !data.acceptOffers.length
                        ? 'none'
                        : 'flex',
                  }}
                >
                  {offersSort(data.acceptOffers).map((item, index) => (
                    <div className="order-card__selected-item" key={index}>
                      <span>
                        {intl.formatMessage({
                          id: `company.supplier.${item.company.spec}`,
                          defaultMessage: companyType[item.company.spec],
                        })}
                      </span>
                      <a
                        className="link"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate(
                            role === 'super admin'
                              ? `/all-companies/${
                                  item.company.spec === 'service'
                                    ? 'providers'
                                    : item.company.spec === 'quality-control'
                                    ? 'quality'
                                    : 'supplier'
                                }/company/${item.company.id}`
                              : `/customer/my-orders/company/${item.company.id}`
                          );
                        }}
                      >
                        {item.company.name}
                      </a>
                    </div>
                  ))}
                </div>
              ) : (
                ''
              )}
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
      {!isNewOrder[data.status] && type !== 'vertical' ? (
        <div
          className="order-card__selected not-new"
          style={{
            paddingBottom:
              data.acceptOffers.length && isNewOrder[data.status] && isMobile
                ? '14px'
                : '0',
          }}
        >
          <div
            className="order-card__selected-title"
            style={{
              fontSize:
                toSelectAllowedStatus[data.status] && data.acceptOffers.length
                  ? '12px'
                  : '14px',
              width: !isMobile && data.acceptOffers.length ? '288px' : '100%',
            }}
          >
            {approvedStatus[data.status] && !isMobile ? approvedMsg() : ''}
            {canceledStatus[data.status] ? canceledMsg() : ''}
            {data.status === 'nulled' ? nulledMsg() : ''}
          </div>
          {data.status !== 'declined' ? (
            <div
              className="order-card__selected-list"
              style={{
                display:
                  data.acceptOffers && !data.acceptOffers.length
                    ? 'none'
                    : 'flex',
              }}
            >
              {offersSort(data.acceptOffers).map((item, index) => (
                <div className="order-card__selected-item" key={index}>
                  <span>
                    {intl.formatMessage({
                      id: `company.supplier.${item.company.spec}`,
                      defaultMessage: companyType[item.company.spec],
                    })}
                  </span>
                  <a
                    className="link"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(
                        role === 'super admin'
                          ? `/all-companies/${
                              item.company.spec === 'service'
                                ? 'providers'
                                : item.company.spec === 'quality-control'
                                ? 'quality'
                                : 'supplier'
                            }/company/${item.company.id}`
                          : `/customer/my-orders/company/${item.company.id}`
                      );
                    }}
                  >
                    {item.company.name}
                  </a>
                </div>
              ))}
            </div>
          ) : (
            ''
          )}
        </div>
      ) : (
        ''
      )}

      {isNewOrder[data.status] ? (
        <div className="order-card__tenders">
          <span>
            <FormattedMessage
              id="component.orderCard.tender"
              defaultMessage="Tender"
            />
          </span>
          <span>
            <FormattedMessage
              id="component.orderCard.new"
              defaultMessage="New"
            />{' '}
            <div className="badge primary">{data.tenderNew}</div>
          </span>
          <span>
            <FormattedMessage
              id="component.orderCard.total"
              defaultMessage="In Total"
            />{' '}
            <div className="badge outline">{data.tenderTotal}</div>
          </span>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

OrderCard.defaultProps = {
  type: CARD_TYPE.VERTICAL,
  path: '',
};

OrderCard.propTypes = {
  data: PropTypes.object.isRequired,
  type: PropTypes.string,
  path: PropTypes.string,
};

export default OrderCard;
