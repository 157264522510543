export const SUPER_ADMIN_NAV_ITEMS = [
  {
    title: 'Analytics',
    path: '/analytics',
    icon: 'analytics',
    subNav: null,
  },
  {
    title: 'All Companies',
    path: '/all-companies',
    icon: 'users',
    subNav: [
      {
        title: 'Customers',
        path: '/all-companies/customers',
      },
      {
        title: 'Material Suppliers',
        path: '/all-companies/supplier',
      },
      {
        title: 'Service Providers',
        path: '/all-companies/providers',
      },
      {
        title: 'Quality Assurance',
        path: '/all-companies/quality',
      },
    ],
  },
  {
    title: 'My Account',
    path: '/my-account',
    icon: 'user',
    subNav: [
      {
        title: 'Personal Info',
        path: '/my-account/personal-info',
      },
      {
        title: 'Password',
        path: '/my-account/password',
      },
      {
        title: 'Users',
        path: '/my-account/users',
      },
    ],
  },
  {
    title: 'Support',
    path: null,
    icon: 'support',
  },
  // {
  //   title: 'English',
  //   path: 'en',
  //   icon: 'lang',
  //   subNav: [
  //     {
  //       title: 'English',
  //       path: 'en',
  //     },
  //     {
  //       title: 'Swedish',
  //       path: 'swe',
  //     },
  //   ],
  // },
];

export const SUPER_ADMIN_NAV_ITEMS_SWE = [
  {
    title: 'Statistik',
    path: '/analytics',
    icon: 'analytics',
    subNav: null,
  },
  {
    title: 'Alla företag',
    path: '/all-companies',
    icon: 'users',
    subNav: [
      {
        title: 'Beställare',
        path: '/all-companies/customers',
      },
      {
        title: 'Materialleverantörer',
        path: '/all-companies/supplier',
      },
      {
        title: 'Tjänsteleverantörer',
        path: '/all-companies/providers',
      },
      {
        title: 'Kvalitetssäkring',
        path: '/all-companies/quality',
      },
    ],
  },
  {
    title: 'Mitt konto',
    path: '/my-account',
    icon: 'user',
    subNav: [
      {
        title: 'Personligt info',
        path: '/my-account/personal-info',
      },
      {
        title: 'Lösenord',
        path: '/my-account/password',
      },
      {
        title: 'Användare',
        path: '/my-account/users',
      },
    ],
  },
  {
    title: 'Support',
    path: null,
    icon: 'support',
  },
  // {
  //   title: 'Swedish',
  //   path: 'swe',
  //   icon: 'lang',
  //   subNav: [
  //     {
  //       title: 'English',
  //       path: 'en',
  //     },
  //     {
  //       title: 'Swedish',
  //       path: 'swe',
  //     },
  //   ],
  // },
];

export const CUSTOMER_ADMIN_NAV_ITEMS = [
  {
    title: 'My Orders',
    path: 'customer/my-orders',
    icon: 'orders',
    subNav: null,
  },
  {
    title: 'My Projects',
    path: '/my-projects',
    icon: 'project',
    subNav: null,
  },
  {
    title: 'My Company',
    icon: 'user',
    path: '/my-company',
    subNav: [
      {
        title: 'My Company',
        path: '/my-company/company-info',
      },
      {
        title: 'Personal Info',
        path: '/my-company/personal-info',
      },
      {
        title: 'Password',
        path: '/my-company/password',
      },
      {
        title: 'Users',
        path: '/my-company/admins',
      },
    ],
  },
  {
    title: 'Support',
    path: null,
    icon: 'support',
  },
  // {
  //   title: 'English',
  //   path: 'en',
  //   icon: 'lang',
  //   subNav: [
  //     {
  //       title: 'English',
  //       path: 'en',
  //     },
  //     {
  //       title: 'Swedish',
  //       path: 'swe',
  //     },
  //   ],
  // },
];

export const CUSTOMER_ADMIN_NAV_ITEMS_SWE = [
  {
    title: 'Mina beställningar',
    path: 'customer/my-orders',
    icon: 'orders',
    subNav: null,
  },
  {
    title: 'Mina projekt',
    path: '/my-projects',
    icon: 'project',
    subNav: null,
  },
  {
    title: 'Mitt företag',
    icon: 'user',
    path: '/my-company',
    subNav: [
      {
        title: 'Mitt företag',
        path: '/my-company/company-info',
      },
      {
        title: 'Personligt info',
        path: '/my-company/personal-info',
      },
      {
        title: 'Lösenord',
        path: '/my-company/password',
      },
      {
        title: 'Användare',
        path: '/my-company/admins',
      },
    ],
  },
  {
    title: 'Support',
    path: null,
    icon: 'support',
  },
  // {
  //   title: 'Swedish',
  //   path: 'swe',
  //   icon: 'lang',
  //   subNav: [
  //     {
  //       title: 'English',
  //       path: 'en',
  //     },
  //     {
  //       title: 'Swedish',
  //       path: 'swe',
  //     },
  //   ],
  // },
];

export const SUPPLIER_ADMIN_NAV_ITEMS = [
  {
    title: 'My Orders',
    path: 'supplier/my-orders',
    icon: 'orders',
    subNav: null,
  },
  {
    title: 'My Company',
    icon: 'user',
    path: '/my-company',
    subNav: [
      {
        title: 'My Company',
        path: '/my-company/company-info',
      },
      {
        title: 'Personal Info',
        path: '/my-company/personal-info',
      },
      {
        title: 'Password',
        path: '/my-company/password',
      },
      {
        title: 'Users',
        path: '/my-company/admins',
      },
    ],
  },
  {
    title: 'Support',
    path: null,
    icon: 'support',
  },
  // {
  //   title: 'English',
  //   path: 'en',
  //   icon: 'lang',
  //   subNav: [
  //     {
  //       title: 'English',
  //       path: 'en',
  //     },
  //     {
  //       title: 'Swedish',
  //       path: 'swe',
  //     },
  //   ],
  // },
];

export const SUPPLIER_ADMIN_NAV_ITEMS_SWE = [
  {
    title: 'Mina uppdrag',
    path: 'supplier/my-orders',
    icon: 'orders',
    subNav: null,
  },
  {
    title: 'Mitt företag',
    icon: 'user',
    path: '/my-company',
    subNav: [
      {
        title: 'Mitt företag',
        path: '/my-company/company-info',
      },
      {
        title: 'Personligt info',
        path: '/my-company/personal-info',
      },
      {
        title: 'Lösenord',
        path: '/my-company/password',
      },
      {
        title: 'Användare',
        path: '/my-company/admins',
      },
    ],
  },
  {
    title: 'Support',
    path: null,
    icon: 'support',
  },
  // {
  //   title: 'Swedish',
  //   path: 'swe',
  //   icon: 'lang',
  //   subNav: [
  //     {
  //       title: 'English',
  //       path: 'en',
  //     },
  //     {
  //       title: 'Swedish',
  //       path: 'swe',
  //     },
  //   ],
  // },
];

export const ENG_SWITCHER = [
  {
    title: 'English',
    path: 'en',
    icon: 'lang',
    subNav: [
      {
        title: 'English',
        path: 'en',
      },
      {
        title: 'Swedish',
        path: 'swe',
      },
    ],
  },
];

export const SV_SWITCHER = [
  {
    title: 'Swedish',
    path: 'swe',
    icon: 'lang',
    subNav: [
      {
        title: 'English',
        path: 'en',
      },
      {
        title: 'Swedish',
        path: 'swe',
      },
    ],
  },
];
