/* eslint-disable no-constant-condition */
/* eslint-disable no-undef */
/* eslint-disable prefer-regex-literals */
/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';

import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { orderViewIdFormat } from '../../utils/order-viewid.format';

const NOTIFICATION_TYPE = {
  ORDER_CREATED: 'OrderCreatedNotification',
  ORDER_EDIT: 'OrderEditNotification',

  ORDER_NEW_OFFER: 'OfferCreatedNotification',

  ORDER_IN_PROGRESS_ACCEPT_OFFER: 'OrderInProgressOfferAcceptNotification',
  ORDER_IN_PROGRESS_REJECT_OFFER: 'OrderInProgressOfferRejectNotification',

  ORDER_ANNULATION: 'OrderAnnulationSupplierNotification',
  ORDER_CANCEL: 'OrderCancelNotification',
};

const NotificationText = ({ data, closeHandler }) => {
  const navigate = useNavigate();

  const getText = (notification) => {
    if (!notification || !notification.data) {
      return '';
    }

    const dataType = notification.type.split('\\').reverse()[0];
    const body = notification.data;

    // useEffect(() => closeHandler(), [window.location.pathname]);

    switch (dataType) {
      case NOTIFICATION_TYPE.ORDER_NEW_OFFER:
        return (
          <span>
            <FormattedMessage
              id={`notification.${NOTIFICATION_TYPE.ORDER_NEW_OFFER}`}
              defaultMessage="New Clients"
              values={{
                orderId: (
                  <span
                    className="link"
                    onClick={() => {
                      navigate(
                        `cutomer/my-orders/info/project/${body?.project?.id}/order/${body?.order?.id}`
                      );
                      dismiss();
                    }}
                  >
                    {orderViewIdFormat(body?.order?.viewId)}
                  </span>
                ),
                supplier: (
                  <span
                    className="link"
                    onClick={() => {
                      navigate(
                        `/customer/my-orders/company/${body?.offer?.supplier?.company?.id}`
                      );
                      closeHandler();
                    }}
                  >
                    {body?.offer?.supplier?.company?.name}
                  </span>
                ),
                project: (
                  <span
                    className="link"
                    onClick={() => {
                      navigate(`/my-projects/${body?.project?.id}`);
                      closeHandler();
                    }}
                  >
                    {body?.project?.name}
                  </span>
                ),
              }}
            />
          </span>
        );

      case NOTIFICATION_TYPE.ORDER_CREATED:
        return (
          <span>
            <FormattedMessage
              id={`notification.${NOTIFICATION_TYPE.ORDER_CREATED}`}
              defaultMessage="New Clients"
              values={{
                orderId: (
                  <span
                    className="link"
                    onClick={() => {
                      navigate(`/supplier/my-orders/order/${body?.id}`);
                      closeHandler();
                    }}
                  >
                    {orderViewIdFormat(body?.viewId)}
                  </span>
                ),
                project: body?.project?.name,
                date: body?.dateOfExecution,
              }}
            />
          </span>
        );
      case NOTIFICATION_TYPE.ORDER_EDIT:
        return (
          <span>
            <FormattedMessage
              id={`notification.${NOTIFICATION_TYPE.ORDER_EDIT}`}
              defaultMessage="New Clients"
              values={{
                project: (
                  <span
                    className="link"
                    onClick={() => {
                      navigate(`/supplier/my-orders/order/${body?.id}`);
                      closeHandler();
                    }}
                  >
                    {body?.project?.name}
                  </span>
                ),
              }}
            />
          </span>
        );
      case NOTIFICATION_TYPE.ORDER_IN_PROGRESS_ACCEPT_OFFER:
        return (
          <span>
            <FormattedMessage
              id={`notification.${NOTIFICATION_TYPE.ORDER_IN_PROGRESS_ACCEPT_OFFER}`}
              defaultMessage="New Clients"
              values={{
                orderId: (
                  <span
                    className="link"
                    onClick={() => {
                      navigate(`/supplier/my-orders/order/${body?.order?.id}`);
                      closeHandler();
                    }}
                  >
                    {orderViewIdFormat(body?.order?.viewId)}
                  </span>
                ),
                project: body?.order?.project?.name,
                companyName: body?.order?.project?.company?.name,
                date: body?.order?.dateOfExecution,
              }}
            />
          </span>
        );
      case NOTIFICATION_TYPE.ORDER_IN_PROGRESS_REJECT_OFFER:
        return (
          <span>
            <FormattedMessage
              id={`notification.${NOTIFICATION_TYPE.ORDER_IN_PROGRESS_REJECT_OFFER}`}
              defaultMessage="New Clients"
              values={{
                orderId: (
                  <span
                    className="link"
                    onClick={() => {
                      navigate(`/supplier/my-orders/order/${body?.order?.id}`);
                      closeHandler();
                    }}
                  >
                    {orderViewIdFormat(body?.order?.viewId)}
                  </span>
                ),
                project: body?.order?.project?.name,
                companyName: (
                  <span
                    className="link"
                    onClick={() => {
                      navigate(
                        `/supplier/my-orders/company/${body?.order?.project?.company?.id}`
                      );
                      closeHandler();
                    }}
                  >
                    {body?.order?.project?.company?.name}
                  </span>
                ),
                date: body?.order?.dateOfExecution,
              }}
            />
          </span>
        );
      case NOTIFICATION_TYPE.ORDER_ANNULATION:
        return (
          <span>
            <FormattedMessage
              id={`notification.${NOTIFICATION_TYPE.ORDER_ANNULATION}`}
              defaultMessage="New Clients"
              values={{
                orderId: (
                  <span
                    className="link"
                    onClick={() => {
                      navigate(`/supplier/my-orders/order/${body?.id}`);
                      closeHandler();
                    }}
                  >
                    {orderViewIdFormat(body?.viewId)}
                  </span>
                ),
                customer: (
                  <span
                    className="link"
                    onClick={() => {
                      navigate(
                        `/supplier/my-orders/company/${body?.project?.company?.id}`
                      );
                      closeHandler();
                    }}
                  >
                    {body?.project?.company?.name}
                  </span>
                ),
                project: body?.project?.name,
                date: body.dateOfExecution,
              }}
            />
          </span>
        );
      case NOTIFICATION_TYPE.ORDER_CANCEL:
        return (
          <span>
            <FormattedMessage
              id={`notification.${NOTIFICATION_TYPE.ORDER_CANCEL}`}
              defaultMessage="New Clients"
              values={{
                date: body.dateOfExecution,
                project: body?.project?.city,
                customer: (
                  <span
                    className="link"
                    onClick={() => {
                      navigate(
                        `/supplier/my-orders/company/${body?.project?.company?.id}`
                      );
                      closeHandler();
                    }}
                  >
                    {body?.project?.company?.name}
                  </span>
                ),
              }}
            />
          </span>
        );
      default:
        return '';
    }
  };

  return <>{getText(data)}</>;
};

NotificationText.defaultProps = {
  data: null,
  closeHandler: () => {},
};

NotificationText.propTypes = {
  data: PropTypes.object,
  closeHandler: PropTypes.func,
};

export default NotificationText;
