/* eslint-disable prefer-regex-literals */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import LoadedContent from '../../../../components/LoadedContent';
import { useDispatch, useSelector } from 'react-redux';
import UsersList from '../../../../components/Users';
import NewUserCard from '../../../../components/NewUserCard';
import { NEW_USER_CARD_TYPE } from '../../../../components/NewUserCard/NewUserCard';
import {
  getUsersSuperAdmins,
  getUsersSuperAdminsBanned,
} from '../../../../store/slice/usersAdminsSlice';
import { FormattedMessage, useIntl } from 'react-intl';

const Users = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const usersAdminsMeta = useSelector((state) => state.usersAdmins.meta);
  const usersAdminsProject = useSelector((state) => state.usersAdmins.data);
  const usersAdminsLoading = useSelector(
    (state) => state.usersAdmins.isListLoading
  );

  const usersAdminsBannedMeta = useSelector(
    (state) => state.usersAdmins.bannedMeta
  );
  const usersAdminsBannedData = useSelector(
    (state) => state.usersAdmins.bannedData
  );
  const usersAdminsBannedLoading = useSelector(
    (state) => state.usersAdmins.isBannedListLoading
  );

  const [searchParams] = useSearchParams();
  const pageQuery = searchParams.get('page') || 1;
  const pageBannedQuery = searchParams.get('pageBanned') || 1;

  const fetchCompany = async (id) => {
    dispatch(
      getUsersSuperAdmins({
        query: {
          page: pageQuery,
          role: 'super_admin',
          status: 'active',
        },
      })
    );
  };

  const fetchListBanned = async (id) => {
    dispatch(
      getUsersSuperAdminsBanned({
        query: {
          page: pageBannedQuery,
          role: 'super_admin',
        },
      })
    );
  };

  useEffect(() => {
    document.title = intl.formatMessage({
      id: 'page.sa.users.title',
      defaultMessage: 'Users',
    });
  }, []);

  useEffect(() => {
    fetchCompany();
  }, [pageQuery]);

  useEffect(() => {
    fetchListBanned();
  }, [pageBannedQuery]);

  const callbackAction = () => {
    fetchCompany();
    fetchListBanned();
  };

  return (
    <div className="users-superadmin-page">
      <LoadedContent loader={usersAdminsLoading || usersAdminsBannedLoading} />
      <div>
        <NewUserCard type={NEW_USER_CARD_TYPE.NEW_SUPER_ADMIN} />
      </div>
      <div className="users-superadmin-page__inner">
        <div className="users-superadmin-page__title">
          <FormattedMessage
            id="page.sa.users.listTitle"
            defaultMessage="All Users"
          />
        </div>
        <div className="users-superadmin-page__content">
          {!usersAdminsLoading && !usersAdminsBannedLoading && (
            <UsersList
              idCompany={null}
              itsSuperAdmin={true}
              currentPage={usersAdminsMeta.current_page}
              totalCount={usersAdminsMeta.last_page}
              isListLoading={usersAdminsLoading}
              list={usersAdminsProject}
              itsBanned={false}
              callbackAction={callbackAction}
            />
          )}
          {!usersAdminsBannedLoading && !usersAdminsLoading && (
            <UsersList
              idCompany={null}
              itsSuperAdmin={true}
              currentPage={usersAdminsBannedMeta.current_page}
              totalCount={usersAdminsBannedMeta.last_page}
              isListLoading={usersAdminsBannedLoading}
              list={usersAdminsBannedData}
              itsBanned={true}
              callbackAction={callbackAction}
            />
          )}
        </div>
      </div>
    </div>
  );
};

Users.propTypes = {
  props: PropTypes.object,
};

export default Users;
