import PropTypes from 'prop-types';
import React from 'react';
import LoadedContent from '../../../../components/LoadedContent';
import Pagination from '../../../../components/Pagination';
import Icon from '../../../../components/ui/Icon';
import ProjectItem from '../../../../components/ProjectItem/ProjectItem';
import { FormattedMessage } from 'react-intl';

const ProjectList = ({
  projectList,
  isListLoading,
  currentPage,
  totalCount,
}) => {
  return (
    <div className="company-page customer-page">
      <div className="company-page__inner">
        <div className="company-page__body">
          <div className="company-page customer-page">
            <LoadedContent loader={isListLoading} />
            <div className="company-page__inner">
              <div className="company-page__list">
                {!isListLoading &&
                  projectList &&
                  projectList.map((item) => (
                    <div key={item.id} className="company-page__list-item">
                      <ProjectItem data={item} url={`${item.id}`} />
                    </div>
                  ))}
                {!isListLoading && projectList && !projectList.length ? (
                  <div className="empty-list">
                    <div className="empty-list__icon">
                      <Icon icon="project" size="52px" />
                    </div>
                    <span className="empty-list__title">
                      <FormattedMessage
                        id="page.projects.noMsg"
                        defaultMessage="You have no project"
                      />
                    </span>
                  </div>
                ) : (
                  ''
                )}
              </div>
              {!isListLoading && (
                <Pagination
                  totalCount={totalCount}
                  currentPage={currentPage}
                  onPageChange={() => {}}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ProjectList.defaultProps = {
  projectList: [],
  totalCount: 1,
  currentPage: 1,
};

ProjectList.propTypes = {
  isListLoading: PropTypes.bool,
  projectList: PropTypes.array,
  currentPage: PropTypes.number,
  totalCount: PropTypes.number,
};

export default ProjectList;
