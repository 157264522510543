import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  userGetInfo,
  userEditInfo,
  userChangePassword,
} from '../../api/personalInfo';

export const editPasswordUser = createAsyncThunk(
  'password/editProfile',
  async (creds, { rejectWithValue }) => {
    try {
      const response = await userChangePassword(creds);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getProfile = createAsyncThunk(
  'personalInfo/getProfile',
  async (creds, { rejectWithValue }) => {
    try {
      const response = await userGetInfo(creds);
      if (response.status === 200) {
        if (response.data.data.companyId) {
          localStorage.setItem('companyId', response.data.data.companyId);
        }
        localStorage.setItem('userId', response.data.data.id);
        if (response.data.data.role) {
          localStorage.setItem('role', response.data.data.role);
        } else {
          localStorage.setItem('role', 'super admin');
        }
        return response.data.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const editProfile = createAsyncThunk(
  'personalInfo/editProfile',
  async (creds, { rejectWithValue }) => {
    try {
      const response = await userEditInfo(creds);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  isLoading: false,
  isError: false,
  data: {},
  role: null,
};

const personalInfoSlice = createSlice({
  name: 'personalInfo',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getProfile.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
    });
    builder.addCase(getProfile.fulfilled, (state, payload) => {
      state.isLoading = false;
      state.isError = false;
      state.data = payload.payload;
      state.data.role = payload.payload.role || 'super admin';
    });

    builder.addCase(editProfile.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
    });
    builder.addCase(editProfile.fulfilled, (state, payload) => {
      state.isLoading = false;
      state.isError = false;
    });
    builder.addCase(editProfile.rejected, (state, payload) => {
      state.isLoading = false;
      state.isError = false;
    });
  },
});

const { reducer } = personalInfoSlice;

export const selectPersonalInfo = (state) => state.personalInfo;

export default reducer;
