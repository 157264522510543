import PropTypes from 'prop-types';
import React from 'react';
import CompanyItem from '../ProjectItem/ProjectItem';
import Icon from '../ui/Icon';
import LoadedContent from '../LoadedContent';
import Pagination from '../../components/Pagination';
import { FormattedMessage } from 'react-intl';

const Project = ({ projectList, isListLoading, currentPage, totalCount }) => {
  return (
    <div className="company-page customer-page">
      <div className="company-page__inner">
        <div className="company-page__body">
          <div className="company-page customer-page">
            <LoadedContent loader={isListLoading} />
            <div className="company-page__inner">
              <div className="company-page__list">
                {!isListLoading &&
                  projectList &&
                  projectList.map((item) => (
                    <div key={item.id} className="company-page__list-item">
                      <CompanyItem data={item} />
                    </div>
                  ))}
                {!isListLoading && projectList && !projectList.length ? (
                  <div className="empty-list">
                    <div className="empty-list__icon">
                      <Icon icon="project" size="52px" />
                    </div>
                    <span className="empty-list__title">
                      <FormattedMessage
                        id="page.projects.noMsg"
                        defaultMessage="You have no project"
                      />
                    </span>
                  </div>
                ) : (
                  ''
                )}
              </div>
              {!isListLoading && projectList && (
                <Pagination
                  totalCount={totalCount}
                  currentPage={currentPage}
                  onPageChange={() => {}}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Project.defaultProps = {
  projectList: [],
  totalCount: 1,
  currentPage: 1,
};

Project.propTypes = {
  isListLoading: PropTypes.bool,
  projectList: PropTypes.array,
  currentPage: PropTypes.number,
  totalCount: PropTypes.number,
};

export default Project;
