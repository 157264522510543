import axios from 'axios';
import { oAuthTokenRefresh } from './auth';

let isRefreshing = false;
const refreshSubscribers = [];

const subscribeTokenRefresh = (cb) => {
  refreshSubscribers.push(cb);
};

const onRrefreshed = (token) => {
  while (refreshSubscribers.length) {
    const req = refreshSubscribers.shift();
    req(token);
  }
};

const api = axios.create({
  // baseURL: 'https://stage.gjutfrossa.digitaltbygg.se/',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Localization': localStorage.getItem('lang') === 'en' ? 'en' : 'sv',
    'Accept-Language': localStorage.getItem('lang') === 'en' ? 'en' : 'sv',
  },
  timeout: 30000,
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = 'Bearer ' + token;
      config.headers['X-Localization'] =
        localStorage.getItem('lang') === 'en' ? 'en' : 'sv';
      config.headers['Accept-Language'] =
        localStorage.getItem('lang') === 'en' ? 'en' : 'sv';
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    const { status } = response;
    if (status === 200 || status === 201) {
      return response;
    }
  },
  async (error) => {
    const { status, statusText } = error.response;
    const originalConfig = error.config;
    const refreshToken = localStorage.getItem('refreshToken');
    console.log(status, statusText);

    if (status === 401 && !refreshToken) {
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('role');
      window.location = '/sign-in';
      return Promise.reject(error);
    }

    if (status === 401) {
      if (!isRefreshing) {
        isRefreshing = true;

        try {
          const response = await oAuthTokenRefresh({ refreshToken });
          localStorage.setItem('token', response.data.accessToken);
          localStorage.setItem('refreshToken', response.data.refreshToken);
          isRefreshing = false;
          onRrefreshed(response.data.accessToken);
        } catch (err) {
          isRefreshing = false;
          return Promise.reject(error);
        }
      }

      const retryOrigReq = new Promise((resolve, reject) => {
        if (!isRefreshing) {
          resolve(api(originalConfig));
        } else {
          subscribeTokenRefresh((token) => {
            originalConfig.headers.Authorization = 'Bearer ' + token;
            resolve(axios(originalConfig));
          });
        }
      });
      return retryOrigReq;
    }
    return Promise.reject(error);
  }
);

export default api;
