import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { deleteCompanyReq, listCompanies } from '../../api/company';

export const getCompany = createAsyncThunk(
  'company/getCompany',
  async (creds, { rejectWithValue }) => {
    try {
      const response = await listCompanies(creds);
      if (response.status === 200) {
        console.log(response.data.data);
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (error) {
      console.log('sliceError', error.response);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const deleteCompany = createAsyncThunk(
  'company/deleteCompany',
  async (creds, { rejectWithValue }) => {
    try {
      const response = await deleteCompanyReq(creds);
      if (!response) {
        return response;
      }
    } catch (error) {
      console.log('sliceError', error.response.data);
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  isListLoading: false,
  isDeleteLoading: false,
  isError: false,
  companyList: null,
  paginationLinks: null,
  paginationMeta: null,
};

const companySlice = createSlice({
  name: 'company',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getCompany.pending, (state) => {
      state.isListLoading = true;
      state.isError = false;
    });
    builder.addCase(getCompany.fulfilled, (state, payload) => {
      state.isListLoading = false;
      state.isError = false;
      state.companyList = payload.payload.data;
      state.paginationLinks = payload.payload.links;
      state.paginationMeta = payload.payload.meta;
    });
    builder.addCase(getCompany.rejected, (state, payload) => {
      state.isListLoading = false;
      state.isError = true;
      state.companyList = [];
    });
    builder.addCase(deleteCompany.pending, (state) => {
      state.isDeleteLoading = true;
      state.isError = false;
    });
    builder.addCase(deleteCompany.fulfilled, (state, payload) => {
      state.isDeleteLoading = false;
      state.isError = false;
    });
  },
});

const { reducer } = companySlice;

export const selectCompany = (state) => state.company;

export default reducer;
