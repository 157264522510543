import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import Icon from '../ui/Icon';
import NavList from '../NavList/NavList';
import useScrollBlock from '../../utils/useScrollBlock';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../store/slice/authSlice';
import useWindowSize from '../../utils/useWindowSize';
import NotificationModal from '../NotificationModal/NotificationModal';
import { clearUnreadCount } from '../../store/slice/notificationSlice';
import { FormattedMessage } from 'react-intl';
import LangSwitcher from '../ui/LangSwitcher';

const Header = ({ navItems, langs }) => {
  const [expanded, setExpanded] = useState(false);
  const [notificationShowModal, setNotificationShowModal] = useState(false);
  const [blockScroll, allowScroll] = useScrollBlock();
  const notificationMeta = useSelector(
    (state) => state.notifications.unreadListMeta
  );
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const size = useWindowSize();

  const getCount = () => notificationMeta && notificationMeta.total;

  const logOut = () => {
    dispatch(clearUnreadCount());
    dispatch(logout()).then((res) => {
      if (res && res.meta.requestStatus === 'fulfilled') {
        navigate('/');
      }
    });
  };

  useEffect(() => {
    setExpanded(false);
    allowScroll();
  }, [location]);

  useEffect(() => {
    if (expanded) {
      blockScroll();
    } else {
      allowScroll();
    }
  }, [expanded]);

  return (
    <>
      <div className="main-header">
        <div className="main-header__logo">
          <Icon icon="logo-light" />
        </div>

        <div
          className="main-header__notification"
          onClick={() => {
            setNotificationShowModal(true);
            setExpanded(false);
          }}
        >
          <div className="notification__icon">
            <Icon icon="notification" size="21px" />
          </div>
          {getCount() > 0 && (
            <div className="notification__counter">
              {getCount() < 99 ? getCount() : 99}
            </div>
          )}
        </div>

        <div className="main-header__burger">
          {!expanded && (
            <div onClick={() => setExpanded(true)}>
              <Icon icon="menu" size="21px" />
            </div>
          )}
          {expanded && (
            <div onClick={() => setExpanded(false)}>
              <Icon icon="close" size="21px" />
            </div>
          )}
        </div>

        {notificationShowModal && (
          <NotificationModal dismiss={() => setNotificationShowModal(false)} />
        )}
      </div>
      <div
        className={
          expanded
            ? 'main-header__navbar expanded mobile'
            : 'main-header__navbar mobile'
        }
        style={{
          position: 'fixed',
          height: `calc(${size.height}px - 56px)`,
          padding: expanded ? '16px' : '0 16px',
          top: expanded ? '56px' : '100vh',
          opacity: expanded ? '1' : '0',
        }}
      >
        <NavList items={navItems} collapseAllSubList={expanded} />

        <div className="main-header__navbar-logout">
          <LangSwitcher items={langs} collapseAllSubList={expanded} />
          <div
            className="main-header__navbar-logout-btn"
            onClick={logOut}
            style={{
              padding: '24px 12px 14px',
            }}
          >
            <span className="icon">
              <Icon icon="login" size="21px" />
            </span>
            <span>
              <FormattedMessage id="logout" defaultMessage="Log Out" />
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

Header.defaultProps = {
  userRole: 'Super Admin',
};

Header.propTypes = {
  userRole: PropTypes.string,
  navItems: PropTypes.array.isRequired,
  langs: PropTypes.array.isRequired,
};

export default Header;
