import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { oAuthToken, oAuthTokenRefresh } from '../../api/auth';

const token = sessionStorage.getItem('token') || localStorage.getItem('token');

export const refreshToken = createAsyncThunk(
  'auth/refresh',
  async (creds, { rejectWithValue }) => {
    try {
      const response = await oAuthTokenRefresh(creds);
      if (response.status === 200) {
        localStorage.setItem('token', response.data.accessToken);
        // localStorage.setItem('role', 'super admin');
        localStorage.setItem('refreshToken', response.data.refreshToken);
        return { token: response.data.accessToken, role: 'super admin' };
      }
    } catch (error) {
      localStorage.removeItem('token');
      localStorage.removeItem('role');
      localStorage.removeItem('refreshToken');
      window.location = '/sign-in';
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const login = createAsyncThunk(
  'auth/login',
  async (creds, { rejectWithValue }) => {
    try {
      const response = await oAuthToken(creds);
      if (response.status === 200) {
        localStorage.setItem('token', response.data.accessToken);
        // localStorage.setItem('role', 'super admin');
        localStorage.setItem('refreshToken', response.data.refreshToken);
        return { token: response.data.accessToken, role: 'super admin' };
      }
    } catch (error) {
      console.log('sliceError', error.response);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const logout = createAsyncThunk(
  'auth/logout',
  async (data, { rejectWithValue }) => {
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('companyType');
    localStorage.removeItem('companySpec');
    localStorage.removeItem('companyId');
    return true;
  }
);

const initialState = {
  isAuthorized: !!token,
  isLoading: false,
  isError: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  extraReducers: {
    [login.pending]: (state) => {
      state.isLoading = true;
      state.isError = false;
    },
    [login.fulfilled]: (state) => {
      state.isLoading = false;
      state.isAuthorized = true;
    },
    [logout.pending]: (state) => {
      state.isLoading = true;
      state.isError = false;
    },
    [logout.fulfilled]: (state) => {
      state.isLoading = false;
      state.isAuthorized = false;
    },
    [login.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = action.payload;
    },
    [refreshToken.pending]: (state) => {
      state.isLoading = true;
      state.isError = false;
    },
    [refreshToken.fulfilled]: (state) => {
      state.isLoading = false;
      state.isAuthorized = true;
    },
    [refreshToken.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = action.payload;
    },
  },
});

const { reducer } = authSlice;

export const selectAuth = (state) => state.auth;

export default reducer;
