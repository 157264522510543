export const ORGANIZATION_NUMBER_VALIDATOR = {
  minLength: (value) => {
    const clearValue = value.replace(/[()_\s-]/g, '');
    return /^\d{10}$/.test(clearValue) || 'Incorrect number';
  },
};

export const ORGANIZATION_NUMBER_VALIDATOR_LOCALE = (msg) => ({
  minLength: (value) => {
    const clearValue = value.replace(/[()_\s-]/g, '');
    return /^\d{10}$/.test(clearValue) || msg;
  },
});
