export const offersSort = (offers) => {
  if (!offers) {
    return [];
  }
  const sorted = [];
  const material = offers.find((item) => item.company.spec === 'factory');
  const supplier = offers.find((item) => item.company.spec === 'service');
  const qa = offers.find((item) => item.company.spec === 'quality-control');

  if (material) {
    sorted.push(material);
  }
  if (supplier) {
    sorted.push(supplier);
  }
  if (qa) {
    sorted.push(qa);
  }

  return sorted;
};
