import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import ConfirmPopup from '../../../../../components/ConfirmPopup/ConfirmPopup';
import Button, {
  BUTTON_SIZE,
} from '../../../../../components/ui/Button/Button';
import OfferCardCustomer, {
  OFFER_CART_TYPE,
} from '../../../../../components/OfferCardCustomer/OfferCardCustomer';
import LoadedContent from '../../../../../components/LoadedContent';
import { AlertMessengerContext } from '../../../../../provider/Alert';
import { setOrderInProgress } from '../../../../../api/order';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  getMyOrder,
  getMyOrderUpdate,
} from '../../../../../store/slice/orderCustomerSlice';
import { offersSort } from '../../../../../utils/offers.sort';
import { cancelAcceptedOffer } from '../../../../../api/offer';
import { useIntl } from 'react-intl';

const companyType = {
  factory: 'Material Supplier',
  service: 'Service Provider',
  'quality-control': 'Quality Assurance',
};

const AcceptedOffersWaiting = ({
  offers,
  changeHandler,
  acceptOrder,
  orderInfoUpdating,
  orderProject,
}) => {
  const [showAcceptOrderPopup, setShowAcceptOrderPopup] = useState(false);
  const [changeOfferPopup, setChangeOfferPopup] = useState({
    offerId: null,
    spec: null,
    showModal: false,
  });
  const [requesting, setRequesting] = useState(false);
  const { addErrorMessage } = useContext(AlertMessengerContext);
  const queryParams = useParams();
  const dispatch = useDispatch();
  const intl = useIntl();

  const cancelOffer = async (offerId) => {
    setRequesting(true);

    try {
      await cancelAcceptedOffer(queryParams.id_order, offerId);
      dispatch(getMyOrderUpdate({ id: queryParams.id_order }));
      setRequesting(false);
    } catch (err) {
      const { response } = err;
      setRequesting(false);
      addErrorMessage({
        message: response && response.data.message,
      });
    }
  };

  const acceptOrderHandler = async () => {
    setShowAcceptOrderPopup(false);
    setRequesting(true);

    try {
      await setOrderInProgress(queryParams.id_order);
      dispatch(getMyOrder({ id: queryParams.id_order }));

      setRequesting(false);
      acceptOrder();
    } catch ({ response }) {
      setRequesting(false);
      addErrorMessage({
        message: response && response.data.message,
      });
    }
  };

  const changeOffer = async (id, spec) => {
    setChangeOfferPopup({ id: null, showModal: false, spec: null });
    cancelOffer(id);
    changeHandler(id, spec);
  };

  return (
    <>
      <div className="accepted-offers-waiting">
        <LoadedContent loader={requesting || orderInfoUpdating} />
        <div className="accepted-offers-waiting__list">
          {offersSort(offers).map((item, index) => (
            <div key={index} className="company-page__list-item">
              <OfferCardCustomer
                description={intl.formatMessage({
                  id: `company.supplier.${item.company.spec}`,
                  defaultMessage: companyType[item.company.spec],
                })}
                data={item}
                changeHandler={() =>
                  setChangeOfferPopup({
                    offerId: item.id,
                    spec: item.company.spec,
                    showModal: true,
                  })
                }
                cardType={OFFER_CART_TYPE.ACCEPTED}
                disabled={
                  orderProject && orderProject.status !== 'not_answered'
                }
              />
            </div>
          ))}
        </div>
        <div className="offer-tab-list__accept">
          <Button
            size={BUTTON_SIZE.MEDIUM}
            text={intl.formatMessage(
              {
                id: `page.orderDetail.makeOffer.fullAccept`,
                defaultMessage: 'Accept 3 of 3 Suppliers',
              },
              {
                current: orderProject.orderClass !== 'no' ? 3 : 2,
                total: orderProject.orderClass !== 'no' ? 3 : 2,
              }
            )}
            disabled={
              requesting ||
              (orderProject && orderProject.status !== 'not_answered')
            }
            onClick={() => setShowAcceptOrderPopup(true)}
          />
        </div>
        <ConfirmPopup
          open={changeOfferPopup.showModal}
          confirmButtonText={intl.formatMessage({
            id: `popup.btn.change`,
            defaultMessage: 'Yes, change',
          })}
          cancelButtonText={intl.formatMessage({
            id: `popup.btn.cancel`,
            defaultMessage: 'Cancel',
          })}
          text={intl.formatMessage({
            id: `popup.order.changeOffer`,
            defaultMessage: 'Are you sure that you want change the offer?',
          })}
          isLoading={false}
          onSubmit={() => {
            changeOffer(changeOfferPopup.offerId, changeOfferPopup.spec);
          }}
          onCloseModal={() =>
            setChangeOfferPopup({ id: null, showModal: false, spec: null })
          }
        />
        <ConfirmPopup
          open={showAcceptOrderPopup}
          confirmButtonText={intl.formatMessage({
            id: `popup.btn.publish`,
            defaultMessage: 'Yes, accept',
          })}
          cancelButtonText={intl.formatMessage({
            id: `popup.btn.cancel`,
            defaultMessage: 'Cancel',
          })}
          text={intl.formatMessage({
            id: `popup.order.accept`,
            defaultMessage: 'Are you sure you want to accept?',
          })}
          isLoading={false}
          onSubmit={acceptOrderHandler}
          onCloseModal={() => setShowAcceptOrderPopup(false)}
        />
      </div>
    </>
  );
};

AcceptedOffersWaiting.defaultProps = {
  offers: [],
  orderInfoUpdating: true,
  acceptOrder: () => {},
};

AcceptedOffersWaiting.propTypes = {
  offers: PropTypes.array.isRequired,
  changeHandler: PropTypes.func.isRequired,
  acceptOrder: PropTypes.func.isRequired,
  orderInfoUpdating: PropTypes.bool.isRequired,
  orderProject: PropTypes.object,
};

export default AcceptedOffersWaiting;
