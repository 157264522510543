import PropTypes from 'prop-types';
import React from 'react';
import OrderCard from '../CompanyOrderItem';
import OrderInProgressCard from '../OrderInProgressCard';
import Icon from '../ui/Icon';
import LoadedContent from '../LoadedContent';
import Pagination from '../../components/Pagination';
import { useMediaQuery } from 'react-responsive';
import { FormattedMessage } from 'react-intl';

const Order = ({
  orderList,
  isListLoading,
  currentPage,
  totalCount,
  description,
  orderProject,
  itsInProgress = false,
}) => {
  const isMobileOrTablet = useMediaQuery({
    query: '(max-width: 767px) ',
  });
  return (
    <div className="company-page customer-page">
      <div className="company-page__inner">
        <div className="company-page__body">
          <div className="company-page customer-page">
            <LoadedContent loader={isListLoading} />
            <div className="company-page__inner">
              {!itsInProgress ? (
                <div className="order-page__tenders_sup">
                  <FormattedMessage
                    id="page.orderDetail.listTitle"
                    defaultMessage="Selected suppliers"
                  />
                </div>
              ) : null}
              <div className="company-page__list">
                {!isListLoading &&
                orderList.length &&
                !isMobileOrTablet &&
                itsInProgress ? (
                  <OrderInProgressCard
                    description={description}
                    data={orderList}
                    orderProject={orderProject}
                  />
                ) : null}

                {!isListLoading &&
                  orderList &&
                  orderList?.map((item) => (
                    <div key={item.id} className="company-page__list-item">
                      {itsInProgress ? (
                        isMobileOrTablet ? (
                          <OrderCard description={description} data={item} />
                        ) : null
                      ) : isMobileOrTablet ? (
                        <OrderCard description={description} data={item} />
                      ) : (
                        <OrderCard description={description} data={item} />
                      )}
                    </div>
                  ))}
                {!isListLoading && orderList && !orderList.length ? (
                  <div className="empty-list">
                    <div className="empty-list__icon">
                      <Icon icon="alert" size="32px" />
                    </div>
                    <span className="empty-list__title">
                      <FormattedMessage
                        id="page.orderDetail.noOffers"
                        defaultMessage="You have no offers"
                      />
                    </span>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <Pagination
                totalCount={totalCount}
                currentPage={currentPage}
                onPageChange={() => {}}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Order.defaultProps = {
  orderList: [],
  totalCount: 1,
  currentPage: 1,
};

Order.propTypes = {
  isListLoading: PropTypes.bool,
  orderList: PropTypes.array,
  currentPage: PropTypes.number,
  totalCount: PropTypes.number,
  description: PropTypes.string,
  itsInProgress: PropTypes.bool,
  orderProject: PropTypes.object,
};

export default Order;
