import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';
import Icon from '../Icon';

const FormInput = (props) => {
  const { field } = useController(props);
  const [isFocus, setIsFocus] = useState(false);
  const {
    label,
    id,
    type,
    placeholder,
    value,
    disabled,
    onFocus,
    errors,
    rules,
    checkSuccess = true,
    autoComplete = 'off',
  } = props;

  const [password, showPassword] = useState(true);

  const iconsWrap = () => {
    let className = 'form-input__input-wrap';
    if (type === 'password') {
      className += ' icons';
    }
    if (errors) {
      className += ' errors';
    }
    return className;
  };

  const formInputClass = () => {
    let classInput = '';
    if (!disabled) {
      classInput += 'form-input';
    } else {
      classInput += 'form-input disabled';
    }
    if (
      checkSuccess &&
      field.value !== null &&
      field.value.length &&
      !disabled &&
      !errors
    ) {
      classInput += ' success';
    }
    return classInput;
  };

  return (
    <div className={formInputClass()}>
      <div className="form-input__label-wrap">
        {label && (
          <label
            htmlFor={id}
            className={errors ? 'form-input__label error' : 'form-input__label'}
          >
            {label}
            {rules && rules.required ? <span className="required">*</span> : ''}
          </label>
        )}
      </div>
      <div
        className={isFocus && !errors ? `input-outline focus` : `input-outline`}
      >
        <div className={iconsWrap()}>
          <input
            {...field}
            id={id}
            type={
              type === 'password' ? (password ? 'password' : 'input') : type
            }
            placeholder={placeholder}
            disabled={disabled}
            onFocus={() => {
              setIsFocus(true);
              if (onFocus) {
                onFocus();
              }
            }}
            onBlur={() => {
              field.onBlur();
              setIsFocus(false);
            }}
            className={errors ? 'error' : ''}
            value={value || (field.value === null ? '' : field.value)}
            autoComplete={autoComplete}
          />
          {type !== 'password' && errors && (
            <div className="danger-icon">
              <Icon icon="alert" size="18px" />
            </div>
          )}
          {type === 'password' && (
            <div onClick={() => showPassword(!password)} className="click-icon">
              <Icon icon={password ? 'eye' : 'eye-blocked'} size="18px" />
            </div>
          )}
        </div>
      </div>
      {errors && <p className="error">{errors.message}</p>}
    </div>
  );
};

FormInput.propTypes = {
  text: PropTypes.string,
  label: PropTypes.string.isRequired,
  id: PropTypes.string,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  onFocus: PropTypes.func,
  errors: PropTypes.object,
  rules: PropTypes.object,
  control: PropTypes.object.isRequired,
  checkSuccess: PropTypes.bool,
  autoComplete: PropTypes.string,
};

export default FormInput;
