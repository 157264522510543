/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import Tab from '../../../../components/ui/Tab';
import {
  ordersSupplierSaArchive,
  ordersSupplierSaNew,
  ordersSupplierSaDecline,
  startLoading,
  ordersSupplierSaCurrent,
  ordersSupplierSaWait,
} from '../../../../store/slice/ordersSupplierSaSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams, useNavigate, useParams } from 'react-router-dom';
import Title from '../../../../components/ui/Title';
import OrdersSupplierSA from '../../../../components/OrdersSupplierSA';
import _ from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import { getCompany } from '../../../../api/company';

const titles = ['newOrders', 'waiting', 'current', 'archive'];

const OrdersSupplierSaPage = () => {
  const intl = useIntl();
  const [pageTitle, setPageTitle] = useState(
    intl.formatMessage({
      id: `page.orders.tab.${titles[0]}`,
      defaultMessage: 'Orders',
    })
  );
  const [searchParams] = useSearchParams();
  const params = useParams();
  const pageQuery = searchParams.get('page') || 1;
  const pageDeclinedQuery = searchParams.get('pageDeclined') || 1;
  const specQuery = searchParams.get('spec') || 'new';
  const [companyInfo, setCompanyInfo] = useState({});

  const isListLoading = useSelector(
    (state) => state.ordersSupplierSA.isLoading
  );
  const ordersList = useSelector((state) => state.ordersSupplierSA.data);
  const ordersMeta = useSelector((state) => state.ordersSupplierSA.meta);

  const isListLoadingDecline = useSelector(
    (state) => state.ordersSupplierSA.isLoadingDecline
  );
  const ordersListDecline = useSelector(
    (state) => state.ordersSupplierSA.dataDecline
  );
  const ordersMetaDecline = useSelector(
    (state) => state.ordersSupplierSA.metaDecline
  );

  // TODO: GET ROLE, AND ADD INTO CUSTOMER/SUPPLIER ORDERS LIST;
  // const role = localStorage.get('role');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const tabs = [
    {
      title: intl.formatMessage({
        id: `page.orders.tab.newOrders`,
        defaultMessage: 'New Orders',
      }),
      id: 1,
      page: (
        <div>
          <OrdersSupplierSA
            meta={ordersMeta}
            isListLoading={isListLoading}
            orderList={ordersList}
            itsNewOrders={true}
            declineOrder={() => {}}
            companyId={params.id_company}
          />
          {!isListLoadingDecline && ordersListDecline.length ? (
            <>
              <div className="order-supplier-page__line"></div>
              <Title classNameContainer={'order-supplier-page__description'}>
                Declined
              </Title>
              <OrdersSupplierSA
                meta={ordersMetaDecline}
                isListLoading={isListLoadingDecline}
                orderList={ordersListDecline}
                itsDeclined={true}
                unDeclineOrder={() => {}}
                companyId={params.id_company}
              />
            </>
          ) : null}
        </div>
      ),
      name: 'new',
    },
    {
      title: intl.formatMessage({
        id: `page.orders.tab.waiting`,
        defaultMessage: 'Waiting for answer',
      }),
      id: 2,
      page: (
        <OrdersSupplierSA
          meta={ordersMeta}
          isListLoading={isListLoading}
          orderList={ordersList}
          itsWait={true}
          companyId={params.id_company}
        />
      ),
      name: 'wait',
    },
    {
      title: intl.formatMessage({
        id: `page.orders.tab.current`,
        defaultMessage: 'Current',
      }),
      id: 3,
      page: (
        <OrdersSupplierSA
          meta={ordersMeta}
          isListLoading={isListLoading}
          orderList={ordersList}
          itsCurrent={true}
          companyId={params.id_company}
        />
      ),
      name: 'current',
    },
    {
      title: intl.formatMessage({
        id: `page.orders.tab.archive`,
        defaultMessage: 'Archive',
      }),
      id: 4,
      page: (
        <OrdersSupplierSA
          meta={ordersMeta}
          isListLoading={isListLoading}
          orderList={ordersList}
          itsArchive={true}
          companyId={params.id_company}
        />
      ),
      name: 'archive',
    },
  ];
  const activeDefault = _.find(tabs, { name: specQuery }).id || 1;

  useEffect(() => {
    const params = new URLSearchParams();
    if (!specQuery) {
      params.append('spec', 'new');
    } else {
      params.append('spec', specQuery);
    }

    if (!pageDeclinedQuery) {
      params.append('pageDeclined', 1);
    } else {
      params.append('pageDeclined', pageDeclinedQuery);
    }

    if (!pageQuery) {
      params.append('page', 1);
    } else {
      params.append('page', pageQuery);
    }
    navigate(
      {
        search: params.toString(),
      },
      {
        replace: true,
      }
    );
    searchListOrder();
  }, []);

  const isMobileOrTablet = useMediaQuery({
    query: '(max-width: 900px) ',
  });

  const searchListOrder = () => {
    if (specQuery === 'wait') {
      dispatch(
        ordersSupplierSaWait({
          data: { page: pageQuery },
          companyId: params.id_company,
        })
      );
    } else if (specQuery === 'archive') {
      dispatch(
        ordersSupplierSaArchive({
          data: { page: pageQuery },
          companyId: params.id_company,
        })
      );
    } else if (specQuery === 'current') {
      dispatch(
        ordersSupplierSaCurrent({
          data: { page: pageQuery },
          companyId: params.id_company,
        })
      );
    } else {
      dispatch(
        ordersSupplierSaDecline({
          data: {
            page: pageDeclinedQuery,
          },
          companyId: params.id_company,
        })
      );
      dispatch(
        ordersSupplierSaNew({
          data: { page: pageQuery },
          companyId: params.id_company,
        })
      );
    }
  };

  const getCompanyAsync = async (id) => {
    try {
      const res = await getCompany(id);
      if (res && res.data && res.data.data) {
        setCompanyInfo(res.data.data);
      }
    } catch {}
  };

  useEffect(() => {
    if (!companyInfo.id) {
      getCompanyAsync(params.id_company);
    }
    dispatch(startLoading());
    searchListOrder();
  }, [pageQuery, specQuery]);

  useEffect(() => {
    document.title = pageTitle;
  }, [pageTitle]);

  return (
    <div className="order-supplier-page">
      <div className="order-supplier-page__inner">
        <Title
          subTitle={companyInfo && companyInfo.name ? companyInfo.name : '...'}
        >
          <FormattedMessage id="page.sa.orders.title" defaultMessage="Orders" />
        </Title>
        <div className="order-supplier-page__body">
          <Tab
            activeDefault={activeDefault}
            tabs={tabs}
            onTabChange={(id) => {
              const params = new URLSearchParams();
              params.append('spec', tabs[id - 1].name);
              params.append('page', 1);

              params.append('pageDeclined', 1);
              navigate(
                {
                  search: params.toString(),
                },
                {
                  replace: true,
                }
              );
              setPageTitle(
                intl.formatMessage({
                  id: `page.orders.tab.${titles[id - 1]}`,
                  defaultMessage: 'Orders',
                })
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

OrdersSupplierSaPage.defaultProps = {
  activeDefault: 1,
};

OrdersSupplierSaPage.propTypes = {
  activeDefault: PropTypes.number,
};

export default OrdersSupplierSaPage;
