import React, { useEffect, useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import FormInput from '../../../../components/ui/FormInput/FormInput';
import Button, { BUTTON_SIZE } from '../../../../components/ui/Button/Button';
import { useDispatch } from 'react-redux';
import { editPasswordUser } from '../../../../store/slice/personalInfoSlice';
import LoadedContent from '../../../../components/LoadedContent';
import { AlertMessengerContext } from '../../../../provider/Alert';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useIntl } from 'react-intl';

const Password = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const { addErrorMessage, addSuccessMessage } = useContext(
    AlertMessengerContext
  );

  const [loading, setLoading] = useState(false);

  const schema = yup.object().shape({
    oldPassword: yup
      .string()
      .required(
        intl.formatMessage({
          id: 'input.error.requiredMsg',
          defaultMessage: 'Field is required',
        })
      )
      .min(
        5,
        intl.formatMessage({
          id: 'page.myCompany.password.error',
          defaultMessage: 'Password must be at least 5 characters',
        })
      ),
    newPassword: yup
      .string()
      .required(
        intl.formatMessage({
          id: 'input.error.requiredMsg',
          defaultMessage: 'Field is required',
        })
      )
      .min(
        5,
        intl.formatMessage({
          id: 'page.myCompany.password.error',
          defaultMessage: 'New Password must be at least 5 characters',
        })
      ),
    reNewPassword: yup
      .string()
      .required(
        intl.formatMessage({
          id: 'input.error.requiredMsg',
          defaultMessage: 'Field is required',
        })
      )
      .min(
        5,
        intl.formatMessage({
          id: 'page.myCompany.password.error',
          defaultMessage: 'Confirm Password must be at least 5 characters',
        })
      )
      .oneOf(
        [yup.ref('newPassword'), null],
        intl.formatMessage({
          id: 'page.myCompany.password.error_2',
          defaultMessage: 'Passwords must match',
        })
      ),
  });

  const { control, handleSubmit, formState, ...formControl } = useForm({
    reValidateMode: 'onChange',
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      reNewPassword: '',
    },
    resolver: yupResolver(schema),
  });

  const { errors } = formState;

  const onSubmit = (data) => {
    setLoading(true);
    dispatch(editPasswordUser(data))
      .unwrap()
      .then(() => {
        addSuccessMessage({
          message: intl.formatMessage({
            id: 'page.myCompany.password.successMsg',
            defaultMessage: 'You update a password!',
          }),
        });
        formControl.setValue('oldPassword', '');
        formControl.setValue('newPassword', '');
        formControl.setValue('reNewPassword', '');
      })
      .catch(({ errors, message }) => {
        if (errors) {
          Object.keys(errors).forEach((item) => {
            formControl.setError(item, {
              type: 'custom',
              message: errors[item][0],
            });
          });
        } else if (message) {
          addErrorMessage({
            message: message,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    document.title = intl.formatMessage({
      id: 'page.myCompany.tab.password',
      defaultMessage: 'Password',
    });
  }, []);

  return (
    <div className="password-user">
      <LoadedContent loader={loading} />
      <form
        className="password-user__form-inner"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="password-user__form">
          <FormInput
            control={control}
            label={intl.formatMessage({
              id: 'input.label.curPass',
              defaultMessage: 'Current Password*',
            })}
            name="oldPassword"
            id="oldPassword"
            type="password"
            errors={errors.oldPassword}
            disabled={loading}
            placeholder={intl.formatMessage({
              id: 'input.placehold.curPass',
              defaultMessage: 'Enter current password',
            })}
            // rules={{
            //   required: 'Field is required',
            // }}
          />
          <FormInput
            control={control}
            label={intl.formatMessage({
              id: 'input.label.newPass',
              defaultMessage: 'New Password*',
            })}
            name="newPassword"
            disabled={loading}
            id="newPassword"
            type="password"
            errors={errors.newPassword}
            placeholder={intl.formatMessage({
              id: 'input.placehold.newPass',
              defaultMessage: 'Enter new password',
            })}
            // rules={{
            //   required: 'Field is required',
            // }}
          />
          <FormInput
            control={control}
            label={intl.formatMessage({
              id: 'input.label.confPass',
              defaultMessage: 'Confirm Password*',
            })}
            name="reNewPassword"
            disabled={loading}
            id="reNewPassword"
            type="password"
            errors={errors.reNewPassword}
            placeholder={intl.formatMessage({
              id: 'input.placehold.confPass',
              defaultMessage: 'Enter new password',
            })}
            // rules={{
            //   required: 'Field is required',
            // }}
          />
        </div>
        <div className="password-user__form-btn">
          <Button
            disabled={loading}
            text={intl.formatMessage({
              id: 'page.sa.myAcc.password.save',
              defaultMessage: 'Save',
            })}
            size={BUTTON_SIZE.LARGE}
          />
        </div>
      </form>
    </div>
  );
};

Password.defaultProps = {};

Password.propTypes = {};

export default Password;
