export const localizeOrderInfo = (key, value, dictionary) => {
  if (!key || !value || !dictionary) {
    return '';
  }

  let fixedKey = key;

  if (key === 'cementVariety') {
    fixedKey = 'cementType';
  }

  if (!dictionary[fixedKey]) {
    return '';
  }

  const filtered = dictionary[fixedKey].filter((item) => item.value === value);
  return filtered.length ? filtered[0].label : '';
};
