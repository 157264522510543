import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  deleteNotificationReq,
  notificationsUnreadReq,
  deleteNotificationsReq,
  notificationMarkAsReadReq,
  notificationsReq,
} from '../../api/notification';

export const getNotifications = createAsyncThunk(
  'notifications/getNotifications',
  async (creds, { rejectWithValue }) => {
    try {
      const response = await notificationsReq(creds);
      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getNotificationsUnread = createAsyncThunk(
  'notifications/getNotificationsUnread',
  async (creds, { rejectWithValue }) => {
    try {
      const response = await notificationsUnreadReq();
      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const deleteNotification = createAsyncThunk(
  'notifications/deleteNotification',
  async (creds, { rejectWithValue }) => {
    try {
      const response = await deleteNotificationReq(creds);
      if (response.status === 200) {
        return { id: creds, data: response.data };
      } else {
        return rejectWithValue(response.data);
      }
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const deleteNotifications = createAsyncThunk(
  'notifications/deleteNotifications',
  async (creds, { rejectWithValue }) => {
    try {
      const response = await deleteNotificationsReq();
      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const notificationMarkAsRead = createAsyncThunk(
  'notifications/notificationMarkAsRead',
  async (creds, { rejectWithValue }) => {
    try {
      const response = await notificationMarkAsReadReq(creds);
      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const markAsReadLocal = (id) => {
  return {
    type: 'notifications/MARK_AS_READ_LOCAL',
    id,
  };
};

export const clearList = () => {
  return {
    type: 'notifications/CLEAR_LIST',
  };
};

export const clearUnreadCount = () => {
  return {
    type: 'notifications/CLEAR_UNREAD_COUNT',
  };
};

const initialState = {
  isListLoading: false,
  isListError: false,
  data: [],
  meta: {},
  links: {},

  isUnreadListLoading: false,
  isUnreadListError: false,
  unreadList: [],
  unreadListMeta: {},
};

const notificationSlice = createSlice({
  name: 'notifications',
  initialState,

  extraReducers: (builder) => {
    builder.addCase(getNotifications.pending, (state) => {
      state.isListLoading = true;
      state.isListError = false;
    });
    builder.addCase(getNotifications.fulfilled, (state, payload) => {
      state.isListLoading = false;
      state.isError = false;
      state.data = [...state.data, ...payload.payload.data];
      state.meta = payload.payload.meta;
      state.links = payload.payload.links;
    });
    builder.addCase(getNotifications.rejected, (state) => {
      state.isListLoading = false;
      state.isListError = true;
    });

    builder.addCase(getNotificationsUnread.pending, (state) => {
      state.isUnreadListLoading = true;
      state.isUnreadListError = false;
      state.unreadList = [];
    });
    builder.addCase(getNotificationsUnread.fulfilled, (state, payload) => {
      state.isUnreadListLoading = false;
      state.isUnreadListError = false;
      state.unreadList = payload.payload.data;
      state.unreadListMeta = payload.payload.meta;
    });
    builder.addCase(getNotificationsUnread.rejected, (state) => {
      state.isUnreadListLoading = false;
      state.isUnreadListError = true;
    });

    builder.addCase(deleteNotification.pending, (state) => {
      state.isListLoading = true;
      state.isListError = false;
    });
    builder.addCase(deleteNotification.fulfilled, (state, payload) => {
      state.isListLoading = false;
      state.isError = false;
      const data = state.data.filter((item) => item.id !== payload.payload.id);
      state.data = data;
      state.meta = {
        ...state.meta,
        total: state.meta.total - 1,
      };
      state.unreadListMeta = {
        ...state.unreadListMeta,
        total: state.unreadListMeta.total - 1,
      };
    });
    builder.addCase(deleteNotification.rejected, (state, payload) => {
      state.isListLoading = false;
      state.isListError = true;
    });

    builder.addCase(deleteNotifications.pending, (state) => {
      state.isListLoading = true;
      state.isListError = false;
    });
    builder.addCase(deleteNotifications.fulfilled, (state, payload) => {
      state.isListLoading = false;
      state.isError = false;
      state.meta = {};
      state.data = [];
      state.links = {};
      state.unreadListMeta = {
        ...state.unreadListMeta,
        total: 0,
      };
    });
    builder.addCase(deleteNotifications.rejected, (state) => {
      state.isListLoading = false;
      state.isListError = true;
    });

    builder.addCase(notificationMarkAsRead.pending, (state) => {});
    builder.addCase(notificationMarkAsRead.fulfilled, (state, payload) => {
      state.unreadListMeta = {
        ...state.unreadListMeta,
        total: state.unreadListMeta.total - 1,
      };
    });
    builder.addCase(notificationMarkAsRead.rejected, (state) => {});

    builder.addCase('notifications/MARK_AS_READ_LOCAL', (state, action) => {
      state.data = state.data.map((item) => {
        if (item.id === action.id) {
          return {
            ...item,
            read_at: new Date().toISOString(),
          };
        } else {
          return item;
        }
      });
    });
    builder.addCase('notifications/CLEAR_LIST', (state, action) => {
      state.data = [];
      state.meta = {};
      state.links = {};
    });
    builder.addCase('notifications/CLEAR_UNREAD_COUNT', (state, action) => {
      state.isUnreadListLoading = false;
      state.isUnreadListError = false;
      state.unreadList = [];
      state.unreadListMeta = {};
    });
  },
});

const { reducer } = notificationSlice;

export const selectNotification = (state) => state.data;

export default reducer;
