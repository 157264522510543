/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Tab from '../../../components/ui/Tab';
import OrdersList from './OrdersList/OrdersList';
import { useDispatch, useSelector } from 'react-redux';
import { orders, orderCancel } from '../../../store/slice/ordersCustomerSlise';
import { debounce } from 'lodash';
import Button from '../../../components/ui/Button';
import { BUTTON_ROLE, BUTTON_SIZE } from '../../../components/ui/Button/Button';
import { useMediaQuery } from 'react-responsive';
import Icon from '../../../components/ui/Icon';
import { customerMyOrders } from '../../../api/order';
import { AlertMessengerContext } from '../../../provider/Alert';
import { FormattedMessage, useIntl } from 'react-intl';

const OrdersPage = ({ activeDefault }) => {
  const navigate = useNavigate();
  const intl = useIntl();
  const titles = [
    intl.formatMessage({
      id: `page.orders.tab.noAnswered`,
      defaultMessage: 'Not Answered',
    }),
    intl.formatMessage({
      id: `page.orders.tab.current`,
      defaultMessage: 'Current',
    }),
    intl.formatMessage({
      id: `page.orders.tab.archive`,
      defaultMessage: 'Archive',
    }),
  ];
  const [pageTitle, setPageTitle] = useState(titles[0]);

  const params = useParams();
  const [searchParams] = useSearchParams();
  const pageQuery = searchParams.get('page') || 1;
  const statusQuery = searchParams.get('status') || 'new';

  const dispatch = useDispatch();
  const isListLoading = useSelector((state) => state.ordersCustoemr.isLoading);
  const orderList = useSelector((state) => state.ordersCustoemr.data);
  const orderMeta = useSelector((state) => state.ordersCustoemr.meta);

  const { addSuccessMessage } = useContext(AlertMessengerContext);

  const getActiveTab = () => {
    if (!statusQuery) {
      return activeDefault;
    }
    if (statusQuery === 'new') {
      return 1;
    }
    if (statusQuery === 'in_progress') {
      return 2;
    }
    if (statusQuery === 'archive') {
      return 3;
    }
  };
  // eslint-disable-next-line no-unused-vars

  const [emptyOrder, setEmptyOrders] = useState(false);

  useEffect(() => {
    customerMyOrders().then((res) => {
      if (res.data.meta.total === 0) {
        setEmptyOrders(true);
      } else {
        setEmptyOrders(false);
      }
    });
  }, []);

  const getOrdersList = () => {
    dispatch(
      orders({
        companyId: params.id_company,
        data: {
          page: pageQuery,
          status: statusQuery,
          per_page: 10,
        },
        type: statusQuery,
      })
    )
      .unwrap()
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const cancelOrder = (id) => {
    dispatch(orderCancel(id))
      .unwrap()
      .then(() => {
        addSuccessMessage({
          message: intl.formatMessage({
            id: `page.orders.cancelOrderMsg`,
            defaultMessage: 'You have successfully canceled your order!',
          }),
        });
      })
      .finally(() => {
        debouncedFetchList();
      });
  };

  const debouncedFetchList = debounce(getOrdersList, 25, { maxWait: 25 });

  useEffect(() => {
    document.title = `${intl.formatMessage({
      id: `page.ad.orders.title`,
      defaultMessage: 'My Orders',
    })}  ${emptyOrder ? '' : '- ' + pageTitle}`;
  }, [pageTitle, emptyOrder]);

  useEffect(() => {
    debouncedFetchList();
  }, [pageQuery, statusQuery]);

  useEffect(() => {
    const params = new URLSearchParams();
    if (!statusQuery) {
      params.append('status', 'new');
    } else {
      params.append('status', statusQuery);
    }
    if (!pageQuery) {
      params.append('page', 1);
    } else {
      params.append('page', pageQuery);
    }

    navigate(
      {
        search: params.toString(),
      },
      {
        replace: true,
      }
    );
  }, []);

  const tabs = [
    {
      title: intl.formatMessage({
        id: `page.orders.tab.noAnswered`,
        defaultMessage: 'Not Answered',
      }),
      id: 1,
      page: (
        <OrdersList
          title={pageTitle}
          meta={orderMeta}
          isListLoading={isListLoading}
          orderList={orderList}
          cancelOrder={cancelOrder}
        />
      ),
    },
    {
      title: intl.formatMessage({
        id: `page.orders.tab.current`,
        defaultMessage: 'Current',
      }),
      id: 2,
      page: (
        <OrdersList
          title={pageTitle}
          meta={orderMeta}
          isListLoading={isListLoading}
          orderList={orderList}
          cancelOrder={cancelOrder}
        />
      ),
    },
    {
      title: intl.formatMessage({
        id: `page.orders.tab.archive`,
        defaultMessage: 'Archive',
      }),
      id: 3,
      page: (
        <OrdersList
          title={pageTitle}
          meta={orderMeta}
          isListLoading={isListLoading}
          orderList={orderList}
          cancelOrder={cancelOrder}
        />
      ),
    },
  ];

  const onTabChange = (id) => {
    const params = new URLSearchParams();

    switch (id) {
      case 1:
        params.append('status', 'new');
        params.append('page', 1);
        break;
      case 2:
        params.append('status', 'in_progress');
        params.append('page', 1);
        break;
      case 3:
        params.append('status', 'archive');
        params.append('page', 1);
        break;
      default:
        break;
    }

    navigate(
      {
        search: params.toString(),
      },
      {
        replace: true,
      }
    );
  };
  const isMobileOrTablet = useMediaQuery({
    query: '(max-width: 900px) ',
  });

  return (
    <div className="company-page">
      <div className="company-page__inner">
        <div className="company-page__header">
          <span className="company-page__header-title">
            <FormattedMessage
              id="page.ad.orders.title"
              defaultMessage="My Orders"
            />
          </span>
          {!emptyOrder && (
            <Button
              text={intl.formatMessage({
                id: `page.orders.add`,
                defaultMessage: 'Add New Order',
              })}
              size={
                isMobileOrTablet ? BUTTON_SIZE.ADD_SMALL : BUTTON_SIZE.MEDIUM
              }
              role={BUTTON_ROLE.ADD_BUTTON}
              hideText={true}
              onClick={() => navigate('/customer/my-orders/order/new-order')}
            />
          )}
        </div>

        {emptyOrder ? (
          <div>
            <div className="empty-list">
              <div className="empty-list__icon">
                <Icon icon="orders-big" size="52px" />
              </div>
              <span className="empty-list__title">
                <FormattedMessage
                  id="page.ad.orders.title"
                  defaultMessage="You have no orders. Create a new order"
                />
              </span>
              <Button
                text={intl.formatMessage({
                  id: `page.orders.add`,
                  defaultMessage: 'Add New Order',
                })}
                size={BUTTON_SIZE.MEDIUM}
                role={BUTTON_ROLE.ADD_BUTTON}
                onClick={() => navigate('/customer/my-orders/order/new-order')}
              />
            </div>
          </div>
        ) : (
          <div className="company-page__body orders-page">
            <Tab
              activeDefault={getActiveTab()}
              tabs={tabs}
              onTabChange={(id) => {
                setPageTitle(titles[id - 1]);
                onTabChange(id);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

OrdersPage.defaultProps = {
  activeDefault: 1,
};

OrdersPage.propTypes = {
  activeDefault: PropTypes.number,
};

export default OrdersPage;
