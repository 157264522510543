import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { projectGet, myProjectGet, projectShow } from '../../api/project';
// import { PROJECT_DETAIL_SA_MOCK } from '../../mocks/project.mock';

// myProjectGet

export const getMyProject = createAsyncThunk(
  'project/getProject',
  async (creds, { rejectWithValue }) => {
    try {
      const response = await myProjectGet(creds.query);
      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getProject = createAsyncThunk(
  'project/getProject',
  async (creds, { rejectWithValue }) => {
    try {
      const response = await projectGet(creds.id, creds.query);
      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const projectDetail = createAsyncThunk(
  'project/projectShow',
  async (creds, { rejectWithValue }) => {
    try {
      const response = await projectShow(creds.companyId, creds.projectId);
      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const editProject = createAsyncThunk(
  'project/editProject',
  async (creds, { rejectWithValue }) => {
    // try {
    //   const response = await userEditInfo(creds);
    //   if (response.status === 200) {
    //     return response.data;
    //   }
    // } catch (error) {
    //   return rejectWithValue(error.response.data);
    // }
  }
);

// TODO: NOT USED
export const redirectProjectEnable = () => {
  return {
    type: 'project/REDIRECT_ENABLE',
  };
};

// TODO: NOT USED
export const setRedirectProjectPayload = (projectId, projectName) => {
  return {
    type: 'project/REDIRECT_PROJECT_PAYLOAD',
    projectId,
    projectName,
  };
};

// TODO: NOT USED
export const clearRedirectProjectPayload = () => {
  return {
    type: 'project/CLEAR_REDIRECT_PROJECT_PAYLOAD',
  };
};

const initialState = {
  isListLoading: false,
  isLoadingEdit: false,
  isError: false,
  // TODO: NOT USED
  createOrderProjectRedirectPayload: {
    redirect: false,
    projectId: null,
    projectName: null,
  },
  data: [],
  meta: {},
};

const projectSlice = createSlice({
  name: 'project',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getProject.pending, (state) => {
      state.isListLoading = true;
      state.isError = false;
      state.data = [];
    });
    builder.addCase(getProject.fulfilled, (state, payload) => {
      state.isListLoading = false;
      state.isError = false;
      state.data = payload.payload.data;
      state.meta = payload.payload.meta;
    });

    //     builder.addCase(editProject.pending, (state) => {
    //       state.isLoadingEdit = true;
    //       state.isError = false;
    //     });
    //     builder.addCase(editProject.fulfilled, (state, payload) => {
    //       state.isLoadingEdit = false;
    //       state.isError = false;
    //     });
    //     builder.addCase(editProject.rejected, (state, payload) => {
    //       state.isLoadingEdit = false;
    //       state.isError = false;
    //     });

    builder.addCase(projectDetail.pending, (state) => {
      state.isListLoading = true;
      state.isError = false;
      state.data = [];
    });
    builder.addCase(projectDetail.fulfilled, (state, payload) => {
      state.isListLoading = false;
      state.isError = false;
      state.data = [payload.payload.data];
    });
    builder.addCase(projectDetail.rejected, (state, payload) => {
      state.isListLoading = false;
      state.isError = true;
      // state.data = [PROJECT_DETAIL_SA_MOCK];
      state.data = [];
    });
    // TODO: NOT USED
    builder.addCase('project/REDIRECT_PROJECT_PAYLOAD', (state, action) => {
      if (action.projectId) {
        state.createOrderProjectRedirectPayload.projectId = action.projectId;
      }
      if (action.projectName) {
        state.createOrderProjectRedirectPayload.projectName =
          action.projectName;
      }
    });
    // TODO: NOT USED
    builder.addCase(
      'project/CLEAR_REDIRECT_PROJECT_PAYLOAD',
      (state, action) => {
        state.createOrderProjectRedirectPayload = {
          redirect: false,
          projectId: null,
          projectName: null,
        };
      }
    );
    // TODO: NOT USED
    builder.addCase('project/REDIRECT_ENABLE', (state, action) => {
      state.createOrderProjectRedirectPayload.redirect = true;
    });
  },
});

const { reducer } = projectSlice;

export const selectProject = (state) => state.project;

export default reducer;
