import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../../../../components/ui/Icon';
import { FormattedMessage } from 'react-intl';

const OrderTitleMsgBar = ({ time, selectedSuppCount }) => {
  return (
    <>
      {time ? (
        <div className="order-page-customer__content-header-msg">
          <Icon icon="timer" size="20px" />
          <span>
            <FormattedMessage
              id="page.orderDetail.makeOfferTime.title"
              defaultMessage={
                <span>
                  You have <span className="link">{time}</span> to make a
                  decision
                </span>
              }
              values={{
                node: <span className="link">{time}</span>,
              }}
            />
          </span>
        </div>
      ) : (
        ''
      )}
      {selectedSuppCount !== 3 ? (
        <div className="order-page-customer__content-header-msg-2">
          <div className="msg-icon">
            <Icon icon="alert" size="20px" />
          </div>
          <span>
            <FormattedMessage
              id="page.orderDetail.makeOffer.toComplete"
              defaultMessage=" To complete an order, you must accept an offer from one types of
            suppliers"
            />
          </span>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

OrderTitleMsgBar.defaultProps = {
  time: '',
  selectedSuppCount: 0,
};

OrderTitleMsgBar.propTypes = {
  time: PropTypes.string.isRequired,
  selectedSuppCount: PropTypes.number.isRequired,
};

export default OrderTitleMsgBar;
