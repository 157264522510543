/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import LoadedContent from '../../../../../components/LoadedContent';

import Icon from '../../../../../components/ui/Icon';
import Pagination from '../../../../../components/Pagination';
import OfferCardCustomer, {
  OFFER_CART_TYPE,
} from '../../../../../components/OfferCardCustomer/OfferCardCustomer';
import { useParams } from 'react-router-dom';
import {
  acceptOffer,
  cancelAcceptedOffer,
  changeOffer,
} from '../../../../../api/offer';
import { AlertMessengerContext } from '../../../../../provider/Alert';
import Button from '../../../../../components/ui/Button';
import { BUTTON_SIZE } from '../../../../../components/ui/Button/Button';
import { setOrderInProgress } from '../../../../../api/order';
import ConfirmPopup, {
  CONFIRM_POPUP_TYPE,
} from '../../../../../components/ConfirmPopup/ConfirmPopup';
import {
  getMyOrder,
  getMyOrderUpdate,
  setChangeSelectedOfferProcessState,
} from '../../../../../store/slice/orderCustomerSlice';
import { useDispatch, useSelector } from 'react-redux';
import { offersSort } from '../../../../../utils/offers.sort';
import { FormattedMessage, useIntl } from 'react-intl';

const acceptText = {
  1: 'page.orderDetail.makeOffer.accept',
  2: 'page.orderDetail.makeOffer.accept',
  3: 'page.orderDetail.makeOffer.fullAccept',
};

const acceptTextNoQa = {
  1: 'page.orderDetail.makeOffer.accept',
  2: 'page.orderDetail.makeOffer.fullAccept',
};

const companyType = {
  factory: 'Material Supplier',
  service: 'Service Provider',
  'quality-control': 'Quality Assurance',
};

const OrderInfoTabOfferList = ({
  orderList,
  isListLoading,
  currentPage,
  totalCount,
  description,
  itsInProgress,
  acceptOfferHandler,
  selectedSuppCount,
  itsDone,
  orderProject,
  spec,
}) => {
  const intl = useIntl();
  const queryParams = useParams();
  const [requesting, setRequesting] = useState(false);
  const [showAcceptOrderPopup, setShowAcceptOrderPopup] = useState(false);
  const [showAcceptFullOrderPopup, setShowAcceptFullOrderPopup] =
    useState(false);
  const [changeOfferPopup, setChangeOfferPopup] = useState({
    offerId: null,
    showModal: false,
  });
  const { addErrorMessage } = useContext(AlertMessengerContext);

  const dispatch = useDispatch();

  // TODO: USELESS FUNCTIONAL;
  const isChangeOfferProcess = useSelector(
    (state) => state.orderCustomer.changeSelectedOfferProcess
  );
  /* eslint-disable no-unused-vars */
  const isMobileOrTablet = useMediaQuery({
    query: '(max-width: 767px) ',
  });

  const acceptHandler = async (offerId, offer) => {
    setRequesting(true);

    try {
      await acceptOffer(queryParams.id_order, offerId);
      dispatch(getMyOrderUpdate({ id: queryParams.id_order }));

      setRequesting(false);
      acceptOfferHandler();
    } catch (err) {
      const { response } = err;

      setRequesting(false);
      addErrorMessage({
        message: response && response.data.message,
      });
    }
  };

  const cancelOffer = async (offerId) => {
    setRequesting(true);
    setChangeOfferPopup({ id: null, showModal: false });

    try {
      await cancelAcceptedOffer(queryParams.id_order, offerId);

      dispatch(getMyOrderUpdate({ id: queryParams.id_order }));
      dispatch(setChangeSelectedOfferProcessState(false));
      setRequesting(false);
      acceptOfferHandler();
    } catch (err) {
      const { response } = err;
      setRequesting(false);
      addErrorMessage({
        message: response && response.data.message,
      });
    }
  };

  const acceptOrder = async () => {
    setShowAcceptOrderPopup(false);
    setShowAcceptFullOrderPopup(false);
    setRequesting(true);

    try {
      const res = await setOrderInProgress(queryParams.id_order);
      dispatch(getMyOrder({ id: queryParams.id_order }));

      setRequesting(false);
    } catch (err) {
      const { response } = err;
      console.log(err);
      setRequesting(false);
      addErrorMessage({
        message: response && response.data.message,
      });
    }
  };

  const getAcceptedOffers = () =>
    orderProject && orderProject.offers
      ? orderProject.offers.filter((item) => item.status === 'accept')
      : [];

  return (
    <div className="company-page customer-page">
      <div className="company-page__inner">
        <div className="company-page__body">
          <div className="company-page customer-page offer-tab-list">
            <LoadedContent loader={isListLoading || requesting} />
            <div className="company-page__inner">
              <div className="company-page__list">
                {orderList &&
                  orderList?.map((item) => (
                    <div key={item.id} className="company-page__list-item">
                      <OfferCardCustomer
                        description={description}
                        data={item}
                        acceptHandler={(id) => {
                          acceptHandler(id);
                        }}
                        changeHandler={(id) => {
                          setChangeOfferPopup({ offerId: id, showModal: true });
                        }}
                        cardType={
                          itsDone
                            ? OFFER_CART_TYPE.ACCEPTED
                            : OFFER_CART_TYPE.NO_ACCEPTED
                        }
                        disabled={
                          orderProject && orderProject.status !== 'not_answered'
                        }
                      />
                    </div>
                  ))}
                {!isListLoading && orderList && !orderList.length ? (
                  <div className="empty-list">
                    <div className="empty-list__icon">
                      <Icon icon="alert" size="32px" />
                    </div>
                    <span className="empty-list__title">
                      <FormattedMessage
                        id="page.orderDetail.noOffers"
                        defaultMessage="You have no offers"
                      />
                    </span>
                  </div>
                ) : (
                  ''
                )}
              </div>
              {!isListLoading &&
              orderList &&
              orderList.length &&
              itsDone &&
              selectedSuppCount ? (
                <div className="offer-tab-list__accept">
                  <Button
                    size={BUTTON_SIZE.MEDIUM}
                    text={intl.formatMessage(
                      {
                        id:
                          orderProject.orderClass !== 'no'
                            ? acceptText[selectedSuppCount.toString()]
                            : acceptTextNoQa[selectedSuppCount.toString()],
                        defaultMessage: `Accept only ${selectedSuppCount.toString()} of 3 Suppliers`,
                      },
                      {
                        current: selectedSuppCount.toString(),
                        total: orderProject.orderClass !== 'no' ? 3 : 2,
                      }
                    )}
                    disabled={
                      orderProject && orderProject.status !== 'not_answered'
                    }
                    onClick={() => {
                      if (selectedSuppCount === 3) {
                        setShowAcceptFullOrderPopup(true);
                      } else {
                        setShowAcceptOrderPopup(true);
                      }
                    }}
                  />
                </div>
              ) : (
                ''
              )}
              {!isListLoading && (
                <Pagination
                  totalCount={totalCount}
                  currentPage={currentPage}
                  onPageChange={() => {}}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <ConfirmPopup
        open={changeOfferPopup.showModal}
        confirmButtonText={intl.formatMessage({
          id: `popup.btn.change`,
          defaultMessage: 'Yes, change',
        })}
        cancelButtonText={intl.formatMessage({
          id: `popup.btn.cancel`,
          defaultMessage: 'Cancel',
        })}
        text={intl.formatMessage({
          id: `popup.order.changeOffer`,
          defaultMessage: 'Are you sure that you want change the offer?',
        })}
        isLoading={false}
        onSubmit={() => {
          dispatch(setChangeSelectedOfferProcessState(true));
          cancelOffer(changeOfferPopup.offerId);
        }}
        onCloseModal={() => setChangeOfferPopup({ id: null, showModal: false })}
      />
      <ConfirmPopup
        open={showAcceptOrderPopup}
        confirmButtonText={intl.formatMessage({
          id: `popup.btn.publish`,
          defaultMessage: 'Yes, accept',
        })}
        cancelButtonText={intl.formatMessage({
          id: `popup.btn.cancel`,
          defaultMessage: 'Cancel',
        })}
        data={offersSort(getAcceptedOffers()).map((item) => ({
          category: intl.formatMessage({
            id: `company.supplier.${item.company.spec}`,
            defaultMessage: companyType[item.company.spec],
          }),
          name: item.company.name,
          id: item.company.id,
        }))}
        max={orderProject.orderClass !== 'no' ? 3 : 2}
        isLoading={false}
        type={CONFIRM_POPUP_TYPE.SUPPLIER_ACCEPT}
        onSubmit={acceptOrder}
        onCloseModal={() => setShowAcceptOrderPopup(false)}
      />
      <ConfirmPopup
        open={showAcceptFullOrderPopup}
        confirmButtonText={intl.formatMessage({
          id: `popup.btn.publish`,
          defaultMessage: 'Yes, accept',
        })}
        cancelButtonText={intl.formatMessage({
          id: `popup.btn.cancel`,
          defaultMessage: 'Cancel',
        })}
        text={intl.formatMessage({
          id: `popup.order.accept`,
          defaultMessage: 'Are you sure you want to accept?',
        })}
        isLoading={false}
        onSubmit={acceptOrder}
        onCloseModal={() => setShowAcceptFullOrderPopup(false)}
      />
    </div>
  );
};

OrderInfoTabOfferList.defaultProps = {
  orderList: [],
  totalCount: 1,
  currentPage: 1,
  itsInProgress: false,
  selectedSuppCount: 0,
  itsDone: false,
  acceptOfferHandler: () => {},
  spec: '',
};

OrderInfoTabOfferList.propTypes = {
  isListLoading: PropTypes.bool,
  orderList: PropTypes.array,
  currentPage: PropTypes.number,
  totalCount: PropTypes.number,
  selectedSuppCount: PropTypes.number,
  description: PropTypes.string,
  itsInProgress: PropTypes.bool,
  acceptOfferHandler: PropTypes.func,
  itsDone: PropTypes.bool,
  orderProject: PropTypes.object,
  spec: PropTypes.string,
};

export default OrderInfoTabOfferList;
