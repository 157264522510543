import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import Icon from '../Icon';
import InputMask from 'react-input-mask';

const FormInputMask = (props) => {
  const {
    label,
    id,
    type,
    placeholder,
    disabled,
    onFocus,
    errors,
    rules,
    control,
    name,
    mask,
    maskChar,
    inputMode,
    beforeMaskedValueChange,
    checkSuccess = true,
  } = props;

  const formInputClass = (value) => {
    let classInput = '';
    if (!disabled) {
      classInput += 'form-input';
    } else {
      classInput += 'form-input disabled';
    }
    if (checkSuccess && value && value.length && !disabled && !errors) {
      classInput += ' success';
    }
    return classInput;
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value, onBlur } }) => {
        const [isFocus, setIsFocus] = useState(false);

        return (
          <InputMask
            mask={typeof mask === 'string' ? mask : mask(value)}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            maskChar={maskChar}
            beforeMaskedValueChange={beforeMaskedValueChange}
          >
            {(inputProps) => (
              <div className={formInputClass(value)}>
                <div className="form-input__label-wrap">
                  {label && (
                    <label
                      htmlFor={id}
                      className={
                        errors ? 'form-input__label error' : 'form-input__label'
                      }
                    >
                      {label}
                      {rules && rules.required ? (
                        <span className="required">*</span>
                      ) : (
                        ''
                      )}
                    </label>
                  )}
                </div>
                <div
                  className={
                    isFocus && !errors ? `input-outline focus` : `input-outline`
                  }
                >
                  <div
                    className={
                      errors
                        ? 'form-input__input-wrap'
                        : 'form-input__input-wrap'
                    }
                  >
                    <input
                      {...inputProps}
                      id={id}
                      type={type}
                      inputMode={inputMode}
                      placeholder={placeholder}
                      disabled={disabled}
                      onFocus={() => {
                        setIsFocus(true);
                        if (onFocus) {
                          onFocus();
                        }
                      }}
                      onBlur={() => {
                        setIsFocus(false);
                        onBlur();
                      }}
                      className={errors ? 'error' : ''}
                    />
                    {errors && (
                      <div className="danger-icon">
                        <Icon icon="alert" size="18px" />
                      </div>
                    )}
                  </div>
                </div>
                {errors && <p className="error">{errors.message}</p>}
              </div>
            )}
          </InputMask>
        );
      }}
    />
  );
};

FormInputMask.defaultProps = {
  inputMode: 'text',
};

FormInputMask.propTypes = {
  text: PropTypes.string,
  label: PropTypes.string.isRequired,
  id: PropTypes.string,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  mask: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.func.isRequired,
  ]),
  placeholder: PropTypes.string,
  value: PropTypes.string,
  maskChar: PropTypes.string,
  disabled: PropTypes.bool,
  onFocus: PropTypes.func,
  errors: PropTypes.object,
  rules: PropTypes.object,
  control: PropTypes.object.isRequired,
  beforeMaskedValueChange: PropTypes.func,
  inputMode: PropTypes.string.isRequired,
  checkSuccess: PropTypes.bool,
};

export default FormInputMask;
