import React from 'react';
import PropTypes from 'prop-types';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import PageLayout from '../../pages';

const AuthGuard = ({ allowedRoles }) => {
  const token = localStorage.getItem('token');
  const role = localStorage.getItem('role');

  const location = useLocation();

  return allowedRoles.includes(role) ? (
    <PageLayout roles={[role]}>
      <Outlet />
    </PageLayout>
  ) : token ? (
    <PageLayout roles={[role]}>
      {/* TODO: OR DISPLAY ERROR PAGE */}
      {/* <ErrorPage /> */}
      <Navigate to="/" state={{ from: location }} replace />
    </PageLayout>
  ) : (
    <PageLayout>
      <Navigate to="/sign-in" state={{ from: location }} replace />
    </PageLayout>
  );
};

AuthGuard.propTypes = {
  allowedRoles: PropTypes.array.isRequired,
};

export default AuthGuard;
