import React, { useEffect } from 'react';
// import useScrollBlock from '../../utils/useScrollBlock';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteNotifications,
  deleteNotification,
  getNotifications,
  notificationMarkAsRead,
  markAsReadLocal,
  clearList,
  getNotificationsUnread,
} from '../../store/slice/notificationSlice';
import NotificationText from './NotificationText';
import InfiniteScroll from 'react-infinite-scroll-component';
import LoadedContent from '../LoadedContent';
import Icon from '../ui/Icon';
import useWindowSize from '../../utils/useWindowSize';
import { FormattedMessage } from 'react-intl';

const NotificationModal = ({ dismiss }) => {
  const data = useSelector((state) => state.notifications.data);
  const meta = useSelector((state) => state.notifications.meta);
  const links = useSelector((state) => state.notifications.links);
  const listLoading = useSelector((state) => state.notifications.isListLoading);
  const dispatch = useDispatch();
  const size = useWindowSize();

  const deleteItem = (id) => {
    dispatch(deleteNotification(id));
    dispatch(getNotificationsUnread());
  };

  const deleteAll = () => {
    dispatch(deleteNotifications());
    dispatch(getNotificationsUnread());
  };

  const markAsReadHandler = (id) => {
    dispatch(notificationMarkAsRead(id));
    dispatch(markAsReadLocal(id));
  };

  useEffect(() => {
    dispatch(getNotifications());

    return () => {
      dispatch(clearList());
    };
  }, []);

  return (
    <div
      className="notification-page"
      style={{
        height: `calc(${size.height}px)`,
        // padding: expanded ? '16px' : '0 16px',
        // top: expanded ? '56px' : '100vh',
        // opacity: expanded ? '1' : '0',
      }}
    >
      <div className="notification-page__header">
        <div
          className="notification-page__header-nav"
          onClick={() => dismiss()}
        >
          <Icon icon="arrow-left" size="20px" />
        </div>
        <span className="notification-page__header-title">
          <FormattedMessage
            id="modal.notification.title"
            defaultMessage="Notification"
          />
        </span>
      </div>

      <div
        className={'notification-page__inner'}
        style={{
          height: `calc(${size.height}px - 56px)`,
        }}
      >
        <div className="notification-page__content">
          <LoadedContent loader={!data.length && listLoading} />

          {data && data.length > 0 && (
            <>
              <div className="notification-list">
                <InfiniteScroll
                  dataLength={data.length}
                  next={() => dispatch(getNotifications(meta.current_page + 1))}
                  hasMore={links.next}
                  loader={<div></div>}
                  height={size.height - 132}
                  scrollableTarget="scrollableDiv"
                >
                  <div className="notification-list__inner">
                    {data.map((item) => {
                      return (
                        <div
                          key={item.id}
                          className="notification-list__item"
                          onTouchStart={() => {
                            if (!item.read_at) {
                              markAsReadHandler(item.id);
                              dispatch(markAsReadLocal(item.id));
                            }
                          }}
                        >
                          <div
                            className={
                              item.read_at
                                ? 'notification-list__text'
                                : 'notification-list__text not-read'
                            }
                          >
                            <NotificationText data={item} dismiss={dismiss} />
                            <div onClick={() => deleteItem(item.id)}>
                              <Icon icon="close" size="20px" />
                            </div>
                          </div>
                          <span className="notification-list__date">
                            {moment(item.created_at).format('YYYY.MM.DD')}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </InfiniteScroll>
              </div>

              <div className="notification-delete">
                <div className="notification-delete__inner" onClick={deleteAll}>
                  <div>
                    <Icon icon="trash" size="20px" />
                  </div>
                  <span>
                    <FormattedMessage
                      id="modal.notification.delete"
                      defaultMessage="Delete All"
                    />
                  </span>
                </div>
              </div>
            </>
          )}

          {!listLoading && data && !data.length && (
            <>
              <div className="empty-list">
                <div className="empty-list__icon">
                  <Icon icon="alert" size="32px" />
                </div>
                <span className="empty-list__title">
                  <FormattedMessage
                    id="modal.notification.empty"
                    defaultMessage="You have no notifications"
                  />
                </span>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

NotificationModal.defaultProps = {
  dismiss: () => {},
};

NotificationModal.propTypes = {
  dismiss: PropTypes.func,
};

export default NotificationModal;
