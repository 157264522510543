import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';

export const BUTTON_SIZE = {
  BIG: 'big',
  MEDIUM: 'medium',
  SMALL: 'small',
  LARGE: 'large',
  ADD_SMALL: 'add_small',
};

export const BUTTON_TYPE = {
  PRIMARY: 'primary',
  OUTLINE: 'outline',
  TERTIARY: 'tertiary',
};

export const BUTTON_ROLE = {
  ADD_BUTTON: 'add_role',
  DETAIL: 'detail_role',
  DEFAULT: 'default_role',
};

const Button = (props) => {
  const { text, size, type, icon, onClick, disabled, hideText, role } = props;

  const getIcon = () => {
    if (!icon && role === BUTTON_ROLE.DEFAULT) {
      return;
    }

    let iconComponent;

    if (icon) {
      iconComponent = icon;
    }
    if (role === BUTTON_ROLE.ADD_BUTTON) {
      iconComponent = <Icon icon="plus" />;
    }
    if (role === BUTTON_ROLE.DETAIL) {
      iconComponent = <Icon icon="chevron-right" />;
    }

    return <span className={'btn__icon'}>{iconComponent}</span>;
  };

  return (
    <button
      className={`btn ${type} ${size} ${role} ${hideText ? 'hide-text' : ''}`}
      onClick={onClick}
      disabled={disabled}
    >
      {text && <span className={'btn__text'}>{text}</span>}
      {getIcon()}
    </button>
  );
};

Button.defaultProps = {
  size: BUTTON_SIZE.BIG,
  type: BUTTON_TYPE.PRIMARY,
  disabled: false,
  hideText: false,
  role: BUTTON_ROLE.DEFAULT,
};

Button.propTypes = {
  text: PropTypes.string,
  size: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  icon: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  role: PropTypes.string,
  hideText: PropTypes.bool,
};

export default Button;
