import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import FormInput from '../../../components/ui/FormInput/FormInput';
import Button, {
  BUTTON_SIZE,
  BUTTON_TYPE,
} from '../../../components/ui/Button/Button';
import Icon from '../../../components/ui/Icon';
import { PASSWORD_VALIDATOR_LOCALE } from '../../../validators/password.validator';
import { Link, useNavigate } from 'react-router-dom';
import { userResetPasswordConfirm } from '../../../api/auth';
import { inviteUserConfirm } from '../../../api/company';
import LoadedContent from '../../../components/LoadedContent';
import { FormattedMessage, useIntl } from 'react-intl';

const NewPasswordPage = ({ inviteUser }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [successResponse, setSuccessResponse] = useState(false);
  const [errorResponse, setErrorResponse] = useState(false);
  const navigate = useNavigate();
  const intl = useIntl();

  const parsedUrl = window.location.href.split('token=');
  const token = parsedUrl[1];

  const { control, handleSubmit, watch, formState } = useForm({
    reValidateMode: 'onChange',
    defaultValues: {
      password: '',
      repassword: '',
    },
  });
  const { errors } = formState;

  const newPassword = watch('password');

  const onSubmit = (data) => {
    setIsLoading(true);

    if (inviteUser) {
      inviteUserReq({
        password: data.password,
        rePassword: data.repassword,
        token,
      });
    } else {
      useResetPassword({
        password: data.password,
        repassword: data.repassword,
        token,
      });
    }
  };

  const useResetPassword = (data) => {
    userResetPasswordConfirm(data)
      .then(() => {
        setIsLoading(false);
        setSuccessResponse(true);
        setErrorResponse(false);
      })
      .catch(() => {
        setIsLoading(false);
        setSuccessResponse(false);
        setErrorResponse(true);
      });
  };

  const inviteUserReq = (data) => {
    inviteUserConfirm(data)
      .then(() => {
        setIsLoading(false);
        setSuccessResponse(true);
        setErrorResponse(false);
      })
      .catch(() => {
        setIsLoading(false);
        setSuccessResponse(false);
        setErrorResponse(true);
      });
  };

  useEffect(() => {
    const title = intl.formatMessage({
      id: 'auth.newPassword.title',
      defaultMessage: 'New Password',
    });
    document.title = title;
  }, []);

  const formLocaleMailValidator = () =>
    PASSWORD_VALIDATOR_LOCALE(
      intl.formatMessage({
        id: 'input.error.password_1',
        defaultMessage: 'Password should contain at least one digit',
      }),
      intl.formatMessage({
        id: 'input.error.password_2',
        defaultMessage: 'Password should contain at least one lowercase letter',
      }),
      intl.formatMessage({
        id: 'input.error.password_3',
        defaultMessage: 'Password should contain at least one uppercase letter',
      }),
      intl.formatMessage({
        id: 'input.error.password_4',
        defaultMessage: 'Password must contain at least 8 characters',
      })
    );

  return (
    <div className="new-password">
      <div className="new-password__header">
        <Link to="/sign-in">
          <Icon icon="logo-light" />
        </Link>
      </div>
      <div className="new-password__logo">
        <div className="new-password__logo-wrapper">
          <Icon icon="logo-big" />
        </div>
      </div>
      <div className="new-password__content">
        <LoadedContent loader={isLoading} />
        {!successResponse && !errorResponse && (
          <div className="new-password__form">
            <form
              className="new-password__form-inner"
              onSubmit={handleSubmit(onSubmit)}
            >
              <span className="new-password__form-title">
                <FormattedMessage
                  id="auth.newPassword.title"
                  defaultMessage="New Password"
                />
              </span>
              <span className="help-message">
                <FormattedMessage
                  id="auth.newPassword.subTitle"
                  defaultMessage="Enter new password and than repeat it"
                />
              </span>
              <FormInput
                control={control}
                label={intl.formatMessage({
                  id: 'auth.newPassword.form.password',
                  defaultMessage: 'New Password',
                })}
                name="password"
                id="password"
                type="password"
                errors={errors.password}
                placeholder={intl.formatMessage({
                  id: 'auth.newPassword.form.passwordPlaceholder',
                  defaultMessage: 'Enter your password',
                })}
                disabled={isLoading}
                rules={{
                  required: (
                    <FormattedMessage
                      id="auth.newPassword.form.required"
                      defaultMessage="Field is required"
                    />
                  ),
                  validate: formLocaleMailValidator(),
                }}
              />
              <FormInput
                control={control}
                label={intl.formatMessage({
                  id: 'auth.newPassword.form.rePassword',
                  defaultMessage: 'Repeat Password',
                })}
                name="repassword"
                id="repassword"
                type="password"
                errors={errors.repassword}
                placeholder={intl.formatMessage({
                  id: 'auth.newPassword.form.rePasswordPlaceholder',
                  defaultMessage: 'Repeat your password',
                })}
                disabled={isLoading}
                rules={{
                  required: (
                    <FormattedMessage
                      id="auth.newPassword.form.required"
                      defaultMessage="Field is required"
                    />
                  ),
                  validate: {
                    ...formLocaleMailValidator(),
                    compare: (value) =>
                      value === newPassword || (
                        <FormattedMessage
                          id="auth.newPassword.form.rePassword.notMatch"
                          defaultMessage="Field is required"
                        />
                      ),
                  },
                }}
              />
              <div className="new-password__form-btn">
                <Button
                  text={intl.formatMessage({
                    id: 'auth.newPassword.btn',
                    defaultMessage: 'Save',
                  })}
                  size={BUTTON_SIZE.MEDIUM}
                  type={BUTTON_TYPE.PRIMARY}
                  disabled={isLoading}
                />
              </div>
            </form>
          </div>
        )}
        {successResponse && (
          <div className="success-screen">
            <div className="success-screen__icon">
              <Icon icon="check-big" size="48px" />
            </div>
            <div className="success-screen__title">
              <FormattedMessage
                id="auth.newPassword.successMsg.title"
                defaultMessage="Password change successful"
              />
            </div>
            <div className="success-screen__subtitle">
              <span className="link" onClick={() => navigate('/sign-in')}>
                <FormattedMessage
                  id="auth.newPassword.successMsg.subTitle"
                  defaultMessage="Sign In"
                />
              </span>
            </div>
          </div>
        )}
        {errorResponse && (
          <div className="success-screen error-screen">
            <div className="success-screen__icon">
              <Icon icon="warning-big" size="48px" />
            </div>
            <div className="success-screen__title">
              <FormattedMessage
                id="auth.newPassword.errorMsg.title"
                defaultMessage="Link is invalid. Please, try again"
              />
            </div>
            <div className="success-screen__subtitle">
              <span
                className="link"
                onClick={() => navigate('/forgot-password')}
              >
                <FormattedMessage
                  id="auth.newPassword.errorMsg.subtitle"
                  defaultMessage="Reset Pasword"
                />
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

NewPasswordPage.defaultProps = {
  inviteUser: false,
};

NewPasswordPage.propTypes = {
  inviteUser: PropTypes.bool,
};

export default NewPasswordPage;
