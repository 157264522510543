import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ordersDictionary } from '../../api/dictionary';
import { dictionaryMapper } from '../../utils/dictionary-mapper';

export const getOrderDictionary = createAsyncThunk(
  'dictionary/getOrderDictionary',
  async (creds, { rejectWithValue }) => {
    try {
      const response = await ordersDictionary();

      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const initialState = {
  isDictionaryLoading: false,
  isError: false,
  orderDictionary: {},
  mappedDictionary: {},
};

const dictionarySlice = createSlice({
  name: 'dictionary',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getOrderDictionary.pending, (state) => {
      state.isProjectLoading = true;
      state.isErrorProject = false;
      state.orderDictionary = {};
      state.mappedDictionary = {};
    });
    builder.addCase(getOrderDictionary.rejected, (state) => {
      state.isProjectLoading = false;
      state.isErrorProject = true;
      state.orderDictionary = {};
      state.mappedDictionary = {};
    });
    builder.addCase(getOrderDictionary.fulfilled, (state, payload) => {
      state.isProjectLoading = false;
      state.isErrorProject = false;
      state.orderDictionary = payload.payload;
      state.mappedDictionary = dictionaryMapper(payload.payload);
    });
  },
});

const { reducer } = dictionarySlice;

export const selectOrderDictionary = (state) => state.orderDictionary;

export default reducer;
