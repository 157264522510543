import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { AlertMessengerContext } from '../../../../provider/Alert';
import { validationErrorParser } from '../../../../utils/validations.error.parser';
import LoadedContent from '../../../../components/LoadedContent';
import StepsComponent, {
  NextStepButton,
  StepPage,
} from '../../../../components/ui/Steps/StepsComponent';
import Dropdown from '../../../../components/ui/dropdown/Dropdown';
import FormInput from '../../../../components/ui/FormInput/FormInput';
import FormInputMask from '../../../../components/ui/FormInputMask';
import Button from '../../../../components/ui/Button';
import {
  BUTTON_SIZE,
  BUTTON_TYPE,
} from '../../../../components/ui/Button/Button';
import { NAME_VALIDATOR } from '../../../../validators/name.validator';
import FormTextArea from '../../../../components/ui/FormTextArea';
import { useDispatch, useSelector } from 'react-redux';
import { editOrder } from '../../../../store/slice/ordersSlice';
import Icon from '../../../../components/ui/Icon';
import { getOrderDictionary } from '../../../../store/slice/dictionarySlice';
import ConfirmPopup from '../../../../components/ConfirmPopup/ConfirmPopup';
import DatePicker from '../../../../components/ui/DatePicker/DatePicker';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { myProjectGet, customerProjectShow } from '../../../../api/project';
import {
  endEditOrder,
  orderCustomer,
  startEditOrder,
} from '../../../../api/order';
import DropdownPaginate from '../../../../components/ui/DropdownPaginate';
import TimePicker from '../../../../components/ui/TimePicker/TimePicker';
import moment from 'moment';
import { getMinDayForCreateOrder } from '../../../../utils/getMinDayForCreateOrder';
import { useMediaQuery } from 'react-responsive';
import { FormattedMessage, useIntl } from 'react-intl';
import { localizeOrderInfo } from '../../../../utils/localizeOrderInfo';

const typeOpt = [
  { label: 'Customer', value: 'customer' },
  { label: 'Supplier', value: 'supplier' },
];

const phoneRegExp = /(^[+]46\d{6,11}$)|(^[+]46\s\d{6,11}$)/;

const EditOrderPage = () => {
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const [isOrderDataFetch, setIsOrderDataFetch] = useState(false);
  const [orderInfoData, setOrderInfoData] = useState();
  const [projectList, setProjectList] = useState([]);
  const [cancelOpen, setCancelPopupOpen] = useState(false);

  const isSubmiting = useSelector((state) => state.orders.isLoading);
  // eslint-disable-next-line no-unused-vars
  const isDictionaryLoading = useSelector(
    (state) => state.dictionary.isDictionaryLoading
  );
  const dictionary = useSelector((state) => state.dictionary.mappedDictionary);

  const [successMsg] = useState(false);
  const dispatch = useDispatch();

  const steps = [
    intl.formatMessage({
      id: 'order.step.1',
      defaultMessage: 'Selection of project',
    }),
    intl.formatMessage({
      id: 'order.step.2',
      defaultMessage: 'Area/Volume',
    }),
    intl.formatMessage({
      id: 'order.step.3',
      defaultMessage: 'Concrete',
    }),
    intl.formatMessage({
      id: 'order.step.4',
      defaultMessage: 'Time',
    }),
    intl.formatMessage({
      id: 'order.step.5',
      defaultMessage: 'Control',
    }),
  ];

  const [step, setStep] = useState(0);
  const navigate = useNavigate();
  const queryParams = useParams();
  const [searchParams] = useSearchParams();
  const redirectProjectId = searchParams.get('projectId') || false;
  const redirectProjectName = searchParams.get('name') || false;
  const [redirectProjectInfoLoading, setRedirectProjectInfoLoading] =
    useState(false);

  const getLocaleMsg = (key, defaultMsg) =>
    intl.formatMessage({
      id: `input.error.${key || 'requiredMsg'}`,
      defaultMessage: defaultMsg || 'Field is required',
    });

  const schema = yup.object().shape({
    service: yup.object().required(getLocaleMsg()).nullable(),
    companyProjectId: yup.object().required(getLocaleMsg()).nullable(),
    contactPerson: yup.object().shape({
      name: yup
        .string()
        .required(getLocaleMsg())
        .min(3, getLocaleMsg('minChar', 'Name must be at least 3 characters')),
      phone: yup
        .string()
        .required(getLocaleMsg())
        .matches(
          phoneRegExp,
          getLocaleMsg('incorrectPhone', 'Phone number is not valid')
        ),
      email: yup
        .string()
        .required(getLocaleMsg())
        .matches(
          /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
          getLocaleMsg('incorrectMail', 'Incorrect E-mail address')
        ),
    }),
    dateOfExecution: yup.string().required(getLocaleMsg()).nullable(),
    location: yup.object().required(getLocaleMsg()).nullable(),
    pump: yup.object().required(getLocaleMsg()).nullable(),
    surfaceType: yup.object().required(getLocaleMsg()).nullable(),
    area: yup
      .number()
      .typeError(getLocaleMsg('incorectVal', 'Incorrect value'))
      .min(1, getLocaleMsg('bigThanZero', 'Must be greater than zero'))
      .required(getLocaleMsg()),
    volume: yup
      .number()
      .typeError(getLocaleMsg('incorectVal', 'Incorrect value'))
      .min(1, getLocaleMsg('bigThanZero', 'Must be greater than zero'))
      .required(getLocaleMsg()),
    slopeOrFall: yup.object().required(getLocaleMsg()).nullable(),
    variety: yup.object().required(getLocaleMsg()).nullable(),
    exposureClass: yup.string().required(getLocaleMsg()),
    vct: yup
      .string()
      .required(getLocaleMsg())
      .test((value, ctx) => {
        const clearValue = value.replace(',', '.');
        if (Number(clearValue < 0.1)) {
          return ctx.createError({
            message: getLocaleMsg('incorectVal', 'Incorrect value'),
          });
        }
        return true;
      }),
    dmax: yup.object().required(getLocaleMsg()).nullable(),
    seatDimensions: yup.object().required(getLocaleMsg()).nullable(),
    additive: yup.object().required(getLocaleMsg()).nullable(),
    cementVariety: yup.object().required(getLocaleMsg()).nullable(),
    heat: yup.object().required(getLocaleMsg()).nullable(),
    timeOfStart: yup.string().required(getLocaleMsg()),
    interval: yup
      .number()
      .typeError(getLocaleMsg('incorectVal', 'Incorrect value'))
      .required(getLocaleMsg()),
    orderClass: yup.object().required(getLocaleMsg()).nullable(),
    coverage: yup.object().required(getLocaleMsg()).nullable(),
  });

  const { addSuccessMessage, addErrorMessage } = useContext(
    AlertMessengerContext
  );

  const {
    control,
    getValues,
    trigger,
    handleSubmit,
    setError,
    clearErrors,
    reset,
    watch,
    setValue,
    formState,
  } = useForm({
    reValidateMode: 'onChange',
    mode: 'onTouched',
    defaultValues: {
      service: {
        value: 'pouring_concrete',
        label: 'Gjutning',
      },
      companyProjectId: '',
      contactPerson: {
        name: '',
        phone: '',
        email: '',
      },
      comments: {
        projectComment: '',
        orderDetailFirstComment: '',
        orderDetailSecondComment: '',
        orderDetailThreeComment: '',
        orderDetailFourComment: '',
      },
      dateOfExecution: '',
      location: '',
      pump: '',
      surfaceType: '',
      area: '',
      volume: '',
      slopeOrFall: '',
      variety: '',
      exposureClass: '',
      vct: '',
      dmax: '',
      seatDimensions: '',
      additive: '',
      cementVariety: '',
      heat: '',
      timeOfStart: '',
      interval: '',
      orderClass: '',
      coverage: '',
    },
    resolver: yupResolver(schema),
  });
  const { errors } = formState;
  const projectIdWatch = watch('companyProjectId');

  const toStepTwo = async () => {
    const res = await trigger([
      'contactPerson',
      'companyProjectId',
      'dateOfExecution',
    ]);

    if (res) {
      clearErrors();
      setStep(step + 1);
    }
  };

  const toStepThree = async () => {
    const res = await trigger([
      'location',
      'pump',
      'surfaceType',
      'area',
      'volume',
      'slopeOrFall',
    ]);

    if (res) {
      clearErrors();
      setStep(step + 1);
    }
  };

  const toStepFour = async () => {
    const res = await trigger([
      'variety',
      'exposureClass',
      'vct',
      'dmax',
      'seatDimensions',
      'additive',
      'cementVariety',
      'heat',
    ]);

    if (res) {
      clearErrors();
      setStep(step + 1);
    }
  };

  const toStepFive = async () => {
    const res = await trigger(['timeOfStart', 'interval']);

    if (res) {
      clearErrors();
      setStep(step + 1);
    }
  };

  const onNextStepLinkClick = async (index) => {
    const stepsFields = {
      0: ['contactPerson', 'companyProjectId', 'dateOfExecution'],
      1: ['location', 'pump', 'surfaceType', 'area', 'volume', 'slopeOrFall'],
      2: [
        'variety',
        'exposureClass',
        'vct',
        'dmax',
        'seatDimensions',
        'additive',
        'cementVariety',
        'heat',
      ],
      3: ['timeOfStart', 'interval'],
    };
    const res = await trigger(stepsFields[index - 1]);

    if (res) {
      clearErrors();
      setStep(index);
    }
  };

  const onSubmit = async () => {
    setOpen(true);
  };

  const sendData = () => {
    const val = getValues();
    val.contactPerson.phone = val.contactPerson.phone.replace(/[()_\s-]/g, '');
    const postData = {
      ...val,
      area: Number(val.area),
      volume: Number(val.volume),
      interval: Number(val.interval),
      service: val.service.value,
      companyProjectId: val.companyProjectId.value,
      location: val.location.value,
      pump: val.pump.value,
      surfaceType: val.surfaceType.value,
      slopeOrFall: val.slopeOrFall.value,
      variety: val.variety.value,
      vct: Number(val.vct.replace(',', '.')),
      dmax: val.dmax.value,
      seatDimensions: val.seatDimensions.value,
      additive: val.additive.value,
      cementVariety: val.cementVariety.value,
      heat: val.heat.value,
      orderClass: val.orderClass.value,
      coverage: val.coverage.value,
      timeOfStart: val.timeOfStart + ':00',
      dateOfExecution: moment(val.dateOfExecution).format('yyyy-MM-DD'),
    };
    dispatch(editOrder({ id: queryParams.id_order, data: postData }))
      .unwrap()
      .then((res) => {
        endEditOrder(queryParams.id_order).then();
        addSuccessMessage({
          message: intl.formatMessage({
            id: 'page.sa.myAcc.info.successMsg',
            defaultMessage: 'Order info update successful!',
          }),
        });
        navigate(`/customer/my-orders?status=new&page=1`);
      })
      .catch((err) => {
        setErrors(err);
      });
    setOpen(false);
  };

  const setErrors = (err) => {
    if (err && err.errors) {
      const parsed = validationErrorParser(err.errors);
      let errorStr = '';

      for (const key of Object.keys(parsed)) {
        setError(key, { type: 'custom', message: parsed[key] });
        errorStr += `${key}: ${parsed[key]} `;
      }

      addErrorMessage({
        message: errorStr,
      });

      return;
    }

    addErrorMessage({
      message: err && err.message ? err.message : err,
    });
  };

  const getProjectsOpt = async (page) => {
    try {
      const res = await myProjectGet({
        per_page: 10,
        page,
      });

      const list = res.data.data.map((item) => ({
        value: item.id,
        label: item.name,
        info: item,
      }));

      if (page === 1) {
        list.unshift({
          label: intl.formatMessage({
            id: 'input.label.mewProj',
            defaultMessage: 'Create a new project',
          }),
          value: 'add_project_opt',
          itsAdd: true,
          action: (e) => {
            navigate(
              `/my-projects/add-project?redirect=true&orderId=${queryParams.id_order}`
            );
          },
        });
        setProjectList(list);
      } else {
        setProjectList([...projectList, ...list]);
      }

      return {
        data: list,
        total: res.data.meta.last_page,
      };
    } catch (err) {
      console.log(err);
    }
  };

  const resetForm = (orderInfo) => {
    reset({
      service: {
        value: 'pouring_concrete',
        label: 'Gjutning',
      },
      companyProjectId: {
        value: redirectProjectId || orderInfo.project.id,
        label: redirectProjectName || orderInfo.project.name,
      },
      contactPerson: {
        name: orderInfo.contactPersonName,
        phone: orderInfo.contactPersonPhone,
        email: orderInfo.contactPersonEmail,
      },
      comments: {
        projectComment: orderInfo.projectComment,
        orderDetailFirstComment: orderInfo.orderDetailFirstComment,
        orderDetailSecondComment: orderInfo.orderDetailSecondComment,
        orderDetailThreeComment: orderInfo.orderDetailThirdComment,
        orderDetailFourComment: orderInfo.orderDetailFourthComment,
      },
      dateOfExecution: new Date(orderInfo.dateOfExecution),
      location: {
        value: orderInfo.location,
        label:
          localizeOrderInfo('location', orderInfo.location, dictionary) ||
          orderInfo.location,
      },
      pump: {
        value: orderInfo.pump,
        label:
          localizeOrderInfo('pump', orderInfo.pump, dictionary) ||
          orderInfo.pump,
      },
      surfaceType: {
        value: orderInfo.surfaceType,
        label:
          localizeOrderInfo('surfaceType', orderInfo.surfaceType, dictionary) ||
          orderInfo.surfaceType,
      },
      area: orderInfo.area,
      volume: orderInfo.volume,
      slopeOrFall: {
        value: orderInfo.slopeOrFall,
        label:
          localizeOrderInfo('slopeOrFall', orderInfo.slopeOrFall, dictionary) ||
          orderInfo.slopeOrFall,
      },
      variety: {
        value: orderInfo.variety,
        label:
          localizeOrderInfo('variety', orderInfo.variety, dictionary) ||
          orderInfo.variety,
      },
      exposureClass: orderInfo.exposureClass,
      vct: orderInfo.vct,
      dmax: {
        value: orderInfo.dmax,
        label:
          localizeOrderInfo('dmax', orderInfo.dmax, dictionary) ||
          orderInfo.dmax,
      },
      seatDimensions: {
        value: orderInfo.seatDimensions,
        label:
          localizeOrderInfo(
            'seatDimensions',
            orderInfo.seatDimensions,
            dictionary
          ) || orderInfo.seatDimensions,
      },
      additive: {
        value: orderInfo.additive,
        label:
          localizeOrderInfo('additive', orderInfo.additive, dictionary) ||
          orderInfo.additive,
      },
      cementVariety: {
        value: orderInfo.cementVariety,
        label:
          localizeOrderInfo(
            'cementVariety',
            orderInfo.cementVariety,
            dictionary
          ) || orderInfo.cementVariety,
      },
      heat: {
        value: orderInfo.heat,
        label:
          localizeOrderInfo('heat', orderInfo.heat, dictionary) ||
          orderInfo.heat,
      },
      timeOfStart: moment(orderInfo.timeOfStart, [
        moment.ISO_8601,
        'HH:mm',
      ]).format('hh:mm'),
      interval: orderInfo.interval,
      orderClass: {
        value: orderInfo.orderClass,
        label:
          localizeOrderInfo('orderClass', orderInfo.orderClass, dictionary) ||
          orderInfo.orderClass,
      },
      coverage: {
        value: orderInfo.coverage,
        label:
          localizeOrderInfo('coverage', orderInfo.coverage, dictionary) ||
          orderInfo.coverage,
      },
    });
  };

  const fetchOrderInfo = async () => {
    setIsOrderDataFetch(true);

    try {
      const res = await orderCustomer(queryParams.id_order);

      if (res && res.data && res.data.data) {
        const orderInfo = res.data.data;
        document.title = intl.formatMessage({
          id: 'order.mainName',
          defaultMessage: 'Concrete casting',
        });
        setOrderInfoData(orderInfo);
        resetForm(orderInfo);
        setIsOrderDataFetch(false);
      }
    } catch (err) {
      setIsOrderDataFetch(false);
    }
  };

  const getSingleProjectInfo = async () => {
    setRedirectProjectInfoLoading(true);
    try {
      const res = await customerProjectShow(redirectProjectId);
      if (res && res.data && res.data.data) {
        setValue('contactPerson.name', res.data.data.contactPerson);
        setValue('contactPerson.phone', res.data.data.phone);
        setValue('contactPerson.email', res.data.data.email);
      }
      setRedirectProjectInfoLoading(false);
    } catch {
      setRedirectProjectInfoLoading(false);
    }
  };

  useEffect(() => {
    document.title = '...';
    dispatch(getOrderDictionary());
    fetchOrderInfo();
    startEditOrder(queryParams.id_order).then();

    if (redirectProjectId) {
      getSingleProjectInfo();
    }

    return () => {
      endEditOrder(queryParams.id_order).then();
    };
  }, []);

  useEffect(() => {
    if (orderInfoData && orderInfoData.id) {
      resetForm(orderInfoData);
    }
  }, [dictionary, orderInfoData]);

  useEffect(() => {
    if (projectIdWatch) {
      if (projectList && projectList.length) {
        const target = projectList.filter(
          (item) => item.value === projectIdWatch.value
        );
        if (target.length) {
          setValue('contactPerson.name', target[0].info.contactPerson);
          setValue('contactPerson.phone', target[0].info.phone);
          setValue('contactPerson.email', target[0].info.email);
        }
      }
    }
  }, [projectIdWatch]);

  // eslint-disable-next-line no-unused-vars
  const isMobileOrTablet = useMediaQuery({
    query: '(max-width: 900px) ',
  });

  return (
    <div className="company-page add-order">
      <LoadedContent
        loader={isSubmiting || isOrderDataFetch || redirectProjectInfoLoading}
      />
      {!successMsg && (
        <div className="company-page__inner">
          <div className="company-page__header">
            <span className="company-page__header-title">
              <FormattedMessage
                id="page.orders.edit"
                defaultMessage="Edit Order"
              />
            </span>
          </div>
          <div className="company-page__body">
            <div className="new-company__steps">
              <form onSubmit={handleSubmit(onSubmit)}>
                <StepsComponent
                  steps={steps}
                  activeStep={step}
                  clickOnPrevStep={(index) => {
                    setStep(index);
                  }}
                  clickOnNextStep={(index) => {
                    onNextStepLinkClick(index);
                  }}
                >
                  <StepPage>
                    <Dropdown
                      handleChange={(data) => {}}
                      options={typeOpt}
                      control={control}
                      label={intl.formatMessage({
                        id: 'input.label.service',
                        defaultMessage: 'Choice of service',
                      })}
                      name="service"
                      id="type"
                      errors={errors.service}
                      disabled={true}
                      rules={{
                        required: 'Field is required',
                      }}
                    />
                    <DropdownPaginate
                      handleChange={(data) => {}}
                      paginateLoad={(page, search) =>
                        getProjectsOpt(page, search)
                      }
                      control={control}
                      label={intl.formatMessage({
                        id: 'input.label.selectProj',
                        defaultMessage: 'Select project',
                      })}
                      name="companyProjectId"
                      id="companyProjectId"
                      errors={errors.companyProjectId}
                      disabled={isSubmiting || isOrderDataFetch}
                      rules={{
                        required: 'Field is required',
                      }}
                    />
                    <DatePicker
                      control={control}
                      label={intl.formatMessage({
                        id: 'input.label.dateExe',
                        defaultMessage: 'Date of execution',
                      })}
                      name="dateOfExecution"
                      id="dateOfExecution"
                      type="text"
                      errors={errors.dateOfExecution}
                      placeholder={intl.formatMessage({
                        id: 'input.placeholder.dateExe',
                        defaultMessage: 'Select date',
                      })}
                      dateFormat={'yyyy.MM.dd'}
                      onFocus={() => clearErrors('dateOfExecution')}
                      minDate={getMinDayForCreateOrder()}
                      disabled={isSubmiting || isOrderDataFetch}
                      rules={{
                        required: 'Field is required',
                      }}
                    />
                    <FormInput
                      control={control}
                      label={intl.formatMessage({
                        id: 'input.label.contactPerson',
                        defaultMessage: 'Contact Person',
                      })}
                      name="contactPerson.name"
                      id="contactPerson.name"
                      type="text"
                      errors={errors.contactPerson && errors.contactPerson.name}
                      placeholder={intl.formatMessage({
                        id: 'input.placeholder.contactPerson',
                        defaultMessage: 'Enter name',
                      })}
                      disabled={isSubmiting || isOrderDataFetch}
                      rules={{
                        required: 'Field is required',
                        validate: NAME_VALIDATOR,
                      }}
                    />
                    <FormInputMask
                      control={control}
                      label={intl.formatMessage({
                        id: 'input.label.phone',
                        defaultMessage: 'Phone',
                      })}
                      name="contactPerson.phone"
                      id="contactPerson.phone"
                      type="tel"
                      inputMode="tel"
                      errors={
                        errors.contactPerson && errors.contactPerson.phone
                      }
                      placeholder="+46 "
                      disabled={isSubmiting || isOrderDataFetch}
                      rules={{
                        required: 'Field is required',
                      }}
                      maskChar=""
                      mask="+46 99999999999"
                    />
                    <FormInput
                      control={control}
                      label={intl.formatMessage({
                        id: 'input.label.mail',
                        defaultMessage: 'E-mail',
                      })}
                      name="contactPerson.email"
                      id="contactPerson.email"
                      type="email"
                      errors={
                        errors.contactPerson && errors.contactPerson.email
                      }
                      placeholder={intl.formatMessage({
                        id: 'input.placeholder.mail',
                        defaultMessage: 'Enter email',
                      })}
                      disabled={isSubmiting || isOrderDataFetch}
                      rules={{
                        required: 'Field is required',
                      }}
                    />
                    <div
                      className="full-width"
                      style={{
                        width: '100%',
                      }}
                    >
                      <FormTextArea
                        control={control}
                        label={intl.formatMessage({
                          id: 'input.label.comment',
                          defaultMessage: 'Comment',
                        })}
                        name="comments.projectComment"
                        id="comments.projectComment"
                        type="text"
                        placeholder={intl.formatMessage({
                          id: 'input.placeholder.comment',
                          defaultMessage: 'Leave a comment',
                        })}
                        disabled={isSubmiting || isOrderDataFetch}
                      />
                    </div>
                    <NextStepButton>
                      <Button
                        disabled={isSubmiting || isOrderDataFetch}
                        text={intl.formatMessage({
                          id: 'steps.btn.cancel',
                          defaultMessage: 'Cancel',
                        })}
                        type={BUTTON_TYPE.OUTLINE}
                        size={BUTTON_SIZE.MEDIUM}
                        onClick={(e) => {
                          e.preventDefault();
                          e.target.blur();
                          setCancelPopupOpen(true);
                        }}
                      />
                      <Button
                        size={BUTTON_SIZE.MEDIUM}
                        text={intl.formatMessage({
                          id: 'steps.btn.nextStep',
                          defaultMessage: 'Next Step',
                        })}
                        onClick={() => {
                          toStepTwo();
                        }}
                      />
                    </NextStepButton>
                  </StepPage>

                  <StepPage>
                    <div className="col-2">
                      <Dropdown
                        handleChange={(data) => {}}
                        options={dictionary.location || []}
                        control={control}
                        label="Läge"
                        name="location"
                        id="location"
                        errors={errors.location}
                        disabled={isSubmiting || isOrderDataFetch}
                        rules={{
                          required: 'Field is required',
                        }}
                      />
                    </div>
                    <div className="col-2">
                      <Dropdown
                        handleChange={(data) => {}}
                        options={dictionary.pump || []}
                        control={control}
                        label="Pump"
                        name="pump"
                        id="pump"
                        errors={errors.pump}
                        disabled={isSubmiting || isOrderDataFetch}
                        rules={{
                          required: 'Field is required',
                        }}
                      />
                    </div>
                    <div className="col-2">
                      <Dropdown
                        handleChange={(data) => {}}
                        options={dictionary.surfaceType || []}
                        control={control}
                        label="Yttyp"
                        name="surfaceType"
                        id="surfaceType"
                        errors={errors.surfaceType}
                        disabled={isSubmiting || isOrderDataFetch}
                        rules={{
                          required: 'Field is required',
                        }}
                      />
                    </div>
                    <div className="col-1">
                      <FormInput
                        control={control}
                        label="Area, m²"
                        name="area"
                        id="area"
                        type="number"
                        errors={errors.area}
                        placeholder="0"
                        disabled={isSubmiting || isOrderDataFetch}
                        rules={{
                          required: 'Field is required',
                        }}
                      />
                    </div>
                    <div className="col-1">
                      <FormInput
                        control={control}
                        label={intl.formatMessage({
                          id: 'input.label.volume',
                          defaultMessage: 'Volym, m³',
                        })}
                        name="volume"
                        id="volume"
                        type="number"
                        errors={errors.volume}
                        placeholder="0"
                        disabled={isSubmiting || isOrderDataFetch}
                        rules={{
                          required: 'Field is required',
                        }}
                      />
                    </div>
                    <div className="col-4">
                      <Dropdown
                        handleChange={(data) => {}}
                        options={dictionary.slopeOrFall || []}
                        control={control}
                        label=" Lutning/fall"
                        name="slopeOrFall"
                        id="slopeOrFall"
                        errors={errors.slopeOrFall}
                        disabled={isSubmiting || isOrderDataFetch}
                        rules={{
                          required: 'Field is required',
                        }}
                      />
                    </div>
                    <div className="col-4">
                      <FormTextArea
                        control={control}
                        label={intl.formatMessage({
                          id: 'input.label.comment',
                          defaultMessage: 'Comment',
                        })}
                        name="comments.orderDetailFirstComment"
                        id="comments.orderDetailFirstComment"
                        type="text"
                        placeholder={intl.formatMessage({
                          id: 'input.placeholder.comment',
                          defaultMessage: 'Leave a comment',
                        })}
                        disabled={isSubmiting || isOrderDataFetch}
                      />
                    </div>

                    <NextStepButton>
                      <Button
                        disabled={isSubmiting || isOrderDataFetch}
                        text={intl.formatMessage({
                          id: 'steps.btn.cancel',
                          defaultMessage: 'Cancel',
                        })}
                        type={BUTTON_TYPE.OUTLINE}
                        size={BUTTON_SIZE.MEDIUM}
                        onClick={(e) => {
                          e.preventDefault();
                          e.target.blur();
                          setCancelPopupOpen(true);
                        }}
                      />
                      <Button
                        size={BUTTON_SIZE.MEDIUM}
                        text={intl.formatMessage({
                          id: 'steps.btn.nextStep',
                          defaultMessage: 'Next Step',
                        })}
                        onClick={() => {
                          toStepThree();
                        }}
                      />
                    </NextStepButton>
                  </StepPage>

                  <StepPage>
                    <div className="col-2">
                      <Dropdown
                        handleChange={(data) => {}}
                        options={dictionary.variety || []}
                        control={control}
                        label="Sort"
                        name="variety"
                        id="variety"
                        errors={errors.variety}
                        disabled={isSubmiting || isOrderDataFetch}
                        rules={{
                          required: 'Field is required',
                        }}
                      />
                    </div>
                    <div className="col-2">
                      <FormInput
                        control={control}
                        label={intl.formatMessage({
                          id: 'input.label.exposureClass',
                          defaultMessage: 'Exposure class',
                        })}
                        name="exposureClass"
                        id="exposureClass"
                        type="text"
                        errors={errors.exposureClass}
                        placeholder={intl.formatMessage({
                          id: 'input.placeholder.exposureClass',
                          defaultMessage: 'Enter exposure class',
                        })}
                        disabled={isSubmiting || isOrderDataFetch}
                        rules={{
                          required: 'Field is required',
                        }}
                      />
                    </div>
                    <div className="col-2">
                      <FormInputMask
                        control={control}
                        label="Vct"
                        name="vct"
                        id="vct"
                        type="text"
                        inputMode="decimal"
                        errors={errors.vct}
                        placeholder="0,1-0,99"
                        disabled={isSubmiting || isOrderDataFetch}
                        rules={{
                          required: 'Field is required',
                        }}
                        maskChar=""
                        mask="0,99"
                      />
                    </div>
                    <div className="col-2">
                      <Dropdown
                        handleChange={(data) => {}}
                        options={dictionary.dMax || []}
                        control={control}
                        label="Dmax"
                        name="dmax"
                        id="dmax"
                        errors={errors.dmax}
                        disabled={isSubmiting || isOrderDataFetch}
                        rules={{
                          required: 'Field is required',
                        }}
                      />
                    </div>
                    <div className="col-2">
                      <Dropdown
                        handleChange={(data) => {}}
                        options={dictionary.seatDimension || []}
                        control={control}
                        label="Sättmått"
                        name="seatDimensions"
                        id="seatDimensions"
                        errors={errors.seatDimensions}
                        disabled={isSubmiting || isOrderDataFetch}
                        rules={{
                          required: 'Field is required',
                        }}
                      />
                    </div>
                    <div className="col-2">
                      <Dropdown
                        handleChange={(data) => {}}
                        options={dictionary.additive || []}
                        control={control}
                        label="Tillsats"
                        name="additive"
                        id="additive"
                        errors={errors.additive}
                        disabled={isSubmiting || isOrderDataFetch}
                        rules={{
                          required: 'Field is required',
                        }}
                      />
                    </div>
                    <div className="col-2">
                      <Dropdown
                        handleChange={(data) => {}}
                        options={dictionary.cementType || []}
                        control={control}
                        label="Cementsort"
                        name="cementVariety"
                        id="cementVariety"
                        errors={errors.cementVariety}
                        disabled={isSubmiting || isOrderDataFetch}
                        rules={{
                          required: 'Field is required',
                        }}
                      />
                    </div>
                    <div className="col-2">
                      <Dropdown
                        handleChange={(data) => {}}
                        options={dictionary.heat || []}
                        control={control}
                        label="Värme"
                        name="heat"
                        id="heat"
                        errors={errors.heat}
                        disabled={isSubmiting || isOrderDataFetch}
                        rules={{
                          required: 'Field is required',
                        }}
                      />
                    </div>
                    <div className="col-4">
                      <FormTextArea
                        control={control}
                        label={intl.formatMessage({
                          id: 'input.label.comment',
                          defaultMessage: 'Comment',
                        })}
                        name="comments.orderDetailSecondComment"
                        id="comments.orderDetailSecondComment"
                        type="text"
                        placeholder={intl.formatMessage({
                          id: 'input.placeholder.comment',
                          defaultMessage: 'Leave a comment',
                        })}
                        disabled={isSubmiting || isOrderDataFetch}
                      />
                    </div>

                    <NextStepButton>
                      <Button
                        disabled={isSubmiting || isOrderDataFetch}
                        text={intl.formatMessage({
                          id: 'steps.btn.cancel',
                          defaultMessage: 'Cancel',
                        })}
                        type={BUTTON_TYPE.OUTLINE}
                        size={BUTTON_SIZE.MEDIUM}
                        onClick={(e) => {
                          e.preventDefault();
                          e.target.blur();
                          setCancelPopupOpen(true);
                        }}
                      />
                      <Button
                        size={BUTTON_SIZE.MEDIUM}
                        text={intl.formatMessage({
                          id: 'steps.btn.nextStep',
                          defaultMessage: 'Next Step',
                        })}
                        onClick={() => {
                          toStepFour();
                        }}
                      />
                    </NextStepButton>
                  </StepPage>

                  <StepPage>
                    <div className="col-2">
                      <TimePicker
                        control={control}
                        label={intl.formatMessage({
                          id: 'input.label.timeOfStart',
                          defaultMessage: 'Start Time',
                        })}
                        name="timeOfStart"
                        id="timeOfStart"
                        type="number"
                        errors={errors.timeOfStart}
                        placeholder="00:00"
                        disabled={isSubmiting || isOrderDataFetch}
                        rules={{
                          required: 'Field is required',
                        }}
                      />
                    </div>
                    <div className="col-2">
                      <FormInput
                        control={control}
                        label={intl.formatMessage({
                          id: 'input.label.interval',
                          defaultMessage: 'Interval, min',
                        })}
                        name="interval"
                        id="interval"
                        type="number"
                        errors={errors.interval}
                        placeholder="0"
                        disabled={isSubmiting || isOrderDataFetch}
                        rules={{
                          required: 'Field is required',
                        }}
                      />
                    </div>

                    <div className="col-4">
                      <FormTextArea
                        control={control}
                        label={intl.formatMessage({
                          id: 'input.label.comment',
                          defaultMessage: 'Comment',
                        })}
                        name="comments.orderDetailThreeComment"
                        id="comments.orderDetailThreeComment"
                        type="text"
                        placeholder={intl.formatMessage({
                          id: 'input.placeholder.comment',
                          defaultMessage: 'Leave a comment',
                        })}
                        disabled={isSubmiting || isOrderDataFetch}
                      />
                    </div>

                    <NextStepButton>
                      <Button
                        disabled={isSubmiting || isOrderDataFetch}
                        text={intl.formatMessage({
                          id: 'steps.btn.cancel',
                          defaultMessage: 'Cancel',
                        })}
                        type={BUTTON_TYPE.OUTLINE}
                        size={BUTTON_SIZE.MEDIUM}
                        onClick={(e) => {
                          e.preventDefault();
                          e.target.blur();
                          setCancelPopupOpen(true);
                        }}
                      />
                      <Button
                        size={BUTTON_SIZE.MEDIUM}
                        text={intl.formatMessage({
                          id: 'steps.btn.nextStep',
                          defaultMessage: 'Next Step',
                        })}
                        onClick={() => {
                          toStepFive();
                        }}
                      />
                    </NextStepButton>
                  </StepPage>

                  <StepPage>
                    <div className="col-2">
                      <Dropdown
                        handleChange={(data) => {}}
                        options={dictionary.orderClass || []}
                        control={control}
                        label={intl.formatMessage({
                          id: 'input.label.orderClass',
                          defaultMessage: 'Class',
                        })}
                        name="orderClass"
                        id="orderClass"
                        errors={errors.orderClass}
                        disabled={isSubmiting || isOrderDataFetch}
                        rules={{
                          required: 'Field is required',
                        }}
                      />
                    </div>
                    <div className="col-2">
                      <Dropdown
                        handleChange={(data) => {}}
                        options={dictionary.coverage || []}
                        control={control}
                        label={intl.formatMessage({
                          id: 'input.label.coverage',
                          defaultMessage: 'Coverage',
                        })}
                        name="coverage"
                        id="coverage"
                        errors={errors.coverage}
                        disabled={isSubmiting || isOrderDataFetch}
                        rules={{
                          required: 'Field is required',
                        }}
                      />
                    </div>

                    <div className="col-4">
                      <FormTextArea
                        control={control}
                        label={intl.formatMessage({
                          id: 'input.label.comment',
                          defaultMessage: 'Comment',
                        })}
                        name="comments.orderDetailFourComment"
                        id="comments.orderDetailFourComment"
                        type="text"
                        placeholder={intl.formatMessage({
                          id: 'input.placeholder.comment',
                          defaultMessage: 'Leave a comment',
                        })}
                        disabled={isSubmiting || isOrderDataFetch}
                      />
                    </div>

                    <NextStepButton>
                      <Button
                        disabled={isSubmiting || isOrderDataFetch}
                        text={intl.formatMessage({
                          id: 'steps.btn.cancel',
                          defaultMessage: 'Cancel',
                        })}
                        type={BUTTON_TYPE.OUTLINE}
                        size={BUTTON_SIZE.MEDIUM}
                        onClick={(e) => {
                          e.preventDefault();
                          e.target.blur();
                          setCancelPopupOpen(true);
                        }}
                      />
                      <Button
                        size={BUTTON_SIZE.MEDIUM}
                        text={intl.formatMessage({
                          id: 'btn.save',
                          defaultMessage: 'Save',
                        })}
                        onClick={handleSubmit(onSubmit)}
                      />
                    </NextStepButton>
                  </StepPage>
                </StepsComponent>
              </form>
            </div>
          </div>
        </div>
      )}
      <ConfirmPopup
        open={open}
        text={intl.formatMessage({
          id: 'popup.order.update',
          defaultMessage: `Are you sure you want to update the order?`,
        })}
        confirmButtonText={intl.formatMessage({
          id: 'popup.btn.update',
          defaultMessage: 'Yes, accept',
        })}
        onSubmit={sendData}
        onCloseModal={() => setOpen(false)}
      />
      <ConfirmPopup
        open={cancelOpen}
        text={intl.formatMessage({
          id: 'popup.order.cancelUpdate',
          defaultMessage: `Are you sure that you want to cancel editing the order?`,
        })}
        confirmButtonText={intl.formatMessage({
          id: 'popup.cancel.ok',
          defaultMessage: 'Yes, cancel',
        })}
        cancelButtonText={intl.formatMessage({
          id: 'popup.cancel.no',
          defaultMessage: 'No, continue',
        })}
        onSubmit={() => navigate(`/customer/my-orders?status=new&page=1`)}
        onCloseModal={() => setCancelPopupOpen(false)}
      />
      {successMsg && (
        <div className="add-order__success">
          <Icon icon="check-big" size="48px" />
          <FormattedMessage
            id="page.orders.publishedMsg"
            defaultMessage="Your order has been published!"
          />
          <div>
            <Button
              size={BUTTON_SIZE.MEDIUM}
              text="My Orders"
              onClick={() => {
                navigate(`/customer/my-orders?status=new&page=1`);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

EditOrderPage.propTypes = {
  props: PropTypes.object,
};

export default EditOrderPage;
