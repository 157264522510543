import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Icon from '../Icon';
import useIsMobile from '../../../utils/useMobile';

const Title = ({
  children,
  subTitle,
  infoContent,
  actions,
  classNameContainer = '',
  h2,
  navBackLink,
  navBackHandler,
}) => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  return (
    <div className={h2 ? '' : 'container_title' + classNameContainer}>
      {!isMobile && subTitle ? (
        <div
          className="back-icon"
          onClick={() =>
            navBackHandler ? navBackHandler() : navigate(navBackLink || -1)
          }
        >
          <Icon icon="arrow-left" size="20px" />
        </div>
      ) : null}
      <div className={!isMobile && subTitle ? 'container_title-text' : null}>
        {subTitle ? (
          <p className="title">
            {subTitle}
            {actions && <div className="actions">{actions}</div>}
          </p>
        ) : null}
        {h2 ? (
          <h2 className="title">
            {subTitle && isMobile ? (
              <div
                onClick={() =>
                  navBackHandler
                    ? navBackHandler()
                    : navigate(navBackLink || -1)
                }
              >
                <Icon icon="arrow-left" size="20px" />
              </div>
            ) : null}
            {children}
          </h2>
        ) : (
          <h1 className="title">
            {subTitle && isMobile ? (
              <div
                onClick={() =>
                  navBackHandler
                    ? navBackHandler()
                    : navigate(navBackLink || -1)
                }
              >
                <Icon icon="arrow-left" size="20px" />
              </div>
            ) : null}
            {children}
          </h1>
        )}
        <div>{infoContent}</div>
      </div>
    </div>
  );
};

Title.defaultProps = {
  navBackLink: null,
};

Title.propTypes = {
  children: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  infoContent: PropTypes.element,
  classNameContainer: PropTypes.string,
  actions: PropTypes.node,
  h2: PropTypes.bool,
  navBackLink: PropTypes.string,
  navBackHandler: PropTypes.func,
};

export default Title;
