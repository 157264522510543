import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';

export const CONTENT_SIZE_TYPE = {
  MOBILE: 'mobile',
  DESCTOP: 'desctop',
};

const AdaptiveContent = ({ children, type }) => {
  const isMobileOrTablet = useMediaQuery({
    query: '(max-width: 900px) ',
  });

  if (isMobileOrTablet && type === CONTENT_SIZE_TYPE.MOBILE) {
    return children;
  }

  if (!isMobileOrTablet && type === CONTENT_SIZE_TYPE.DESCTOP) {
    return children;
  }

  return <></>;
};

AdaptiveContent.defaultProps = {
  type: CONTENT_SIZE_TYPE.DESCTOP,
};

AdaptiveContent.propTypes = {
  children: PropTypes.node,
  type: PropTypes.string,
};

export default AdaptiveContent;
