import moment from 'moment';

export const getMinDayForCreateOrder = () => {
  const now = new Date().toLocaleTimeString();
  const hh = now.split(':')[0];

  if (hh < 12) {
    return moment().add(1, 'days').toDate();
  } else {
    return moment().add(2, 'days').toDate();
  }
};
