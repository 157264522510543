import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';

import swe from '../languages/swe.json';
import en from '../languages/en.json';

export const LangContext = createContext({});

const lang = localStorage.getItem('lang') || 'swe';
const dictionaries = { swe: swe, en: en };

export const LangContextProvider = ({ children }) => {
  const [locale, setLocale] = useState(lang);
  const [messages, setMessages] = useState(dictionaries[lang]);

  const selectLang = ({ value }) => {
    setLocale(value);
    setMessages(dictionaries[value]);
    localStorage.setItem('lang', value);
  };

  return (
    <LangContext.Provider value={{ locale, selectLang }}>
      <IntlProvider messages={messages} locale={locale}>
        {children}
      </IntlProvider>
    </LangContext.Provider>
  );
};

LangContextProvider.propTypes = {
  children: PropTypes.object,
};
