import React, { useEffect, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import FormInput from '../../../../components/ui/FormInput/FormInput';
import Button, { BUTTON_SIZE } from '../../../../components/ui/Button/Button';
import LoadedContent from '../../../../components/LoadedContent';
import { AlertMessengerContext } from '../../../../provider/Alert';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Title from '../../../../components/ui/Title';
import useIsMobile from '../../../../utils/useMobile';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { getUserCompany, editUserCompany } from '../../../../api/users';
import FormInputMask from '../../../../components/ui/FormInputMask';
import { FormattedMessage, useIntl } from 'react-intl';

const phoneRegExp = /(^[+]46\d{6,11}$)|(^[+]46\s\d{6,11}$)/;

const User = () => {
  const queryParams = useParams();
  const intl = useIntl();

  const { addErrorMessage, addSuccessMessage } = useContext(
    AlertMessengerContext
  );

  const schema = yup.object().shape({
    phone: yup
      .string()
      .required(
        intl.formatMessage({
          id: 'input.error.requiredMsg',
          defaultMessage: 'Field is required',
        })
      )
      .matches(
        phoneRegExp,
        intl.formatMessage({
          id: 'input.error.incorrectPhone',
          defaultMessage: 'Incorrect phone',
        })
      ),
    email: yup
      .string()
      .email()
      .required(
        intl.formatMessage({
          id: 'input.error.requiredMsg',
          defaultMessage: 'Field is required',
        })
      ),
    firstname: yup
      .string()
      .required(
        intl.formatMessage({
          id: 'input.error.requiredMsg',
          defaultMessage: 'Field is required',
        })
      )
      .min(
        3,
        intl.formatMessage({
          id: 'input.error.incorrectFName',
          defaultMessage: 'First Name must be at least 3 characters',
        })
      ),
    lastname: yup
      .string()
      .required(
        intl.formatMessage({
          id: 'input.error.requiredMsg',
          defaultMessage: 'Field is required',
        })
      )
      .min(
        3,
        intl.formatMessage({
          id: 'input.error.incorrectLName',
          defaultMessage: 'Last Name must be at least 3 characters',
        })
      ),
  });

  const { control, handleSubmit, formState, ...formControl } = useForm({
    reValidateMode: 'onChange',
    defaultValues: {
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
    },
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();

  const { errors } = formState;
  const isMobile = useIsMobile();
  const [isLoading, setLoading] = useState(false);
  const [isDisabled, setDisabled] = useState(false);

  const onSubmit = (data) => {
    setLoading(true);
    editUserCompany(queryParams.id_company, queryParams.id_user, {
      ...data,
      phone: data.phone.replace(/[()_\s-]/g, ''),
    })
      .then(() => {
        formControl.setValue('firstname', '');
        formControl.setValue('lastname', '');
        formControl.setValue('phone', '');
        formControl.setValue('email', '');
        addSuccessMessage({
          message: intl.formatMessage({
            id: 'page.sa.editUser.successMsg',
            defaultMessage: 'Success edit user!',
          }),
        });
        navigate(-1);
      })
      .catch((error) => {
        if (error.response.data?.errors) {
          Object.keys(error.response.data?.errors).forEach((item) => {
            formControl.setError(item, {
              type: 'custom',
              message: error.response.data?.errors[item][0],
            });
          });
        } else if (error.response.data?.message) {
          addErrorMessage({
            message: error.response.data?.message,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const [searchParams] = useSearchParams();

  useEffect(() => {
    document.title = intl.formatMessage({
      id: 'page.sa.editUser.title',
      defaultMessage: 'Edit user',
    });
    setDisabled(true);
    if (queryParams.id_user && queryParams.id_company !== undefined) {
      setLoading(true);
      getUserCompany(queryParams.id_company, queryParams.id_user)
        .then((res) => {
          formControl.setValue('firstname', res.data.data.firstname);
          formControl.setValue('lastname', res.data.data.lastname);
          formControl.setValue('phone', res.data.data.phone);
          formControl.setValue('email', res.data.data.email);
          setLoading(false);
        })
        .finally(() => {
          setDisabled(false);
        });
    } else {
      if (searchParams.get('fn')) {
        formControl.setValue('firstname', searchParams.get('fn'));
        formControl.setValue('lastname', searchParams.get('ln'));
        formControl.setValue('phone', searchParams.get('ph'));
        formControl.setValue('email', window.location.search.split('em=')[1]);
      }
    }
  }, []);

  return (
    <>
      <div className="account-user__content-header">
        <Title
          subTitle={
            isMobile
              ? queryParams.id_user
                ? intl.formatMessage({
                    id: 'page.sa.editUser.title',
                    defaultMessage: 'Users',
                  })
                : searchParams.get('fn')
                ? intl.formatMessage({
                    id: 'page.sa.editUser.showTitle',
                    defaultMessage: 'Show user',
                  })
                : intl.formatMessage({
                    id: 'page.sa.editUser.subTitle',
                    defaultMessage: 'Edit user',
                  })
              : intl.formatMessage({
                  id: 'page.sa.editUser.title',
                  defaultMessage: 'Users',
                })
          }
        >
          {isMobile
            ? intl.formatMessage({
                id: 'page.sa.editUser.subTitle',
                defaultMessage: 'Edit user',
              })
            : queryParams.id_user
            ? searchParams.get('fn')
              ? intl.formatMessage({
                  id: 'page.sa.editUser.showTitle',
                  defaultMessage: 'Show user',
                })
              : intl.formatMessage({
                  id: 'page.sa.editUser.subTitle',
                  defaultMessage: 'Edit user',
                })
            : intl.formatMessage({
                id: 'page.sa.addUserCard.title_sa',
                defaultMessage: 'Add New Super Admin',
              })}
        </Title>
      </div>

      <div className="account-user">
        <LoadedContent loader={isLoading} />
        <form
          className="account-user__form-inner"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="account-user__form">
            <FormInput
              control={control}
              label={intl.formatMessage({
                id: 'input.label.fname*',
                defaultMessage: 'First Name*',
              })}
              name="firstname"
              id="firstname"
              type="firstname"
              errors={errors.firstname}
              disabled={isLoading || isDisabled}
              placeholder={intl.formatMessage({
                id: 'input.placeholder.fname',
                defaultMessage: 'Enter your First Name',
              })}
            />
            <FormInput
              control={control}
              label={intl.formatMessage({
                id: 'input.label.lname*',
                defaultMessage: 'Last Name*',
              })}
              name="lastname"
              id="lastname"
              type="lastname"
              errors={errors.lastname}
              disabled={isLoading || isDisabled}
              placeholder={intl.formatMessage({
                id: 'input.placeholder.lname',
                defaultMessage: 'Enter your Last Name',
              })}
            />
            <FormInputMask
              control={control}
              label={intl.formatMessage({
                id: 'input.label.phone*',
                defaultMessage: 'Phone*',
              })}
              name="phone"
              disabled={isLoading || isDisabled}
              id="phone"
              type="phone"
              errors={errors.phone}
              placeholder="+46"
              maskChar=""
              mask="+46 99999999999"
            />

            <FormInput
              control={control}
              label={intl.formatMessage({
                id: 'input.label.mail*',
                defaultMessage: 'E-mail*',
              })}
              name="email"
              id="email"
              type="email"
              errors={errors.email}
              disabled={isLoading || isDisabled}
              placeholder={intl.formatMessage({
                id: 'input.placeholder.mail',
                defaultMessage: 'Enter your email',
              })}
            />
          </div>
          {!queryParams.id_user && (
            <div className="filed-add-user">
              <FormattedMessage
                id="page.sa.editUser.tip"
                defaultMessage="We will send an email to this address so that the user can create
              a password"
              />
            </div>
          )}
          {queryParams.id_company !== undefined && queryParams.id_user ? (
            <Button
              disabled={isLoading}
              text={
                !queryParams.id_user
                  ? intl.formatMessage({
                      id: 'btn.create',
                      defaultMessage: 'Create',
                    })
                  : intl.formatMessage({
                      id: 'btn.save',
                      defaultMessage: 'Save',
                    })
              }
              size={BUTTON_SIZE.LARGE}
            />
          ) : null}
          <div className="account-user__form-btn"></div>
        </form>
      </div>
    </>
  );
};

User.defaultProps = {};

User.propTypes = {};

export default User;
