import Button from '../../../../components/ui/Button';
import {
  BUTTON_ROLE,
  BUTTON_SIZE,
} from '../../../../components/ui/Button/Button';
import { useNavigate, useSearchParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getMyProject } from '../../../../store/slice/projectSlice';
import useIsMobile from '../../../../utils/useMobile';
import ProjectList from '../ProjectList/ProjectList';
import { FormattedMessage, useIntl } from 'react-intl';

const HomeProjects = () => {
  const dispatch = useDispatch();
  const isListLoading = useSelector((state) => state.project.isListLoading);
  const projectList = useSelector((state) => state.project.data);
  const projectMeta = useSelector((state) => state.project.meta);
  const intl = useIntl();

  const [searchParams] = useSearchParams();
  const pageQuery = searchParams.get('page') || 1;

  const fetchList = async (page) => {
    dispatch(
      getMyProject({
        query: { page: page, per_page: 10 },
      })
    );
  };

  useEffect(() => {
    fetchList(pageQuery);
    document.title = intl.formatMessage({
      id: 'page.projects.title',
      defaultMessage: 'My Project',
    });
  }, [pageQuery]);

  const isMobile = useIsMobile();
  const navigate = useNavigate();

  return (
    <div className="project-page customer-page">
      <div className="project-page__inner">
        <div className="project-page__header">
          <span className="project-page__header-title">
            <FormattedMessage
              id="page.projects.title"
              defaultMessage="Projects"
            />
          </span>
          <Button
            text={intl.formatMessage({
              id: 'page.projects.addProject',
              defaultMessage: 'Add New Project',
            })}
            size={isMobile ? BUTTON_SIZE.ADD_SMALL : BUTTON_SIZE.MEDIUM}
            role={BUTTON_ROLE.ADD_BUTTON}
            hideText={true}
            onClick={() => navigate('/my-projects/add-project')}
          />
        </div>

        <ProjectList
          currentPage={projectMeta.current_page}
          totalCount={projectMeta.last_page}
          isListLoading={isListLoading}
          projectList={projectList}
        />
      </div>
    </div>
  );
};

HomeProjects.defaultProps = {};

HomeProjects.propTypes = {};

export default HomeProjects;
