/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
// src/components/Alert/AlertContextProvider.tsx

import { createContext, useCallback, useReducer, useEffect } from 'react';
import NotificationAlert from '../components/NotificationAlert';
import { NOTIFICATION_ALERT_TYPE } from '../components/NotificationAlert/NotificationAlert';
import { useLocation } from 'react-router-dom';

export const AlertMessengerContext = createContext({});

const REMOVE_MESSAGE = 'REMOVE_MESSAGE';

const AlertReducer = (state, action) => {
  switch (action.type) {
    case NOTIFICATION_ALERT_TYPE.SUCCES:
      return {
        ...state,
        messages: [
          { type: 'success', ...action.payload },
          ...state.messages,
        ].slice(0, state.noOfMessages),
      };
    case NOTIFICATION_ALERT_TYPE.ERROR:
      return {
        ...state,
        messages: [
          { type: 'error', ...action.payload },
          ...state.messages,
        ].slice(0, state.noOfMessages),
      };
    case REMOVE_MESSAGE:
      return {
        ...state,
        messages: state.messages.filter(
          (message) => message.id !== action.payload.id
        ),
      };
  }
};

export const AlertContextProvider = ({
  children,
  noOfMessages = 5,
  autoHideError = true,
}) => {
  const autoHideTimeout = 9000;
  const location = useLocation();

  const [state, dispatch] = useReducer(AlertReducer, {
    messages: [],
    noOfMessages,
  });

  useEffect(() => {
    // state.messages.forEach((item) => {
    //   const id = item.id;
    //   dispatch({ type: REMOVE_MESSAGE, payload: { id } });
    // });
  }, [location]);

  const addSuccessMessage = useCallback(
    (data) => {
      const id = Date.now();
      window.scrollTo(0, 0);
      if (document.querySelector('.page-layout__content')) {
        document.querySelector('.page-layout__content').scrollTop = 0;
      }
      dispatch({
        type: NOTIFICATION_ALERT_TYPE.SUCCES,
        payload: { id, ...data },
      });
      setTimeout(() => {
        dispatch({ type: REMOVE_MESSAGE, payload: { id } });
      }, autoHideTimeout);
    },
    [dispatch, autoHideTimeout]
  );

  const addErrorMessage = useCallback(
    (data) => {
      const id = Date.now();
      window.scrollTo(0, 0);
      if (document.querySelector('.page-layout__content')) {
        document.querySelector('.page-layout__content').scrollTop = 0;
      }
      dispatch({
        type: NOTIFICATION_ALERT_TYPE.ERROR,
        payload: { id, ...data },
      });
      if (autoHideError) {
        setTimeout(() => {
          dispatch({ type: REMOVE_MESSAGE, payload: { id } });
        }, autoHideTimeout);
      }
    },
    [dispatch, autoHideError, autoHideTimeout]
  );

  const removeMessage = useCallback(
    (id) => {
      dispatch({ type: REMOVE_MESSAGE, payload: { id } });
    },
    [dispatch]
  );

  return (
    <AlertMessengerContext.Provider
      value={{ state, addSuccessMessage, addErrorMessage, removeMessage }}
    >
      <div className={state.messages.length ? 'alert_container' : ''}>
        {state.messages.map(({ type, message, id }) => (
          <NotificationAlert key={id} type={type} message={message} />
        ))}
      </div>
      {children}
    </AlertMessengerContext.Provider>
  );
};
