import API from '../api';

// SUPER-ADMIN
export const orderGetInfo = (id, data) =>
  API.get(`/api/api/v1/company/companies/customer/orders/${id}/offers`, {
    params: data,
  });

export const orderCustomer = (id) =>
  API.get(`/api/api/v1/company/companies/customer/orders/${id}`);

export const orderCustomerSA = (id, companyId) =>
  API.get(
    `/api/api/v1/company/companies/${companyId}/customer/orders/${id}/show`
  );

export const orderCustomerGetInfoSa = (orderId, companyId, data) =>
  API.get(
    `/api/api/v1/company/companies/${companyId}/customer/orders/${orderId}/offers`,
    {
      params: data,
    }
  );

export const customerOrders = (companyId, data) =>
  API.get(`/api/api/v1/company/companies/${companyId}/orders`, {
    params: data,
  });

export const customerOrdersSupplierArchive = (data) =>
  API.get(`/api/api/v1/company/companies/supplier/orders/archive`, {
    params: data,
  });

export const customerOrdersSupplierNew = (data) =>
  API.get(`/api/api/v1/company/companies/supplier/orders/new`, {
    params: data,
  });

export const customerOrdersSupplierWait = (data) =>
  API.get(`/api/api/v1/company/companies/supplier/orders/waiting-for-answer`, {
    params: data,
  });

export const customerOrdersSupplierCurrent = (data) =>
  API.get(`/api/api/v1/company/companies/supplier/orders/current`, {
    params: data,
  });

export const customerOrdersSupplierDecline = (data) =>
  API.get(`/api/api/v1/company/companies/supplier/orders/decline`, {
    params: data,
  });
export const customerAddOrder = (data) =>
  API.post(`/api/api/v1/company/companies/customer/orders`, data);

export const customerEditOrder = (orderId, data) =>
  API.put(`/api/api/v1/company/companies/customer/orders/${orderId}`, data);

export const startEditOrder = (id) =>
  API.patch(`/api/api/v1/company/companies/customer/orders/start-edit/${id}`);

export const endEditOrder = (id) =>
  API.patch(`/api/api/v1/company/companies/customer/orders/cancel-edit/${id}`);

export const setOrderInProgress = (orderId) =>
  API.patch(
    `/api/api/v1/company/companies/customer/orders/${orderId}/in-progress`
  );

export const cancelOrder = (orderId) =>
  API.patch(`/api/api/v1/company/companies/customer/orders/${orderId}/cancel`);

// CUSTOMER ADMIN
export const customerMyOrders = (data) =>
  API.get(`/api/api/v1/company/companies/customer/orders/my-orders`, {
    params: data,
  });

export const orderIdDecline = (order) =>
  API.post(`/api/api/v1/company/companies/supplier/orders/decline/${order}`);

export const orderIdUnDecline = (order) =>
  API.delete(`/api/api/v1/company/companies/supplier/orders/decline/${order}`);

export const orderOfferSupplier = (order) =>
  API.get(`/api/api/v1/company/companies/supplier/orders/${order}/offers`);

export const orderSupplier = (order) =>
  API.get(`/api/api/v1/company/companies/supplier/orders/${order}/show`);

// http://api.localhost/api/v1/company/companies/supplier/orders/{orderId}/offers

export const orderCreateOffer = (order, data) => {
  console.log(data);
  return API.post(
    `/api/api/v1/company/companies/supplier/orders/${order}/offers`,
    data,
    {
      params: data,
    }
  );
};

// orders/cancel

export const orderIdCancel = (order) =>
  API.patch(`/api/api/v1/company/companies/customer/orders/${order}/cancel`);

// SUPER ADMIN SUPPLIER COMPANY ORDERS LISTS
export const supplierOrdersSAWait = (companyId, data) =>
  API.get(
    `/api/api/v1/company/companies/${companyId}/supplier/orders/waiting-for-answer`,
    {
      params: data,
    }
  );

export const supplierOrdersSaNew = (companyId, data) =>
  API.get(`/api/api/v1/company/companies/${companyId}/supplier/orders/new`, {
    params: data,
  });

export const supplierOrdersSaDecline = (companyId, data) =>
  API.get(
    `/api/api/v1/company/companies/${companyId}/supplier/orders/decline`,
    {
      params: data,
    }
  );

export const supplierOrdersSaCurrent = (companyId, data) =>
  API.get(
    `/api/api/v1/company/companies/${companyId}/supplier/orders/current`,
    {
      params: data,
    }
  );

export const supplierOrdersSaArchive = (companyId, data) =>
  API.get(
    `/api/api/v1/company/companies/${companyId}/supplier/orders/archive`,
    {
      params: data,
    }
  );

// SUPER ADMIN SUPPLIER COMPANY ORDERS DETAIL VIEW
export const supplierOrderSa = (companyId, orderId) =>
  API.get(
    `/api/api/v1/company/companies/${companyId}/supplier/orders/${orderId}/show`
  );

export const supplierOrderOfferSa = (orderId, companyId) =>
  API.get(
    `/api/api/v1/company/companies/${companyId}/supplier/orders/${orderId}/offers`
  );
