import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { orderCustomer, orderGetInfo } from '../../api/order';
import { orderObjectMapperCustomer } from '../../utils/order-mapper';

export const getMyOrder = createAsyncThunk(
  'orderCustomer/getOrder',
  async (creds, { rejectWithValue }) => {
    try {
      const response = await orderCustomer(creds.id);
      if (response.status === 200) {
        return orderObjectMapperCustomer(response.data.data);
      } else {
        return rejectWithValue(response.data);
      }
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getMyOrderUpdate = createAsyncThunk(
  'orderCustomer/getMyOrderUpdate',
  async (creds, { rejectWithValue }) => {
    try {
      const response = await orderCustomer(creds.id);
      if (response.status === 200) {
        return orderObjectMapperCustomer(response.data.data);
      } else {
        return rejectWithValue(response.data);
      }
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getMyOrderOffer = createAsyncThunk(
  'orderCustomer/getOrderOffer',
  async (creds, { rejectWithValue }) => {
    try {
      const response = await orderGetInfo(creds.id, creds.query);
      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getMyOrderOfferUpdate = createAsyncThunk(
  'orderCustomer/getOrderOfferUpdate',
  async (creds, { rejectWithValue }) => {
    try {
      const response = await orderGetInfo(creds.id, creds.query);
      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const changeAcceptedInfo = (category, isAccept) => {
  return {
    type: 'orderCustomer/CHANGE_ACCEPTED_OFFER_INFO',
    category,
    isAccept,
  };
};

export const setChangeSelectedOfferProcessState = (isChangeOfferProcess) => {
  return {
    type: 'orderCustomer/SET_CHANGE_SELECTED_OFFER_PROCCESS',
    isChangeOfferProcess,
  };
};

// TODO: NOT USED;
export const addSelectedOffer = (offer) => {
  return {
    type: 'orderCustomer/ADD_SELECTED_OFFER',
    offer,
  };
};

// TODO: NOT USED;
export const replaceSelectedOffer = (newOffer, oldOfferId) => {
  return {
    type: 'orderCustomer/REPLACE_SELECTED_OFFER',
    newOffer,
    oldOfferId,
  };
};

// TODO: NOT USED;
export const clearSelectedOffers = () => {
  return {
    type: 'orderCustomer/CLEAR_SELECTED_OFFER',
  };
};

const initialState = {
  isListLoading: false,
  isProjectLoading: false,
  isOrderInfoUpdating: false,
  isLoadingEdit: false,
  isErrorProject: false,
  dataProject: {
    project: {},
  },
  data: [],
  meta: {},

  changeSelectedOfferProcess: false,
  acceptedOffers: [],
};

const orderCustomerSlice = createSlice({
  name: 'orderCustomer',
  initialState,
  reducers: {
    setClearOrder(state) {
      state = {
        isListLoading: false,
        isProjectLoading: false,
        isLoadingEdit: false,
        isErrorProject: false,
        dataProject: {
          project: {},
        },
        data: [],
        meta: {},
        changeSelectedOfferProcess: false,
        acceptedOffers: [],
      };
    },
    setOffersLoading(state) {
      state.isListLoading = true;
      state.data = [];
      state.meta = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMyOrder.pending, (state) => {
      state.isProjectLoading = true;
      state.isErrorProject = false;
      state.dataProject = {};
      state.data = [];
    });
    builder.addCase(getMyOrder.rejected, (state) => {
      state.isProjectLoading = false;
      state.isErrorProject = true;
      state.dataProject = {};
    });
    builder.addCase(getMyOrder.fulfilled, (state, payload) => {
      state.isProjectLoading = false;
      state.isErrorProject = false;
      state.dataProject = payload.payload;
    });

    builder.addCase(getMyOrderUpdate.pending, (state, payload) => {
      state.isOrderInfoUpdating = true;
    });
    builder.addCase(getMyOrderUpdate.rejected, (state, payload) => {
      state.isOrderInfoUpdating = false;
    });
    builder.addCase(getMyOrderUpdate.fulfilled, (state, payload) => {
      state.dataProject = payload.payload;
      state.isOrderInfoUpdating = false;
    });

    builder.addCase(getMyOrderOffer.pending, (state) => {
      state.isListLoading = true;
      state.isError = false;
      state.data = [];
    });
    builder.addCase(getMyOrderOffer.rejected, (state) => {
      state.isListLoading = false;
      state.isError = true;
      state.data = [];
    });
    builder.addCase(getMyOrderOffer.fulfilled, (state, payload) => {
      state.isListLoading = false;
      state.isError = false;
      state.data = payload.payload.data;
      state.meta = payload.payload.meta;
    });

    builder.addCase(getMyOrderOfferUpdate.fulfilled, (state, payload) => {
      state.data = payload.payload.data;
      state.meta = payload.payload.meta;
    });

    builder.addCase(
      'orderCustomer/CHANGE_ACCEPTED_OFFER_INFO',
      (state, action) => {
        state.dataProject.info[action.category].hasAccept = action.isAccept;
      }
    );

    builder.addCase(
      'orderCustomer/SET_CHANGE_SELECTED_OFFER_PROCCESS',
      (state, action) => {
        state.changeSelectedOfferProcess = action.isChangeOfferProcess;
      }
    );

    // TODO: NOT USED;
    builder.addCase('orderCustomer/ADD_SELECTED_OFFER', (state, action) => {
      state.acceptedOffers = [...state.acceptedOffers, action.offer];
    });
    // TODO: NOT USED;
    builder.addCase('orderCustomer/CLEAR_SELECTED_OFFER', (state, action) => {
      state.acceptedOffers = [];
    });
    // TODO: NOT USED;
    builder.addCase('orderCustomer/REPLACE_SELECTED_OFFER', (state, action) => {
      state.acceptedOffers = state.acceptedOffers.map((item) => {
        if (item.id === action.oldOfferId) {
          return action.newOffer;
        } else {
          return item;
        }
      });
    });
  },
});

const { reducer } = orderCustomerSlice;

export const selectProject = (state) => state.order;
export const { setClearOrder } = orderCustomerSlice.actions;
export const { setOffersLoading } = orderCustomerSlice.actions;

export default reducer;
