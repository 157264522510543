/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react';
import Button from '../../../components/ui/Button';
import Icon from '../../../components/ui/Icon';
import Title from '../../../components/ui/Title';
import { useMediaQuery } from 'react-responsive';
import _, { debounce } from 'lodash';
import { BUTTON_ROLE, BUTTON_SIZE } from '../../../components/ui/Button/Button';
import { useNavigate } from 'react-router-dom';
import { getAnalytics } from '../../../api/analytics';
import {
  LineChart,
  ResponsiveContainer,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Rectangle,
} from 'recharts';
import Dropdown from '../../../components/ui/dropdown/Dropdown';
import LoadedContent from '../../../components/LoadedContent';
import moment from 'moment';
import { FormattedMessage, useIntl } from 'react-intl';
import { LangContext } from '../../../provider/Lang';
import sv from 'moment/locale/sv';
import en from 'moment/locale/en-gb';
import { Scrollbars } from 'react-custom-scrollbars';

const localeLibVal = {
  swe: sv,
  en: en,
};

export default function Analytics() {
  const [isSubmiting, setIsSubmiting] = useState(false);

  const [posDataX, setposDataX] = useState(0);
  const [posDataY1, setposDataY0] = useState({});
  const [posDataY2, setposDataY1] = useState({});
  const [posDataY3, setposDataY2] = useState({});
  const [posDataY4, setposDataY3] = useState({});
  const [minWidth, setMinWidth] = useState('0px');
  const [analytics, setAnalytics] = useState({});
  const [dataAnalytics, setDataAnalytics] = useState({});
  const [maxCountNumber, setMaxCount] = useState(0);
  const [lastMonth, setLastMonth] = useState(1);
  const intl = useIntl();
  const context = useContext(LangContext);
  const chartRef = React.createRef();

  const numberWithSpaces = (x) => {
    const parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    return parts.join('.');
  };

  const getLocalText = (val) => {
    return intl.formatMessage({
      id: `page.sa.analytics.select.${val}`,
      defaultMessage: `Last ${val} ${
        val === 12 ? 'year' : val === 1 ? 'month' : 'months'
      }`,
    });
  };

  const typeOpt = [
    { label: getLocalText(12), value: 12 },
    { label: getLocalText(6), value: 6 },
    { label: getLocalText(3), value: 3 },
    { label: getLocalText(1), value: 1 },
  ];

  useEffect(() => {
    setIsSubmiting(true);
    setDataAnalytics({});
    setAnalytics({});
    setMaxCount(1);

    getAnalytics({
      lastMonths: lastMonth,
    })
      .then((res) => {
        setAnalytics(res.data);
        let maxNumber = 0;
        const localeMoment = moment();
        const currentLocale = context.locale === 'swe' ? 'sv' : 'en';
        localeMoment.locale(currentLocale, localeLibVal[currentLocale]);

        const data = res.data.clients.map((item) => {
          if (item.count > maxNumber) {
            maxNumber = item.count;
          }
          return {
            name: localeMoment.month(item.month - 1).format('MMM'),
            [item.type]: item.count,
          };
        });
        let b = _.map(
          _.groupBy(data, function (u) {
            return u.name;
          }),
          function (g) {
            return _.merge.apply(this, g);
          }
        );

        b = b.map((item) => {
          if (!item.customer) {
            item.customer = 0;
          }
          if (!item['quality-control']) {
            item['quality-control'] = 0;
          }
          if (!item.service) {
            item.service = 0;
          }
          if (!item.factory) {
            item.factory = 0;
          }
          return item;
        });

        setMaxCount(maxNumber);
        setDataAnalytics([...b]);
      })
      .finally(() => {
        setIsSubmiting(false);
      });
  }, [lastMonth, context.locale]);

  // const maxHeight = posDataY1 < posDataY2 ? posDataY1 : posDataY2;

  useEffect(() => {
    setTimeout(() => {
      document.querySelector('.recharts-surface').setAttribute('height', 320);
    }, 100);
  }, []);

  useEffect(() => {
    document.title = intl.formatMessage({
      id: `page.layout.menu.analytics`,
      defaultMessage: 'Analytics',
    });
  }, []);

  // console.log(posDataYY);

  let result = {};
  if (posDataY1.cy && posDataY2.cy) {
    const posDataYY = {
      0: posDataY1,
      1: posDataY2,
      2: posDataY3,
      3: posDataY4,
    };

    result = Object.keys(posDataYY).reduce(
      (acc, curr) =>
        acc.cy
          ? posDataYY[curr]?.cy < acc?.cy
            ? posDataYY[curr]
            : acc
          : posDataYY[curr],
      {}
    );
  }

  const setposDataY0Debounce = debounce(setposDataY0, 50, { maxWait: 50 });
  const setposDataY1Debounce = debounce(setposDataY1, 50, { maxWait: 50 });
  const setposDataY2Debounce = debounce(setposDataY2, 50, { maxWait: 50 });
  const setposDataY3Debounce = debounce(setposDataY3, 50, { maxWait: 50 });
  const navigate = useNavigate();

  // eslint-disable-next-line no-unused-vars
  const isMobile = useMediaQuery({
    query: '(max-width: 900px) ',
  });

  const CustomScroll = Scrollbars;

  useEffect(() => {
    // .recharts-responsive-container
    if (isMobile) {
      setMinWidth(dataAnalytics.length * 60 + 'px');
    } else {
      setMinWidth('0px');
    }
  }, [isMobile, dataAnalytics]);

  function MyRound10(val) {
    return Math.ceil(val / 6) * 10;
  }

  const getX = (x, itsUnderTooltip = false) => {
    const allWidth = document.querySelector('.recharts-surface')?.clientWidth; // 520
    const elementWidth =
      document.querySelector('.custom-tooltip')?.clientWidth / 2; // 78

    const variableForShow = isMobile ? 15 : 15;

    if (x + elementWidth > allWidth) {
      if (itsUnderTooltip) {
        return {
          cord: allWidth - elementWidth * 2 - x + variableForShow,
          its: 1,
        };
      }
      return allWidth - elementWidth * 2 + variableForShow;
    }
    if (x - elementWidth < 0) {
      if (itsUnderTooltip) {
        return {
          cord: 0 - x,
          its: 2,
        };
      }
      return -variableForShow;
    }
    if (itsUnderTooltip) {
      return {
        its: 3,
      };
    }
    return x - elementWidth;
  };

  const getSumm = () => {
    if (analytics && analytics.offers) {
      const val = analytics.offers.reduce(
        (prev, curr) => prev + Number(curr.sum),
        0
      );
      return val.toFixed(0);
    }
    return 0;
  };

  const [dataScroll, setDataScroll] = useState({});

  if (
    isMobile &&
    +minWidth?.replace('px', '') >
      document?.querySelector('.analytics-chart')?.clientWidth
  ) {
    return (
      <div className="analytics">
        <LoadedContent loader={isSubmiting} />
        <div className="analytics__header">
          <Title>
            <FormattedMessage
              id="page.sa.analytics.title"
              defaultMessage="Analytics"
            />
          </Title>
          <div className="project-page__header">
            <Button
              text={intl.formatMessage({
                id: 'page.sa.analytics.btn',
                defaultMessage: 'Add New Company"',
              })}
              size={isMobile ? BUTTON_SIZE.ADD_SMALL : BUTTON_SIZE.MEDIUM}
              role={BUTTON_ROLE.ADD_BUTTON}
              hideText={true}
              onClick={() => navigate('/all-companies/customers/add-company')}
            />
          </div>
        </div>

        <div className="analytics__content-card">
          <div className="analytics__card">
            <div className="analytics__card-title">
              <div className="analytics__card-icon">
                <Icon icon="orders" size="20px" />
              </div>
              <FormattedMessage
                id="page.sa.analytics.orders"
                defaultMessage="Orders"
              />
            </div>
            <div className="analytics__card-description">
              {analytics.orders || 0}
            </div>
          </div>

          <div className="analytics__card">
            <div className="analytics__card-title">
              <div className="analytics__card-icon">
                <Icon icon="offer" size="20px" />
              </div>
              <FormattedMessage
                id="page.sa.analytics.offers"
                defaultMessage="Offers"
              />
            </div>
            <div className="analytics__card-description">
              {_.sumBy(analytics.offers, 'count') || 0}
            </div>
          </div>

          <div className="analytics__card">
            <div className="analytics__card-title">
              <div className="analytics__card-icon">
                <Icon icon="order-amount" size="20px" />
              </div>
              <FormattedMessage
                id="page.sa.analytics.amount"
                defaultMessage="Average order amount"
              />
            </div>
            <div className="analytics__card-description">
              {numberWithSpaces(getSumm()) || 0} kr
            </div>
          </div>
        </div>

        <div className="analytics-content">
          <div className="analytics-content-title">
            <h2 className="analytics-title">
              <FormattedMessage
                id="page.sa.analytics.chart.title"
                defaultMessage="New Clients"
              />
            </h2>
            <Dropdown
              handleChange={(data) => setLastMonth(data.value)}
              options={typeOpt}
              label=""
              name="type"
              withoutControll={true}
              control={null}
              id="type"
              sear
              defaultValue={typeOpt[3]}
              customPlaceholder="Last year"
              onClick={() => {
                if (isMobile) {
                  chartRef.current.setState({ isTooltipActive: false });
                }
              }}
            />
          </div>
          <div className="analytics-content__container-chart">
            <div className="analytics-chart-legend">
              <div>0</div>
              <div>{MyRound10(maxCountNumber) * 0.2}</div>
              <div>{MyRound10(maxCountNumber) * 0.4}</div>
              <div>{MyRound10(maxCountNumber) * 0.6}</div>
              <div>{MyRound10(maxCountNumber) * 0.8}</div>
              <div>{MyRound10(maxCountNumber) * 1}</div>
            </div>
            {!isSubmiting && (
              <div
                className="analytics-chart"
                style={{
                  minHeight: '360px',
                }}
              >
                <CustomScroll
                  autoHeightMin={360}
                  autoHeightMax={360}
                  thumbSize={116}
                  renderView={(props) => <div {...props} className="view" />}
                  renderTrackHorizontal={(props) => (
                    <div {...props} className="track-horizontal" />
                  )}
                  onUpdate={(e) => {
                    // console.log(e);
                    setDataScroll(e);
                    // setTimeout(() => {
                    //   document.querySelector(
                    //     '.thumb-horizontal'
                    //   ).style.left = `calc( ${dataScroll.left * 100} - 50px )`;
                    // });
                  }}
                  renderThumbHorizontal={(props) => (
                    <div
                      {...props}
                      style={{
                        left: `calc(${
                          dataScroll.left *
                          (100 -
                            (document?.querySelector('.thumb-horizontal')
                              ?.clientWidth /
                              document?.querySelector('.analytics-chart')
                                ?.clientWidth) *
                              100)
                        }%
                      
                    )`,
                        width: `${
                          document.querySelector('.analytics-chart')
                            ?.clientWidth /
                            ((document.querySelector('.analytics-chart')
                              ?.clientWidth /
                              +minWidth?.replace('px', '')) *
                              20) +
                          'px'
                        }`,
                      }}
                      className="thumb-horizontal"
                    ></div>
                  )}
                >
                  <ResponsiveContainer
                    minWidth={minWidth}
                    minHeight={isMobile ? 360 : 340}
                  >
                    <LineChart data={dataAnalytics} ref={chartRef}>
                      <CartesianGrid vertical={false} strokeDasharray="4 4" />
                      <XAxis
                        dataKey="name"
                        tickMargin={isMobile ? 15 : 25}
                        height={45}
                        interval="preserveStartEnd"
                      />
                      <YAxis
                        width={0}
                        type="number"
                        className="y-cord"
                        domain={[0, MyRound10(maxCountNumber)]}
                        tickCount={6}
                        // tickMargin={100}
                        // tick={[0, 1, 2, 3, 4, maxCountNumber]}
                        r={'8'}
                      />
                      <Line
                        type="monotone"
                        dataKey="customer" // customer
                        stroke="#4446B0"
                        activeDot={(line) => {
                          setposDataX(line.cx);
                          setposDataY0Debounce({
                            color: line.fill,
                            cy: line.cy,
                          });
                          return (
                            <circle
                              cx={line.cx}
                              cy={line.cy}
                              r={result.color === line.fill ? '6' : '0'}
                              fill={
                                result.color === line.fill ? line.fill : null
                              }
                              strokeWidth="1"
                              stroke="#fff"
                              className="recharts-dot"
                            ></circle>
                          );
                        }}
                        r={'8'}
                      />
                      <Line
                        activeDot={(line) => {
                          setposDataY1Debounce({
                            color: line.color,
                            cy: line.cy,
                          });
                          return (
                            <circle
                              cx={line.cx}
                              cy={line.cy}
                              r={result.color === line.fill ? '6' : '0'}
                              fill={
                                result.color === line.fill ? line.fill : null
                              }
                              strokeWidth="1"
                              stroke="#fff"
                              className="recharts-dot"
                            ></circle>
                          );
                        }}
                        type="monotone"
                        dataKey="factory" // factory
                        r={'8'}
                        stroke="#7FB39A"
                      />
                      <Line
                        activeDot={(line) => {
                          setposDataY2Debounce({
                            color: line.color,
                            cy: line.cy,
                          });
                          return (
                            <circle
                              cx={line.cx}
                              cy={line.cy}
                              r={result.color === line.fill ? '6' : '0'}
                              fill={
                                result.color === line.fill ? line.fill : null
                              }
                              strokeWidth="1"
                              stroke="#fff"
                              className="recharts-dot"
                            ></circle>
                          );
                        }}
                        type="monotone"
                        dataKey="quality-control" // quality-control
                        r={'8'}
                        stroke="#E66558"
                      />
                      <Line
                        activeDot={(line) => {
                          setposDataY3Debounce({
                            color: line.color,
                            cy: line.cy,
                          });
                          return (
                            <circle
                              cx={line.cx}
                              cy={line.cy}
                              r={result.color === line.fill ? '6' : '0'}
                              fill={
                                result.color === line.fill ? line.fill : null
                              }
                              strokeWidth="1"
                              stroke="#fff"
                              className="recharts-dot"
                            ></circle>
                          );
                        }}
                        // activeDot={(line) => {
                        //   setposDataY1Debounce({
                        //     color: line.color,
                        //     cy: line.cy,
                        //   });
                        // }}
                        type="monotone"
                        dataKey="service" // service
                        r={'8'}
                        stroke="#F0D284"
                      />
                      <Tooltip
                        isAnimationActive={false}
                        useTranslate3d={false}
                        // trigger={'click'}
                        // position={{ x: posDataX, y: result?.cy || 0 }}
                        // position={{ x: 1, y: 0 }}
                        position={{
                          y: result?.cy - (isMobile ? 151 : 151),
                          x: getX(posDataX),
                          // posDataX -
                          // document.querySelector('.custom-tooltip')?.clientWidth /
                          //   2,
                        }}
                        // offset={
                        //   -(
                        //     document.querySelector('.custom-tooltip')?.clientWidth / 2
                        //   )
                        // }
                        content={
                          <CustomTooltip
                            itsTraspend={getX(posDataX, true)}
                            dataAnalytics={dataAnalytics}
                            translates={{
                              customers: intl.formatMessage({
                                id: 'page.sa.analytics.chart.legend.customers',
                                defaultMessage: 'Customers',
                              }),
                              material: intl.formatMessage({
                                id: 'page.sa.analytics.chart.legend.material',
                                defaultMessage: 'Material Supplier',
                              }),
                              service: intl.formatMessage({
                                id: 'page.sa.analytics.chart.legend.service',
                                defaultMessage: 'Service provider',
                              }),
                              qa: intl.formatMessage({
                                id: 'page.sa.analytics.chart.legend.qa',
                                defaultMessage: 'Quality assurance',
                              }),
                            }}
                          />
                        }
                        cursor={
                          <CustomCursor
                            cy={result.cy}
                            color={result.color}
                            isMobile={isMobile}
                          />
                        }
                      />
                    </LineChart>
                  </ResponsiveContainer>
                  {/* </Scrollbar> */}
                </CustomScroll>
              </div>
            )}
            <div className="pseduo-track"></div>
            <div className="analytics-chart-info">
              <div className="analytics-chart-info-title">
                <div className="analytics-chart-info-color"></div>
                <FormattedMessage
                  id="page.sa.analytics.chart.legend.customers"
                  defaultMessage="Customers"
                />
              </div>

              <div className="analytics-chart-info-title">
                <div className="analytics-chart-info-color"></div>
                <FormattedMessage
                  id="page.sa.analytics.chart.legend.material"
                  defaultMessage="Material Supplier"
                />
              </div>

              <div className="analytics-chart-info-title">
                <div className="analytics-chart-info-color"></div>
                <FormattedMessage
                  id="page.sa.analytics.chart.legend.service"
                  defaultMessage="Service provider"
                />
              </div>
              <div className="analytics-chart-info-title">
                <div className="analytics-chart-info-color"></div>
                <FormattedMessage
                  id="page.sa.analytics.chart.legend.qa"
                  defaultMessage="Quality assurance"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="analytics">
      <LoadedContent loader={isSubmiting} />
      <div className="analytics__header">
        <Title>
          <FormattedMessage
            id="page.sa.analytics.title"
            defaultMessage="Analytics"
          />
        </Title>
        <div className="project-page__header">
          <Button
            text={intl.formatMessage({
              id: 'page.sa.analytics.btn',
              defaultMessage: 'Add New Company"',
            })}
            size={isMobile ? BUTTON_SIZE.ADD_SMALL : BUTTON_SIZE.MEDIUM}
            role={BUTTON_ROLE.ADD_BUTTON}
            hideText={true}
            onClick={() => navigate('/all-companies/customers/add-company')}
          />
        </div>
      </div>

      <div className="analytics__content-card">
        <div className="analytics__card">
          <div className="analytics__card-title">
            <div className="analytics__card-icon">
              <Icon icon="orders" size="20px" />
            </div>
            <FormattedMessage
              id="page.sa.analytics.orders"
              defaultMessage="Orders"
            />
          </div>
          <div className="analytics__card-description">
            {analytics.orders || 0}
          </div>
        </div>

        <div className="analytics__card">
          <div className="analytics__card-title">
            <div className="analytics__card-icon">
              <Icon icon="offer" size="20px" />
            </div>
            <FormattedMessage
              id="page.sa.analytics.offers"
              defaultMessage="Offers"
            />
          </div>
          <div className="analytics__card-description">
            {_.sumBy(analytics.offers, 'count') || 0}
          </div>
        </div>

        <div className="analytics__card">
          <div className="analytics__card-title">
            <div className="analytics__card-icon">
              <Icon icon="order-amount" size="20px" />
            </div>
            <FormattedMessage
              id="page.sa.analytics.amount"
              defaultMessage="Average order amount"
            />
          </div>
          <div className="analytics__card-description">
            {numberWithSpaces(getSumm()) || 0} kr
          </div>
        </div>
      </div>

      <div className="analytics-content">
        <div className="analytics-content-title">
          <h2 className="analytics-title">
            <FormattedMessage
              id="page.sa.analytics.chart.title"
              defaultMessage="New Clients"
            />
          </h2>
          <Dropdown
            handleChange={(data) => setLastMonth(data.value)}
            options={typeOpt}
            label=""
            name="type"
            withoutControll={true}
            control={null}
            id="type"
            sear
            defaultValue={typeOpt[3]}
            customPlaceholder="Last year"
            onClick={() => {
              if (isMobile) {
                chartRef.current.setState({ isTooltipActive: false });
              }
            }}
          />
        </div>
        <div className="analytics-content__container-chart">
          <div className="analytics-chart-legend">
            <div>0</div>
            <div>{MyRound10(maxCountNumber) * 0.2}</div>
            <div>{MyRound10(maxCountNumber) * 0.4}</div>
            <div>{MyRound10(maxCountNumber) * 0.6}</div>
            <div>{MyRound10(maxCountNumber) * 0.8}</div>
            <div>{MyRound10(maxCountNumber) * 1}</div>
          </div>
          {!isSubmiting && (
            <div
              className="analytics-chart"
              style={{
                minHeight: '360px',
                paddingTop: isMobile ? 0 : 60,
              }}
            >
              <ResponsiveContainer
                minWidth={minWidth}
                minHeight={isMobile ? 360 : 300}
              >
                <LineChart data={dataAnalytics} ref={chartRef}>
                  <CartesianGrid vertical={false} strokeDasharray="4 4" />
                  <XAxis
                    dataKey="name"
                    tickMargin={isMobile ? 15 : 25}
                    height={45}
                    interval="preserveStartEnd"
                  />
                  <YAxis
                    width={0}
                    type="number"
                    className="y-cord"
                    domain={[0, MyRound10(maxCountNumber)]}
                    tickCount={6}
                    // tickMargin={100}
                    // tick={[0, 1, 2, 3, 4, maxCountNumber]}
                    r={'8'}
                  />
                  <Line
                    type="monotone"
                    dataKey="customer" // customer
                    stroke="#4446B0"
                    activeDot={(line) => {
                      setposDataX(line.cx);
                      setposDataY0Debounce({
                        color: line.fill,
                        cy: line.cy,
                      });
                      return (
                        <circle
                          cx={line.cx}
                          cy={line.cy}
                          r={result.color === line.fill ? '6' : '0'}
                          fill={result.color === line.fill ? line.fill : null}
                          strokeWidth="1"
                          stroke="#fff"
                          className="recharts-dot"
                        ></circle>
                      );
                    }}
                    r={'8'}
                  />
                  <Line
                    activeDot={(line) => {
                      setposDataY1Debounce({
                        color: line.color,
                        cy: line.cy,
                      });
                      return (
                        <circle
                          cx={line.cx}
                          cy={line.cy}
                          r={result.color === line.fill ? '6' : '0'}
                          fill={result.color === line.fill ? line.fill : null}
                          strokeWidth="1"
                          stroke="#fff"
                          className="recharts-dot"
                        ></circle>
                      );
                    }}
                    type="monotone"
                    dataKey="factory" // factory
                    r={'8'}
                    stroke="#7FB39A"
                  />
                  <Line
                    activeDot={(line) => {
                      setposDataY2Debounce({
                        color: line.color,
                        cy: line.cy,
                      });
                      return (
                        <circle
                          cx={line.cx}
                          cy={line.cy}
                          r={result.color === line.fill ? '6' : '0'}
                          fill={result.color === line.fill ? line.fill : null}
                          strokeWidth="1"
                          stroke="#fff"
                          className="recharts-dot"
                        ></circle>
                      );
                    }}
                    type="monotone"
                    dataKey="quality-control" // quality-control
                    r={'8'}
                    stroke="#E66558"
                  />
                  <Line
                    activeDot={(line) => {
                      setposDataY3Debounce({
                        color: line.color,
                        cy: line.cy,
                      });
                      return (
                        <circle
                          cx={line.cx}
                          cy={line.cy}
                          r={result.color === line.fill ? '6' : '0'}
                          fill={result.color === line.fill ? line.fill : null}
                          strokeWidth="1"
                          stroke="#fff"
                          className="recharts-dot"
                        ></circle>
                      );
                    }}
                    // activeDot={(line) => {
                    //   setposDataY1Debounce({
                    //     color: line.color,
                    //     cy: line.cy,
                    //   });
                    // }}
                    type="monotone"
                    dataKey="service" // service
                    r={'8'}
                    stroke="#F0D284"
                  />
                  <Tooltip
                    isAnimationActive={false}
                    useTranslate3d={false}
                    // trigger={'click'}
                    // position={{ x: posDataX, y: result?.cy || 0 }}
                    // position={{ x: 1, y: 0 }}
                    position={{
                      y: result?.cy - (isMobile ? 151 : 151),
                      x: getX(posDataX),
                      // posDataX -
                      // document.querySelector('.custom-tooltip')?.clientWidth /
                      //   2,
                    }}
                    // offset={
                    //   -(
                    //     document.querySelector('.custom-tooltip')?.clientWidth / 2
                    //   )
                    // }
                    content={
                      <CustomTooltip
                        itsTraspend={getX(posDataX, true)}
                        dataAnalytics={dataAnalytics}
                        translates={{
                          customers: intl.formatMessage({
                            id: 'page.sa.analytics.chart.legend.customers',
                            defaultMessage: 'Customers',
                          }),
                          material: intl.formatMessage({
                            id: 'page.sa.analytics.chart.legend.material',
                            defaultMessage: 'Material Supplier',
                          }),
                          service: intl.formatMessage({
                            id: 'page.sa.analytics.chart.legend.service',
                            defaultMessage: 'Service provider',
                          }),
                          qa: intl.formatMessage({
                            id: 'page.sa.analytics.chart.legend.qa',
                            defaultMessage: 'Quality assurance',
                          }),
                        }}
                      />
                    }
                    cursor={
                      <CustomCursor
                        cy={result.cy}
                        color={result.color}
                        isMobile={isMobile}
                      />
                    }
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          )}
          <div className="analytics-chart-info">
            <div className="analytics-chart-info-title">
              <div className="analytics-chart-info-color"></div>
              <FormattedMessage
                id="page.sa.analytics.chart.legend.customers"
                defaultMessage="Customers"
              />
            </div>

            <div className="analytics-chart-info-title">
              <div className="analytics-chart-info-color"></div>
              <FormattedMessage
                id="page.sa.analytics.chart.legend.material"
                defaultMessage="Material Supplier"
              />
            </div>

            <div className="analytics-chart-info-title">
              <div className="analytics-chart-info-color"></div>
              <FormattedMessage
                id="page.sa.analytics.chart.legend.service"
                defaultMessage="Service provider"
              />
            </div>
            <div className="analytics-chart-info-title">
              <div className="analytics-chart-info-color"></div>
              <FormattedMessage
                id="page.sa.analytics.chart.legend.qa"
                defaultMessage="Quality assurance"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const CustomTooltip = ({ payload, itsTraspend, translates }) => {
  if (payload && payload.length) {
    return (
      <div className="tooltip">
        <div className="custom-tooltip">
          <p className="title">
            {payload[0].payload.name} {new Date().getFullYear()}
          </p>
          <p className="label">
            <div className="color"></div>
            {`${translates.customers} ${payload[0].payload.customer || 0}`}
          </p>

          <p className="label">
            <div className="color"></div>
            {`${translates.material} ${payload[0].payload.factory || 0}`}
          </p>
          <p className="label">
            <div className="color"></div>
            {`${translates.service} ${payload[0].payload.service || 0}`}
          </p>

          <p className="label">
            {' '}
            <div className="color"></div>
            {`${translates.qa} ${payload[0]?.payload['quality-control'] || 0}`}
          </p>
          <div
            style={
              itsTraspend?.its === 1
                ? {
                    left: -itsTraspend.cord - 4 + 'px',
                  }
                : itsTraspend?.its === 2
                ? {
                    right: itsTraspend.cord - 10.5 + 'px',
                  }
                : {}
            }
            className="underTooltip"
          ></div>
        </div>
      </div>
    );
  }

  return null;
};

const CustomCursor = (props) => {
  const { x, y, width, height, stroke } = props;
  return (
    <path
      fill="none"
      stroke={props.color}
      strokeWidth="2"
      id="triangle"
      x={props.points[0].x}
      y={props.cy}
      d={`m1 0 L1 ${height - props.cy + 5}`}
      transform={`translate(${props.points[0].x - 1}, ${props.cy})`}
      strokeDasharray={'8px'}
      zIndex={9}
    />
  );
};
