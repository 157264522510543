import API from '../api';

export const userEditInfo = (data) =>
  API.post('/api/api/v1/users/edit-info', data);

export const userGetInfo = () => API.get('/api/api/v1/users/info');

export const userChangePassword = (data) =>
  API.post('/api/api/v1/users/change-password', data);

export const usersAdmins = (data) =>
  API.get('/api/api/v1/users', {
    params: data,
  });

export const inviteSuperAdmin = (data) =>
  API.post('/api/api/v1/users/super-admin/invite', data);

export const getUserCompany = (companyId, userId) =>
  API.get(`/api/api/v1/company/companies/${companyId}/users/${userId}`);

export const editUserCompany = (companyId, userId, data) =>
  API.post(`/api/api/v1/company/companies/${companyId}/users/${userId}`, data);
