import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { LangContext } from '../../../provider/Lang';
import Icon from '../Icon';

//  TODO: TEMP REALIZATION OF LANG SWITCHER. NEED REFACTORING
//  TODO: TEMP REALIZATION OF LANG SWITCHER. NEED REFACTORING
//  TODO: TEMP REALIZATION OF LANG SWITCHER. NEED REFACTORING
//  TODO: TEMP REALIZATION OF LANG SWITCHER. NEED REFACTORING
const LangSwitcher = ({ items, collapseAllSubList, onSubmenuExpand }) => {
  const [subListExpanded, setSubListExpanded] = useState([]);
  const location = useLocation();
  const intl = useIntl();

  const context = useContext(LangContext);

  const setExpandedArr = (key) => {
    const exist = subListExpanded.filter((item) => item === key)?.length;
    if (exist) {
      setSubListExpanded(subListExpanded.filter((item) => item !== key));
    } else {
      console.log(exist);

      onSubmenuExpand();
      setSubListExpanded([key]);
    }
  };

  const checkIsExpand = (key) => {
    return subListExpanded.filter((item) => item === key)?.length;
  };

  const checkActive = (isActive, enableActiveStyle) =>
    isActive && enableActiveStyle
      ? { background: '#4a515e' }
      : { background: 'transparent' };

  const checkActiveTopNav = (path, key) => {
    if (path === 'en' && intl.locale === 'en' && checkIsExpand(key)) {
      return { background: '#4a515e' };
    } else if (path === 'swe' && intl.locale === 'swe' && checkIsExpand(key)) {
      return { background: '#4a515e' };
    } else {
      return location.pathname.includes(path)
        ? { background: '#4a515e' }
        : { background: 'transparent' };
    }
  };

  useEffect(() => {
    if (!collapseAllSubList) {
      setSubListExpanded([]);
    }
  }, [collapseAllSubList]);

  const makeLinkItem = (data, enableActiveStyle = true, topIndex = null) => {
    if (data.path) {
      return (
        <NavLink
          to={data.path}
          onClick={(e) => {
            if (data.path === 'swe' || data.path === 'en') {
              e.preventDefault();
              context.selectLang({ value: data.path });
              if (!data.icon) {
                setExpandedArr(topIndex);
              }
            }
          }}
          style={({ isActive }) => {
            if (data.path === 'en' && intl.locale === 'en') {
              return { background: 'transparent' };
            } else if (data.path === 'swe' && intl.locale === 'swe') {
              return { background: 'transparent' };
            } else {
              return checkActive(isActive, enableActiveStyle);
            }
          }}
        >
          {data.icon && (
            <span className="icon">
              <Icon icon={data.icon} size="21px" />
            </span>
          )}
          <span className="nav-list__item-title">{data.title}</span>
        </NavLink>
      );
    } else {
      return (
        <div>
          {data.icon && (
            <span className="icon">
              <Icon icon={data.icon} size="21px" />
            </span>
          )}
          <span className="nav-list__item-title">{data.title}</span>
        </div>
      );
    }
  };

  return (
    <ul className="nav-list lang-switcher-temp">
      {items &&
        items.map((topItem, topIndex) => {
          if (!topItem.subNav) {
            return (
              <li
                className="nav-list__item"
                key={topIndex}
                style={{
                  cursor: !topItem.path ? 'auto' : 'pointer',
                }}
              >
                {makeLinkItem(topItem)}
              </li>
            );
          } else {
            return (
              <li className="nav-list__item" key={topIndex}>
                <div style={checkActiveTopNav(topItem.path, topIndex)}>
                  <span
                    className="icon"
                    onClick={() => {
                      setSubListExpanded([topIndex]);
                      onSubmenuExpand();
                      if (topItem.path === 'swe' || topItem.path === 'en') {
                        context.selectLang({ value: topItem.path });
                        setExpandedArr(topIndex);
                      }
                    }}
                  >
                    <Icon icon={topItem.icon} size="21px" />
                  </span>
                  <span
                    className="nav-list__item-title"
                    onClick={() => {
                      setSubListExpanded([topIndex]);
                      onSubmenuExpand();
                      if (topItem.path === 'swe' || topItem.path === 'en') {
                        context.selectLang({ value: topItem.path });
                        setExpandedArr(topIndex);
                      }
                    }}
                  >
                    {topItem.title}
                  </span>
                  <span
                    onClick={() => {
                      setExpandedArr(topIndex);
                    }}
                    className={`icon ${
                      checkIsExpand(topIndex) ? `sublist-open` : ''
                    }`}
                  >
                    <Icon icon="chevron-down" size="16px" />
                  </span>
                </div>
                <ul
                  className={
                    checkIsExpand(topIndex)
                      ? 'nav-list__sublist expanded'
                      : 'nav-list__sublist'
                  }
                >
                  {topItem.subNav.map((subItem, subIndex) => (
                    <li
                      key={`${topIndex} ${subIndex}`}
                      className={
                        location.pathname.includes(subItem.path) ? 'active' : ''
                      }
                    >
                      {makeLinkItem(subItem, false, topIndex)}
                    </li>
                  ))}
                </ul>
              </li>
            );
          }
        })}
    </ul>
  );
};

LangSwitcher.defaultProps = {
  items: [],
  onSubmenuExpand: () => {},
};

LangSwitcher.propTypes = {
  items: PropTypes.array,
  collapseAllSubList: PropTypes.bool,
  onSubmenuExpand: PropTypes.func,
};

export default LangSwitcher;
