export const dictionaryMapper = (data) => {
  if (!data) {
    return;
  }
  const mapped = {};

  for (const key of Object.keys(data)) {
    mapped[key] = data[key].map((item) => ({
      value: item.key || item,
      label: item.translation || item,
    }));
  }

  return mapped;
};
