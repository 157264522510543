import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  CUSTOMER_ADMIN_NAV_ITEMS,
  CUSTOMER_ADMIN_NAV_ITEMS_SWE,
  ENG_SWITCHER,
  SUPER_ADMIN_NAV_ITEMS,
  SUPER_ADMIN_NAV_ITEMS_SWE,
  SUPPLIER_ADMIN_NAV_ITEMS,
  SUPPLIER_ADMIN_NAV_ITEMS_SWE,
  SV_SWITCHER,
} from '../constants/nav-bar.const';
import { useMediaQuery } from 'react-responsive';
import Header from '../components/Header/Header';
import SideBar from '../components/SideBar/SideBar';
import Icon from '../components/ui/Icon';
import { AlertContextProvider } from '../provider/Alert';
import { useSelector } from 'react-redux';
import useWindowSize from '../utils/useWindowSize';
import { LangContext } from '../provider/Lang';

const getSANavMenu = () => {
  const locale = localStorage.getItem('lang') || 'swe';

  if (locale === 'swe') {
    return chekcLangSwitcherEnable(SUPER_ADMIN_NAV_ITEMS_SWE);
  } else {
    return chekcLangSwitcherEnable(SUPER_ADMIN_NAV_ITEMS);
  }
};

const getCustomerNavMenu = () => {
  const locale = localStorage.getItem('lang') || 'swe';

  if (locale === 'swe') {
    return chekcLangSwitcherEnable(CUSTOMER_ADMIN_NAV_ITEMS_SWE);
  } else {
    return chekcLangSwitcherEnable(CUSTOMER_ADMIN_NAV_ITEMS);
  }
};

const getSupplierNavMenu = () => {
  const locale = localStorage.getItem('lang') || 'swe';

  if (locale === 'swe') {
    return chekcLangSwitcherEnable(SUPPLIER_ADMIN_NAV_ITEMS_SWE);
  } else {
    return chekcLangSwitcherEnable(SUPPLIER_ADMIN_NAV_ITEMS);
  }
};

const chekcLangSwitcherEnable = (arr) => {
  // if (process.env.NODE_ENV === 'development') {
  return arr;
  // } else {
  //   return arr.filter(
  //     (item) => item.title !== 'Swedish' && item.title !== 'English'
  //   );
  // }
};

const PageLayout = ({ children }) => {
  const roles = localStorage.getItem('role') || '';
  const companyType = localStorage.getItem('companyType') || '';
  const auth = useSelector((state) => state.auth.isAuthorized);
  const user = useSelector((state) => state.personalInfo);
  const [expanded, setExpanded] = useState(true);
  const isSuperAdmin = roles.includes('super admin');
  const [itemsSidebar, setItemsSidebar] = useState([]);
  const [langs, setLangs] = useState([]);
  const size = useWindowSize();
  const context = useContext(LangContext);

  useEffect(() => {
    if (!user.isLoading) {
      setItemsSidebar(
        isSuperAdmin
          ? getSANavMenu()
          : companyType === 'customer'
          ? getCustomerNavMenu()
          : getSupplierNavMenu()
      );

      // TODO: NEED TO REFACTOR THIS
      if (localStorage.getItem('lang') === 'en') {
        setLangs(chekcLangSwitcherEnable(ENG_SWITCHER));
      }

      if (localStorage.getItem('lang') === 'swe') {
        setLangs(chekcLangSwitcherEnable(SV_SWITCHER));
      }
    }
  }, [roles, companyType, user.data, context.locale]);

  const isMobileOrTablet = useMediaQuery({
    query: '(max-width: 900px) ',
  });

  const menuButtonClass = !expanded
    ? 'page-layout__sidebar-btn collapsed'
    : 'page-layout__sidebar-btn';

  if (!auth) {
    return children;
  }

  return (
    <div
      className="page-layout"
      style={{
        height: isMobileOrTablet ? `${size.height}px` : '100vh',
      }}
    >
      {isMobileOrTablet && <Header navItems={itemsSidebar} langs={langs} />}

      {!isMobileOrTablet && (
        <div className={`page-layout__sidebar ${!expanded ? 'collapsed' : ''}`}>
          <SideBar
            menuExpand={expanded}
            menuExpandChange={setExpanded}
            items={itemsSidebar}
            langs={langs}
          />
          <div
            className={menuButtonClass}
            onClick={() => setExpanded(!expanded)}
          >
            <Icon icon="chevron-left" size="16px" />
          </div>
        </div>
      )}

      {isMobileOrTablet && <div className="fix-mobile-scroll"></div>}

      <div
        className="page-layout__content"
        style={{
          height: isMobileOrTablet ? `calc(${size.height}px - 56px)` : '100vh',
        }}
      >
        <AlertContextProvider>{children}</AlertContextProvider>
      </div>
      <div id="portal-action"></div>
    </div>
  );
};

PageLayout.propTypes = {
  children: PropTypes.object,
  roles: PropTypes.array.isRequired,
};

export default PageLayout;
