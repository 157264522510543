import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useMediaQuery } from 'react-responsive';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Icon from '../../../../components/ui/Icon';
import Tab from '../../../../components/ui/Tab';
import OrdersList from './OrdersList/OrdersList';
import { useDispatch, useSelector } from 'react-redux';
import { orders } from '../../../../store/slice/ordersSlice';
import { debounce } from 'lodash';
import { getCompany } from '../../../../api/company';
import { FormattedMessage, useIntl } from 'react-intl';

const OrdersPage = ({ activeDefault }) => {
  const navigate = useNavigate();
  const intl = useIntl();
  const titles = [
    intl.formatMessage({
      id: `page.orders.tab.noAnswered`,
      defaultMessage: 'Not Answered',
    }),
    intl.formatMessage({
      id: `page.orders.tab.current`,
      defaultMessage: 'Current',
    }),
    intl.formatMessage({
      id: `page.orders.tab.archive`,
      defaultMessage: 'Archive',
    }),
  ];
  const [pageTitle, setPageTitle] = useState(titles[0]);
  const [companyInfo, setCompanyInfo] = useState({});

  const params = useParams();
  const [searchParams] = useSearchParams();
  const pageQuery = searchParams.get('page') || 1;
  const statusQuery = searchParams.get('status') || 'new';

  const dispatch = useDispatch();
  const isListLoading = useSelector((state) => state.orders.isLoading);
  const orderList = useSelector((state) => state.orders.data);
  const orderMeta = useSelector((state) => state.orders.meta);

  const getActiveTab = () => {
    if (!statusQuery) {
      return activeDefault;
    }
    if (statusQuery === 'new') {
      return 1;
    }
    if (statusQuery === 'in_progress') {
      return 2;
    }
    if (statusQuery === 'archive') {
      return 3;
    }
  };

  const tabs = [
    {
      title: intl.formatMessage({
        id: `page.orders.tab.noAnswered`,
        defaultMessage: 'Not Answered',
      }),
      id: 1,
      page: (
        <OrdersList
          title={pageTitle}
          meta={orderMeta}
          isListLoading={isListLoading}
          orderList={orderList}
        />
      ),
    },
    {
      title: intl.formatMessage({
        id: `page.orders.tab.current`,
        defaultMessage: 'Current',
      }),
      id: 2,
      page: (
        <OrdersList
          title={pageTitle}
          meta={orderMeta}
          isListLoading={isListLoading}
          orderList={orderList}
        />
      ),
    },
    {
      title: intl.formatMessage({
        id: `page.orders.tab.archive`,
        defaultMessage: 'Archive',
      }),
      id: 3,
      page: (
        <OrdersList
          title={pageTitle}
          meta={orderMeta}
          isListLoading={isListLoading}
          orderList={orderList}
        />
      ),
    },
  ];
  // eslint-disable-next-line no-unused-vars
  const isMobileOrTablet = useMediaQuery({
    query: '(max-width: 900px) ',
  });

  const getOrdersList = (company) => {
    dispatch(
      orders({
        companyId: params.id_company,
        data: {
          page: pageQuery,
          status: statusQuery,
          per_page: 10,
        },
        companyType: company ? company.type : companyInfo.type,
      })
    )
      .unwrap()
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const debouncedFetchList = debounce(getOrdersList, 25, { maxWait: 25 });

  const getCompanyAsync = async (id) => {
    try {
      const res = await getCompany(id);

      if (res && res.data && res.data.data) {
        setCompanyInfo(res.data.data);
        getOrdersList(res.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    document.title = pageTitle;
  }, [pageTitle]);

  useEffect(() => {
    if (!companyInfo.id) {
      getCompanyAsync(params.id_company);
    } else {
      debouncedFetchList();
    }
  }, [pageQuery, statusQuery]);

  useEffect(() => {
    const params = new URLSearchParams();
    if (!statusQuery) {
      params.append('status', 'new');
    } else {
      params.append('status', statusQuery);
    }
    if (!pageQuery) {
      params.append('page', 1);
    } else {
      params.append('page', pageQuery);
    }

    navigate(
      {
        search: params.toString(),
      },
      {
        replace: true,
      }
    );
  }, []);

  const onTabChange = (id) => {
    const params = new URLSearchParams();

    switch (id) {
      case 1:
        params.append('status', 'new');
        params.append('page', 1);
        break;
      case 2:
        params.append('status', 'in_progress');
        params.append('page', 1);
        break;
      case 3:
        params.append('status', 'archive');
        params.append('page', 1);
        break;
      default:
        break;
    }

    navigate(
      {
        search: params.toString(),
      },
      {
        replace: true,
      }
    );
  };

  return (
    <div className="company-page orders-page">
      <div className="company-page__inner">
        <div className="company-page__header">
          <div className="company-page__header-type">
            <div onClick={() => navigate(-1)}>
              <Icon icon="arrow-left" size="20px" />
            </div>
            <span>{(companyInfo && companyInfo.name) || '...'}</span>
          </div>
          <span className="company-page__header-title">
            <FormattedMessage
              id="page.sa.orders.title"
              defaultMessage="Orders"
            />
          </span>
        </div>
        <div className="company-page__body">
          <Tab
            activeDefault={getActiveTab()}
            tabs={tabs}
            onTabChange={(id) => {
              setPageTitle(titles[id - 1]);
              onTabChange(id);
            }}
          />
        </div>
      </div>
    </div>
  );
};

OrdersPage.defaultProps = {
  activeDefault: 1,
};

OrdersPage.propTypes = {
  activeDefault: PropTypes.number,
};

export default OrdersPage;
