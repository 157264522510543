export const PASSWORD_VALIDATOR = {
  isNumber: (value) =>
    /^(?=.*[0-9])/.test(value) || 'Password should contain at least one digit',
  isUpper: (value) =>
    /^(?=.*[a-zа-я])/.test(value) ||
    'Password should contain at least one lowercase letter',
  isLower: (value) =>
    /^(?=.*[A-ZА-Я])/.test(value) ||
    'Password should contain at least one uppercase letter',
  // isSimbol: (value) =>
  //   /^(?=.*[!@#$%^&*.,?;:"'№_=/<>|{}`~()[\]\\])/.test(value) ||
  //   'Password should contain at least one special symbol',
  minLength: (value) =>
    /^.{8,}$/.test(value) || 'Password must contain at least 8 characters',
};

export const PASSWORD_VALIDATOR_LOCALE = (msg, msg1, msg2, msg4) => ({
  isNumber: (value) => /^(?=.*[0-9])/.test(value) || msg,
  isUpper: (value) => /^(?=.*[a-zа-я])/.test(value) || msg1,
  isLower: (value) => /^(?=.*[A-ZА-Я])/.test(value) || msg2,
  // isSimbol: (value) =>
  //   /^(?=.*[!@#$%^&*.,?;:"'№_=/<>|{}`~()[\]\\])/.test(value) ||
  //   'Password should contain at least one special symbol',
  minLength: (value) => /^.{8,}$/.test(value) || msg4,
});
