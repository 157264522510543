export const COMPANY_TYPE = {
  CUSTOMER: 'customer',
  SUPPLIER: 'supplier',
};

export const COMPANY_SPEC = {
  FACTORY: 'factory',
  SERVICE: 'service',
  QUALITY_CONTROL: 'quality-control',
};
