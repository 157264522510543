import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { offersSort } from '../../utils/offers.sort';
import { FormattedMessage, useIntl } from 'react-intl';

const companyType = {
  factory: 'Material Supplier',
  service: 'Service Provider',
  'quality-control': 'Quality Assurance',
};

const CompanyOrderItem = ({
  data,
  onSuccess,
  idCompany,
  description,
  orderProject,
}) => {
  const navigate = useNavigate();
  const intl = useIntl();

  const isMobileOrTablet = useMediaQuery({
    query: '(max-width: 767px) ',
  });

  function numberWithSpaces(x) {
    const parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    return parts.join('.');
  }

  return (
    <div className="company-order-in-progress-item">
      <div className="company-order-in-progress-item__inner">
        <div className="company-order-in-progress-item__name">
          {!isMobileOrTablet ? (
            <span className="company-order-in-progress-item__name-name">
              <FormattedMessage
                id="page.orderDetail.listTitle"
                defaultMessage="Selected suppliers"
              />
            </span>
          ) : (
            <span className="company-order-in-progress-item__name-name">
              <FormattedMessage
                id="component.offerCard.total"
                defaultMessage="Quotation amount (excl. VAT)"
              />
            </span>
          )}
          <div className="company-order-in-progress-item__box">
            {orderProject && orderProject.offers
              ? offersSort(orderProject.offers).map((item) => {
                  return (
                    <div
                      key={item?.id}
                      className="company-order-in-progress-item__info"
                    >
                      <div className="company-order-in-progress-item__info-block">
                        <span className="company-order-in-progress-item__info-title">
                          {intl.formatMessage({
                            id: `company.supplier.${item.company.spec}`,
                            defaultMessage: companyType[item.company.spec],
                          })}
                        </span>
                        <span
                          onClick={() =>
                            navigate(
                              `/all-companies/${
                                item.company.spec === 'service'
                                  ? 'providers'
                                  : item.company.spec === 'quality-control'
                                  ? 'quality'
                                  : 'supplier'
                              }/company/${item.company.id}`
                            )
                          }
                          className="company-order-in-progress-item__info-desc link"
                        >
                          {item.company.name}
                        </span>
                        <span className="company-order-in-progress-item__info-amount">
                          {numberWithSpaces(item.amount)} kr
                        </span>
                      </div>
                    </div>
                  );
                })
              : ''}
          </div>
        </div>
      </div>
    </div>
  );
};

CompanyOrderItem.defaultProps = {
  onSuccess: () => {},
};

CompanyOrderItem.propTypes = {
  data: PropTypes.object.isRequired,
  onSuccess: PropTypes.func,
  idCompany: PropTypes.string,
  description: PropTypes.string,
  orderProject: PropTypes.object,
};

export default CompanyOrderItem;
