// http://api.localhost/api/v1/company/companies/customer/my-projects
// http://api.localhost/api/v1/company/companies/{companyId}/projects
import API from '../api';

export const projectEdit = (projectId, data) =>
  API.put(`/api/api/v1/company/companies/customer/projects/${projectId}`, data);

export const projectGet = (id, data) =>
  API.get(`/api/api/v1/company/companies/${id}/projects`, {
    params: data,
  });

export const myProjectGet = (data) =>
  API.get('/api/api/v1/company/companies/customer/my-projects', {
    params: data,
  });

export const projectShow = (companyId, projectId) =>
  API.get(`/api/api/v1/company/companies/${companyId}/projects/${projectId}`);

export const customerProjectShow = (projectId) =>
  API.get(`/api/api/v1/company/companies/customer/my-projects/${projectId}`);

export const projectCreate = (data) =>
  API.post('/api/api/v1/company/companies/customer/projects', data);
