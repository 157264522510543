/* eslint-disable prefer-regex-literals */
/* eslint-disable no-unused-vars */
/* eslint-disable  no-debugger */
import { useSearchParams, useParams, useNavigate } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeAcceptedInfo,
  getMyOrder,
  getMyOrderOffer,
  setChangeSelectedOfferProcessState,
  setClearOrder,
  setOffersLoading,
} from '../../../../store/slice/orderCustomerSlice';
import Title from '../../../../components/ui/Title';
import Tab from '../../../../components/ui/Tab';
import { debounce } from 'lodash';
import Status from '../../../../components/ui/Status/Status';
import OrderInfoBar from './OrderInfoBar';
import AdaptiveContent, {
  CONTENT_SIZE_TYPE,
} from '../../../../components/AdaptiveContent/AdaptiveContent';
import OrderTitleMsgBar from './OrderInfoBar/OrderTitleMsgBar';
import Actions from '../../../../components/ui/Actions';
import ConfirmPopup from '../../../../components/ConfirmPopup/ConfirmPopup';
import OrderInfoTabOfferList from './OrderInfoTabOfferList';
import LoadedContent from '../../../../components/LoadedContent';
import { cancelOrder } from '../../../../api/order';
import { AlertMessengerContext } from '../../../../provider/Alert';
import AcceptedOffersInProgress from './AcceptedOffersInProgress/AcceptedOffersInProgress';
import moment from 'moment';
import OfferCardCustomer, {
  OFFER_CART_TYPE,
} from '../../../../components/OfferCardCustomer/OfferCardCustomer';
import AcceptedOffersWaiting from './AcceptedOffersWaiting';
import { offersSort } from '../../../../utils/offers.sort';
import { orderViewIdFormat } from '../../../../utils/order-viewid.format';
import { FormattedMessage, useIntl } from 'react-intl';

const companyType = {
  factory: 'Material Supplier',
  service: 'Service Provider',
  'quality-control': 'Quality Assurance',
};

const OrderDetailCustomer = () => {
  const intl = useIntl();
  const [selectedSuppCount, setSelectedSuppCount] = useState(0);
  const [cancelOrderLoad, setCancelOrderLoad] = useState(false);
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  const isListLoading = useSelector(
    (state) => state.orderCustomer.isListLoading
  );
  const isProjectLoading = useSelector(
    (state) => state.orderCustomer.isProjectLoading
  );
  const isErrorProject = useSelector(
    (state) => state.orderCustomer.isErrorProject
  );
  const isOrderInfoUpdating = useSelector(
    (state) => state.orderCustomer.isOrderInfoUpdating
  );

  const orderList = useSelector((state) => state.orderCustomer.data);
  const orderMeta = useSelector((state) => state.orderCustomer.meta);
  const orderProject = useSelector((state) => state.orderCustomer.dataProject);

  // TODO: USELESS FUNCTIONAL;
  const isChangeOfferProcess = useSelector(
    (state) => state.orderCustomer.changeSelectedOfferProcess
  );

  const { addSuccessMessage, addErrorMessage } = useContext(
    AlertMessengerContext
  );

  const [searchParams] = useSearchParams();
  const pageQuery = searchParams.get('page') || 1;
  const specQuery = searchParams.get('spec') || 'factory';
  const queryParams = useParams();
  const navigate = useNavigate();

  const onSubmit = () => {
    setCancelOrderLoad(true);
    setOpen(false);

    cancelOrder(queryParams.id_order)
      .then(() => {
        setCancelOrderLoad(false);
        addSuccessMessage({
          message: intl.formatMessage({
            id: `page.orders.cancelOrderMsg`,
            defaultMessage: 'You have successfully canceled your order!',
          }),
        });
        navigate('/customer/my-orders?status=archive&page=1');
      })
      .catch(({ response }) => {
        setCancelOrderLoad(false);
        addErrorMessage({
          message: response && response.data.message,
        });
      });
  };

  const fetchList = async () => {
    dispatch(
      getMyOrderOffer({
        id: queryParams.id_order,
        query: {
          page: pageQuery,
          spec: specQuery,
        },
      })
    );
  };

  const debouncedFetchList = debounce(fetchList, 25, { maxWait: 25 });

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(
      getMyOrder({
        id: queryParams.id_order,
      })
    );
    dispatch(setClearOrder());
    document.title = intl.formatMessage({
      id: `page.orderDetail.title`,
      defaultMessage: 'Order',
    });

    return () => {
      dispatch(setChangeSelectedOfferProcessState(false));
    };
  }, []);

  useEffect(() => {
    if (orderProject.info) {
      if (orderProject.status !== 'in_progress') {
        const params = new URLSearchParams();

        if (!specQuery) {
          params.append('spec', 'factory');
        } else {
          params.append('spec', specQuery);
        }
        if (!pageQuery) {
          params.append('page', 1);
        } else {
          params.append('page', pageQuery);
        }

        navigate(
          {
            search: params.toString(),
          },
          {
            replace: true,
          }
        );

        debouncedFetchList();
      }
    }
  }, [orderProject.info]);

  useEffect(() => {
    setSelectedSuppCount(getAcceptedOffers().length);
  }, [orderProject]);

  useEffect(() => {
    if (orderProject.info) {
      if (selectedSuppCount !== 3) {
        dispatch(setChangeSelectedOfferProcessState(false));
        dispatch(setOffersLoading());
      }
      if (orderProject.status.length && orderProject.status !== 'in_progress') {
        debouncedFetchList();
      }
    }
  }, [pageQuery, specQuery]);

  const activeDefault =
    specQuery === 'factory' ? 1 : specQuery === 'service' ? 2 : 3;

  const getAcceptedOffers = () =>
    orderProject && orderProject.offers
      ? orderProject.offers.filter((item) => item.status === 'accept')
      : [];

  const onTabChange = (id) => {
    const params = new URLSearchParams();

    switch (id) {
      case 1:
        params.append('spec', 'factory');
        params.append('page', 1);

        break;
      case 2:
        params.append('spec', 'service');
        params.append('page', 1);

        break;
      case 3:
        params.append('spec', 'quality-control');
        params.append('page', 1);

        break;
      default:
        break;
    }

    navigate(
      {
        search: params.toString(),
      },
      {
        replace: true,
      }
    );
  };

  const acceptOfferHandler = (id) => {
    debouncedFetchList();

    if (id === 1) {
      dispatch(changeAcceptedInfo('materialSupplier', true));
    }
    if (id === 2) {
      dispatch(changeAcceptedInfo('serviceProvider', true));
    }
    if (id === 3) {
      dispatch(changeAcceptedInfo('qualityAssurance', true));
    }
  };

  const tabs = [
    {
      title: intl.formatMessage({
        id: `page.orderDetail.tab.ms`,
        defaultMessage: 'Material supplier',
      }),
      id: 1,
      countActive: orderProject.info?.materialSupplier?.count || 0,
      itsDone: orderProject.info?.materialSupplier?.hasAccept,
      page: (
        <OrderInfoTabOfferList
          currentPage={orderMeta.current_page}
          totalCount={orderMeta.last_page}
          isListLoading={isListLoading}
          selectedSuppCount={selectedSuppCount}
          itsDone={orderProject.info?.materialSupplier?.hasAccept}
          orderList={orderList}
          orderProject={orderProject}
          spec="factory"
          description={intl.formatMessage({
            id: `page.orderDetail.tab.ms`,
            defaultMessage: 'Material supplier',
          })}
          acceptOfferHandler={() => acceptOfferHandler(1)}
          getAcceptedOffers={getAcceptedOffers()}
        />
      ),
    },
    {
      title: intl.formatMessage({
        id: `page.orderDetail.tab.sp`,
        defaultMessage: 'Service provider',
      }),
      id: 2,
      countActive: orderProject.info?.serviceProvider?.count || 0,
      itsDone: orderProject.info?.serviceProvider?.hasAccept,
      page: (
        <OrderInfoTabOfferList
          currentPage={orderMeta.current_page}
          totalCount={orderMeta.last_page}
          isListLoading={isListLoading}
          selectedSuppCount={selectedSuppCount}
          itsDone={orderProject.info?.serviceProvider?.hasAccept}
          orderList={orderList}
          orderProject={orderProject}
          spec="service"
          description={intl.formatMessage({
            id: `page.orderDetail.tab.sp`,
            defaultMessage: 'Service provider',
          })}
          acceptOfferHandler={() => acceptOfferHandler(2)}
          getAcceptedOffers={getAcceptedOffers()}
        />
      ),
    },
    {
      title: intl.formatMessage({
        id: `page.orderDetail.tab.qa`,
        defaultMessage: 'Quality assurance',
      }),
      id: 3,
      countActive: orderProject.info?.qualityAssurance?.count || 0,
      itsDone: orderProject.info?.qualityAssurance?.hasAccept,
      page: (
        <OrderInfoTabOfferList
          currentPage={orderMeta.current_page}
          totalCount={orderMeta.last_page}
          isListLoading={isListLoading}
          selectedSuppCount={selectedSuppCount}
          itsDone={orderProject.info?.qualityAssurance?.hasAccept}
          orderList={orderList}
          orderProject={orderProject}
          spec="quality-control"
          description={intl.formatMessage({
            id: `page.orderDetail.tab.qa`,
            defaultMessage: 'Quality assurance',
          })}
          acceptOfferHandler={() => acceptOfferHandler(3)}
          getAcceptedOffers={getAcceptedOffers()}
        />
      ),
    },
  ];

  const getTimer = (date) => {
    const currentTime = new Date().toUTCString();
    const localeDate = new Date(date).toUTCString();

    const diff = moment(localeDate).unix() - moment(currentTime).unix();

    if (diff < 0) {
      return '';
    }

    const hh = Math.floor(diff / 3600);
    const mm = Math.floor((diff - hh * 3600) / 60);

    if (hh) {
      return `${hh} ${intl.formatMessage({
        id: `hh`,
        defaultMessage: ' houres ',
      })} ${mm} ${intl.formatMessage({
        id: `mm`,
        defaultMessage: ' min ',
      })}`;
    } else {
      return `${mm} ${intl.formatMessage({
        id: `mm`,
        defaultMessage: ' min ',
      })}`;
    }
  };

  return (
    <div className="order-page-customer">
      <LoadedContent loader={cancelOrderLoad} />
      <div className="order-page-customer__content">
        <AdaptiveContent type={CONTENT_SIZE_TYPE.DESCTOP}>
          <div className="order-page-customer__content-header">
            <Title
              infoContent={
                <div>
                  <div className="order-page-customer__content-viewid">
                    {orderViewIdFormat(orderProject.viewId)}
                  </div>
                  <div className="order-page-customer__content-project">
                    {orderProject.project?.name}
                  </div>
                  <div className="status-container">
                    <Status type={orderProject && orderProject.status} />
                    {orderProject &&
                      (orderProject.edited || orderProject.inEditing) && (
                        <span className="edited-label">
                          <FormattedMessage
                            id={`status.${
                              !orderProject.inEditing && orderProject.edited
                                ? 'edited'
                                : 'editing'
                            }`}
                            defaultMessage={
                              !orderProject.inEditing && orderProject.edited
                                ? 'Edited'
                                : 'Editing'
                            }
                          />
                        </span>
                      )}
                  </div>
                </div>
              }
              subTitle={intl.formatMessage({
                id: `page.orderDetail.title`,
                defaultMessage: 'Order',
              })}
            >
              <span>
                <FormattedMessage
                  id="order.mainName_2"
                  defaultMessage="Concrete casting"
                />
              </span>
            </Title>
            {orderProject.status ===
            'in_progress' ? null : orderProject.status === 'not_answered' ? (
              <div className="order-page-customer__content-header-count">
                <span>
                  {selectedSuppCount === 3
                    ? intl.formatMessage({
                        id: `page.orderDetail.listTitle`,
                        defaultMessage: 'Selected suppliers',
                      })
                    : `${
                        orderProject.tenderTotal !== undefined
                          ? orderProject.tenderTotal
                          : ''
                      } ${intl.formatMessage({
                        id: `page.orderDetail.tenderRec`,
                        defaultMessage: 'tenders received',
                      })}`}
                </span>
                <OrderTitleMsgBar
                  selectedSuppCount={selectedSuppCount}
                  time={getTimer(orderProject.expiredAt)}
                />
              </div>
            ) : (
              <div className="order-page-customer__content-header-count">
                <span>
                  {selectedSuppCount === 3
                    ? intl.formatMessage({
                        id: `page.orderDetail.listTitle`,
                        defaultMessage: 'Selected suppliers',
                      })
                    : `${
                        orderProject.tenderTotal !== undefined
                          ? orderProject.tenderTotal
                          : ''
                      } ${intl.formatMessage({
                        id: `page.orderDetail.tenderRec`,
                        defaultMessage: 'tenders received',
                      })}`}
                </span>
              </div>
            )}
          </div>
        </AdaptiveContent>

        <div className="order-page-customer__body">
          {orderProject.status !== 'in_progress' && selectedSuppCount !== 3 && (
            <Tab
              onTabChange={(id) => onTabChange(id)}
              tabs={
                orderProject.orderClass !== 'no' ? tabs : [tabs[0], tabs[1]]
              }
              activeDefault={activeDefault}
            />
          )}

          {orderProject.status !== 'in_progress' &&
            (selectedSuppCount === 3 ||
              (selectedSuppCount === 2 &&
                orderProject.orderClass === 'no')) && (
              <AcceptedOffersWaiting
                offers={getAcceptedOffers()}
                orderProject={orderProject}
                orderInfoUpdating={isOrderInfoUpdating}
                changeHandler={(id, spec) => {
                  dispatch(setChangeSelectedOfferProcessState(true));
                  navigate(
                    {
                      search: `spec=${spec}&page=1`,
                    },
                    {
                      replace: true,
                    }
                  );
                }}
              />
            )}

          <AdaptiveContent type={CONTENT_SIZE_TYPE.DESCTOP}>
            {orderProject.status === 'in_progress' && (
              <div>
                <AcceptedOffersInProgress offers={getAcceptedOffers()} />
              </div>
            )}
          </AdaptiveContent>

          <AdaptiveContent type={CONTENT_SIZE_TYPE.MOBILE}>
            {orderProject.status === 'in_progress' && (
              <div className="accepted-offers-inprogress-mobile">
                <span className="accepted-offers-inprogress-mobile__title">
                  <FormattedMessage
                    id="page.orderDetail.listTitle"
                    defaultMessage="Selected suppliers"
                  />
                </span>

                <div className="accepted-offers-inprogress-mobile__list">
                  {offersSort(getAcceptedOffers()).map((item, index) => (
                    <div key={index} className="company-page__list-item">
                      <OfferCardCustomer
                        description={intl.formatMessage({
                          id: `company.supplier.${item.company.spec}`,
                          defaultMessage: companyType[item.company.spec],
                        })}
                        data={item}
                        cardType={OFFER_CART_TYPE.DONE}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </AdaptiveContent>
        </div>
      </div>

      <AdaptiveContent type={CONTENT_SIZE_TYPE.MOBILE}>
        {orderProject.status === 'in_progress' ? null : orderProject.status ===
          'not_answered' ? (
          <div className="order-page-customer__tenders">
            <span>
              {selectedSuppCount === 3
                ? intl.formatMessage({
                    id: `page.orderDetail.listTitle`,
                    defaultMessage: 'Selected suppliers',
                  })
                : `${
                    orderProject.tenderTotal !== undefined
                      ? orderProject.tenderTotal
                      : ''
                  } ${intl.formatMessage({
                    id: `page.orderDetail.tenderRec`,
                    defaultMessage: 'tenders received',
                  })}`}
            </span>
            <OrderTitleMsgBar
              selectedSuppCount={selectedSuppCount}
              time={getTimer(orderProject.expiredAt)}
            />
          </div>
        ) : (
          <div className="order-page-customer__tenders">
            <span>
              {selectedSuppCount === 3
                ? intl.formatMessage({
                    id: `page.orderDetail.listTitle`,
                    defaultMessage: 'Selected suppliers',
                  })
                : `${
                    orderProject.tenderTotal !== undefined
                      ? orderProject.tenderTotal
                      : ''
                  } ${intl.formatMessage({
                    id: `page.orderDetail.tenderRec`,
                    defaultMessage: 'tenders received',
                  })}`}
            </span>
          </div>
        )}
      </AdaptiveContent>

      <div className="order-page-customer__info">
        <OrderInfoBar
          isProjectLoading={isProjectLoading}
          isErrorProject={isErrorProject}
          orderProject={orderProject}
          isListLoading={isListLoading}
          setCancelOrderLoad={setCancelOrderLoad}
        />
      </div>

      <AdaptiveContent type={CONTENT_SIZE_TYPE.MOBILE}>
        <div>
          <Title
            infoContent={
              <div>
                <div className="order-page-customer__content-viewid">
                  {orderViewIdFormat(orderProject.viewId)}
                </div>
                <div className="order-page-customer__content-project">
                  {orderProject.project?.name}
                </div>
                <div className="status-container">
                  {' '}
                  <Status type={orderProject && orderProject.status} />
                  {orderProject &&
                    (orderProject.edited || orderProject.inEditing) && (
                      <span className="edited-label">
                        <FormattedMessage
                          id={`status.${
                            !orderProject.inEditing && orderProject.edited
                              ? 'edited'
                              : 'editing'
                          }`}
                          defaultMessage={
                            !orderProject.inEditing && orderProject.edited
                              ? 'Edited'
                              : 'Editing'
                          }
                        />
                      </span>
                    )}
                </div>
              </div>
            }
            subTitle={'Order'}
            actions={
              <div className="order-page-customer__info-actions">
                {!orderProject ? null : orderProject.status !==
                    'not_answered' &&
                  orderProject.status !== 'in_progress' ? null : (
                  <Actions
                    actions={
                      orderProject.offers && !orderProject.offers.length
                        ? [
                            {
                              text: intl.formatMessage({
                                id: `actions.edit`,
                                defaultMessage: 'Edit',
                              }),
                              icon: 'edit',
                              action: () =>
                                navigate(
                                  `/customer/my-orders/order/${orderProject.id}/edit`
                                ),
                            },
                            {
                              text: intl.formatMessage({
                                id: `actions.cancel`,
                                defaultMessage: 'Cancel',
                              }),
                              icon: 'close',
                              action: () => setOpen(true),
                            },
                          ]
                        : [
                            {
                              text: intl.formatMessage({
                                id: `actions.cancel`,
                                defaultMessage: 'Cancel',
                              }),
                              icon: 'close',
                              action: () => setOpen(true),
                            },
                          ]
                    }
                  />
                )}
              </div>
            }
          >
            <FormattedMessage
              id="order.mainName_2"
              defaultMessage="Concrete casting"
            />
          </Title>
        </div>
      </AdaptiveContent>
      <ConfirmPopup
        open={open}
        text={intl.formatMessage({
          id: `popup.order.cancelOrder`,
          defaultMessage: `Are you sure that you want to cancel the order?`,
        })}
        confirmButtonText={intl.formatMessage({
          id: `popup.cancel.ok`,
          defaultMessage: 'Yes, cancel',
        })}
        cancelButtonText={intl.formatMessage({
          id: `popup.cancel.no`,
          defaultMessage: 'No',
        })}
        isLoading={false}
        onSubmit={onSubmit}
        onCloseModal={() => setOpen(false)}
      />
    </div>
  );
};

OrderDetailCustomer.defaultProps = {};

OrderDetailCustomer.propTypes = {};

export default OrderDetailCustomer;
