import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// import Button from '../../components/ui/Button';
// import { BUTTON_SIZE, BUTTON_TYPE } from '../../components/ui/Button/Button';
import Icon from '../../../components/ui/Icon';
import LoadedContent from '../../../components/LoadedContent';
import OrderCard from '../../../components/OrderCard/OrderCard';
import { useMediaQuery } from 'react-responsive';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import AboutCard from '../../../components/AboutCard/AboutCard';
import Pagination from '../../../components/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { orders } from '../../../store/slice/ordersSlice';
import { projectDetail } from '../../../store/slice/projectSlice';
import Accordion from '../../../components/Accordion';
import { AlertMessengerContext } from '../../../provider/Alert';
import { getCompany } from '../../../api/company';
import { FormattedMessage, useIntl } from 'react-intl';
import AdaptiveContent from '../../../components/AdaptiveContent';
import { CONTENT_SIZE_TYPE } from '../../../components/AdaptiveContent/AdaptiveContent';
import Title from '../../../components/ui/Title';

const ProjectDetailPage = () => {
  const [aboutCardData, setAboutCardData] = useState();
  const [companyInfo, setCompanyInfo] = useState({});

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const pageQuery = searchParams.get('page') || 1;

  const isListLoading = useSelector((state) => state.orders.isLoading);
  const orderList = useSelector((state) => state.orders.data);
  const orderMeta = useSelector((state) => state.orders.meta);
  const projectInfo = useSelector((state) => state.project.data);
  // eslint-disable-next-line no-unused-vars
  const projectLoading = useSelector((state) => state.project.isListLoading);
  const intl = useIntl();

  const isMobileOrTablet = useMediaQuery({
    query: '(max-width: 900px) ',
  });

  const { addErrorMessage } = useContext(AlertMessengerContext);

  const fetchList = (companyId, projectId, page, companyType) => {
    dispatch(
      orders({
        companyId,
        data: {
          page,
          projectId,
          per_page: 10,
        },
        companyType: companyType,
      })
    )
      .unwrap()
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  const getCompanyAsync = async (id) => {
    try {
      const res = await getCompany(id);

      if (res && res.data && res.data.data) {
        setCompanyInfo(res.data.data);
        fetchList(
          params.id_company,
          params.id_project,
          pageQuery,
          res.data.data.type
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchProjectInfo = (companyId, projectId) => {
    dispatch(
      projectDetail({
        companyId,
        projectId,
      })
    )
      .unwrap()
      .then((res) => {})
      .catch((err) => {
        addErrorMessage({
          message: err.message || 'Server error',
        });
      });
  };

  const localeMsg = (key, msg) =>
    intl.formatMessage({
      id: `company.${key}`,
      defaultMessage: msg,
    });

  useEffect(() => {
    document.title = `Project ${
      projectInfo && projectInfo.length ? projectInfo[0].name : ''
    }`;

    if (projectInfo && projectInfo.length) {
      setAboutCardData([
        {
          title: localeMsg('marking', 'Marking'),
          value: projectInfo[0].marking,
        },
        {
          title: localeMsg('address', 'Adress'),
          value: projectInfo[0].address,
        },
        {
          title: localeMsg('zip', 'Zip Code'),
          value: projectInfo[0].zipCode,
        },
        {
          title: localeMsg('city', 'City'),
          value: projectInfo[0].city,
        },
        {
          title: localeMsg('contactPerson', 'Contact Person'),
          value: projectInfo[0].contactPerson,
        },
        {
          title: localeMsg('phone', 'Phone'),
          value: projectInfo[0].phone,
        },
        {
          title: localeMsg('mail', 'Email'),
          value: projectInfo[0].email,
        },
      ]);
    }
  }, [projectInfo]);

  useEffect(() => {
    if (!projectInfo || !companyInfo.id) {
      if (params && params.id_company && params.id_project) {
        fetchProjectInfo(params.id_company, params.id_project);
        getCompanyAsync(params.id_company);
      }
    } else if (
      params &&
      params.id_company &&
      params.id_project &&
      companyInfo
    ) {
      fetchList(
        params.id_company,
        params.id_project,
        pageQuery,
        companyInfo.type
      );
    }
  }, [pageQuery]);

  return (
    <div className="project-detail-page">
      <div className="company-page">
        <div className="company-page__inner">
          <AdaptiveContent type={CONTENT_SIZE_TYPE.DESCTOP}>
            <div className="company-page__header">
              <div className="company-page__header-type">
                <div
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <Icon icon="arrow-left" size="20px" />
                </div>
                <span>
                  {companyInfo && companyInfo.name ? companyInfo.name : '...'}
                </span>
              </div>
              <span className="company-page__header-title">
                {projectInfo && projectInfo.length
                  ? projectInfo[0].name
                  : '...'}
              </span>
            </div>
          </AdaptiveContent>

          <AdaptiveContent type={CONTENT_SIZE_TYPE.MOBILE}>
            <Title
              subTitle={
                companyInfo && companyInfo.name ? companyInfo.name : '...'
              }
            >
              {projectInfo && projectInfo.length ? projectInfo[0].name : '...'}
            </Title>
          </AdaptiveContent>

          <div className="company-page__content">
            {isMobileOrTablet && (
              <div className="company-page__desc">
                <AboutCard title="About Project" data={aboutCardData} />
              </div>
            )}
            <div className="company-page__orders">
              <LoadedContent loader={isListLoading} />

              {isMobileOrTablet && !isListLoading && (
                <Accordion
                  title={intl.formatMessage(
                    {
                      id: 'page.projectDetail.contains',
                      defaultMessage: `Contains ${
                        orderMeta ? orderMeta.total : ''
                      } orders`,
                    },
                    {
                      total: orderMeta ? orderMeta.total : '',
                    }
                  )}
                >
                  {orderList.map((item, index) => (
                    <OrderCard
                      key={index}
                      data={item}
                      path={`orders/${item.id}/order`}
                    />
                  ))}
                  {orderList.length ? (
                    <div className="company-page__pagination">
                      <Pagination
                        currentPage={orderMeta.current_page}
                        totalCount={orderMeta.last_page}
                        onPageChange={() => {}}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                  {!orderList.length && (
                    <div className="empty-list">
                      <div className="empty-list__icon">
                        <Icon icon="orders-big" size="52px" />
                      </div>
                      <span className="empty-list__title">
                        <FormattedMessage
                          id="page.projectDetail.noMsg"
                          defaultMessage="There is no orders"
                        />
                      </span>
                    </div>
                  )}
                </Accordion>
              )}
              {!isMobileOrTablet && !isListLoading && (
                <>
                  {orderList.map((item, index) => (
                    <OrderCard
                      key={index}
                      data={item}
                      path={`orders/${item.id}/order`}
                    />
                  ))}
                  {orderList.length ? (
                    <div className="company-page__pagination">
                      <Pagination
                        currentPage={orderMeta.current_page}
                        totalCount={orderMeta.last_page}
                        onPageChange={() => {}}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                  {!orderList.length && (
                    <div className="empty-list">
                      <div className="empty-list__icon">
                        <Icon icon="orders-big" size="52px" />
                      </div>
                      <span className="empty-list__title">
                        <FormattedMessage
                          id="page.sa.projectDetail.noMsg"
                          defaultMessage="There is no orders"
                        />
                      </span>
                    </div>
                  )}
                </>
              )}
            </div>
            {!isMobileOrTablet && (
              <div className="company-page__desc">
                <AboutCard title="About Project" data={aboutCardData} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

ProjectDetailPage.propTypes = {
  props: PropTypes.object,
};

export default ProjectDetailPage;
