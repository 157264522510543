export const orderViewIdFormat = (id) => {
  if (!id && id !== 0) {
    return '';
  }

  const numberCount = Math.trunc(Math.log10(id) + 1);

  return numberCount >= 4
    ? `#${id}`
    : numberCount === 3
    ? `#0${id}`
    : numberCount === 2
    ? `#00${id}`
    : `#000${id}`;
};
