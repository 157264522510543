/* eslint-disable prefer-regex-literals */
/* eslint-disable no-unused-vars */
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

/* eslint-disable no-unused-vars */
import { useNavigate } from 'react-router-dom';
import Icon from '../../../../../components/ui/Icon';
import LoadedContent from '../../../../../components/LoadedContent';
import moment from 'moment';
import InfoAccordion from './InfoAccordion';
import AdaptiveContent, {
  CONTENT_SIZE_TYPE,
} from '../../../../../components/AdaptiveContent/AdaptiveContent';
import Actions from '../../../../../components/ui/Actions';
import ConfirmPopup from '../../../../../components/ConfirmPopup/ConfirmPopup';
import { numberFormat } from '../../../../../utils/number.format';
import { cancelOrder } from '../../../../../api/order';
import { AlertMessengerContext } from '../../../../../provider/Alert';
import { FormattedMessage, useIntl } from 'react-intl';

const OrderInfoBar = ({
  isProjectLoading,
  isErrorProject,
  orderProject,
  isListLoading,
  setCancelOrderLoad,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { addSuccessMessage, addErrorMessage } = useContext(
    AlertMessengerContext
  );

  const onSubmit = () => {
    setCancelOrderLoad(true);
    setOpen(false);

    cancelOrder(orderProject.id)
      .then(() => {
        setCancelOrderLoad(false);
        addSuccessMessage({
          message: intl.formatMessage({
            id: `page.orders.cancelOrderMsg`,
            defaultMessage: 'You have successfully canceled your order!',
          }),
        });
        navigate('/customer/my-orders?status=archive&page=1');
      })
      .catch(({ response }) => {
        setCancelOrderLoad(false);
        addErrorMessage({
          message: response && response.data.message,
        });
      });
  };

  return (
    <>
      {!isProjectLoading && isErrorProject ? (
        <div className="empty-list">
          <div className="empty-list__icon">
            <Icon icon="alert" size="32px" />
          </div>
          <span className="empty-list__title">
            <FormattedMessage
              id="page.orderDetail.noDetailMsg"
              defaultMessage="You have no detail information"
            />
          </span>
        </div>
      ) : (
        <div className="relative">
          <LoadedContent
            loader={isProjectLoading && !isListLoading && orderProject.status}
          />
          <div className="order-page-customer__info-title">
            <div className="title">
              <FormattedMessage
                id="page.orderDetail.infoTitle"
                defaultMessage="Detailed information"
              />{' '}
              {!orderProject ? null : orderProject.status !== 'not_answered' &&
                orderProject.status !== 'in_progress' ? null : (
                <AdaptiveContent type={CONTENT_SIZE_TYPE.DESCTOP}>
                  <div className="order-page-customer__info-actions">
                    <Actions
                      actions={
                        orderProject.offers && !orderProject.offers.length
                          ? [
                              {
                                text: intl.formatMessage({
                                  id: `actions.edit`,
                                  defaultMessage: 'Edit',
                                }),
                                icon: 'edit',
                                action: () =>
                                  navigate(
                                    `/customer/my-orders/order/${orderProject.id}/edit`
                                  ),
                              },
                              {
                                text: intl.formatMessage({
                                  id: `actions.cancel`,
                                  defaultMessage: 'Cancel',
                                }),
                                icon: 'close',
                                action: () => setOpen(true),
                              },
                            ]
                          : [
                              {
                                text: intl.formatMessage({
                                  id: `actions.cancel`,
                                  defaultMessage: 'Cancel',
                                }),
                                icon: 'close',
                                action: () => setOpen(true),
                              },
                            ]
                      }
                    />
                  </div>
                </AdaptiveContent>
              )}
            </div>
            <div className="row mb-8">
              <div className="row-title">
                <div className="icon_cyrcle">
                  <Icon icon="calendar" stroke={'#4446B0'} size="20px" />
                </div>
                <FormattedMessage
                  id="order.dateOfExecution"
                  defaultMessage="Date of execution"
                />
              </div>
              <div className="bold">
                {moment(orderProject.dateOfExecution).format('yyyy.MM.DD')}
              </div>
            </div>

            <div className="row mb-16">
              <div className="row-title">
                <div className="icon_cyrcle">
                  <Icon icon="time" stroke={'#4446B0'} size="20px" />
                </div>
                <FormattedMessage
                  id="order.timeOfStart"
                  defaultMessage="Start time"
                />
              </div>
              <div className="bold">
                {orderProject.timeOfStart &&
                  moment(orderProject.timeOfStart, 'hh:mm:ss').format('HH:mm')}
              </div>
            </div>

            <div className="row">
              <div className="row-title">
                <FormattedMessage id="company.city" defaultMessage="City" />
              </div>
              <div className="row-description">
                {orderProject.project?.city}
              </div>
            </div>
            <div className="row">
              <div className="row-title">
                <FormattedMessage id="order.area" defaultMessage="Area, m²" />
              </div>
              <div className="row-description">
                {numberFormat(orderProject.area)}
              </div>
            </div>
            <div className="row">
              <div className="row-title">
                <FormattedMessage
                  id="order.volume"
                  defaultMessage="Volume, m³"
                />
              </div>
              <div className="row-description">
                {numberFormat(orderProject.volume)}
              </div>
            </div>
          </div>

          <AdaptiveContent type={CONTENT_SIZE_TYPE.MOBILE}>
            <div
              onClick={() => {
                navigate('detail-information');
              }}
              className="order-page-customer__info-detail"
            >
              <FormattedMessage
                id="page.orderDetail.infoTitle"
                defaultMessage="Detailed information"
              />{' '}
              &#62;
            </div>
          </AdaptiveContent>

          <InfoAccordion orderProject={orderProject} />
        </div>
      )}
      <ConfirmPopup
        open={open}
        text={intl.formatMessage({
          id: `popup.order.cancelOrder`,
          defaultMessage: `Are you sure that you want to cancel the order?`,
        })}
        confirmButtonText={intl.formatMessage({
          id: `popup.cancel.ok`,
          defaultMessage: 'Yes, cancel',
        })}
        cancelButtonText={intl.formatMessage({
          id: `popup.cancel.no`,
          defaultMessage: 'No',
        })}
        isLoading={false}
        onSubmit={onSubmit}
        onCloseModal={() => setOpen(false)}
      />
    </>
  );
};

OrderInfoBar.defaultProps = {};

OrderInfoBar.propTypes = {
  isProjectLoading: PropTypes.bool.isRequired,
  isListLoading: PropTypes.bool.isRequired,
  isErrorProject: PropTypes.bool.isRequired,
  orderProject: PropTypes.object.isRequired,
  setCancelOrderLoad: PropTypes.func.isRequired,
};

export default OrderInfoBar;
