import React from 'react';
import PropTypes from 'prop-types';
// import Actions from '../ui/Actions';
// import ConfirmDeletePopup from '../ConfirmDeletePopup';
// import { useDispatch } from 'react-redux';
// import { deleteproject } from '../../store/slice/projectSlice';
// import { useNavigate, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { FormattedMessage } from 'react-intl';

const projectItem = ({ data, url }) => {
  const { name, type, marking, address, id, city, numberOfOrders } = data;
  const navigate = useNavigate();
  // const queryParams = useParams();

  const isMobileOrTablet = useMediaQuery({
    query: '(max-width: 900px) ',
  });

  return (
    <div id={id} className="project-item">
      <div
        onClick={() => {
          if (isMobileOrTablet) {
            navigate(url || `${data.id}`);
          }
        }}
        className="project-item__inner"
      >
        <div
          onClick={() => {
            navigate(url || `${data.id}`);
          }}
          className="project-item__name"
        >
          <span className="project-item__name-project">{name}</span>
          <span className="project-item__name-role">{type}</span>
        </div>

        <div className="project-item__info">
          <div className="project-item__info-block">
            <span className="project-item__info-title">
              <FormattedMessage
                id="component.projectCard.ordersNum"
                defaultMessage="Number of orders"
              />
            </span>
            <span className="project-item__info-desc">{numberOfOrders}</span>
          </div>
          <div className="project-item__info-block">
            <span className="project-item__info-title">
              <FormattedMessage id="company.address" defaultMessage="Address" />
            </span>
            <span className="project-item__info-desc">{address}</span>
          </div>
          <div className="project-item__info-block">
            <span className="project-item__info-title">
              <FormattedMessage
                id="component.projectCard.marking"
                defaultMessage="Marking"
              />
            </span>
            <span className="project-item__info-desc">{marking}</span>
          </div>
          <div className="project-item__info-block">
            <span className="project-item__info-title">
              <FormattedMessage id="company.city" defaultMessage="City" />
            </span>
            <span className="project-item__info-desc">{city}</span>
          </div>
          {/* 
          <div className="project-item__actions">
            <Actions
              actions={[
                {
                  text: 'Edit',
                  icon: 'edit',
                  action: () => navigate(`/all-companies/edit-project/${id}`),
                },
                {
                  text: 'Delete',
                  icon: 'trash',
                  // action: () => setOpen(true),
                },
              ]}
            />
          </div> */}
        </div>
      </div>
    </div>
  );
};

projectItem.defaultProps = {
  onSuccess: () => {},
  url: '',
};

projectItem.propTypes = {
  data: PropTypes.object.isRequired,
  onSuccess: PropTypes.func,
  url: PropTypes.string,
};

export default projectItem;
