import API from '../api';

export const notificationsReq = (page) =>
  API.get('/api/api/v1/users/notifications', {
    params: {
      page,
    },
  });

export const notificationsUnreadReq = () =>
  API.get('/api/api/v1/users/notifications/not-read');

export const deleteNotificationReq = (id) =>
  API.delete(`/api/api/v1/users/notifications/${id}`);

export const deleteNotificationsReq = () =>
  API.delete('/api/api/v1/users/notifications');

export const notificationMarkAsReadReq = (id) =>
  API.patch(`/api/api/v1/users/notifications/${id}`);
