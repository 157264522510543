import React, { useEffect, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import FormInput from '../../../../components/ui/FormInput/FormInput';
import Button, {
  BUTTON_SIZE,
  BUTTON_TYPE,
} from '../../../../components/ui/Button/Button';
import { useSelector } from 'react-redux';
import LoadedContent from '../../../../components/LoadedContent';
import { AlertMessengerContext } from '../../../../provider/Alert';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Title from '../../../../components/ui/Title';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import Icon from '../../../../components/ui/Icon';
import { inviteSuperAdmin } from '../../../../api/users';
import FormInputMask from '../../../../components/ui/FormInputMask';
import ConfirmPopup from '../../../../components/ConfirmPopup/ConfirmPopup';
import { FormattedMessage, useIntl } from 'react-intl';

const phoneRegExp = /(^[+]46\d{6,11}$)|(^[+]46\s\d{6,11}$)/;

const User = () => {
  const personalData = useSelector((state) => state.personalInfo);
  const queryParams = useParams();
  const intl = useIntl();

  const [cancelPopupOpen, setCancelPopupOpen] = useState(false);

  const { addErrorMessage, addSuccessMessage } = useContext(
    AlertMessengerContext
  );

  const schema = yup.object().shape({
    phone: yup
      .string()
      .required(
        intl.formatMessage({
          id: 'input.error.requiredMsg',
          defaultMessage: 'Field is required',
        })
      )
      .matches(
        phoneRegExp,
        intl.formatMessage({
          id: 'input.error.incorrectPhone',
          defaultMessage: 'Phone number is not valid',
        })
      ),
    email: yup
      .string()
      .email(
        intl.formatMessage({
          id: 'input.error.incorrectMail',
          defaultMessage: 'Incorrect E-mail address',
        })
      )
      .required(
        intl.formatMessage({
          id: 'input.error.requiredMsg',
          defaultMessage: 'Field is required',
        })
      ),
    firstname: yup
      .string()
      .required(
        intl.formatMessage({
          id: 'input.error.requiredMsg',
          defaultMessage: 'Field is required',
        })
      )
      .min(
        3,
        intl.formatMessage({
          id: 'input.error.incorrectFName',
          defaultMessage: 'First Name must be at least 3 characters',
        })
      ),
    lastname: yup
      .string()
      .required(
        intl.formatMessage({
          id: 'input.error.requiredMsg',
          defaultMessage: 'Field is required',
        })
      )
      .min(
        3,
        intl.formatMessage({
          id: 'input.error.incorrectLName',
          defaultMessage: 'Last Name must be at least 3 characters',
        })
      ),
  });

  const { control, handleSubmit, formState, ...formControl } = useForm({
    reValidateMode: 'onChange',
    defaultValues: {
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
    },
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();

  const { errors } = formState;
  const [isLoading, setLoading] = useState(false);

  const onSubmit = (data) => {
    setLoading(true);
    inviteSuperAdmin({
      ...data,
      phone: data.phone.replace(/[()_\s-]/g, ''),
    })
      .then(() => {
        formControl.setValue('firstname', '');
        formControl.setValue('lastname', '');
        formControl.setValue('phone', '');
        formControl.setValue('email', '');
        addSuccessMessage({
          message: intl.formatMessage({
            id: 'page.sa.users.inviteSuccessMsg',
            defaultMessage: 'You invited a user!',
          }),
        });
        navigate(-1);
      })
      .catch((error) => {
        if (error.response.data?.errors) {
          Object.keys(error.response.data?.errors).forEach((item) => {
            formControl.setError(item, {
              type: 'custom',
              message: error.response.data?.errors[item][0],
            });
          });
        } else if (error.response.data?.message) {
          addErrorMessage({
            message: error.response.data?.message,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (searchParams.get('fn')) {
      document.title = intl.formatMessage({
        id: 'page.sa.addUserCard.title_ad',
        defaultMessage: 'Super Admin',
      });
    } else {
      document.title = intl.formatMessage({
        id: 'page.sa.addUserCard.title_sa',
        defaultMessage: 'Add New Super Admin',
      });
    }

    if (searchParams.get('fn')) {
      formControl.setValue('firstname', searchParams.get('fn'));
      formControl.setValue('lastname', searchParams.get('ln'));
      formControl.setValue('phone', searchParams.get('ph')?.slice(2));
      formControl.setValue(
        'email',
        window.location.search.split('em=')?.[1] || searchParams.get('em')
      );
    }
  }, []);

  const [searchParams] = useSearchParams();

  return (
    <>
      <div className="account-user__content-header">
        <Title
          subTitle={intl.formatMessage({
            id: 'page.sa.myAcc.title',
            defaultMessage: 'My Account',
          })}
          navBackHandler={() => {
            if (searchParams.get('fn')) {
              navigate(-1);
            } else {
              setCancelPopupOpen(true);
            }
          }}
        >
          {searchParams.get('fn')
            ? intl.formatMessage({
                id: 'role.sa',
                defaultMessage: 'Super Admin',
              })
            : intl.formatMessage({
                id: 'page.sa.addUserCard.title_sa',
                defaultMessage: 'Add New Super Admin',
              })}
        </Title>
        {!searchParams.get('fn') && (
          <div className="account-user__content-header_content">
            <div>
              <Icon icon="alert" size={'20px'} />
              <span>
                <FormattedMessage
                  id="page.sa.addUserCard.subTitle_sa"
                  defaultMessage="Super admin has access to the entire system and can create other
                users and company"
                />
              </span>
            </div>
          </div>
        )}
      </div>
      <div className="account-user">
        <LoadedContent loader={isLoading} />
        <form
          className="account-user__form-inner"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="account-user__form">
            <FormInput
              control={control}
              label={intl.formatMessage({
                id: 'input.label.fname*',
                defaultMessage: 'First Name*',
              })}
              name="firstname"
              id="firstname"
              type="firstname"
              errors={errors.firstname}
              disabled={
                queryParams.id_user ||
                personalData.isError ||
                personalData.isLoading ||
                isLoading
              }
              placeholder={intl.formatMessage({
                id: 'input.placeholder.fname',
                defaultMessage: 'Enter First Name',
              })}
            />
            <FormInput
              control={control}
              label={intl.formatMessage({
                id: 'input.label.lname*',
                defaultMessage: 'Last Name*',
              })}
              name="lastname"
              id="lastname"
              type="lastname"
              errors={errors.lastname}
              disabled={
                queryParams.id_user ||
                personalData.isError ||
                personalData.isLoading ||
                isLoading
              }
              placeholder={intl.formatMessage({
                id: 'input.placeholder.lname',
                defaultMessage: 'Enter Last Name*',
              })}
            />
            <FormInputMask
              control={control}
              label={intl.formatMessage({
                id: 'input.label.phone*',
                defaultMessage: 'Phone*',
              })}
              name="phone"
              disabled={
                queryParams.id_user ||
                personalData.isError ||
                personalData.isLoading ||
                isLoading
              }
              id="phone"
              type="phone"
              errors={errors.phone}
              // placeholder="+46"
              maskChar=""
              mask="+46 99999999999"
              placeholder="+46 "
            />
            <FormInput
              control={control}
              label={intl.formatMessage({
                id: 'input.label.mail*',
                defaultMessage: 'E-mail*',
              })}
              name="email"
              id="email"
              type="email"
              errors={errors.email}
              disabled={
                queryParams.id_user ||
                personalData.isError ||
                personalData.isLoading ||
                isLoading
              }
              placeholder={intl.formatMessage({
                id: 'input.placeholder.mail',
                defaultMessage: 'Enter Email',
              })}
            />
          </div>
          {!queryParams.id_user && (
            <div className="filed-add-user">
              <FormattedMessage
                id="page.sa.users.inviteMailMsg"
                defaultMessage={`We will send an email to this address so that the super admin can create
              a password`}
              />
            </div>
          )}
          {!queryParams.id_user && (
            <div className="account-user__form-btn">
              <Button
                text={intl.formatMessage({
                  id: 'btn.cancel',
                  defaultMessage: 'Cancel',
                })}
                type={BUTTON_TYPE.OUTLINE}
                size={BUTTON_SIZE.LARGE}
                onClick={(e) => {
                  e.preventDefault();
                  e.target.blur();
                  setCancelPopupOpen(true);
                }}
                disabled={isLoading}
              />
              <Button
                disabled={
                  personalData.isError || personalData.isLoading || isLoading
                }
                text={intl.formatMessage({
                  id: 'btn.create',
                  defaultMessage: 'Create',
                })}
                size={BUTTON_SIZE.LARGE}
              />
            </div>
          )}
        </form>
      </div>
      <ConfirmPopup
        open={cancelPopupOpen}
        text={intl.formatMessage({
          id: 'popup.cancelCreating.text',
          defaultMessage: `Are you sure that you want to cancel ${'creating'}?`,
        })}
        isLoading={false}
        confirmButtonText={intl.formatMessage({
          id: 'popup.cancel.ok',
          defaultMessage: 'Yes, cancel',
        })}
        cancelButtonText={intl.formatMessage({
          id: 'popup.cancel.no',
          defaultMessage: 'No, continue',
        })}
        onSubmit={() => {
          navigate(-1);
        }}
        onCloseModal={() => setCancelPopupOpen(false)}
      />
    </>
  );
};

User.defaultProps = {};

User.propTypes = {};

export default User;
