/* eslint-disable no-unused-expressions */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  supplierOrdersSaNew,
  supplierOrdersSaDecline,
  supplierOrdersSAWait,
  supplierOrdersSaCurrent,
  supplierOrdersSaArchive,
} from '../../api/order';
import {
  orderMapperSA,
  orderSupplierArchiveMapper,
} from '../../utils/order-mapper';

export const ordersSupplierSaNew = createAsyncThunk(
  'ordersSupplierSa/ordersSupplierSaNew',
  async (creds, { rejectWithValue }) => {
    console.log(creds);
    try {
      const response = await supplierOrdersSaNew(creds.companyId, creds.data);
      if (response.status === 200) {
        response.data.data = response.data.data.map((item) => {
          if (item.status === 'new') {
            return {
              ...item,
              status: 'new_order',
            };
          } else {
            return item;
          }
        });
        console.log(response.data.data);
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (error) {
      console.log('sliceError', error.response);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const ordersSupplierSaDecline = createAsyncThunk(
  'ordersSupplierSa/ordersSupplierSaDecline',
  async (creds, { rejectWithValue }) => {
    try {
      const response = await supplierOrdersSaDecline(
        creds.companyId,
        creds.data
      );
      if (response.status === 200) {
        response.data.data = response.data.data.map((item) => {
          if (item.status === 'new') {
            return {
              ...item,
              status: 'new_order',
            };
          } else {
            return item;
          }
        });
        console.log(response.data.data);
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (error) {
      console.log('sliceError', error.response);
      console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const ordersSupplierSaWait = createAsyncThunk(
  'ordersSupplierSa/ordersSupplierSaWait',
  async (creds, { rejectWithValue }) => {
    try {
      const response = await supplierOrdersSAWait(creds.companyId, creds.data);
      if (response.status === 200) {
        response.data.data = response.data.data.map((item) => {
          if (item.status === 'new') {
            return {
              ...item,
              status: 'waiting_for_answer',
            };
          } else {
            return item;
          }
        });

        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (error) {
      console.log('sliceError', error.response);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const ordersSupplierSaCurrent = createAsyncThunk(
  'ordersSupplierSa/ordersSupplierSaCurrent',
  async (creds, { rejectWithValue }) => {
    try {
      const response = await supplierOrdersSaCurrent(
        creds.companyId,
        creds.data
      );
      if (response.status === 200) {
        console.log(response.data.data);
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (error) {
      console.log('sliceError', error.response);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const ordersSupplierSaArchive = createAsyncThunk(
  'ordersSupplierSa/ordersSupplierSaArchive',
  async (creds, { rejectWithValue }) => {
    try {
      const response = await supplierOrdersSaArchive(
        creds.companyId,
        creds.data
      );
      if (response.status === 200) {
        console.log(response.data.data);
        return orderSupplierArchiveMapper(response.data);
      } else {
        return rejectWithValue(response.data);
      }
    } catch (error) {
      console.log('sliceError', error.response);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export function startLoading(text) {
  return {
    type: 'ADD_LOADING',
  };
}

export function changeordersSuppliertatus(status) {
  return {
    type: 'CHANDE_ORDER_STATUS',
    status: status,
  };
}

const initialState = {
  isLoading: false,
  isError: false,
  data: [],
  mappedData: [],
  meta: null,

  isLoadingDecline: false,
  isErrorDecline: false,
  dataDecline: [],
  metaDecline: null,
};

const ordersSupplierSaSlice = createSlice({
  name: 'ordersSupplierSa',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(ordersSupplierSaNew.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
    });
    builder.addCase(ordersSupplierSaNew.fulfilled, (state, payload) => {
      state.isLoading = false;
      state.isError = false;
      state.data = payload.payload.data;
      state.meta = payload.payload.meta;
    });
    builder.addCase(ordersSupplierSaNew.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    builder.addCase(ordersSupplierSaDecline.pending, (state) => {
      state.isLoadingDecline = true;
      state.isErrorDecline = false;
    });
    builder.addCase(ordersSupplierSaDecline.fulfilled, (state, payload) => {
      state.isLoadingDecline = false;
      state.isErrorDecline = false;
      state.dataDecline = orderMapperSA(payload.payload.data);
      state.metaDecline = payload.payload.meta;
    });
    builder.addCase(ordersSupplierSaDecline.rejected, (state) => {
      state.isLoadingDecline = false;
      state.isErroDecliner = true;
    });

    builder.addCase(ordersSupplierSaWait.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
    });
    builder.addCase(ordersSupplierSaWait.fulfilled, (state, payload) => {
      state.isLoading = false;
      state.isError = false;
      state.data = orderMapperSA(payload.payload.data);
      state.meta = payload.payload.meta;
    });
    builder.addCase(ordersSupplierSaWait.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    builder.addCase(ordersSupplierSaCurrent.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
    });
    builder.addCase(ordersSupplierSaCurrent.fulfilled, (state, payload) => {
      state.isLoading = false;
      state.isError = false;
      state.data = payload.payload.data;
      state.meta = payload.payload.meta;
    });
    builder.addCase(ordersSupplierSaCurrent.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    builder.addCase(ordersSupplierSaArchive.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
    });
    builder.addCase(ordersSupplierSaArchive.fulfilled, (state, payload) => {
      state.isLoading = false;
      state.isError = false;
      state.data = orderMapperSA(payload.payload.data);
      state.meta = payload.payload.meta;
    });
    builder.addCase(ordersSupplierSaArchive.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    builder.addCase('ADD_LOADING', (state, action) => {
      console.log('/');
      state.data = [];
      state.dataDecline = [];
      state.isLoading = true;
      state.isLoadingDecline = true;
    });
  },
});

const { reducer } = ordersSupplierSaSlice;

export const selectOrder = (state) => state.order;

export default reducer;
