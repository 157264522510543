import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { orderCustomer, orderGetInfo } from '../../api/order';
import { orderObjectMapperSA } from '../../utils/order-mapper';

export const getMyOrder = createAsyncThunk(
  'order/getOrder',
  async (creds, { rejectWithValue }) => {
    try {
      const response = await orderCustomer(creds.id);
      if (response.status === 200) {
        // return response.data;
        return orderObjectMapperSA(response.data.data);
      } else {
        return rejectWithValue(response.data);
      }
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getMyOrderOffer = createAsyncThunk(
  'order/getOrderOffer',
  async (creds, { rejectWithValue }) => {
    try {
      const response = await orderGetInfo(creds.id, creds.query);
      if (response.status === 200) {
        // return response.data;
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const initialState = {
  isListLoading: false,
  isProjectLoading: false,
  isLoadingEdit: false,
  isErrorProject: false,
  dataProject: {
    project: {},
  },
  data: [],
  meta: {},
};

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setClearOrder(state) {
      state = {
        isListLoading: false,
        isProjectLoading: false,
        isLoadingEdit: false,
        isErrorProject: false,
        dataProject: {
          project: {},
        },
        data: [],
        meta: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMyOrder.pending, (state) => {
      state.isProjectLoading = true;
      state.isErrorProject = false;
      state.dataProject = {};
      state.data = [];
    });
    builder.addCase(getMyOrder.rejected, (state) => {
      state.isProjectLoading = false;
      state.isErrorProject = true;
      state.dataProject = {};
    });
    builder.addCase(getMyOrder.fulfilled, (state, payload) => {
      state.isProjectLoading = false;
      state.isErrorProject = false;
      state.dataProject = payload.payload;
    });

    builder.addCase(getMyOrderOffer.pending, (state) => {
      state.isListLoading = true;
      state.isError = false;
      state.data = [];
    });
    builder.addCase(getMyOrderOffer.rejected, (state) => {
      state.isListLoading = false;
      state.isError = true;
      state.data = [];
    });
    builder.addCase(getMyOrderOffer.fulfilled, (state, payload) => {
      state.isListLoading = false;
      state.isError = false;
      state.data = payload.payload.data;
      state.meta = payload.payload.meta;
    });
  },
});

const { reducer } = orderSlice;

export const selectProject = (state) => state.order;
export const { setClearOrder } = orderSlice.actions;

export default reducer;
