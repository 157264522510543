import React, { useEffect, useState } from 'react';
import Icon from '../ui/Icon';
import useScrollBlock from '../../utils/useScrollBlock';
import PropTypes from 'prop-types';
import moment from 'moment';
import LoadedContent from '../LoadedContent';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteNotifications,
  deleteNotification,
  getNotifications,
  notificationMarkAsRead,
  markAsReadLocal,
  clearList,
  getNotificationsUnread,
} from '../../store/slice/notificationSlice';
import NotificationText from './NotificationText';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FormattedMessage } from 'react-intl';

const NotificationPopup = ({ onClose }) => {
  const [blockScroll, allowScroll] = useScrollBlock();
  const [allowAnimation, setAllowAnimation] = useState();
  const [hideAnimation, setHideAnimation] = useState();
  const data = useSelector((state) => state.notifications.data);
  const meta = useSelector((state) => state.notifications.meta);
  const links = useSelector((state) => state.notifications.links);
  const listLoading = useSelector((state) => state.notifications.isListLoading);
  const dispatch = useDispatch();

  blockScroll();

  const closeHandler = () => {
    setAllowAnimation(false);
    setHideAnimation(true);

    setTimeout(() => {
      allowScroll();
      onClose();
    }, 100);
  };

  const deleteItem = (id) => {
    dispatch(deleteNotification(id));
    dispatch(getNotificationsUnread());
  };

  const deleteAll = () => {
    dispatch(deleteNotifications());
    dispatch(getNotificationsUnread());
  };

  const markAsReadHandler = (id) => {
    dispatch(notificationMarkAsRead(id));
    dispatch(markAsReadLocal(id));
  };

  useEffect(() => {
    setAllowAnimation(true);
    dispatch(getNotifications());

    return () => {
      dispatch(clearList());
    };
  }, []);

  const isIosOrMac =
    /iPad|iPhone|iPod|Mac|Mac OS/.test(navigator.userAgent) && !window.MSStream;

  return (
    <div className={'notification-popup'}>
      <div
        className={
          allowAnimation
            ? 'notification-popup__inner animation'
            : hideAnimation
            ? 'notification-popup__inner animation-hide'
            : 'notification-popup__inner'
        }
      >
        <div className="notification-popup__content">
          <LoadedContent loader={!data.length && listLoading} />

          {data && data.length && (
            <>
              <div
                className={
                  isIosOrMac
                    ? 'notification-list apple-fix'
                    : 'notification-list not-apple'
                }
              >
                <InfiniteScroll
                  dataLength={data.length}
                  next={() => dispatch(getNotifications(meta.current_page + 1))}
                  hasMore={links.next}
                  loader={<div></div>}
                  height={328}
                  scrollableTarget="scrollableDiv"
                >
                  <div className="notification-list__inner">
                    {data.map((item) => {
                      return (
                        <div
                          key={item.id}
                          className="notification-list__item"
                          onMouseOver={() => {
                            if (!item.read_at) {
                              markAsReadHandler(item.id);
                              dispatch(markAsReadLocal(item.id));
                            }
                          }}
                        >
                          <div
                            className={
                              item.read_at
                                ? 'notification-list__text'
                                : 'notification-list__text not-read'
                            }
                          >
                            <NotificationText
                              data={item}
                              closeHandler={closeHandler}
                            />
                            <div onClick={() => deleteItem(item.id)}>
                              <Icon icon="close" size="20px" />
                            </div>
                          </div>
                          <span className="notification-list__date">
                            {moment(item.created_at).format('YYYY.MM.DD')}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </InfiniteScroll>
              </div>

              <div className="notification-delete">
                <div className="notification-delete__inner" onClick={deleteAll}>
                  <div>
                    <Icon icon="trash" size="20px" />
                  </div>
                  <span>
                    <FormattedMessage
                      id="modal.notification.delete"
                      defaultMessage="Delete All"
                    />
                  </span>
                </div>
              </div>
            </>
          )}

          {!listLoading && data && !data.length && (
            <>
              <div className="empty-list">
                <div className="empty-list__icon">
                  <Icon icon="alert" size="32px" />
                </div>
                <span className="empty-list__title">
                  <FormattedMessage
                    id="modal.notification.empty"
                    defaultMessage="You have no notifications"
                  />
                </span>
              </div>
            </>
          )}

          <div
            className="notification-popup__close"
            onClick={() => closeHandler()}
          >
            <Icon icon="close" size="16px" />
          </div>
        </div>
      </div>
      <div
        className="notification-popup__area"
        onClick={() => closeHandler()}
      ></div>
    </div>
  );
};

NotificationPopup.defaultProps = {
  onClose: () => {},
};

NotificationPopup.propTypes = {
  onClose: PropTypes.func,
};

export default NotificationPopup;
