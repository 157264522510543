import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// import Button from '../../components/ui/Button';
// import { BUTTON_SIZE, BUTTON_TYPE } from '../../components/ui/Button/Button';
import Icon from '../../../../components/ui/Icon';
import LoadedContent from '../../../../components/LoadedContent';
import AdaptiveContent from '../../../../components/AdaptiveContent';
import OrderCard from '../../../../components/OrderCard/OrderCard';
import { useMediaQuery } from 'react-responsive';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import AboutCard from '../../../../components/AboutCard/AboutCard';
import Pagination from '../../../../components/Pagination';
import Accordion from '../../../../components/Accordion';
import { AlertMessengerContext } from '../../../../provider/Alert';
import { getCompany } from '../../../../api/company';
import { customerProjectShow } from '../../../../api/project';
import { customerMyOrders } from '../../../../api/order';
import { orderMapper } from '../../../../utils/order-mapper';
import Actions from '../../../../components/ui/Actions';
import { CONTENT_SIZE_TYPE } from '../../../../components/AdaptiveContent/AdaptiveContent';
import Title from '../../../../components/ui/Title';
import { FormattedMessage, useIntl } from 'react-intl';

const ProjectDetailCustomerPage = () => {
  const [aboutCardData, setAboutCardData] = useState();
  // eslint-disable-next-line no-unused-vars
  const [companyInfo, setCompanyInfo] = useState({});
  const [projectInfo, setProjectInfo] = useState();
  const [isProjectLoad, setIsProjectLoad] = useState(false);

  const [isListLoading, setIsListLoading] = useState(false);
  const [orderList, setOrderList] = useState();
  const [orderMeta, setOrderMeta] = useState();

  const navigate = useNavigate();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const pageQuery = searchParams.get('page') || 1;
  const intl = useIntl();

  // eslint-disable-next-line no-unused-vars
  const isMobileOrTablet = useMediaQuery({
    query: '(max-width: 900px) ',
  });

  const { addErrorMessage } = useContext(AlertMessengerContext);

  const fetchList = (projectId, page) => {
    setIsListLoading(true);

    customerMyOrders({
      page,
      projectId,
      per_page: 10,
    })
      .then((res) => {
        const mapped = orderMapper('customer', res.data);
        setOrderList(mapped.data);
        setOrderMeta(res.data.meta);
        setIsListLoading(false);
      })
      .catch(({ message }) => {
        addErrorMessage({
          message: message,
        });
        setIsListLoading(false);
      });
  };

  const getCompanyAsync = async (id) => {
    try {
      const res = await getCompany(id);

      if (res && res.data && res.data.data) {
        setCompanyInfo(res.data.data);
        fetchList(params.id_project, pageQuery);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchProjectInfo = (projectId) => {
    setIsProjectLoad(true);
    customerProjectShow(projectId)
      .then((res) => {
        setProjectInfo(res.data.data);
        setIsProjectLoad(false);
      })
      .catch(({ message }) => {
        addErrorMessage({
          message: message,
        });
        setIsProjectLoad(false);
      });
  };

  const localeMsg = (key, msg) =>
    intl.formatMessage({
      id: `company.${key}`,
      defaultMessage: msg,
    });

  useEffect(() => {
    document.title = `Project ${projectInfo ? projectInfo.name : ''}`;

    if (projectInfo) {
      setAboutCardData([
        {
          title: localeMsg('marking', 'Marking'),
          value: projectInfo.marking,
        },
        {
          title: localeMsg('address', 'Adress'),
          value: projectInfo.address,
        },
        {
          title: localeMsg('zip', 'Zip Code'),
          value: projectInfo.zipCode,
        },
        {
          title: localeMsg('city', 'City'),
          value: projectInfo.city,
        },
        {
          title: localeMsg('contactPerson', 'Contact Person'),
          value: projectInfo.contactPerson,
        },
        {
          title: localeMsg('phone', 'Phone'),
          value: projectInfo.phone,
        },
        {
          title: localeMsg('mail', 'Email'),
          value: projectInfo.email,
        },
      ]);
    }
  }, [projectInfo]);

  useEffect(() => {
    if (!projectInfo) {
      if (params && params.id_project) {
        getCompanyAsync(localStorage.getItem('companyId'));
        fetchProjectInfo(params.id_project);
      }
    } else if (params && params.id_project) {
      fetchList(params.id_project, pageQuery);
    }
  }, [pageQuery]);

  return (
    <div className="company-page project-detail-page-customer">
      <div className="company-page__inner">
        <AdaptiveContent type={CONTENT_SIZE_TYPE.DESCTOP}>
          <div className="company-page__header">
            <div className="company-page__header-type">
              <div onClick={() => navigate('/my-projects')}>
                <Icon icon="arrow-left" size="20px" />
              </div>
              <span className="company-page__header-title">
                {projectInfo ? projectInfo.name : '...'}
              </span>
            </div>
            <span className="company-page__header-subtitle">
              <FormattedMessage
                id="page.projectDetail.contains"
                defaultMessage={`Contains ${
                  orderMeta ? orderMeta.total : ''
                } orders`}
                values={{
                  total: orderMeta ? orderMeta.total : '',
                }}
              />
            </span>
          </div>
        </AdaptiveContent>

        <AdaptiveContent type={CONTENT_SIZE_TYPE.MOBILE}>
          <Title
            subTitle={intl.formatMessage({
              id: `page.projects.title`,
              defaultMessage: 'My Projects',
            })}
            actions={
              <div className="order-page-customer__info-actions">
                <Actions
                  actions={[
                    {
                      text: intl.formatMessage({
                        id: `actions.edit`,
                        defaultMessage: 'Edit',
                      }),
                      icon: 'edit',
                      action: () =>
                        navigate(
                          `/my-projects/edit-project/${params.id_project}`
                        ),
                    },
                  ]}
                />
              </div>
            }
          >
            {projectInfo ? projectInfo.name : '...'}
          </Title>
        </AdaptiveContent>

        <div className="company-page__content">
          <AdaptiveContent type={CONTENT_SIZE_TYPE.MOBILE}>
            <div className="company-page__desc">
              <AboutCard
                title={intl.formatMessage({
                  id: `page.projectDetail.about`,
                  defaultMessage: 'About Project',
                })}
                data={aboutCardData}
              />
            </div>
          </AdaptiveContent>

          <div className="company-page__orders">
            <LoadedContent loader={isListLoading || isProjectLoad} />

            <AdaptiveContent type={CONTENT_SIZE_TYPE.MOBILE}>
              {!isListLoading && orderList && (
                <Accordion
                  title={intl.formatMessage(
                    {
                      id: `page.projectDetail.contains`,
                      defaultMessage: `Contains ${
                        orderMeta ? orderMeta.total : ''
                      } orders`,
                    },
                    {
                      total: orderMeta ? orderMeta.total : '',
                    }
                  )}
                >
                  {orderList.map((item, index) => (
                    <OrderCard
                      key={index}
                      data={item}
                      path={`/customer/my-orders/info/project/${params.id_project}/order/${item.id}`}
                    />
                  ))}
                  {orderList.length ? (
                    <div className="company-page__pagination">
                      <Pagination
                        currentPage={orderMeta.current_page}
                        totalCount={orderMeta.last_page}
                        onPageChange={() => {}}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                  {!orderList.length && (
                    <div className="empty-list">
                      <div className="empty-list__icon">
                        <Icon icon="orders-big" size="52px" />
                      </div>
                      <span className="empty-list__title">
                        <FormattedMessage
                          id="page.projectDetail.noMsg"
                          defaultMessage="There is no orders"
                        />
                      </span>
                    </div>
                  )}
                </Accordion>
              )}
            </AdaptiveContent>

            <AdaptiveContent type={CONTENT_SIZE_TYPE.DESCTOP}>
              {!isListLoading && orderList && (
                <>
                  {orderList.map((item, index) => (
                    <OrderCard
                      key={index}
                      data={item}
                      path={`/customer/my-orders/info/project/${params.id_project}/order/${item.id}`}
                    />
                  ))}
                  {orderList.length ? (
                    <div className="company-page__pagination">
                      <Pagination
                        currentPage={orderMeta.current_page}
                        totalCount={orderMeta.last_page}
                        onPageChange={() => {}}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                  {!orderList.length && (
                    <div className="empty-list">
                      <div className="empty-list__icon">
                        <Icon icon="orders-big" size="52px" />
                      </div>
                      <span className="empty-list__title">
                        <FormattedMessage
                          id="page.projectDetail.noMsg"
                          defaultMessage="There is no orders"
                        />
                      </span>
                    </div>
                  )}
                </>
              )}
            </AdaptiveContent>
          </div>

          <AdaptiveContent type={CONTENT_SIZE_TYPE.DESCTOP}>
            <div className="company-page__desc">
              <AboutCard
                title={intl.formatMessage({
                  id: `page.projectDetail.about`,
                  defaultMessage: 'About Project',
                })}
                data={aboutCardData}
                actions={
                  <Actions
                    actions={[
                      {
                        text: intl.formatMessage({
                          id: `actions.edit`,
                          defaultMessage: 'Edit',
                        }),
                        icon: 'edit',
                        action: () =>
                          navigate(
                            `/my-projects/edit-project/${params.id_project}`
                          ),
                      },
                    ]}
                  />
                }
              />
            </div>
          </AdaptiveContent>
        </div>
      </div>
    </div>
  );
};

ProjectDetailCustomerPage.propTypes = {
  props: PropTypes.object,
};

export default ProjectDetailCustomerPage;
