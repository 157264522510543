import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Actions from '../ui/Actions';
import { useDispatch } from 'react-redux';
import { deleteCompany } from '../../store/slice/companySlice';
import { useNavigate } from 'react-router-dom';
import { organizationNumberFormat } from '../../utils/organization-number.format';
import ConfirmPopup from '../ConfirmPopup/ConfirmPopup';
import { FormattedMessage, useIntl } from 'react-intl';

const CompanyCard = ({ data, onSuccess, onError }) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useIntl();

  const { name, type, organisationNumber, city, address, id, zipCode } = data;

  const localeType = () =>
    intl.formatMessage({
      id: `${
        type === 'customer' ? 'company.type.customer' : 'company.type.supplier'
      }`,
      defaultMessage: type,
    });

  const onSubmit = () => {
    setIsLoading(true);

    dispatch(deleteCompany(id))
      .unwrap()
      .then((res) => {
        setIsLoading(false);
        setOpen(false);
        onSuccess();
      })
      .catch((err) => {
        setIsLoading(false);
        setOpen(false);
        onError(err);
      });
  };

  const closeModal = () => setOpen(false);

  return (
    <div className="company-item">
      <div
        className="company-item__inner"
        onClick={(e) => {
          navigate(`company/${id}`);
        }}
      >
        <div className="company-item__name">
          <span className="company-item__name-company">{name}</span>
          <span className="company-item__name-role">{localeType()}</span>
        </div>
        <div className="company-item__info">
          <div className="company-item__info-block">
            <span className="company-item__info-title">
              <FormattedMessage
                id="company.orgNumber"
                defaultMessage="Organization number"
              />
            </span>
            <span className="company-item__info-desc">
              {organizationNumberFormat(organisationNumber)}
            </span>
          </div>
          <div className="company-item__info-block">
            <span className="company-item__info-title">
              <FormattedMessage id="company.address" defaultMessage="Address" />
            </span>
            <span className="company-item__info-desc">{address}</span>
          </div>
          <div className="company-item__info-block">
            <span className="company-item__info-title">
              <FormattedMessage id="company.city" defaultMessage="City" />
            </span>
            <span className="company-item__info-desc">
              {zipCode}, {city}
            </span>
          </div>
        </div>
      </div>
      <div className="company-item__actions">
        <Actions
          actions={[
            {
              text: intl.formatMessage({
                id: 'actions.edit',
                defaultMessage: 'Edit',
              }),
              icon: 'edit',
              action: () => navigate(`edit-company/${id}`),
            },
            {
              text: intl.formatMessage({
                id: 'actions.delete',
                defaultMessage: 'Delete',
              }),
              icon: 'trash',
              action: () => setOpen(true),
            },
          ]}
        />
      </div>

      <ConfirmPopup
        open={open}
        text={intl.formatMessage(
          {
            id: 'popup.sa.deleteCompany.text',
            defaultMessage: `Are you sure you want to delete the company${'\u00A0'}${name}?`,
          },
          {
            value: name,
          }
        )}
        isLoading={isLoading}
        onSubmit={onSubmit}
        onCloseModal={closeModal}
      />
    </div>
  );
};

CompanyCard.defaultProps = {
  onSuccess: () => {},
  onError: () => {},
};

CompanyCard.propTypes = {
  data: PropTypes.object.isRequired,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
};

export default CompanyCard;
