export const PHONE_VALIDATOR = {
  minLength: (value) => {
    const clearValue = value.replace(/[()_\s-]/g, '');
    return /^[+]\d{8,13}$/.test(clearValue) || 'Incorrect phone';
  },
  // isNumber: (value) =>
  //   /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g.test(value) ||
  //   'Incorrect phone',
};

export const PHONE_VALIDATOR_LOCALE = (msg) => ({
  minLength: (value) => {
    const clearValue = value.replace(/[()_\s-]/g, '');
    return /^[+]\d{8,13}$/.test(clearValue) || msg;
  },
  // isNumber: (value) =>
  //   /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g.test(value) ||
  //   'Incorrect phone',
});
