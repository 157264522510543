// import Button from '../../../../components/ui/Button';
// import { BUTTON_SIZE } from '../../../../components/ui/Button/Button';
import { useSearchParams, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getProject } from '../../../../store/slice/projectSlice';
import Project from '../../../../components/Project/Project';
import Title from '../../../../components/ui/Title';
import { getCompany } from '../../../../api/company';
import { FormattedMessage, useIntl } from 'react-intl';

const HomeProjects = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const isListLoading = useSelector((state) => state.project.isListLoading);
  const projectList = useSelector((state) => state.project.data);
  const projectMeta = useSelector((state) => state.project.meta);

  // const projectList = [
  //   {
  //     id: '497f6eca-6276-4993-bfeb-53cbbbba6f08',
  //     name: 'string',
  //     marking: 'string',
  //     address: 'string',
  //     zipCode: 'string',
  //     city: 'string',
  //     comment: 'string',
  //     contactPerson: 'string',
  //     phone: 'string',
  //     email: 'string',
  //     createdAt: '2019-08-24T14:15:22Z',
  //     updatedAt: '2019-08-24T14:15:22Z',
  //   },
  // ];

  const [searchParams] = useSearchParams();
  const pageQuery = searchParams.get('page') || 1;
  const [companyInfo, setCompanyInfo] = useState({});

  const queryParams = useParams();

  const fetchList = async (page) => {
    dispatch(
      getProject({
        id: queryParams.id_company,
        query: { page: page },
      })
    );
  };

  useEffect(() => {
    fetchList(pageQuery);
    document.title = intl.formatMessage({
      id: 'page.projects.title_2',
      defaultMessage: 'My Project',
    });

    const getCompanyAsync = async () => {
      try {
        const res = await getCompany(queryParams.id_company);

        if (res && res.data && res.data.data) {
          setCompanyInfo(res.data.data);
        }
      } catch {}
    };
    getCompanyAsync();
  }, [pageQuery]);

  // const navigate = useNavigate();

  return (
    <div className="project-page customer-page sa-projects">
      <div className="project-page__inner">
        <Title subTitle={companyInfo.name || '...'}>
          <FormattedMessage
            id="page.projects.title_2"
            defaultMessage="Projects"
          />
        </Title>
        {/* <div className="project-page__header">
          <Button
            text={isMobile ? '+' : 'Add New Project +'}
            size={isMobile ? BUTTON_SIZE.ADD_SMALL : BUTTON_SIZE.MEDIUM}
            onClick={() => navigate('/all-projects/add-project')}
          />
        </div> */}

        <Project
          currentPage={projectMeta.current_page}
          totalCount={projectMeta.last_page}
          isListLoading={isListLoading}
          projectList={projectList}
        />
      </div>
    </div>
  );
};

HomeProjects.defaultProps = {};

HomeProjects.propTypes = {};

export default HomeProjects;
