import React, { Fragment, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Icon from '../../ui/Icon';

// itsDone / countInactive / countActive

const Tab = ({
  onTabChange,
  tabs,
  disabled = false,
  activeDefault = null,
  ...e
}) => {
  const [activeTab, setActiveTab] = useState(activeDefault);
  const tabContainerRef = useRef(null);

  const getActiveTabContent = (id) => {
    const active = tabs.find((item) => item.id === id);

    if (active) {
      return (
        <div className="custom-tab__content-activeItem">{active.page}</div>
      );
    } else {
      return <div className="custom-tab__content-item"></div>;
    }
  };

  const scrollToCenter = (tabIndex) => {
    setTimeout(() => {
      const windowWidth = window.innerWidth;
      const elementWidth =
        document.querySelector('.custom-tab')?.children[tabIndex - 1]
          .clientWidth;
      const leftWidth = (windowWidth - elementWidth) / 2;
      document.querySelector('.custom-tab').scrollLeft =
        (tabIndex - 1) * elementWidth - leftWidth;
    }, 100);
    // setTimeout(
    //   () =>
    //     document
    //       .querySelector('.custom-tab')
    //       ?.children[tabIndex - 1]?.scrollIntoView(),
    //   100
    // );
  };

  useEffect(() => {
    setActiveTab(activeDefault);
    scrollToCenter(activeDefault);
  }, [activeDefault]);

  return (
    <Fragment>
      <div
        className={disabled ? 'custom-tab custom-tab__disabled' : 'custom-tab'}
        ref={tabContainerRef}
      >
        {tabs.map((item) => {
          // eslint-disable-next-line no-prototype-builtins
          const itsCyrcle = !item.itsDone && item.hasOwnProperty('countActive');
          // eslint-disable-next-line no-prototype-builtins
          const itsCyrcleInactive = item.hasOwnProperty('countInactive');
          return (
            <>
              <div
                onClick={() => {
                  onTabChange(item.id);
                  setActiveTab(item.id);
                }}
                className={
                  activeTab === item.id
                    ? 'custom-tab__item-active'
                    : 'custom-tab__item'
                }
                key={item.id}
              >
                {item.title}
                <div className="custom-tab__item-icon">
                  {itsCyrcle ? (
                    <div className="custom-tab__item__cyrcle">
                      {item.countActive > 99 ? '99+' : item.countActive}
                    </div>
                  ) : item.itsDone ? (
                    <Icon icon={'check-1'} size="16px" />
                  ) : itsCyrcleInactive ? (
                    <div className="custom-tab__item__cyrcle2">
                      {item.countInactive > 99 ? '99+' : item.countInactive}
                    </div>
                  ) : null}
                </div>
              </div>
            </>
          );
        })}
      </div>
      <div className="custom-tab__content">
        {getActiveTabContent(activeTab)}
      </div>
    </Fragment>
  );
};

Tab.defaultProps = {
  onTabChange: () => {},
};

Tab.propTypes = {
  tabs: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  activeDefault: PropTypes.number,
  onTabChange: PropTypes.func,
};

export default Tab;
