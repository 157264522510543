import React from 'react';
import PropTypes from 'prop-types';
import OrderCard, { CARD_TYPE } from '../OrderSupplierCard/OrderSupplierCard';
import LoadedContent from '../LoadedContent';
import Icon from '../ui/Icon';
import Pagination from '../Pagination';
import { FormattedMessage } from 'react-intl';

const OrdersSupplier = ({
  meta,
  isListLoading,
  orderList,
  itsDeclined,
  itsNewOrders,
  itsWait,
  itsCurrent,
  itsArchive,
  declineOrder,
  unDeclineOrder,
}) => {
  return (
    <>
      <LoadedContent loader={isListLoading} />
      <div className="order-supplier-list">
        {!isListLoading &&
          orderList &&
          orderList.map((item, index) => (
            <OrderCard
              itsDeclined={itsDeclined}
              itsWait={itsWait}
              itsNewOrders={itsNewOrders}
              itsArchive={itsArchive}
              itsCurrent={itsCurrent}
              key={index}
              data={item}
              type={CARD_TYPE.HORIZONTAL}
              declineOrder={declineOrder}
              unDeclineOrder={unDeclineOrder}
              noneTender={true}
            />
          ))}
        {!isListLoading && meta && (
          <div className="order-supplier-list__pagination">
            <Pagination
              totalCount={meta.last_page}
              currentPage={meta.current_page}
              onPageChange={() => {}}
              typePage={itsDeclined ? 'pageDeclined' : 'page'}
            />
          </div>
        )}
        {orderList && !orderList.length && !isListLoading ? (
          <div className="empty-list">
            <div className="empty-list__icon">
              <Icon icon="orders-big" size="52px" />
            </div>
            <span className="empty-list__title">
              <FormattedMessage
                id="page.orders.noMsg"
                defaultMessage="You have no orders"
              />
            </span>
          </div>
        ) : (
          ''
        )}
      </div>
    </>
  );
};

OrdersSupplier.propTypes = {
  title: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  isListLoading: PropTypes.bool.isRequired,
  orderList: PropTypes.array.isRequired,
  itsDeclined: PropTypes.bool,
  itsNewOrders: PropTypes.bool,
  itsWait: PropTypes.bool,
  itsCurrent: PropTypes.bool,
  itsArchive: PropTypes.bool,
  declineOrder: PropTypes.func,
  unDeclineOrder: PropTypes.func,
};

export default OrdersSupplier;
