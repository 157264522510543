import API from '../api';

export const acceptOffer = (orderId, offerId) =>
  API.patch(
    `/api/api/v1/company/companies/customer/orders/${orderId}/offers/${offerId}/accept`
  );

export const cancelAcceptedOffer = (orderId, offerId) =>
  API.patch(
    `/api/api/v1/company/companies/customer/orders/${orderId}/offers/${offerId}/cancel`
  );

export const changeOffer = (orderId, offerId, offerIdNew) =>
  API.patch(
    `/api/api/v1/company/companies/customer/orders/${orderId}/offers/${offerId}/change`,
    { offerIdNew }
  );
