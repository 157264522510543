import React, { useState } from 'react';

import PropTypes from 'prop-types';
import Actions from '../ui/Actions';
import ConfirmPopup from '../ConfirmPopup/ConfirmPopup';
import { useDispatch } from 'react-redux';
import {
  startLoading,
  stopLoading,
  changeStatusRole,
} from '../../store/slice/usersCompanySlice';
import { useNavigate, useParams } from 'react-router-dom';
// import { , useParams } from 'react-router-dom';

import {
  companyUserCompanyBan,
  companyUserCompanyUnban,
  superAdminBan,
  superAdminUnBan,
} from '../../api/companyUser/index';
import { resolveTabPath } from '../../utils/resolveTabPath';
import { FormattedMessage, useIntl } from 'react-intl';

const UserItem = ({
  data,
  onSuccess,
  idCompany,
  itsSuperAdmin,
  itsBanned,
  callbackAction,
}) => {
  const [open, setOpen] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useIntl();

  const queryParams = useParams();

  const { lastname, phone, role, firstname, email, id, status } = data;

  const handleBanUser = () => {
    setOpen(false);
    dispatch(startLoading());
    if (status === 'banned') {
      // active
      companyUserCompanyUnban(idCompany, id)
        .then(() => {
          dispatch(changeStatusRole(id, 'active'));
        })
        .finally(() => {
          dispatch(stopLoading());
          if (callbackAction) {
            callbackAction();
          }
        });
    } else {
      // banned
      companyUserCompanyBan(idCompany, id)
        .then(() => {
          dispatch(changeStatusRole(id, 'banned'));
        })
        .finally(() => {
          dispatch(stopLoading());
          if (callbackAction) {
            callbackAction();
          }
        });
    }
  };

  const handleBanSuperAdmin = () => {
    setOpen(false);
    dispatch(startLoading());
    if (status === 'banned') {
      superAdminUnBan(id)
        .then(() => {
          dispatch(changeStatusRole(id, 'active'));
        })
        .finally(() => {
          dispatch(stopLoading());
          if (callbackAction) {
            callbackAction();
          }
        });
    } else {
      superAdminBan(id)
        .then(() => {
          dispatch(changeStatusRole(id, 'banned'));
        })
        .finally(() => {
          dispatch(stopLoading());
          if (callbackAction) {
            callbackAction();
          }
        });
    }
  };

  //   const onSubmit = () => {
  //     setIsLoading(true);

  //     dispatch(deleteCompany(id))
  //       .unwrap()
  //       .then((res) => {
  //         setIsLoading(false);
  //         setOpen(false);
  //         onSuccess();
  //       })
  //       .catch((err) => {
  //         setIsLoading(false);
  //         console.log(err);
  //       });
  //   };

  //   const closeModal = () => setOpen(false);
  // const capitalizeFirstLetter = (string) => {
  //   if (string) {
  //     return string.charAt(0).toUpperCase() + string.slice(1);
  //   }
  //   return string;
  // };

  const formatPhone = (string) => {
    return [string.slice(0, 3), ' ', string.slice(3)].join('');
  };

  const getLocaleRole = () => {
    const roleShort =
      role === undefined ? 'sa' : role === 'admin' ? 'ad' : 'pl';
    const roleFull =
      role === undefined
        ? 'Super Admin'
        : role === 'admin'
        ? 'Admin'
        : 'Project Leader';

    if (itsBanned || data.status === 'banned') {
      return intl.formatMessage({
        id: `component.userCard.role.banned_${roleShort}`,
        defaultMessage: `Banned ${roleFull}`,
      });
    }

    return intl.formatMessage({
      id: `component.userCard.role.${roleShort}`,
      defaultMessage: `${roleFull}`,
    });
  };

  const getSaActions = () => {
    if (id === localStorage.getItem('userId')) {
      return [
        {
          text: intl.formatMessage({
            id: 'actions.show',
            defaultMessage: 'Show',
          }),
          icon: 'edit',
          action: () => {
            navigate(
              `/my-account/user/${id}?fn=${firstname}&ln=${lastname}&ph=${phone}&em=${email}`
            );
          },
        },
      ];
    } else if (status !== 'banned') {
      return [
        {
          text: intl.formatMessage({
            id: 'actions.show',
            defaultMessage: 'Show',
          }),
          icon: 'edit',
          action: () => {
            navigate(
              `/my-account/user/${id}?fn=${firstname}&ln=${lastname}&ph=${phone}&em=${email}`
            );
          },
        },
        {
          text: intl.formatMessage({
            id: 'actions.block',
            defaultMessage: 'Block',
          }),
          icon: 'cancel',
          action: () => setOpen(true),
        },
      ];
    } else {
      return [
        {
          text: intl.formatMessage({
            id: 'actions.unblockUser',
            defaultMessage: 'Unblock',
          }),
          icon: 'unbanned',
          action: handleBanSuperAdmin,
        },
      ];
    }
  };

  return (
    <div className="user-company-item">
      <div className="user-company-item__inner">
        <div
          className="user-company-item__name"
          //   onClick={() => navigate(`/all-companies/user-company/${id}`)}
        >
          <span className="user-company-item__name-name">
            {firstname} {lastname}
          </span>
          <div>
            <span className="user-company-item__name-role">
              {getLocaleRole()}
            </span>
          </div>
        </div>
        <div className="user-company-item__info">
          <div className="user-company-item__info-block">
            <span className="user-company-item__info-title">
              <FormattedMessage
                id="component.userCard.phone"
                defaultMessage="Phone"
              />
            </span>
            <span className="user-company-item__info-desc">
              {formatPhone(phone)}
            </span>
          </div>
          <div className="user-company-item__info-block">
            <span className="user-company-item__info-title">
              <FormattedMessage
                id="component.userCard.mail"
                defaultMessage="Email"
              />
            </span>
            <span className="user-company-item__info-desc">{email}</span>
          </div>

          <div className="user-company-item__actions">
            <Actions
              actions={
                itsSuperAdmin
                  ? getSaActions()
                  : status === 'banned'
                  ? [
                      {
                        text: intl.formatMessage({
                          id:
                            status !== 'banned'
                              ? 'actions.block'
                              : 'actions.unblockUser',
                          defaultMessage:
                            status !== 'banned' ? 'Block' : 'Unblock',
                        }),
                        icon: status !== 'banned' ? 'cancel' : 'unbanned',
                        action:
                          status !== 'banned'
                            ? () => setOpen(true)
                            : handleBanUser,
                      },
                    ]
                  : [
                      {
                        text: intl.formatMessage({
                          id: 'actions.edit',
                          defaultMessage: 'Edit',
                        }),
                        icon: 'edit',
                        action: () => {
                          const tabName = resolveTabPath();
                          navigate(
                            `/all-companies/${tabName}/company/${queryParams.id_company}/user/${id}`
                          );
                        },
                      },
                      // {
                      //   text: 'Delete',
                      //   icon: 'trash',
                      //   action: () => setOpen(true),
                      // },
                      {
                        text: intl.formatMessage({
                          id:
                            status !== 'banned'
                              ? 'actions.block'
                              : 'actions.unblockUser',
                          defaultMessage:
                            status !== 'banned' ? 'Block' : 'Unblock',
                        }),
                        icon: status !== 'banned' ? 'cancel' : 'unbanned',
                        action:
                          status !== 'banned'
                            ? () => setOpen(true)
                            : handleBanUser,
                      },
                    ]
              }
            />
          </div>
        </div>
      </div>

      <ConfirmPopup
        open={open}
        text={intl.formatMessage(
          {
            id: 'popup.blockUser.text',
            defaultMessage: `Are you sure to want to block an user${'\u00A0'}${firstname} ${lastname}?`,
          },
          {
            firstname,
            lastname,
          }
        )}
        isLoading={false}
        confirmButtonText={intl.formatMessage({
          id: 'popup.btn.block',
          defaultMessage: 'Block',
        })}
        onSubmit={() => {
          if (itsSuperAdmin) {
            handleBanSuperAdmin();
          } else {
            handleBanUser();
          }
        }}
        onCloseModal={() => setOpen(false)}
      />
    </div>
  );
};

UserItem.defaultProps = {
  onSuccess: () => {},
};

UserItem.propTypes = {
  data: PropTypes.object.isRequired,
  onSuccess: PropTypes.func,
  idCompany: PropTypes.string,
  itsSuperAdmin: PropTypes.bool,
  itsBanned: PropTypes.bool,
  callbackAction: PropTypes.func,
};

export default UserItem;
