import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import FormInput from '../../../components/ui/FormInput/FormInput';
import Button, {
  BUTTON_SIZE,
  BUTTON_TYPE,
} from '../../../components/ui/Button/Button';
import Icon from '../../../components/ui/Icon';
import { MAIL_VALIDATOR_LOCALE } from '../../../validators/mail.validator';
import { userResetPasswordRequest } from '../../../api/auth';
import { Link, useNavigate } from 'react-router-dom';
import LoadedContent from '../../../components/LoadedContent';
import { FormattedMessage, useIntl } from 'react-intl';

const ResetPasswordPage = () => {
  const navigate = useNavigate();
  const intl = useIntl();

  const [isLoading, setIsLoading] = useState(false);
  const [mailSended, setMailSended] = useState(false);

  const { control, handleSubmit, watch, setError, clearErrors, formState } =
    useForm({
      reValidateMode: 'onChange',
      defaultValues: {
        email: '',
      },
    });
  const { errors } = formState;
  const emailWatch = watch('email');

  const onSubmit = (data) => {
    setIsLoading(true);

    userResetPasswordRequest({ email: data.email.toLowerCase() })
      .then((res) => {
        setMailSended(true);
        setIsLoading(false);
      })
      .catch((err) => {
        setMailSended(false);
        setIsLoading(false);
        setError('email', {
          type: 'custom',
          message:
            err.response && err.response.data && err.response.data.message
              ? err.response.data.message
              : intl.formatMessage({
                  id: 'auth.resetPassword.form.errorOcurred',
                  defaultMessage: 'An error occurred!',
                }),
        });
      });
  };

  const navBack = () => {
    navigate('/sign-in');
  };

  const setTitle = () => {
    const title = intl.formatMessage({
      id: 'auth.resetPassword.title',
      defaultMessage: 'Reset Password',
    });
    document.title = title;
  };

  useEffect(() => {
    setTitle();

    if (errors) {
      clearErrors('email');
    }
  }, [emailWatch]);

  useEffect(() => {
    setTitle();
  }, []);

  const formLocaleMailValidator = () =>
    MAIL_VALIDATOR_LOCALE(
      intl.formatMessage({
        id: 'input.error.incorrectMail',
        defaultMessage: 'Incorrect E-mail address',
      })
    );

  const renderSuccessScreen = () => {
    return (
      <div className="success-screen">
        <div className="success-screen__icon">
          <Icon icon="check-big" size="48px" />
        </div>
        <div className="success-screen__title">
          <FormattedMessage
            id="auth.resetPassword.seccessText.title"
            defaultMessage="We have sent a letter to your e-mail"
          />
        </div>
        <div className="success-screen__subtitle">
          <FormattedMessage
            id="auth.resetPassword.seccessText.subtitle"
            defaultMessage="Check and follow the link to reset your password"
          />
        </div>
      </div>
    );
  };

  return (
    <div className="reset-password">
      <div className="reset-password__header">
        <Link to="/sign-in">
          <Icon icon="logo-light" />
        </Link>
      </div>
      <div className="reset-password__logo">
        <div className="reset-password__logo-wrapper">
          <Icon icon="logo-big" />
        </div>
      </div>
      <div className="reset-password__content">
        <LoadedContent loader={isLoading} />
        {!mailSended && (
          <div className="reset-password__form">
            <form
              className="reset-password__form-inner"
              onSubmit={handleSubmit(onSubmit)}
            >
              <span className="reset-password__form-title">
                <span onClick={() => navBack()}>
                  <Icon icon="arrow-left" size="16px" />
                </span>
                <FormattedMessage
                  id="auth.resetPassword.title"
                  defaultMessage="Reset Password"
                />
              </span>

              <FormInput
                control={control}
                label={intl.formatMessage({
                  id: 'auth.resetPassword.form.mail',
                  defaultMessage: 'E-mail',
                })}
                name="email"
                id="email"
                type="email"
                errors={errors.email}
                placeholder={intl.formatMessage({
                  id: 'auth.resetPassword.form.mailPlaceholder',
                  defaultMessage: 'Enter your e-mail',
                })}
                disabled={isLoading}
                rules={{
                  required: (
                    <FormattedMessage
                      id="auth.resetPassword.form.required"
                      defaultMessage="Field is required"
                    />
                  ),
                  validate: formLocaleMailValidator(),
                }}
              />

              <span className="help-message">
                <FormattedMessage
                  id="auth.resetPassword.helpMessage"
                  defaultMessage="Enter your email to reset your password"
                />
              </span>

              <div className="reset-password__form-btn">
                <Button
                  text={intl.formatMessage({
                    id: 'auth.resetPassword.btn',
                    defaultMessage: 'Reset',
                  })}
                  size={BUTTON_SIZE.MEDIUM}
                  type={BUTTON_TYPE.PRIMARY}
                  disabled={isLoading}
                />
              </div>
            </form>
          </div>
        )}

        {mailSended && renderSuccessScreen()}
      </div>
    </div>
  );
};

export default ResetPasswordPage;
