import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { LangContextProvider } from './provider/Lang';
import store from './store';
import App from './App';

import './main.scss';
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <React.StrictMode>
      <LangContextProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </LangContextProvider>
    </React.StrictMode>
  </Provider>
);
