import React, { useState } from 'react';

import PropTypes from 'prop-types';
import Actions from '../ui/Actions';
import ConfirmPopup from '../ConfirmPopup/ConfirmPopup';
import { useDispatch } from 'react-redux';
import {
  startLoading,
  stopLoading,
  changeStatusRole,
} from '../../store/slice/usersCompanySlice';
// import { useNavigate, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import {
  companyUserCompanyBan,
  companyUserCompanyUnban,
} from '../../api/companyUser/index';
import RoleCheck from '../RoleCheck';
import { formatPhoneNumber } from '../../utils/phone-number.format';
import { FormattedMessage, useIntl } from 'react-intl';

const UserItemAdmin = ({
  data,
  onSuccess,
  idCompany,
  currentUserId,
  callbackAction,
}) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useIntl();

  const { lastname, phone, role, firstname, email, id, status } = data;

  const handleBanUser = () => {
    dispatch(startLoading());
    setOpen(false);

    if (status === 'banned') {
      companyUserCompanyUnban(idCompany, id)
        .then(() => {
          dispatch(changeStatusRole(id, 'active'));
        })
        .finally(() => {
          dispatch(stopLoading());
          callbackAction();
        });
    } else {
      companyUserCompanyBan(idCompany, id)
        .then(() => {
          dispatch(changeStatusRole(id, 'banned'));
        })
        .finally(() => {
          dispatch(stopLoading());
          callbackAction();
        });
    }
  };

  const getLocaleRole = () => {
    const roleShort =
      role === undefined ? 'sa' : role === 'admin' ? 'ad' : 'pl';
    const roleFull =
      role === undefined
        ? 'Super Admin'
        : role === 'admin'
        ? 'Admin'
        : 'Project Leader';

    if (status === 'banned') {
      return intl.formatMessage({
        id: `component.userCard.role.banned_${roleShort}`,
        defaultMessage: `Banned ${roleFull}`,
      });
    }

    return intl.formatMessage({
      id: `component.userCard.role.${roleShort}`,
      defaultMessage: `${roleFull}`,
    });
  };

  return (
    <div className="user-company-item">
      <div className="user-company-item__inner">
        <div
          className="user-company-item__name"
          //   onClick={() => navigate(`/all-companies/user-company/${id}`)}
        >
          <span className="user-company-item__name-name">
            {firstname} {lastname}
          </span>
          <div>
            <span className="user-company-item__name-role">
              {getLocaleRole()}
            </span>
          </div>
        </div>
        <div className="user-company-item__info">
          <div className="user-company-item__info-block">
            <span className="user-company-item__info-title">
              <FormattedMessage
                id="component.userCard.phone"
                defaultMessage="Phone"
              />
            </span>
            <span className="user-company-item__info-desc">
              {formatPhoneNumber(phone)}
            </span>
          </div>
          <div className="user-company-item__info-block">
            <span className="user-company-item__info-title">
              <FormattedMessage
                id="component.userCard.mail"
                defaultMessage="Email"
              />
            </span>
            <span className="user-company-item__info-desc">{email}</span>
          </div>

          <div className="user-company-item__actions">
            <RoleCheck roles={['admin']}>
              <Actions
                actions={
                  status !== 'banned'
                    ? id !== currentUserId
                      ? [
                          {
                            text: intl.formatMessage({
                              id: 'actions.edit',
                              defaultMessage: 'Edit',
                            }),
                            icon: 'edit',
                            action: () =>
                              navigate(`company/${idCompany}/user/${id}`),
                          },
                          {
                            text: intl.formatMessage({
                              id: 'actions.block',
                              defaultMessage: 'Block',
                            }),
                            icon: 'cancel',
                            action: () => setOpen(true),
                          },
                        ]
                      : [
                          {
                            text: intl.formatMessage({
                              id: 'actions.edit',
                              defaultMessage: 'Edit',
                            }),
                            icon: 'edit',
                            action: () =>
                              navigate(`company/${idCompany}/user/${id}`),
                          },
                        ]
                    : [
                        {
                          text: intl.formatMessage({
                            id: 'actions.unblockUser',
                            defaultMessage: 'Block',
                          }),
                          icon: 'unbanned',
                          action: handleBanUser,
                        },
                      ]
                }
              />
            </RoleCheck>
          </div>
        </div>
      </div>

      <ConfirmPopup
        open={open}
        text={intl.formatMessage(
          {
            id: 'popup.blockUser.text',
            defaultMessage: `Are you sure to want to block an user${'\u00A0'}${firstname} ${lastname}?`,
          },
          {
            firstname,
            lastname,
          }
        )}
        isLoading={false}
        confirmButtonText={intl.formatMessage({
          id: 'popup.btn.block',
          defaultMessage: 'Block',
        })}
        onSubmit={() => {
          handleBanUser();
        }}
        onCloseModal={() => setOpen(false)}
      />
    </div>
  );
};

UserItemAdmin.defaultProps = {
  onSuccess: () => {},
};

UserItemAdmin.propTypes = {
  data: PropTypes.object.isRequired,
  onSuccess: PropTypes.func,
  idCompany: PropTypes.string,
  itsSuperAdmin: PropTypes.bool,
  currentUserId: PropTypes.string,
  callbackAction: PropTypes.func,
};

export default UserItemAdmin;
