import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Steps, { Step } from 'rc-steps';
import 'rc-steps/assets/index.css';
import Icon from '../Icon';
import { useMediaQuery } from 'react-responsive';
import { FormattedMessage, useIntl } from 'react-intl';

export const StepPage = ({ children }) => (
  <div className="steps__page-item">{children}</div>
);

StepPage.propTypes = {
  children: PropTypes.node.isRequired,
};

export const NextStepButton = ({ children }) => (
  <div className="step-button">{children}</div>
);

NextStepButton.propTypes = {
  children: PropTypes.node.isRequired,
};

const StepsComponent = ({
  steps,
  activeStep,
  clickOnPrevStep,
  clickOnNextStep,
  children,
}) => {
  const pageArray = Array.isArray(children) ? children : [children];
  const isMobileOrTablet = useMediaQuery({
    query: '(max-width: 900px) ',
  });
  const intl = useIntl();

  const scroll = () => {
    window.scrollTo(0, 0);
    if (document.querySelector('.page-layout__content')) {
      document.querySelector('.page-layout__content').scrollTop = 0;
    }
  };

  useEffect(() => scroll(), [activeStep]);

  return (
    <>
      <div className="steps">
        <div className="steps__nav">
          {!isMobileOrTablet && (
            <Steps
              direction="vertical"
              current={activeStep}
              stepIcon={({ status, node }) => {
                const isFinish = status === 'finish';
                return isFinish ? (
                  <div className="steps__finish">
                    <Icon icon="check-1" />
                  </div>
                ) : (
                  ''
                );
              }}
            >
              {steps.map((item, index) => (
                <Step
                  key={index}
                  title={intl.formatMessage(
                    {
                      id: 'steps.step',
                      defaultMessage: `Step ${index + 1}. ${item}`,
                    },
                    {
                      pos: index + 1,
                      stepName: item,
                    }
                  )}
                  onClick={() => {
                    if (index < activeStep) {
                      clickOnPrevStep(index);
                    } else if (index > activeStep) {
                      clickOnNextStep(index);
                    }
                  }}
                />
              ))}
            </Steps>
          )}

          {isMobileOrTablet && (
            <div className="steps__mobile-header">
              {activeStep > 0 && (
                <div
                  className="steps__mobile-icon"
                  onClick={() => {
                    clickOnPrevStep(activeStep - 1);
                  }}
                >
                  <Icon icon="arrow-left" size="20px" />
                </div>
              )}
              <span className="steps__mobile-title">
                <FormattedMessage
                  id="steps.step"
                  defaultMessage={`Step ${activeStep + 1}. ${
                    steps[activeStep]
                  }`}
                  values={{
                    pos: activeStep + 1,
                    stepName: steps[activeStep],
                  }}
                />
              </span>
            </div>
          )}
        </div>

        <div className="steps__page">
          {pageArray.map((item, index) => {
            return index === activeStep ? item : '';
          })}
          {isMobileOrTablet && (
            <div
              className="steps__mobile-indicator"
              style={{
                background: ` linear-gradient(80deg, var(--violet-primary) ${
                  (100 / pageArray.length) * activeStep
                }%, var(--lgray-primary) 0%)`,
              }}
            >
              <span className="steps__mobile-count">
                <FormattedMessage
                  id="steps.indicator"
                  defaultMessage={`Step ${activeStep + 1} of ${
                    pageArray.length
                  }`}
                  values={{
                    current: activeStep + 1,
                    total: pageArray.length,
                  }}
                />
              </span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

StepsComponent.defaultProps = {
  clickOnNextStep: () => {},
};

StepsComponent.propTypes = {
  steps: PropTypes.array.isRequired,
  activeStep: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  clickOnPrevStep: PropTypes.func.isRequired,
  clickOnNextStep: PropTypes.func.isRequired,
};

export default StepsComponent;
