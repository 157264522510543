import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';

import { useForm } from 'react-hook-form';
import Button, {
  BUTTON_SIZE,
  BUTTON_TYPE,
} from '../../../../../components/ui/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import LoadedContent from '../../../../../components/LoadedContent';
import { AlertMessengerContext } from '../../../../../provider/Alert';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import useIsMobile from '../../../../../utils/useMobile';
import Title from '../../../../../components/ui/Title';
import FormInputMask from '../../../../../components/ui/FormInputMask';
import Icon from '../../../../../components/ui/Icon';
import {
  inviteAdmin,
  inviteProjectLead,
  userEdit,
  userShow,
} from '../../../../../store/slice/usersCompanySlice';
import { v4 as uuidv4 } from 'uuid';
import FormInput from '../../../../../components/ui/FormInput/FormInput';
import { useNavigate, useParams } from 'react-router-dom';
import ConfirmPopup from '../../../../../components/ConfirmPopup/ConfirmPopup';
import { FormattedMessage, useIntl } from 'react-intl';

const phoneRegExp = /(^[+]46\s\d{6,11}$)|(^[+]46\d{6,11}$)/;

const AddUserPage = ({ role, editPage }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useIntl();

  const isMobile = useIsMobile();
  const queryParams = useParams();
  const [title, setTitle] = useState();
  const [cancelPopupOpen, setCancelPopupOpen] = useState(false);

  const isInviteLoading = useSelector(
    (state) => state.usersCompany.isInviteLoading
  );
  const isUserLoading = useSelector(
    (state) => state.usersCompany.isUserLoading
  );
  const personalInfo = useSelector((state) => state.personalInfo.data);
  const userData = useSelector((state) => state.usersCompany.userInfo);

  const { addErrorMessage, addSuccessMessage } = useContext(
    AlertMessengerContext
  );

  const schema = yup.object().shape({
    phone: yup
      .string()
      .required(
        intl.formatMessage({
          id: 'input.error.requiredMsg',
          defaultMessage: 'Field is required',
        })
      )
      .matches(
        phoneRegExp,
        intl.formatMessage({
          id: 'input.error.incorrectPhone',
          defaultMessage: 'Phone number is not valid',
        })
      ),
    email: yup
      .string()
      .email(
        intl.formatMessage({
          id: 'input.error.incorrectMail',
          defaultMessage: 'Incorrect E-mail address',
        })
      )
      .required(
        intl.formatMessage({
          id: 'input.error.requiredMsg',
          defaultMessage: 'Field is required',
        })
      ),
    firstname: yup
      .string()
      .required(
        intl.formatMessage({
          id: 'input.error.requiredMsg',
          defaultMessage: 'Field is required',
        })
      )
      .min(
        3,
        intl.formatMessage({
          id: 'input.error.incorrectFName',
          defaultMessage: 'First Name must be at least 3 characters',
        })
      ),
    lastname: yup
      .string()
      .required(
        intl.formatMessage({
          id: 'input.error.requiredMsg',
          defaultMessage: 'Field is required',
        })
      )
      .min(
        3,
        intl.formatMessage({
          id: 'input.error.incorrectLName',
          defaultMessage: 'Last Name must be at least 3 characters',
        })
      ),
  });

  const { control, handleSubmit, formState, ...formControl } = useForm({
    reValidateMode: 'onChange',
    defaultValues: {
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
    },
    resolver: yupResolver(schema),
  });

  const { errors } = formState;

  const onSubmit = (postData) => {
    if (editPage) {
      editUser(postData);
    } else {
      addUser(postData);
    }
  };

  const addUser = (postData) => {
    const phone = postData.phone.replace(/[()_\s-]/g, '');

    dispatch(
      role === 'admin'
        ? inviteAdmin({
            id: personalInfo.companyId,
            data: { ...postData, id: uuidv4(), phone },
          })
        : inviteProjectLead({
            id: personalInfo.companyId,
            data: { ...postData, id: uuidv4(), phone },
          })
    )
      .unwrap()
      .then(() => {
        addSuccessMessage({
          message: intl.formatMessage({
            id: 'page.myCompany.users.inviteSuccessMsg',
            defaultMessage: 'You invited a user!',
          }),
        });
        navigate('/my-company/admins');
      })
      .catch((err) => {
        const { errors, message } = err;
        if (errors) {
          Object.keys(errors).forEach((item) => {
            formControl.setError(item, {
              type: 'custom',
              message: errors[item][0],
            });
          });
        } else if (message) {
          addErrorMessage({
            message: message,
          });
        } else if (typeof err === 'string') {
          addErrorMessage({
            message: err,
          });
        }
      });
  };

  const editUser = (postData) => {
    const phone = postData.phone.replace(/[()_\s-]/g, '');

    dispatch(
      userEdit({
        userId: queryParams.id,
        companyId: queryParams.id_company,
        data: { ...postData, phone },
      })
    )
      .unwrap()
      .then(() => {
        addSuccessMessage({
          message: intl.formatMessage({
            id: 'page.myCompany.info.successMsg',
            defaultMessage: 'User information update!',
          }),
        });
        navigate('/my-company/admins');
      })
      .catch((err) => {
        const { errors, message } = err;
        if (errors) {
          Object.keys(errors).forEach((item) => {
            formControl.setError(item, {
              type: 'custom',
              message: errors[item][0],
            });
          });
        } else if (message) {
          addErrorMessage({
            message: message,
          });
        } else if (typeof err === 'string') {
          addErrorMessage({
            message: err,
          });
        }
      });
  };

  const getUserData = () => {
    dispatch(
      userShow({
        userId: queryParams.id,
        companyId: queryParams.id_company,
      })
    );
  };

  const getTitle = () => {
    switch (role) {
      case 'admin':
        return intl.formatMessage({
          id: 'page.myCompany.addUser.title_ad',
          defaultMessage: 'Add New Admin',
        });
      case 'super admin':
        return intl.formatMessage({
          id: 'page.sa.addUserCard.title_sa',
          defaultMessage: 'Add New Super Admin',
        });
      case 'project_leader':
        return intl.formatMessage({
          id: 'page.myCompany.addUser.title_pl',
          defaultMessage: 'Add New Project Leader',
        });
      default:
        return '';
    }
  };

  const getAlertMsgText = () => {
    switch (role) {
      case 'admin':
        return intl.formatMessage({
          id: 'page.myCompany.addUser.subTitle_ad',
          defaultMessage:
            'Admin has access to the entire system and can create other users',
        });
      case 'super admin':
        return intl.formatMessage({
          id: 'page.myCompany.addUser.subTitle_sa',
          defaultMessage:
            'Super admin has access to the entire system and can create other users and company',
        });
      case 'project_leader':
        return intl.formatMessage({
          id: 'page.myCompany.addUser.subTitle_pl',
          defaultMessage:
            'Project leaders have access to the entire system, but can not create new users',
        });
      default:
        return '';
    }
  };

  useEffect(() => {
    const title = !editPage
      ? getTitle()
      : userData
      ? intl.formatMessage(
          {
            id: 'page.myCompany.addUser.edit.title',
            defaultMessage: `Edit ${userData.firstname} ${userData.lastname} Info`,
          },
          {
            firstname: userData.firstname,
            lastname: userData.lastname,
          }
        )
      : '... ';
    document.title = title;
    setTitle(title);
    if (editPage) {
      getUserData();
    }
  }, []);

  useEffect(() => {
    if (!userData || !editPage) {
      return;
    }

    const title = !editPage
      ? getTitle()
      : userData
      ? intl.formatMessage(
          {
            id: 'page.myCompany.addUser.edit.title',
            defaultMessage: `Edit ${userData.firstname} ${userData.lastname} Info`,
          },
          {
            firstname: userData.firstname,
            lastname: userData.lastname,
          }
        )
      : '... ';
    document.title = title;
    setTitle(title);

    formControl.setValue('firstname', userData.firstname);
    formControl.setValue('lastname', userData.lastname);
    formControl.setValue('phone', userData.phone);
    formControl.setValue('email', userData.email);
  }, [userData]);

  return (
    <div className="add-user-page">
      <div className="add-user-page__inner">
        <Title
          subTitle={
            isMobile
              ? intl.formatMessage({
                  id: 'page.myCompany.admins',
                  defaultMessage: 'Admins',
                })
              : intl.formatMessage({
                  id: 'page.myCompany.title',
                  defaultMessage: 'My Company',
                })
          }
          navBackHandler={() => setCancelPopupOpen(true)}
        >
          <span className="add-user-page_main-title">{title}</span>
          {!editPage && (
            <span className="info-msg">
              <div>
                <Icon size="16px" icon="alert" />
              </div>
              {getAlertMsgText()}
            </span>
          )}
        </Title>
        <LoadedContent loader={isInviteLoading || isUserLoading} />
        <form className="add-user-page__form" onSubmit={handleSubmit(onSubmit)}>
          <div className="add-user-page__form-inner">
            <FormInput
              control={control}
              label={intl.formatMessage({
                id: 'input.label.fname',
                defaultMessage: 'First Name',
              })}
              name="firstname"
              id="firstname"
              type="firstname"
              errors={errors.firstname}
              rules={{ required: 'This field is required' }}
              disabled={isInviteLoading || isUserLoading}
              placeholder={intl.formatMessage({
                id: 'input.placeholder.fname',
                defaultMessage: 'Enter First Name',
              })}
            />
            <FormInput
              control={control}
              label={intl.formatMessage({
                id: 'input.label.lname',
                defaultMessage: 'Last Name',
              })}
              name="lastname"
              id="lastname"
              type="lastname"
              errors={errors.lastname}
              rules={{ required: 'This field is required' }}
              disabled={isInviteLoading || isUserLoading}
              placeholder={intl.formatMessage({
                id: 'input.placeholder.lname',
                defaultMessage: 'Enter Last Name*',
              })}
            />
            <FormInputMask
              control={control}
              label={intl.formatMessage({
                id: 'input.label.phone',
                defaultMessage: 'Phone',
              })}
              name="phone"
              id="phone"
              type="tel"
              inputMode="tel"
              errors={errors.phone}
              placeholder="+46 "
              disabled={isInviteLoading || isUserLoading}
              rules={{
                required: 'Field is required',
              }}
              maskChar=""
              mask="+46 99999999999"
            />
            <FormInput
              control={control}
              label={intl.formatMessage({
                id: 'input.label.mail',
                defaultMessage: 'E-mail',
              })}
              name="email"
              id="email"
              type="email"
              errors={errors.email}
              rules={{ required: 'This field is required' }}
              disabled={isInviteLoading || isUserLoading}
              placeholder={intl.formatMessage({
                id: 'input.placeholder.mail',
                defaultMessage: 'Enter Email',
              })}
            />
          </div>
          {!editPage && (
            <span className="info-msg">
              <FormattedMessage
                id="page.sa.users.inviteMailMsg"
                defaultMessage={`We will send an email to this address so that the ${role.replace(
                  '_',
                  ' '
                )} can create
              a password`}
                values={{
                  role: intl.formatMessage({
                    id: `role.${role}`,
                  }),
                }}
              />
            </span>
          )}
          <div className="add-user-page__form-btn">
            <Button
              disabled={isInviteLoading || isUserLoading}
              text={intl.formatMessage({
                id: 'btn.cancel',
                defaultMessage: 'Cancel',
              })}
              type={BUTTON_TYPE.OUTLINE}
              size={BUTTON_SIZE.LARGE}
              onClick={(e) => {
                e.preventDefault();
                e.target.blur();
                setCancelPopupOpen(true);
              }}
            />
            <Button
              disabled={isInviteLoading || isUserLoading}
              text={
                editPage
                  ? intl.formatMessage({
                      id: 'btn.save',
                      defaultMessage: 'Save',
                    })
                  : intl.formatMessage({
                      id: 'btn.create',
                      defaultMessage: 'Create',
                    })
              }
              size={BUTTON_SIZE.LARGE}
            />
          </div>
        </form>
      </div>
      <ConfirmPopup
        open={cancelPopupOpen}
        text={`${
          editPage
            ? intl.formatMessage({
                id: 'popup.cancelEditing.text',
                defaultMessage: `Are you sure that you want to cancel editing`,
              })
            : intl.formatMessage({
                id: 'popup.cancelCreating.text',
                defaultMessage: `Are you sure that you want to cancel creating`,
              })
        }`}
        isLoading={false}
        confirmButtonText={intl.formatMessage({
          id: 'popup.cancel.ok',
          defaultMessage: 'Yes, cancel',
        })}
        cancelButtonText={intl.formatMessage({
          id: 'popup.cancel.no',
          defaultMessage: 'No, continue',
        })}
        onSubmit={() => {
          navigate('/my-company/admins');
        }}
        onCloseModal={() => setCancelPopupOpen(false)}
      />
    </div>
  );
};

AddUserPage.defaultProps = {
  editPage: false,
};

AddUserPage.propTypes = {
  role: PropTypes.string.isRequired,
  editPage: PropTypes.bool.isRequired,
};

export default AddUserPage;
