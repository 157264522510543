import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  customerAddOrder,
  customerEditOrder,
  customerOrders,
} from '../../api/order';
// import { ORDERS_LIST_BY_PROJECT_SA_MOCK } from '../../mocks/orders.mock';
import { orderMapper } from '../../utils/order-mapper';

export const orders = createAsyncThunk(
  'orders/orders',
  async (creds, { rejectWithValue }) => {
    console.log(creds, creds.data);
    try {
      const response = await customerOrders(creds.companyId, creds.data);
      if (response.status === 200) {
        console.log(response.data.data);
        return orderMapper(creds.companyType, response.data);
      } else {
        return rejectWithValue(response.data);
      }
    } catch (error) {
      console.log('sliceError', error.response);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const addOrder = createAsyncThunk(
  'orders/addOrder',
  async (creds, { rejectWithValue }) => {
    console.log(creds);
    try {
      const response = await customerAddOrder(creds);
      if (response.status === 200 || response.status === 201) {
        console.log(response.data.data);
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (error) {
      console.log('sliceError', error.response);
      return rejectWithValue(error.response.data);
    }
  }
);

export const editOrder = createAsyncThunk(
  'orders/editOrder',
  async (creds, { rejectWithValue }) => {
    console.log(creds);
    try {
      console.log(creds.data);

      const response = await customerEditOrder(creds.id, creds.data);
      if (response.status === 200 || response.status === 201) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (error) {
      console.log('sliceError', error.response);
      return rejectWithValue(error.response.data);
    }
  }
);

export function changeOrderStatus(type) {
  return {
    type: 'CHANGE_ORDER_STATUS',
    companyType: type,
  };
}

const initialState = {
  isLoading: false,
  isError: false,
  data: [],
  mappedData: [],
  meta: null,
};

const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(orders.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
    });
    builder.addCase(orders.fulfilled, (state, payload) => {
      state.isLoading = false;
      state.isError = false;
      state.data = payload.payload.data;
      state.meta = payload.payload.meta;
      // TODO: MOCK
      // state.data = orderMapperSA(ORDERS_LIST_BY_PROJECT_SA_MOCK.data);
      // state.meta = ORDERS_LIST_BY_PROJECT_SA_MOCK.meta;
    });
    builder.addCase(orders.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
      // TODO: MOCK
      // state.data = orderMapperSA(ORDERS_LIST_BY_PROJECT_SA_MOCK.data);
      // state.meta = ORDERS_LIST_BY_PROJECT_SA_MOCK.meta;
    });

    builder.addCase(addOrder.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
    });
    builder.addCase(addOrder.fulfilled, (state, payload) => {
      state.isLoading = false;
      state.isError = false;
    });
    builder.addCase(addOrder.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    builder.addCase(editOrder.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
    });
    builder.addCase(editOrder.fulfilled, (state, payload) => {
      state.isLoading = false;
      state.isError = false;
    });
    builder.addCase(editOrder.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // TODO: NOT USED YET. MAP ORDERS RESPONSE
    builder.addCase('CHANGE_ORDER_STATUS', (state, action) => {
      // state.data = data;
      state.mappedData = orderMapper(state.data, action.companyType);
    });
  },
});

const { reducer } = ordersSlice;

export const selectOrder = (state) => state.order;

export default reducer;
