import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Icon from '../ui/Icon';
// chevron-up

const Accordion = ({ title, children }) => {
  const [isActive, setIsActive] = useState(true);

  return (
    <div className="accordion">
      <div className="accordion-item">
        <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
          <div className="">{title}</div>
          <div>
            {isActive ? (
              <Icon icon={'chevron-up'} size="16px" />
            ) : (
              <Icon icon={'chevron-down'} size="16px" />
            )}
          </div>
        </div>
        {isActive ? <div className="accordion-content">{children}</div> : null}
      </div>
    </div>
  );
};

Accordion.defaultProps = {};

Accordion.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

export default Accordion;
