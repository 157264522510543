import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import StepsComponent, {
  NextStepButton,
  StepPage,
} from '../../../components/ui/Steps/StepsComponent';
import Button from '../../../components/ui/Button';
import { BUTTON_SIZE, BUTTON_TYPE } from '../../../components/ui/Button/Button';
import { useForm } from 'react-hook-form';
import FormInput from '../../../components/ui/FormInput/FormInput';
import { MAIL_VALIDATOR_LOCALE } from '../../../validators/mail.validator';
import { NAME_VALIDATOR_WITH_LOCALE } from '../../../validators/name.validator';
import { PHONE_VALIDATOR_LOCALE } from '../../../validators/phone.validator';
import Dropdown from '../../../components/ui/dropdown/Dropdown';
import { addCompany } from '../../../api/company';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import LoadedContent from '../../../components/LoadedContent';
import { AlertMessengerContext } from '../../../provider/Alert';
import FormInputMask from '../../../components/ui/FormInputMask/FormInputMask';
import { ORGANIZATION_NUMBER_VALIDATOR_LOCALE } from '../../../validators/organization-number.validator';
import { validationErrorParser } from '../../../utils/validations.error.parser';
import { ZIP_CODE_VALIDATOR_LOCALE } from '../../../validators/zip-code.validator';
import { useMediaQuery } from 'react-responsive';
import { resolveTabPath } from '../../../utils/resolveTabPath';
import ConfirmPopup from '../../../components/ConfirmPopup/ConfirmPopup';
import { FormattedMessage, useIntl } from 'react-intl';

const AddCompanyPage = () => {
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [step, setStep] = useState(0);
  const [cancelPopupOpen, setCancelPopupOpen] = useState(false);

  const navigate = useNavigate();
  const intl = useIntl();

  const steps = [
    intl.formatMessage({
      id: 'page.sa.addNewCompany.steps.1',
      defaultMessage: 'Personal Info',
    }),
    intl.formatMessage({
      id: 'page.sa.addNewCompany.steps.2',
      defaultMessage: 'Company Info',
    }),
  ];

  const typeOpt = [
    {
      label: intl.formatMessage({
        id: 'company.type.customer',
        defaultMessage: 'Customer',
      }),
      value: 'customer',
    },
    {
      label: intl.formatMessage({
        id: 'company.type.supplier',
        defaultMessage: 'Supplier',
      }),
      value: 'supplier',
    },
  ];

  const specOpt = [
    {
      label: intl.formatMessage({
        id: 'company.spec.material',
        defaultMessage: 'Material',
      }),
      value: 'factory',
    },
    {
      label: intl.formatMessage({
        id: 'company.spec.service',
        defaultMessage: 'Service',
      }),
      value: 'service',
    },
    {
      label: intl.formatMessage({
        id: 'company.spec.qa',
        defaultMessage: 'Quality control',
      }),
      value: 'quality-control',
    },
  ];

  const { addErrorMessage, addSuccessMessage } = useContext(
    AlertMessengerContext
  );

  const { control, trigger, handleSubmit, watch, setError, formState } =
    useForm({
      reValidateMode: 'onChange',
      mode: 'onTouched',
      defaultValues: {
        firstname: '',
        lastname: '',
        phone: '',
        email: '',
        type: '',
        spec: null,
        name: '',
        organisationNumber: '',
        address: '',
        zipCode: '',
        city: '',
      },
    });
  const { errors } = formState;

  const typeWatch = watch('type');

  const isMobileOrTablet = useMediaQuery({
    query: '(max-width: 900px) ',
  });

  const nextStep = async () => {
    const res = await trigger(['firstname', 'lastname', 'phone', 'email']);

    if (res) {
      setStep(step + 1);
    }
  };

  const onNextStepLinkClick = async (index) => {
    const res = await trigger(['firstname', 'lastname', 'phone', 'email']);

    if (res) {
      setStep(index);
    }
  };

  const onSubmit = async (data) => {
    setIsSubmiting(true);

    const type = data.type && data.type.value;
    const spec = data.spec && data.spec.value;

    const phone = data.phone.replace(/[()_\s-]/g, '');
    // const organisationNumber = data.organisationNumber.replace(/[()_\s-]/g, '');

    const postData = {
      id: uuidv4(),
      ...data,
      phone,
      // organisationNumber,
      type,
      spec: type === 'supplier' ? spec : null,
    };

    try {
      const res = await addCompany(postData);
      console.log(res);
      setIsSubmiting(false);
      addSuccessMessage({
        message: intl.formatMessage({
          id: 'page.sa.addNewCompany.successMsg',
          defaultMessage:
            'You create a new company successful! The letter was sent to the mail',
        }),
      });
      const tabName = resolveTabPath();
      navigate(`/all-companies/${tabName}`);
    } catch ({ response }) {
      setTimeout(() => {
        window.scrollTo(0, 0);
        if (document.querySelector('.page-layout__content')) {
          document.querySelector('.page-layout__content').scrollTop = 0;
        }
      }, 100);
      const data = response && response.data;
      setErrors(data);
      setIsSubmiting(false);
    }
  };

  const setErrors = (err) => {
    if (err && err.errors) {
      const parsed = validationErrorParser(err.errors);
      let errorStr = '';

      for (const key of Object.keys(parsed)) {
        setError(key, { type: 'custom', message: parsed[key] });
        errorStr += `${key}: ${parsed[key]} `;
      }

      addErrorMessage({
        message: errorStr,
      });

      return;
    }

    addErrorMessage({
      message:
        err && err.message
          ? err.message
          : intl.formatMessage({
              id: 'page.sa.addNewCompany.errorMsg',
              defaultMessage: 'An error occured!',
            }),
    });
  };

  useEffect(() => {
    document.title = intl.formatMessage({
      id: 'page.sa.addNewCompany.title',
      defaultMessage: 'New Company',
    });

    // if (/(android)/i.test(navigator.userAgent) && !window.MSStream) {
    //   document.body.classList.add(
    //     'fix-android-company-page-keyboard-scroll-page'
    //   );
    // }

    // if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
    //   document.body.classList.add('fix-ios-company-page-keyboard-scroll-page');
    // }

    // return () => {
    //   document.body.classList.remove(
    //     'fix-android-company-page-keyboard-scroll-page'
    //   );
    //   document.body.classList.remove(
    //     'fix-ios-company-page-keyboard-scroll-page'
    //   );
    // };
  }, []);

  const formLocaleRequiredFieldMsg = () =>
    intl.formatMessage({
      id: 'input.error.requiredMsg',
      defaultMessage: 'Field is required',
    });

  const formLocaleNameValidator = () =>
    NAME_VALIDATOR_WITH_LOCALE(
      intl.formatMessage({
        id: 'input.error.minChar',
        defaultMessage: 'Field must contain at least 3 characters',
      })
    );

  const formLocalePhoneValidator = () =>
    PHONE_VALIDATOR_LOCALE(
      intl.formatMessage({
        id: 'input.error.incorrectPhone',
        defaultMessage: 'Incorrect phone',
      })
    );

  const formLocaleMailValidator = () =>
    MAIL_VALIDATOR_LOCALE(
      intl.formatMessage({
        id: 'input.error.incorrectMail',
        defaultMessage: 'Incorrect E-mail address',
      })
    );

  const formLocaleOrgNumValidator = () =>
    ORGANIZATION_NUMBER_VALIDATOR_LOCALE(
      intl.formatMessage({
        id: 'input.error.incorrectOrg',
        defaultMessage: 'Incorrect number',
      })
    );

  const formLocaleZipValidator = () =>
    ZIP_CODE_VALIDATOR_LOCALE(
      intl.formatMessage({
        id: 'input.error.incorrectZip',
        defaultMessage: 'Incorrect Zip Code',
      })
    );

  return (
    <div className="company-page new-company">
      <LoadedContent loader={isSubmiting} />
      <div className="company-page__inner">
        <div className="company-page__header">
          <span className="company-page__header-title">
            <FormattedMessage
              id="page.sa.addNewCompany.title"
              defaultMessage="Add New Company"
            />
          </span>
        </div>
        <div className="company-page__body">
          <div className="new-company__steps">
            <form onSubmit={handleSubmit(onSubmit)}>
              <StepsComponent
                steps={steps}
                activeStep={step}
                clickOnPrevStep={(index) => {
                  setStep(index);
                }}
                clickOnNextStep={(index) => {
                  onNextStepLinkClick(index);
                }}
              >
                <StepPage>
                  <FormInput
                    control={control}
                    label={intl.formatMessage({
                      id: 'input.label.fname',
                      defaultMessage: 'First Name',
                    })}
                    name="firstname"
                    id="firstname"
                    type="text"
                    errors={errors.firstname}
                    placeholder={intl.formatMessage({
                      id: 'input.placeholder.fname',
                      defaultMessage: 'Type First Name',
                    })}
                    disabled={isSubmiting}
                    rules={{
                      required: formLocaleRequiredFieldMsg(),
                      validate: formLocaleNameValidator(),
                    }}
                  />
                  <FormInput
                    control={control}
                    label={intl.formatMessage({
                      id: 'input.label.lname',
                      defaultMessage: 'Last Name',
                    })}
                    name="lastname"
                    id="lastname"
                    type="text"
                    errors={errors.lastname}
                    placeholder={intl.formatMessage({
                      id: 'input.placeholder.lname',
                      defaultMessage: 'Type Last Name',
                    })}
                    disabled={isSubmiting}
                    rules={{
                      required: formLocaleRequiredFieldMsg(),
                      validate: formLocaleNameValidator(),
                    }}
                  />
                  <FormInputMask
                    control={control}
                    label={intl.formatMessage({
                      id: 'input.label.phone',
                      defaultMessage: 'Phone',
                    })}
                    name="phone"
                    id="phone"
                    type="tel"
                    inputMode="tel"
                    errors={errors.phone}
                    placeholder="+46 "
                    disabled={isSubmiting}
                    rules={{
                      required: formLocaleRequiredFieldMsg(),
                      validate: formLocalePhoneValidator(),
                    }}
                    maskChar=""
                    mask="+46 99999999999"
                  />
                  <FormInput
                    control={control}
                    label={intl.formatMessage({
                      id: 'input.label.mail',
                      defaultMessage: 'E-mail',
                    })}
                    name="email"
                    id="email"
                    type="email"
                    errors={errors.email}
                    placeholder={intl.formatMessage({
                      id: 'input.placeholder.mail',
                      defaultMessage: 'Enter email',
                    })}
                    disabled={isSubmiting}
                    rules={{
                      required: formLocaleRequiredFieldMsg(),
                      validate: formLocaleMailValidator(),
                    }}
                  />
                  <NextStepButton>
                    <Button
                      disabled={isSubmiting}
                      text={intl.formatMessage({
                        id: 'steps.btn.cancel',
                        defaultMessage: 'Cancel',
                      })}
                      type={BUTTON_TYPE.OUTLINE}
                      size={BUTTON_SIZE.MEDIUM}
                      onClick={(e) => {
                        e.preventDefault();
                        e.target.blur();
                        setCancelPopupOpen(true);
                      }}
                    />
                    <Button
                      size={BUTTON_SIZE.MEDIUM}
                      text={intl.formatMessage({
                        id: 'steps.btn.nextStep',
                        defaultMessage: 'Next Step',
                      })}
                      onClick={() => {
                        nextStep();
                      }}
                    />
                  </NextStepButton>
                </StepPage>
                <StepPage>
                  <Dropdown
                    handleChange={(data) => console.log(data)}
                    options={typeOpt}
                    control={control}
                    label={intl.formatMessage({
                      id: 'input.label.companyType',
                      defaultMessage: 'Company Type',
                    })}
                    name="type"
                    id="type"
                    errors={errors.type}
                    disabled={isSubmiting}
                    rules={{
                      required: formLocaleRequiredFieldMsg(),
                    }}
                  />
                  {typeWatch && typeWatch.value === 'supplier' && (
                    <Dropdown
                      handleChange={(data) => console.log(data)}
                      options={specOpt}
                      control={control}
                      label={intl.formatMessage({
                        id: 'input.label.companySpec',
                        defaultMessage: 'Company Specialization',
                      })}
                      name="spec"
                      id="spec"
                      errors={errors.spec}
                      disabled={isSubmiting}
                      rules={{
                        required: formLocaleRequiredFieldMsg(),
                      }}
                    />
                  )}
                  <FormInput
                    control={control}
                    label={intl.formatMessage({
                      id: 'input.label.companyName',
                      defaultMessage: 'Company Name',
                    })}
                    name="name"
                    id="name"
                    type="text"
                    errors={errors.name}
                    placeholder={intl.formatMessage({
                      id: 'input.placeholder.companyNamelaceholder',
                      defaultMessage: 'Type Company Name',
                    })}
                    disabled={isSubmiting}
                    rules={{
                      required: formLocaleRequiredFieldMsg(),
                    }}
                  />
                  <FormInputMask
                    control={control}
                    label={intl.formatMessage({
                      id: 'input.label.orgNumber',
                      defaultMessage: 'Organization Number',
                    })}
                    name="organisationNumber"
                    id="organisationNumber"
                    type="text"
                    errors={errors.organisationNumber}
                    placeholder="______-____"
                    disabled={isSubmiting}
                    inputMode="numeric"
                    rules={{
                      required: formLocaleRequiredFieldMsg(),
                      validate: formLocaleOrgNumValidator(),
                    }}
                    mask="999999-9999"
                  />
                  <div
                    className="full-width"
                    style={{
                      width:
                        (typeWatch && typeWatch.value === 'supplier') ||
                        isMobileOrTablet
                          ? '100%'
                          : '48%',
                    }}
                  >
                    <FormInput
                      control={control}
                      label={intl.formatMessage({
                        id: 'input.label.address',
                        defaultMessage: 'Address',
                      })}
                      name="address"
                      id="address"
                      type="text"
                      errors={errors.address}
                      placeholder={intl.formatMessage({
                        id: 'input.placeholder.addressPlaceholder',
                        defaultMessage: 'Type Address',
                      })}
                      disabled={isSubmiting}
                      rules={{
                        required: formLocaleRequiredFieldMsg(),
                      }}
                    />
                  </div>
                  <FormInputMask
                    control={control}
                    label={intl.formatMessage({
                      id: 'input.label.zipCode',
                      defaultMessage: 'Zip Code',
                    })}
                    name="zipCode"
                    id="zipCode"
                    type="text"
                    errors={errors.zipCode}
                    placeholder={intl.formatMessage({
                      id: 'input.placeholder.zipPlaceholder',
                      defaultMessage: 'Type Zip Code',
                    })}
                    disabled={isSubmiting}
                    rules={{
                      required: formLocaleRequiredFieldMsg(),
                      validate: formLocaleZipValidator(),
                    }}
                    mask="999 99"
                    inputMode="numeric"
                  />
                  <FormInput
                    control={control}
                    label={intl.formatMessage({
                      id: 'input.label.city',
                      defaultMessage: 'City',
                    })}
                    name="city"
                    id="city"
                    type="text"
                    errors={errors.city}
                    placeholder={intl.formatMessage({
                      id: 'input.placeholder.cityPlaceholder',
                      defaultMessage: 'Type City',
                    })}
                    disabled={isSubmiting}
                    rules={{
                      required: formLocaleRequiredFieldMsg(),
                    }}
                  />
                  <div className="step-button">
                    <Button
                      disabled={isSubmiting}
                      text={intl.formatMessage({
                        id: 'steps.btn.cancel',
                        defaultMessage: 'Cancel',
                      })}
                      type={BUTTON_TYPE.OUTLINE}
                      size={BUTTON_SIZE.MEDIUM}
                      onClick={(e) => {
                        e.preventDefault();
                        e.target.blur();
                        setCancelPopupOpen(true);
                      }}
                    />
                    <Button
                      size={BUTTON_SIZE.MEDIUM}
                      text={intl.formatMessage({
                        id: 'page.sa.addNewCompany.steps.btn.create',
                        defaultMessage: 'Create Company',
                      })}
                      disabled={isSubmiting}
                      onClick={handleSubmit(onSubmit)}
                    />
                  </div>
                </StepPage>
              </StepsComponent>
            </form>
          </div>
        </div>
      </div>
      <ConfirmPopup
        open={cancelPopupOpen}
        text={intl.formatMessage({
          id: 'popup.sa.addNewCompany.cancel.text',
          defaultMessage:
            'Are you sure that you want to cancel creating the new company?',
        })}
        isLoading={false}
        confirmButtonText={intl.formatMessage({
          id: 'popup.cancel.ok',
          defaultMessage: 'Yes, cancel',
        })}
        cancelButtonText={intl.formatMessage({
          id: 'popup.cancel.no',
          defaultMessage: 'No, continue',
        })}
        onSubmit={() => {
          navigate(-1);
        }}
        onCloseModal={() => setCancelPopupOpen(false)}
      />
    </div>
  );
};

AddCompanyPage.propTypes = {
  props: PropTypes.object,
};

export default AddCompanyPage;
