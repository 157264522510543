/* eslint-disable no-constant-condition */
/* eslint-disable no-undef */
/* eslint-disable prefer-regex-literals */
/* eslint-disable no-unused-vars */
import { useSearchParams, useParams, useNavigate } from 'react-router-dom';
import React, { useEffect, useContext, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {
  getMyOrderSupplierSa,
  getMyOrderOfferSupplierSa,
} from '../../../../store/slice/orderOfferSupplierSaSlice';
import Title from '../../../../components/ui/Title';
import useIsMobile from '../../../../utils/useMobile';
import Accordion from '../../../../components/Accordion';
import Icon from '../../../../components/ui/Icon';
import { get } from 'lodash';
import LoadedContent from '../../../../components/LoadedContent';
import Status from '../../../../components/ui/Status/Status';
import moment from 'moment';
import FormInput from '../../../../components/ui/FormInput/FormInput';
import { useForm } from 'react-hook-form';
import Button from '../../../../components/ui/Button';
import {
  BUTTON_SIZE,
  BUTTON_TYPE,
} from '../../../../components/ui/Button/Button';
import { AlertMessengerContext } from '../../../../provider/Alert';
import ConfirmSupplierOfferPopup from '../../../../components/ConfirmSupplierOfferPopup/ConfirmSupplierOfferPopup';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { orderViewIdFormat } from '../../../../utils/order-viewid.format';
import { offersSort } from '../../../../utils/offers.sort';
import { FormattedMessage, useIntl } from 'react-intl';
import { localizeOrderInfo } from '../../../../utils/localizeOrderInfo';

const companyType = {
  factory: 'Material Supplier',
  service: 'Service Provider',
  'quality-control': 'Quality Assurance',
};

const schema = yup.object().shape({
  amount: yup
    .number()
    .typeError('Field is not number')
    .required('Field is required')
    .min(1, 'Field is required'),
});

const HomeOrders = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [itsYourOffer, setItsYourOffer] = useState();

  const isOrderLoading = useSelector(
    (state) => state.orderSupplierSA.isLoading
  );
  const orderProject = useSelector((state) => state.orderSupplierSA.data);
  const isErrorOrder = useSelector((state) => state.orderSupplierSA.isError);
  const [loader, setLoader] = useState(false);
  const intl = useIntl();

  const localeMsg = (key, defVal) =>
    intl.formatMessage({
      id: `order.${key}`,
      defaultMessage: defVal,
    });

  const isDictionaryLoading = useSelector(
    (state) => state.dictionary.isDictionaryLoading
  );
  const dictionary = useSelector((state) => state.dictionary.mappedDictionary);

  const contentRowsAccordion = {
    1: {
      title: localeMsg('project', 'Project'),
      row: [
        {
          text: localeMsg('customer', 'Customer'),
          id: 'project.company.name',
          link: true,
          id_project: true,
        },
        {
          text: localeMsg('project.name', 'Project name'),
          id: 'project.name',
          link: true,
          id_project: true,
        },
        {
          text: localeMsg('project.marking', 'Marking'),
          id: 'project.marking',
        },
        {
          text: localeMsg('project.address', 'Adress'),
          id: 'project.address',
        },
        {
          text: localeMsg('project.zipCode', 'ZIP'),
          id: 'project.zipCode',
          regexp: { first: /(.{3})/, second: '$1 ' },
        },
        {
          text: localeMsg('project.city', 'City'),
          id: 'project.city',
        },
        {
          text: localeMsg('project.comment', 'Comment'),
          id: 'project.comment',
        },
      ],
    },
    2: {
      title: localeMsg('step.1', 'Project info'),
      row: [
        {
          text: localeMsg('dateOfExecution', 'Date of execution'),
          id: 'dateOfExecution',
          itsFullDate: true,
        },
        {
          text: localeMsg('contactPerson', 'Contact person'),
          id: 'project.contactPerson',
        },
        {
          text: localeMsg('phone', 'Phone'),
          id: 'project.phone',
          link: true,
          phone: true,
        },
        {
          text: localeMsg('email', 'Email'),
          id: 'project.email',
          link: true,
          email: true,
        },
        {
          text: localeMsg('comment', 'Comment'),
          id: 'orderDetailFirstComment',
        },
      ],
    },
    3: {
      title: localeMsg('step.2', 'Area/Volym'),
      row: [
        {
          text: localeMsg('location', 'Läge'),
          id: 'location',
        },
        {
          text: localeMsg('pump', 'Pump'),
          id: 'pump',
        },
        {
          text: localeMsg('surfaceType', 'Yttyp'),
          id: 'surfaceType',
        },
        {
          text: localeMsg('area', 'Area, m²'),
          id: 'area',
        },
        {
          text: localeMsg('volume', 'Volume, m³'),
          id: 'volume',
        },
        {
          text: localeMsg('slopeOrFall', 'Lutning/fall'),
          id: 'slopeOrFall',
        },
        {
          text: localeMsg('comment', 'Comment'),
          id: 'orderDetailSecondComment',
        },
      ],
    },
    4: {
      title: localeMsg('step.3', 'Concrete'),
      row: [
        {
          text: localeMsg('variety', 'Sort'),
          id: 'variety',
        },
        {
          text: localeMsg('exposureClass', 'Exposure class'),
          id: 'exposureClass',
        },
        {
          text: localeMsg('vct', 'Vct'),
          id: 'vct',
        },
        {
          text: localeMsg('dmax', 'Dmax'),
          id: 'dmax',
        },
        {
          text: localeMsg('seatDimensions', 'Sättmått'),
          id: 'seatDimensions',
        },
        {
          text: localeMsg('additive', 'Tillsats'),
          id: 'additive',
        },
        {
          text: localeMsg('cementVariety', 'Cementsort'),
          id: 'cementVariety',
        },
        {
          text: localeMsg('heat', 'Värme'),
          id: 'heat',
        },
        {
          text: localeMsg('comment', 'Comment'),
          id: 'orderDetailSecondComment',
        },
      ],
    },
    5: {
      title: localeMsg('step.4', 'Time'),
      row: [
        {
          text: localeMsg('timeOfStart', 'Start time'),
          id: 'timeOfStart',
        },
        {
          text: localeMsg('interval', 'Interval, min'),
          id: 'interval',
        },
        {
          text: localeMsg('comment', 'Comment'),
          id: 'orderDetailThirdComment',
        },
      ],
    },
    6: {
      title: localeMsg('step.5', 'Control'),
      row: [
        {
          text: localeMsg('orderClass', 'Class'),
          id: 'orderClass',
        },
        {
          text: localeMsg('coverage', 'Coverage'),
          id: 'coverage',
        },
        {
          text: localeMsg('comment', 'Comment'),
          id: 'orderDetailFourthComment',
        },
      ],
    },
  };

  const {
    control,
    trigger,
    handleSubmit,
    watch,
    formState,
    reset,
    ...formControl
  } = useForm({
    reValidateMode: 'onBlur',
    mode: 'onTouched',
    defaultValues: {
      amount: '',
    },
    resolver: yupResolver(schema),
  });
  const { errors } = formState;

  const orderOffer = useSelector((state) => state.orderSupplierSA.dataOffer);
  const isOfferLoading = useSelector(
    (state) => state.orderSupplierSA.isListOfferLoading
  );
  const isErrorOffer = useSelector(
    (state) => state.orderSupplierSA.isOfferError
  );
  const withoutOffer = useSelector(
    (state) => state.orderSupplierSA.withoutOffer
  );

  const [orderStatus, setStatus] = useState(orderProject.status);

  useEffect(() => {
    if (
      orderProject.status === 'in_progress' &&
      !isOfferLoading &&
      (!orderProject.acceptOffers?.filter(
        (item) => item.company.id === params.id_company
      )?.length ||
        orderOffer?.status === 'reject')
    ) {
      setStatus('declined');
      return;
    }
    if (orderProject.status !== 'new_order') {
      setStatus(orderProject.status);
      return;
    }

    if (
      orderProject.status === 'new_order' &&
      !isOfferLoading &&
      !withoutOffer &&
      orderOffer &&
      orderOffer.company?.id === params.id_company
    ) {
      setStatus('waiting_for_answer');
    }

    if (
      orderProject.status === 'new_order' &&
      !isOfferLoading &&
      !withoutOffer
    ) {
      setStatus('waiting_for_answer');
    }

    if (
      orderProject.status === 'new_order' &&
      !isOfferLoading &&
      (withoutOffer ||
        (orderOffer && orderOffer?.company.id !== params.id_company))
    ) {
      setStatus(orderProject.status);
    }
  }, [orderProject.status, orderOffer]);

  useEffect(() => {
    if (orderOffer?.id && orderOffer?.company?.id === params.id_company) {
      reset({
        amount: orderOffer.amount,
      });
      setItsYourOffer(orderOffer.company.id === params.id_company);
    }
  }, [orderOffer]);

  const [searchParams] = useSearchParams();
  const pageQuery = searchParams.get('page') || 1;
  const specQuery = searchParams.get('spec') || 'factory';
  const params = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    document.title = intl.formatMessage({
      id: `page.orderDetail.title`,
      defaultMessage: 'Order',
    });
    formControl.setValue('amount', '');
  }, []);

  useEffect(() => {
    dispatch(
      getMyOrderSupplierSa({
        companyId: params.id_company,
        orderId: params.id_order,
      })
    );
    dispatch(
      getMyOrderOfferSupplierSa({
        orderId: params.id_order,
        companyId: params.id_company,
      })
    );
  }, [orderProject.info]);

  useEffect(() => {
    // if (orderProject.info) {
    //   if (orderProject.status.length && orderProject.status !== 'in_progress') {
    //   }
    // }
  }, [pageQuery, specQuery]);

  const { addErrorMessage, addSuccessMessage } = useContext(
    AlertMessengerContext
  );

  function numberWithSpaces(x) {
    const parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    return parts.join('.');
  }

  const isMobile = useIsMobile();

  const getCountdown = (ending) => {
    const now = moment(new Date());
    const end = moment(ending); // another date
    const duration = moment.duration(end.diff(now));

    // Get Days and subtract from duration
    const days = duration.days();
    duration.subtract(days, 'days');

    // Get hours and subtract from duration
    const hours = duration.hours();
    duration.subtract(hours, 'hours');

    // Get Minutes and subtract from duration
    const minutes = duration.minutes();
    duration.subtract(minutes, 'minutes');

    // Get seconds
    // const seconds = duration.seconds();
    return {
      days: 0,
      hours: hours + days * 24,
      min: minutes,
    };
    // console.log('Days: ', days);
  };

  const getTimeLink = () => (
    <span className="time-link">
      {getCountdown(orderProject?.expiredAt).days > 0 ? (
        <>
          {getCountdown(orderProject?.expiredAt).days}
          {' days '}
        </>
      ) : null}
      {getCountdown(orderProject?.expiredAt).hours > 0 ? (
        <>
          {' '}
          {getCountdown(orderProject?.expiredAt).hours}{' '}
          {intl.formatMessage({
            id: `hh`,
            defaultMessage: ' hours ',
          })}
        </>
      ) : null}
      {getCountdown(orderProject?.expiredAt).min > 0 ? (
        <>
          {' '}
          {getCountdown(orderProject?.expiredAt).min}{' '}
          {intl.formatMessage({
            id: `mm`,
            defaultMessage: ' min ',
          })}
        </>
      ) : null}
    </span>
  );

  const localize = (item) => {
    if (!isDictionaryLoading && dictionary) {
      const localize = localizeOrderInfo(
        item.id,
        get(orderProject, item.id),
        dictionary
      );

      return localize || get(orderProject, item.id);
    }

    return get(orderProject, item.id);
  };

  return (
    <div className="order-page order-offer supplier">
      <div className="order-page__content">
        {!isMobile ? (
          <div className="order-page__content-header">
            <Title
              infoContent={
                <div>
                  <div className="order-page__content-viewid">
                    {orderViewIdFormat(orderProject.viewId)}
                  </div>
                  <div className="order-page__content-project">
                    {orderProject.project?.name}
                  </div>
                  <div className="status-container">
                    <Status type={orderStatus} />
                    {orderProject &&
                      (orderProject.edited || orderProject.inEditing) && (
                        <span className="edited-label">
                          <FormattedMessage
                            id={`status.${
                              !orderProject.inEditing && orderProject.edited
                                ? 'edited'
                                : 'editing'
                            }`}
                            defaultMessage={
                              !orderProject.inEditing && orderProject.edited
                                ? 'Edited'
                                : 'Editing'
                            }
                          />
                        </span>
                      )}
                  </div>
                </div>
              }
              subTitle={
                isMobile
                  ? intl.formatMessage({
                      id: `order.mainName_2`,
                      defaultMessage: 'Concrete casting',
                    })
                  : intl.formatMessage({
                      id: `page.orderDetail.title`,
                      defaultMessage: 'Order',
                    })
              }
            >
              {isMobile
                ? intl.formatMessage({
                    id: `page.orderDetail.title`,
                    defaultMessage: 'Order',
                  })
                : intl.formatMessage({
                    id: `order.mainName_2`,
                    defaultMessage: 'Concrete casting',
                  })}
            </Title>
            <div>
              {(!itsYourOffer && orderOffer?.id) || withoutOffer === true ? (
                <div className="order-page-info">
                  {localStorage.getItem('companySpec') && (
                    <h3>
                      <FormattedMessage
                        id="page.orderDetail.makeOffer.title"
                        defaultMessage="Make an Offer as"
                      />{' '}
                      <FormattedMessage
                        id={`company.supplier.${localStorage.getItem(
                          'companySpec'
                        )}`}
                        defaultMessage={
                          companyType[localStorage.getItem('companySpec')]
                        }
                      />
                    </h3>
                  )}

                  {(getCountdown(orderProject?.expiredAt).days > 0 ||
                    getCountdown(orderProject?.expiredAt).hours > 0 ||
                    getCountdown(orderProject?.expiredAt).days > 0) && (
                    <>
                      <div className="order-page-info_header">
                        <Icon icon="timer" size="20px" />
                        <FormattedMessage
                          id="page.orderDetail.makeOfferTime.title"
                          defaultMessage={`You have ${getTimeLink()} to make an offer`}
                          values={{
                            node: getTimeLink(),
                          }}
                        />
                      </div>
                    </>
                  )}

                  <div className="order-page-info_header">
                    <Icon icon="alert" size="20px" />
                    <FormattedMessage
                      id="page.orderDetail.makeOffer.warning"
                      defaultMessage="If you send an amount, you will not be able to edit or
                    cancel offer"
                    />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        ) : null}

        {isOfferLoading && isOrderLoading ? (
          <LoadedContent loader={true} />
        ) : (
          <>
            {itsYourOffer && orderOffer?.id ? (
              <div className="order-offer__card">
                <h3
                  style={{
                    color:
                      orderProject?.status !== 'in_progress' &&
                      orderProject?.status !== 'completed' &&
                      orderProject?.status !== 'waiting_for_answer' &&
                      orderProject?.status !== 'new' &&
                      orderProject?.status !== 'new_order'
                        ? 'var(--black-primary)'
                        : orderStatus === 'declined'
                        ? 'var(--black-primary)'
                        : 'var(--green-accept)',
                  }}
                >
                  {(orderProject.status !== 'in_progress' &&
                    orderProject.status !== 'completed') ||
                  orderStatus === 'declined' ? (
                    <FormattedMessage
                      id="component.offerCard.makeOffer.sentMsg"
                      defaultMessage={`Your Offer as ${
                        companyType[orderOffer?.company?.spec]
                      }
                    has been sent`}
                      values={{
                        spec: intl.formatMessage({
                          id: `company.supplier.${orderOffer?.company?.spec}`,
                        }),
                      }}
                    />
                  ) : (
                    <FormattedMessage
                      id="component.offerCard.makeOffer.acceptedMsg"
                      defaultMessage={`Your Offer as ${
                        companyType[orderOffer?.company?.spec]
                      }
                    accepted`}
                      values={{
                        spec: intl.formatMessage({
                          id: `company.supplier.${orderOffer?.company?.spec}`,
                        }),
                      }}
                    />
                  )}
                </h3>
                <div className="order-offer__card-description">
                  <FormattedMessage
                    id="component.offerCard.total"
                    defaultMessage="Quotation amount (excl. VAT)"
                  />
                </div>
                <div className="order-offer__card-count">
                  {numberWithSpaces(orderOffer?.amount)} kr
                </div>
                {orderProject?.acceptOffers?.filter(
                  (item) => item.company.id !== params.id_company
                ).length &&
                (orderProject.status === 'in_progress' ||
                  orderProject.status === 'completed') ? (
                  <>
                    <div className="order-offer__card-title_offer">
                      <FormattedMessage
                        id="component.offerCard.otherSupp"
                        defaultMessage="Other Suppliers of Order"
                      />
                    </div>
                    {offersSort(
                      orderProject.acceptOffers.filter(
                        (item) => item.company.id !== params.id_company
                      )
                    ).map((item, index) => (
                      <div className="order-card__selected-item" key={index}>
                        <span>
                          <FormattedMessage
                            id={`company.supplier.${item.company.spec}`}
                            defaultMessage={companyType[item.company.spec]}
                          />
                        </span>
                        <a
                          className="link"
                          onClick={(e) => {
                            e.preventDefault();
                            navigate(
                              `/all-companies/${
                                item.company.spec === 'service'
                                  ? 'providers'
                                  : item.company.spec === 'quality-control'
                                  ? 'quality'
                                  : 'supplier'
                              }/company/${item.company.id}`
                            );
                          }}
                        >
                          {item.company.name}
                        </a>
                      </div>
                    ))}
                  </>
                ) : null}
              </div>
            ) : (
              <div className="order-page-order_create">
                <FormInput
                  control={control}
                  label={intl.formatMessage({
                    id: `component.offerCard.total`,
                    defaultMessage: 'Offert (exkl. moms)',
                  })}
                  name="amount"
                  id="amount"
                  type="text"
                  errors={errors.amount}
                  placeholder="0"
                  rules={{
                    required: 'Field is required',
                  }}
                  disabled={true}
                />

                <Button
                  size={BUTTON_SIZE.MEDIUM}
                  text={intl.formatMessage({
                    id: `page.orderDetail.makeOffer.make`,
                    defaultMessage: 'Make an offer',
                  })}
                  onClick={() => {
                    if (errors.amount === undefined) {
                      handleSubmit(setOpen(true));
                    }
                  }}
                  disabled={true}
                />

                {!(withoutOffer === true) &&
                orderOffer?.company?.id === params.id_company ? (
                  <div className="order-offer__card-your_company">
                    <FormattedMessage
                      id="page.orderDetail.makeOffer.exist"
                      defaultMessage="Your company sent an offer for this order"
                    />
                  </div>
                ) : (
                  <Button
                    type={BUTTON_TYPE.TERTIARY}
                    size={BUTTON_SIZE.MEDIUM}
                    disabled={true}
                    text={intl.formatMessage({
                      id: `page.orderDetail.makeOffer.decline`,
                      defaultMessage: 'Decline this order',
                    })}
                    onClick={() => {}}
                  />
                )}
              </div>
            )}
          </>
        )}
      </div>

      {orderProject.status !== 'in_progress' && isMobile ? (
        <div>
          {(!itsYourOffer && orderOffer?.id) ||
            (withoutOffer === true && (
              <div className="order-page-info">
                {localStorage.getItem('companySpec') && (
                  <h3>
                    <FormattedMessage
                      id="page.orderDetail.makeOffer.title"
                      defaultMessage="Make an Offer as"
                    />{' '}
                    <FormattedMessage
                      id={`company.supplier.${localStorage.getItem(
                        'companySpec'
                      )}`}
                      defaultMessage={
                        companyType[localStorage.getItem('companySpec')]
                      }
                    />
                  </h3>
                )}
                {(getCountdown(orderProject?.expiredAt).days > 0 ||
                  getCountdown(orderProject?.expiredAt).hours > 0 ||
                  getCountdown(orderProject?.expiredAt).days > 0) && (
                  <>
                    <div className="order-page-info_header">
                      <Icon icon="timer" size="20px" />
                      <FormattedMessage
                        id="page.orderDetail.makeOfferTime.title"
                        defaultMessage={`You have ${getTimeLink()} to make an offer`}
                        values={{
                          node: getTimeLink(),
                        }}
                      />
                    </div>
                  </>
                )}
                <div className="order-page-info_header">
                  <Icon icon="alert" size="20px" />
                  <FormattedMessage
                    id="page.orderDetail.makeOffer.warning"
                    defaultMessage="If you send an amount, you will not be able to edit or
                    cancel offer"
                  />
                </div>
              </div>
            ))}
        </div>
      ) : null}

      <div className="order-page__info mobile">
        {!isOrderLoading && isErrorOrder ? (
          <div className="empty-list">
            <div className="empty-list__icon">
              <Icon icon="alert" size="32px" />
            </div>
            <span className="empty-list__title">
              <FormattedMessage
                id="page.orderDetail.noDetailMsg"
                defaultMessage="You have no detail information"
              />
            </span>
          </div>
        ) : (
          <div className="relative">
            <LoadedContent loader={isOrderLoading && orderProject.status} />
            <div className="order-page__info-title">
              <div className="title">
                <FormattedMessage
                  id="page.orderDetail.infoTitle"
                  defaultMessage="Detailed information"
                />
              </div>
              <div className="row mb-8">
                <div className="row-title">
                  <div className="icon_cyrcle">
                    <Icon icon="calendar" stroke={'#4446B0'} size="20px" />
                  </div>
                  <FormattedMessage
                    id="order.dateOfExecution"
                    defaultMessage="Date of execution"
                  />
                </div>
                <div className="bold">
                  {moment(orderProject.dateOfExecution).format('YYYY.MM.DD')}
                </div>
              </div>

              <div className="row mb-16">
                <div className="row-title">
                  <div className="icon_cyrcle">
                    <Icon icon="time" stroke={'#4446B0'} size="20px" />
                  </div>
                  <FormattedMessage
                    id="order.timeOfStart"
                    defaultMessage="Start time"
                  />
                </div>
                <div className="bold">
                  {orderProject.timeOfStart &&
                    moment(orderProject.timeOfStart, 'hh:mm:ss').format(
                      'HH:mm'
                    )}
                </div>
              </div>

              <div className="row">
                <div className="row-title">
                  <FormattedMessage id="company.city" defaultMessage="City" />
                </div>
                <div className="row-description">
                  {orderProject.project?.city}
                </div>
              </div>
              <div className="row">
                <div className="row-title">
                  <FormattedMessage id="order.area" defaultMessage="Area, m²" />
                </div>
                <div className="row-description">{orderProject.area}</div>
              </div>
              <div className="row">
                <div className="row-title">
                  <FormattedMessage
                    id="order.volume"
                    defaultMessage="Volume, m³"
                  />
                </div>
                <div className="row-description">{orderProject.volume}</div>
              </div>
            </div>

            {orderOffer?.acceptOffers?.length ? (
              <div className="order-offer_detail">
                <FormattedMessage
                  id="page.orderDetail.infoTitle"
                  defaultMessage="Detailed information"
                />
              </div>
            ) : null}

            {isMobile ? (
              <div className="order-offer_detail">
                <FormattedMessage
                  id="page.orderDetail.infoTitle"
                  defaultMessage="Detailed information"
                />
              </div>
            ) : null}

            {Object.keys(contentRowsAccordion).map((item) => {
              return (
                <Accordion
                  key={contentRowsAccordion[item]}
                  title={contentRowsAccordion[item].title}
                >
                  {contentRowsAccordion[item].row.map((item) => {
                    if (!get(orderProject, item.id)) {
                      return '';
                    }
                    return (
                      <div key={item.id} className="row">
                        <div className={'row-title'}>{item.text}</div>
                        <div
                          onClick={() => {
                            if (item.link) {
                              if (item.id_project) {
                                navigate(
                                  `/all-companies/customers/company/${get(
                                    orderProject,
                                    'project.company.id'
                                  )}/projects/${get(
                                    orderProject,
                                    'project.id'
                                  )}`
                                );
                              } else if (item.phone) {
                                document.location.href = `tel:${get(
                                  orderProject,
                                  item.id
                                )}`;
                              } else if (item.email) {
                                window.location.href = `mailto:${get(
                                  orderProject,
                                  item.id
                                )}`;
                              }
                            }
                          }}
                          className={
                            item.link
                              ? 'row-description-link link'
                              : 'row-description'
                          }
                        >
                          {/* {item.regexp} */}
                          {item?.itsFullDate
                            ? moment(get(orderProject, item.id)).format(
                                'YYYY.MM.DD'
                              )
                            : item?.itsTimeDate
                            ? moment(
                                get(orderProject, item.id),
                                'hh:mm:ss'
                              ).format('HH:mm')
                            : item?.regexp?.first
                            ? get(orderProject, item.id)?.replace(
                                item.regexp.first,
                                item.regexp.second
                              )
                            : localize(item)}
                        </div>
                      </div>
                    );
                  })}
                </Accordion>
              );
            })}
          </div>
        )}
      </div>
      {isMobile ? (
        <div>
          <Title
            infoContent={
              <div>
                <div className="order-page__content-viewid">
                  {orderViewIdFormat(orderProject.viewId)}
                </div>
                <div className="order-page__content-project">
                  {orderProject.project?.name}
                </div>
                <div className="status-container">
                  {' '}
                  <Status type={orderStatus} />
                  {orderProject &&
                    (orderProject.edited || orderProject.inEditing) && (
                      <span className="edited-label">
                        <FormattedMessage
                          id={`status.${
                            !orderProject.inEditing && orderProject.edited
                              ? 'edited'
                              : 'editing'
                          }`}
                          defaultMessage={
                            !orderProject.inEditing && orderProject.edited
                              ? 'Edited'
                              : 'Editing'
                          }
                        />
                      </span>
                    )}
                </div>
              </div>
            }
            subTitle={
              !isMobile
                ? intl.formatMessage({
                    id: `order.mainName_2`,
                    defaultMessage: 'Concrete casting',
                  })
                : intl.formatMessage({
                    id: `page.orderDetail.title`,
                    defaultMessage: 'Order',
                  })
            }
          >
            {!isMobile
              ? intl.formatMessage({
                  id: `page.orderDetail.title`,
                  defaultMessage: 'Order',
                })
              : intl.formatMessage({
                  id: `order.mainName_2`,
                  defaultMessage: 'Concrete casting',
                })}
          </Title>
        </div>
      ) : null}
      <ConfirmSupplierOfferPopup
        open={open}
        text={watch('amount')}
        isLoading={loader}
        onSubmit={handleSubmit(() => {})}
        onCloseModal={() => setOpen(false)}
        inEditing={orderProject.inEditing}
      />
    </div>
  );
};

HomeOrders.defaultProps = {};

HomeOrders.propTypes = {};

export default HomeOrders;
