import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import StepsComponent, {
  NextStepButton,
  StepPage,
} from '../../../components/ui/Steps/StepsComponent';
import Button from '../../../components/ui/Button';
import { BUTTON_SIZE, BUTTON_TYPE } from '../../../components/ui/Button/Button';
import { useForm } from 'react-hook-form';
import FormInput from '../../../components/ui/FormInput/FormInput';
import { MAIL_VALIDATOR_LOCALE } from '../../../validators/mail.validator';
import { NAME_VALIDATOR_WITH_LOCALE } from '../../../validators/name.validator';
import { PHONE_VALIDATOR_LOCALE } from '../../../validators/phone.validator';
import { ORGANIZATION_NUMBER_VALIDATOR_LOCALE } from '../../../validators/organization-number.validator';
import { ZIP_CODE_VALIDATOR_LOCALE } from '../../../validators/zip-code.validator';
import {
  editCustomerCompanyReq,
  editCompanyUserReq,
  getCompany,
} from '../../../api/company';
import { useNavigate, useParams } from 'react-router-dom';
import LoadedContent from '../../../components/LoadedContent';
import { AlertMessengerContext } from '../../../provider/Alert';
import FormInputMask from '../../../components/ui/FormInputMask/FormInputMask';
import { validationErrorParser } from '../../../utils/validations.error.parser';
import { resolveTabPath } from '../../../utils/resolveTabPath';
import ConfirmPopup from '../../../components/ConfirmPopup/ConfirmPopup';
import { FormattedMessage, useIntl } from 'react-intl';
import Dropdown from '../../../components/ui/dropdown/Dropdown';
import { useMediaQuery } from 'react-responsive';

const EditCompanyPage = () => {
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [isCompanyDataFetch, setIsCompanyDataFetch] = useState(false);
  const [cancelPopupOpen, setCancelPopupOpen] = useState(false);

  const [companyName, setCompanyName] = useState('');
  const [companyData, setCompanyData] = useState('');
  const [companyUser, setCompanyUser] = useState('');
  const [companySpec, setCompanySpec] = useState();
  const [step, setStep] = useState(0);
  const params = useParams();
  const navigate = useNavigate();
  const intl = useIntl();

  const steps = [
    intl.formatMessage({
      id: 'page.sa.addNewCompany.steps.1',
      defaultMessage: 'Personal Info',
    }),
    intl.formatMessage({
      id: 'page.sa.addNewCompany.steps.2',
      defaultMessage: 'Company Info',
    }),
  ];

  const typeOpt = [
    {
      label: intl.formatMessage({
        id: 'company.type.customer',
        defaultMessage: 'Customer',
      }),
      value: 'customer',
    },
    {
      label: intl.formatMessage({
        id: 'company.type.supplier',
        defaultMessage: 'Supplier',
      }),
      value: 'supplier',
    },
  ];

  const specOpt = [
    {
      label: intl.formatMessage({
        id: 'company.spec.material',
        defaultMessage: 'Material',
      }),
      value: 'factory',
    },
    {
      label: intl.formatMessage({
        id: 'company.spec.service',
        defaultMessage: 'Service',
      }),
      value: 'service',
    },
    {
      label: intl.formatMessage({
        id: 'company.spec.qa',
        defaultMessage: 'Quality control',
      }),
      value: 'quality-control',
    },
  ];

  const { addErrorMessage, addSuccessMessage } = useContext(
    AlertMessengerContext
  );

  const { control, reset, trigger, setError, handleSubmit, formState } =
    useForm({
      reValidateMode: 'onChange',
      mode: 'onTouched',
      defaultValues: {
        firstname: '',
        lastname: '',
        phone: '',
        email: '',
        name: '',
        organisationNumber: '',
        address: '',
        zipCode: '',
        city: '',
      },
    });
  const { errors } = formState;

  const nextStep = async () => {
    const res = await trigger(['firstname', 'lastname', 'phone', 'email']);

    if (res) {
      setStep(step + 1);
    }
  };

  const onNextStepLinkClick = async (index) => {
    const res = await trigger(['firstname', 'lastname', 'phone', 'email']);

    if (res) {
      setStep(index);
    }
  };

  const onSubmit = async (data) => {
    setIsSubmiting(true);

    const phone = data.phone.replace(/[()_\s-]/g, '');
    // const organisationNumber = data.organisationNumber.replace(/[()_\s-]/g, '');

    const companyPayload = {
      name: data.name,
      organisationNumber: data.organisationNumber,
      address: data.address,
      zipCode: data.zipCode,
      city: data.city,
    };

    const userPayload = {
      firstname: data.firstname,
      lastname: data.lastname,
      phone,
      email: data.email,
    };

    try {
      // eslint-disable-next-line no-unused-vars
      const companyRes = await editCustomerCompanyReq(
        companyData.id,
        companyPayload
      );
      // eslint-disable-next-line no-unused-vars
      const userRes = await editCompanyUserReq(
        companyData.id,
        companyUser.id,
        userPayload
      );

      setIsSubmiting(false);
      addSuccessMessage({
        message: intl.formatMessage({
          id: 'page.sa.addNewCompany.successEditMsg',
          defaultMessage: 'Company information has been successfully edited',
        }),
      });

      const tabName = resolveTabPath();
      navigate(`/all-companies/${tabName}`);
    } catch ({ response }) {
      setTimeout(() => {
        window.scrollTo(0, 0);
        if (document.querySelector('.page-layout__content')) {
          document.querySelector('.page-layout__content').scrollTop = 0;
        }
      }, 100);
      const data = response && response.data;
      setErrors(data);
      setIsSubmiting(false);
    }
  };

  const fetchCompany = async (id) => {
    setIsCompanyDataFetch(true);

    try {
      const res = await getCompany(id);

      if (res && res.data && res.data.data) {
        const data = res.data.data;
        const adminData =
          data.admins && data.admins.length ? data.admins[0] : null;

        setCompanyName(data.name);
        setCompanyData(res.data.data);
        setCompanyUser(adminData);
        setCompanySpec(data.spec);
        reset({
          firstname: adminData.firstname,
          lastname: adminData.lastname,
          phone: adminData.phone,
          email: adminData.email,
          type: typeOpt.find((item) => item.value === data.type) || null,
          spec: specOpt.find((item) => item.value === data.spec) || null,
          name: data.name,
          organisationNumber: data.organisationNumber,
          address: data.address,
          zipCode: data.zipCode,
          city: data.city,
        });
        setIsCompanyDataFetch(false);
      }
    } catch (err) {
      setIsCompanyDataFetch(false);
    }
  };

  const setErrors = (err) => {
    if (err && err.errors) {
      const parsed = validationErrorParser(err.errors);
      let errorStr = '';

      for (const key of Object.keys(parsed)) {
        setError(key, { type: 'custom', message: parsed[key] });
        errorStr += `${key}: ${parsed[key]} `;
      }

      addErrorMessage({
        message: errorStr,
      });

      return;
    }

    addErrorMessage({
      message:
        err && err.message
          ? err.message
          : intl.formatMessage({
              id: 'page.sa.addNewCompany.errorMsg',
              defaultMessage: 'An error occured!',
            }),
    });
  };

  useEffect(() => {
    document.title = intl.formatMessage(
      {
        id: 'page.sa.addNewCompany.editTitle',
        defaultMessage: `Company ${companyName}`,
      },
      {
        value: companyName,
      }
    );

    // if (/(android)/i.test(navigator.userAgent) && !window.MSStream) {
    //   document.body.classList.add(
    //     'fix-android-company-page-keyboard-scroll-page'
    //   );
    // }

    // if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
    //   document.body.classList.add('fix-ios-company-page-keyboard-scroll-page');
    // }

    // return () => {
    //   document.body.classList.remove(
    //     'fix-android-company-page-keyboard-scroll-page'
    //   );
    //   document.body.classList.remove(
    //     'fix-ios-company-page-keyboard-scroll-page'
    //   );
    // };
  }, []);

  useEffect(() => {
    document.title = intl.formatMessage(
      {
        id: 'page.sa.addNewCompany.editTitle',
        defaultMessage: `Company ${companyName}`,
      },
      {
        value: companyName,
      }
    );
  }, [companyName]);

  useEffect(() => {
    if (params && params.id) {
      fetchCompany(params.id);
    }
  }, [params]);

  const formLocaleRequiredFieldMsg = () =>
    intl.formatMessage({
      id: 'input.error.requiredMsg',
      defaultMessage: 'Field is required',
    });

  const formLocaleNameValidator = () =>
    NAME_VALIDATOR_WITH_LOCALE(
      intl.formatMessage({
        id: 'input.error.minChar',
        defaultMessage: 'Field must contain at least 3 characters',
      })
    );

  const formLocalePhoneValidator = () =>
    PHONE_VALIDATOR_LOCALE(
      intl.formatMessage({
        id: 'input.error.incorrectPhone',
        defaultMessage: 'Incorrect phone',
      })
    );

  const formLocaleMailValidator = () =>
    MAIL_VALIDATOR_LOCALE(
      intl.formatMessage({
        id: 'input.error.incorrectMail',
        defaultMessage: 'Incorrect E-mail address',
      })
    );

  const formLocaleOrgNumValidator = () =>
    ORGANIZATION_NUMBER_VALIDATOR_LOCALE(
      intl.formatMessage({
        id: 'input.error.incorrectOrg',
        defaultMessage: 'Incorrect number',
      })
    );

  const formLocaleZipValidator = () =>
    ZIP_CODE_VALIDATOR_LOCALE(
      intl.formatMessage({
        id: 'input.error.incorrectZip',
        defaultMessage: 'Incorrect Zip Code',
      })
    );

  const isMobileOrTablet = useMediaQuery({
    query: '(max-width: 900px) ',
  });

  return (
    <div className="company-page new-company edit-company-page">
      <LoadedContent loader={isCompanyDataFetch || isSubmiting} />
      <div className="company-page__inner">
        <div className="company-page__header">
          <span className="company-page__header-title">
            <FormattedMessage
              id="page.sa.addNewCompany.editTitle_2"
              defaultMessage={`Edit Company ${companyName}`}
              values={{
                value: companyName,
              }}
            />
          </span>
        </div>
        <div className="company-page__body">
          <div className="new-company__steps">
            <form onSubmit={handleSubmit(onSubmit)}>
              <StepsComponent
                steps={steps}
                activeStep={step}
                clickOnPrevStep={(index) => {
                  setStep(index);
                }}
                clickOnNextStep={(index) => {
                  onNextStepLinkClick(index);
                }}
              >
                <StepPage>
                  <FormInput
                    control={control}
                    label={intl.formatMessage({
                      id: 'input.label.fname',
                      defaultMessage: 'First Name',
                    })}
                    name="firstname"
                    id="firstname"
                    type="text"
                    errors={errors.firstname}
                    placeholder={intl.formatMessage({
                      id: 'input.placeholder.fname',
                      defaultMessage: 'Type First Name',
                    })}
                    disabled={isSubmiting || isCompanyDataFetch}
                    rules={{
                      required: formLocaleRequiredFieldMsg(),
                      validate: formLocaleNameValidator(),
                    }}
                  />
                  <FormInput
                    control={control}
                    label={intl.formatMessage({
                      id: 'input.label.lname',
                      defaultMessage: 'Last Name',
                    })}
                    name="lastname"
                    id="lastname"
                    type="text"
                    errors={errors.lastname}
                    placeholder={intl.formatMessage({
                      id: 'input.placeholder.lname',
                      defaultMessage: 'Type Last Name',
                    })}
                    disabled={isSubmiting || isCompanyDataFetch}
                    rules={{
                      required: formLocaleRequiredFieldMsg(),
                      validate: formLocaleNameValidator(),
                    }}
                  />
                  <FormInputMask
                    control={control}
                    label={intl.formatMessage({
                      id: 'input.label.phone',
                      defaultMessage: 'Phone',
                    })}
                    name="phone"
                    id="phone"
                    type="tel"
                    errors={errors.phone}
                    placeholder="+46 "
                    disabled={isSubmiting || isCompanyDataFetch}
                    rules={{
                      required: formLocaleRequiredFieldMsg(),
                      validate: formLocalePhoneValidator(),
                    }}
                    maskChar=""
                    mask="+46 99999999999"
                    inputMode="tel"
                  />
                  <FormInput
                    control={control}
                    label={intl.formatMessage({
                      id: 'input.label.mail',
                      defaultMessage: 'E-mail',
                    })}
                    name="email"
                    id="email"
                    type="email"
                    errors={errors.email}
                    placeholder={intl.formatMessage({
                      id: 'input.placeholder.mail',
                      defaultMessage: 'Enter email',
                    })}
                    disabled={isSubmiting || isCompanyDataFetch}
                    rules={{
                      required: formLocaleRequiredFieldMsg(),
                      validate: formLocaleMailValidator(),
                    }}
                  />
                  <div className="step-button">
                    <Button
                      disabled={isSubmiting}
                      text={intl.formatMessage({
                        id: 'steps.btn.cancel',
                        defaultMessage: 'Cancel',
                      })}
                      type={BUTTON_TYPE.OUTLINE}
                      size={BUTTON_SIZE.MEDIUM}
                      onClick={(e) => {
                        e.preventDefault();
                        e.target.blur();
                        setCancelPopupOpen(true);
                      }}
                    />
                    <Button
                      size={BUTTON_SIZE.MEDIUM}
                      text={intl.formatMessage({
                        id: 'steps.btn.nextStep',
                        defaultMessage: 'Next Step',
                      })}
                      onClick={() => {
                        nextStep();
                      }}
                    />
                  </div>
                </StepPage>
                <StepPage>
                  <Dropdown
                    handleChange={(data) => console.log(data)}
                    options={typeOpt}
                    control={control}
                    label={intl.formatMessage({
                      id: 'input.label.companyType',
                      defaultMessage: 'Company Type',
                    })}
                    name="type"
                    id="type"
                    errors={errors.type}
                    disabled={true}
                    rules={{
                      required: formLocaleRequiredFieldMsg(),
                    }}
                  />
                  {companySpec && (
                    <Dropdown
                      handleChange={(data) => console.log(data)}
                      options={specOpt}
                      control={control}
                      label={intl.formatMessage({
                        id: 'input.label.companySpec',
                        defaultMessage: 'Company Specialization',
                      })}
                      name="spec"
                      id="spec"
                      errors={errors.spec}
                      disabled={true}
                      rules={{
                        required: formLocaleRequiredFieldMsg(),
                      }}
                    />
                  )}
                  <FormInput
                    control={control}
                    label={intl.formatMessage({
                      id: 'input.label.companyName',
                      defaultMessage: 'Company Name',
                    })}
                    name="name"
                    id="name"
                    type="text"
                    errors={errors.name}
                    placeholder={intl.formatMessage({
                      id: 'input.placeholder.companyNamelaceholder',
                      defaultMessage: 'Type Company Name',
                    })}
                    disabled={isSubmiting || isCompanyDataFetch}
                    rules={{
                      required: formLocaleRequiredFieldMsg(),
                    }}
                  />
                  <FormInputMask
                    control={control}
                    label={intl.formatMessage({
                      id: 'input.label.orgNumber',
                      defaultMessage: 'Organization Number',
                    })}
                    name="organisationNumber"
                    id="organisationNumber"
                    type="text"
                    errors={errors.organisationNumber}
                    placeholder="______-____"
                    disabled={isSubmiting || isCompanyDataFetch}
                    rules={{
                      required: formLocaleRequiredFieldMsg(),
                      validate: formLocaleOrgNumValidator(),
                    }}
                    mask="999999-9999"
                    inputMode="numeric"
                  />
                  <div
                    className="full-width"
                    style={{
                      width: companySpec || isMobileOrTablet ? '100%' : '48%',
                    }}
                  >
                    <FormInput
                      control={control}
                      label={intl.formatMessage({
                        id: 'input.label.address',
                        defaultMessage: 'Address',
                      })}
                      name="address"
                      id="address"
                      type="text"
                      errors={errors.address}
                      placeholder={intl.formatMessage({
                        id: 'input.placeholder.addressPlaceholder',
                        defaultMessage: 'Type Address',
                      })}
                      disabled={isSubmiting || isCompanyDataFetch}
                      rules={{
                        required: formLocaleRequiredFieldMsg(),
                      }}
                    />
                  </div>
                  <FormInputMask
                    control={control}
                    label={intl.formatMessage({
                      id: 'input.label.zipCode',
                      defaultMessage: 'Zip Code',
                    })}
                    name="zipCode"
                    id="zipCode"
                    type="text"
                    errors={errors.zipCode}
                    placeholder={intl.formatMessage({
                      id: 'input.placeholder.zipPlaceholder',
                      defaultMessage: 'Type Zip Code',
                    })}
                    disabled={isSubmiting || isCompanyDataFetch}
                    rules={{
                      required: formLocaleRequiredFieldMsg(),
                      validate: formLocaleZipValidator(),
                    }}
                    mask="999 99"
                    inputMode="numeric"
                  />
                  <FormInput
                    control={control}
                    label={intl.formatMessage({
                      id: 'input.label.city',
                      defaultMessage: 'City',
                    })}
                    name="city"
                    id="city"
                    type="text"
                    errors={errors.city}
                    placeholder={intl.formatMessage({
                      id: 'input.placeholder.cityPlaceholder',
                      defaultMessage: 'Type City',
                    })}
                    disabled={isSubmiting || isCompanyDataFetch}
                    rules={{
                      required: formLocaleRequiredFieldMsg(),
                    }}
                  />
                  <NextStepButton>
                    <Button
                      disabled={isSubmiting}
                      text={intl.formatMessage({
                        id: 'steps.btn.cancel',
                        defaultMessage: 'Cancel',
                      })}
                      type={BUTTON_TYPE.OUTLINE}
                      size={BUTTON_SIZE.MEDIUM}
                      onClick={(e) => {
                        e.preventDefault();
                        e.target.blur();
                        setCancelPopupOpen(true);
                      }}
                    />
                    <Button
                      size={BUTTON_SIZE.MEDIUM}
                      text={intl.formatMessage({
                        id: 'page.sa.addNewCompany.steps.btn.save',
                        defaultMessage: 'Save',
                      })}
                      disabled={isSubmiting}
                      onClick={handleSubmit(onSubmit)}
                    />
                  </NextStepButton>
                </StepPage>
              </StepsComponent>
            </form>
          </div>
        </div>
      </div>
      <ConfirmPopup
        open={cancelPopupOpen}
        text={intl.formatMessage({
          id: 'popup.sa.addNewCompany.cancel.text',
          defaultMessage:
            'Are you sure that you want to cancel editing current company?',
        })}
        isLoading={false}
        confirmButtonText={intl.formatMessage({
          id: 'popup.cancel.ok',
          defaultMessage: 'Yes, cancel',
        })}
        cancelButtonText={intl.formatMessage({
          id: 'popup.cancel.no',
          defaultMessage: 'No, continue',
        })}
        onSubmit={() => {
          navigate(-1);
        }}
        onCloseModal={() => setCancelPopupOpen(false)}
      />
    </div>
  );
};

EditCompanyPage.propTypes = {
  props: PropTypes.object,
};

export default EditCompanyPage;
