import API from '../api';

export const listCompanies = (params = '') =>
  API.get(`/api/api/v1/company/companies${params}`);

export const addCompany = (data) =>
  API.post(`/api/api/v1/company/companies`, data);

export const inviteUserConfirm = (data) =>
  API.post(`/api/api/v1/company/companies/users/confirm`, data);

export const getCompany = (id) =>
  API.get(`/api/api/v1/company/companies/${id}`);

export const getCompanyUsers = (id) =>
  API.get(`/api/api/v1/company/companies/${id}/users`);

export const deleteCompanyReq = (id) =>
  API.delete(`/api/api/v1/company/companies/${id}`);

export const editCustomerCompanyReq = (id, data) =>
  API.put(`/api/api/v1/company/companies/${id}`, data);

export const editCompanyUserReq = (companyId, userId, data) =>
  API.post(`/api/api/v1/company/companies/${companyId}/users/${userId}`, data);

export const editCompanyAdmin = (data) =>
  API.put(`/api/api/v1/company/companies/my-company/edit`, data);

export const getMyCompany = () =>
  API.get(`/api/api/v1/company/companies/my-company/show`);
