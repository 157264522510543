import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { customerMyOrders, orderIdCancel } from '../../api/order';
import {
  orderMapper,
  orderMapperCustomerOrders,
} from '../../utils/order-mapper';

export const orderCancel = createAsyncThunk(
  'orders/ordersCustomerCancel',
  async (creds, { rejectWithValue }) => {
    try {
      const response = await orderIdCancel(creds);
      if (response.status === 200) {
        console.log(response.data.data);
      } else {
        return rejectWithValue(response.data);
      }
    } catch (error) {
      console.log('sliceError', error.response);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const orders = createAsyncThunk(
  'orders/ordersCustomer',
  async (creds, { rejectWithValue }) => {
    console.log(creds, creds.data);
    try {
      const response = await customerMyOrders(creds.data);
      if (response.status === 200) {
        console.log(response.data.data);
        return orderMapperCustomerOrders(creds.type, response.data);
      } else {
        return rejectWithValue(response.data);
      }
    } catch (error) {
      console.log('sliceError', error.response);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export function changeOrderStatus(type) {
  return {
    type: 'CHANGE_ORDER_STATUS',
    companyType: type,
  };
}

const initialState = {
  isLoading: false,
  isError: false,
  data: [],
  mappedData: [],
  meta: null,
};

const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  extraReducers: (builder) => {
    // orderCancel
    builder.addCase(orderCancel.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(orders.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
    });
    builder.addCase(orders.fulfilled, (state, payload) => {
      state.isLoading = false;
      state.isError = false;
      state.data = payload.payload.data;
      state.meta = payload.payload.meta;
      // TODO: MOCK
      // state.data = orderMapperSA(ORDERS_LIST_BY_PROJECT_SA_MOCK.data);
      // state.meta = ORDERS_LIST_BY_PROJECT_SA_MOCK.meta;
    });
    builder.addCase(orders.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
    // TODO: NOT USED YET. MAP ORDERS RESPONSE
    builder.addCase('CHANGE_ORDER_STATUS', (state, action) => {
      state.mappedData = orderMapper(state.data, action.companyType);
    });
  },
});

const { reducer } = ordersSlice;

export const selectOrder = (state) => state.order;

export default reducer;
