import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { numberFormat } from '../../utils/number.format';
import Button from '../ui/Button';
import { BUTTON_SIZE, BUTTON_TYPE } from '../ui/Button/Button';
import { useMediaQuery } from 'react-responsive';
import { FormattedMessage, useIntl } from 'react-intl';

export const OFFER_CART_TYPE = {
  NO_ACCEPTED: 'no_accepted',
  ACCEPTED: 'accepted',
  DONE: 'done',
};

const OfferCardCustomer = ({
  data,
  acceptHandler,
  changeHandler,
  description,
  cardType,
  disabled,
}) => {
  // const [isLoading, setIsLoading] = useState(false);
  const intl = useIntl();
  const { company, amount } = data;
  const navigate = useNavigate();

  const isMobileOrTablet = useMediaQuery({
    query: '(max-width: 900px) ',
  });

  return (
    <div
      className={
        cardType === OFFER_CART_TYPE.ACCEPTED
          ? 'offer-card-customer accepted'
          : cardType === OFFER_CART_TYPE.DONE
          ? 'offer-card-customer done'
          : 'offer-card-customer'
      }
    >
      <div className="offer-card-customer__inner">
        <div
          className="offer-card-customer__name"
          //   onClick={() => navigate(`/all-companies/company-order/${id}`)}
        >
          <span className="offer-card-customer__name-name">
            <FormattedMessage
              id="component.offerCard.total"
              defaultMessage="Quotation amount (excl. VAT)"
            />
          </span>
          <div className="offer-card-customer__name-count">
            {numberFormat(amount)} kr
          </div>
        </div>
        <div className="offer-card-customer__info">
          <div className="offer-card-customer__info-block">
            <span className="offer-card-customer__info-title">
              {description}
            </span>
            <span
              onClick={() =>
                navigate(`/customer/my-orders/company/${company.id}`)
              }
              className="offer-card-customer__info-desc link"
            >
              {company.name}
            </span>
          </div>
          {cardType === OFFER_CART_TYPE.ACCEPTED && (
            <div className="offer-card-customer__info-block offer-btn">
              <Button
                size={isMobileOrTablet ? BUTTON_SIZE.BIG : BUTTON_SIZE.MEDIUM}
                text={intl.formatMessage({
                  id: `btn.change`,
                  defaultMessage: 'Change',
                })}
                type={BUTTON_TYPE.TERTIARY}
                disabled={disabled}
                onClick={() => {
                  changeHandler(data.id, data);
                }}
              />
            </div>
          )}
          {cardType === OFFER_CART_TYPE.NO_ACCEPTED && (
            <div className="offer-card-customer__info-block offer-btn">
              <Button
                size={isMobileOrTablet ? BUTTON_SIZE.BIG : BUTTON_SIZE.MEDIUM}
                text={intl.formatMessage({
                  id: `btn.accept`,
                  defaultMessage: 'Accept',
                })}
                disabled={disabled}
                onClick={() => {
                  acceptHandler(data.id, data);
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

OfferCardCustomer.defaultProps = {
  acceptHandler: () => {},
  changeHandler: () => {},
  cardType: OFFER_CART_TYPE.NO_ACCEPTED,
  disabled: false,
};

OfferCardCustomer.propTypes = {
  data: PropTypes.object.isRequired,
  acceptHandler: PropTypes.func,
  changeHandler: PropTypes.func,
  description: PropTypes.string,
  cardType: PropTypes.string,
  disabled: PropTypes.bool,
};

export default OfferCardCustomer;
