import { useSearchParams, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getUsersCompany,
  getBannedUsersCompany,
} from '../../../store/slice/usersCompanySlice';
import Users from '../../../components/Users';
// import { useSearchParams } from 'react-router-dom';
import { getCompany } from '../../../api/company';
import Title from '../../../components/ui/Title';
import { FormattedMessage, useIntl } from 'react-intl';
// import useIsMobile from '../../../utils/useMobile';

const HomeProjects = () => {
  const dispatch = useDispatch();
  const isListLoading = useSelector(
    (state) => state.usersCompany.isListLoading
  );

  const isListBannedLoading = useSelector(
    (state) => state.usersCompany.isListBannedLoading
  );

  const usersBannedList = useSelector((state) => state.usersCompany.dataBanned);

  const usersBannedMeta = useSelector((state) => state.usersCompany.metaBanned);

  const usersList = useSelector((state) => state.usersCompany.data);
  const usersMeta = useSelector((state) => state.usersCompany.meta);
  const [companyInfo, setCompanyInfo] = useState({});

  const [searchParams] = useSearchParams();
  const pageQuery = searchParams.get('page') || 1;
  const pageBannedQuery = searchParams.get('pageBanned') || 1;
  const queryParams = useParams();
  const intl = useIntl();

  const fetchListBanned = async (page) => {
    dispatch(
      getBannedUsersCompany({
        id: queryParams.id_company,
        query: { page: pageBannedQuery },
      })
    );
  };

  const fetchList = async (page) => {
    dispatch(
      getUsersCompany({
        id: queryParams.id_company,
        query: { page: page, status: 'active' },
      })
    );
  };

  const callbackAction = () => {
    fetchList(pageQuery);
    fetchListBanned();
  };

  const getCompanyAsync = async () => {
    try {
      const res = await getCompany(queryParams.id_company);

      if (res && res.data && res.data.data) {
        setCompanyInfo(res.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getCompanyAsync();
  }, []);

  useEffect(() => {
    fetchListBanned(pageBannedQuery);
  }, [pageBannedQuery]);

  useEffect(() => {
    fetchList(pageQuery);
    document.title = intl.formatMessage({
      id: 'page.sa.companyUsers.title',
      defaultMessage: 'Users company',
    });
  }, [pageQuery]);
  // const isMobile = useIsMobile();

  return (
    <div className="users-company-page customer-page">
      <div className="users-company-page__inner">
        <Title subTitle={companyInfo.name || '...'}>
          <FormattedMessage
            id="page.sa.companyUsers.title"
            defaultMessage="Users"
          />
        </Title>
        <Users
          idCompany={queryParams.id_company}
          currentPage={usersMeta.current_page}
          totalCount={usersMeta.last_page}
          isListLoading={isListLoading || isListBannedLoading}
          list={usersList}
          itsActive={true}
          callbackAction={callbackAction}
          listBanned={usersBannedList}
        />
        <Users
          idCompany={queryParams.id_company}
          currentPage={usersBannedMeta.current_page}
          totalCount={usersBannedMeta.last_page}
          isListLoading={isListLoading || isListBannedLoading}
          list={usersBannedList}
          itsBanned={true}
          callbackAction={callbackAction}
        />
      </div>
    </div>
  );
};

HomeProjects.defaultProps = {};

HomeProjects.propTypes = {};

export default HomeProjects;
