import React from 'react';
import PropTypes from 'prop-types';

export const NOTIFICATION_ALERT_TYPE = {
  SUCCES: 'success',
  WARNING: 'warning',
  ERROR: 'error',
};

const NotificationAlert = ({ type, message }) => {
  return (
    <div className="notification-alert">
      <div className={`notification-alert__inner ${type}`}>
        <span className={`notification-alert__content ${type}`}>{message}</span>
      </div>
    </div>
  );
};

NotificationAlert.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default NotificationAlert;
