import moment from 'moment';

/* eslint-disable no-unused-expressions */
export const orderMapperSA = (data) =>
  data.map((item) => ({
    ...item,
    status:
      item.status === 'new'
        ? 'not_answered'
        : item.status === 'archive'
        ? 'canceled'
        : item.status,
  }));

export const orderObjectMapperSA = (data) => {
  data.status === 'new'
    ? 'not_answered'
    : data.status === 'archive'
    ? 'canceled'
    : data.status;
  data = {
    ...data,
    status:
      data.status === 'new'
        ? 'not_answered'
        : data.status === 'archive'
        ? 'canceled'
        : data.status,
    timeOfStart: moment(data.timeOfStart, 'hh:mm:ss').format('HH:mm'),
  };
  return data;
};

export const orderObjectMapperCustomer = (data) => {
  data.status === 'new'
    ? 'not_answered'
    : data.status === 'archive'
    ? 'canceled'
    : data.status;
  data = {
    ...data,
    status:
      data.status === 'new'
        ? 'not_answered'
        : data.status === 'archive'
        ? 'canceled'
        : data.status,
    timeOfStart: moment(data.timeOfStart, 'hh:mm:ss').format('HH:mm'),
  };
  return data;
};

export const orderMapperCustomer = (data) => {
  data.data = data.data.map((item) => ({
    ...item,
    status: item.status === 'new' ? 'not_answered' : item.status,
  }));
  return data;
};

export const orderMapperSupplier = (data) => {
  data.data = data.data.map((item) => ({
    ...item,
    // TODO: ADD SUPPLIER STATUS MAP LOGIC
    // status:
    //   item.status === 'new' && item.tenderTotal
    //     ? 'waiting_for_answer'
    //     : item.status === 'new' && !item.tenderTotal
    //     ? 'new'
    //     : item.status,
  }));
  return data;
};

export const orderMapper = (type, data) => {
  if (!data || !data.data) {
    return;
  }

  if (type === 'customer') {
    return orderMapperCustomer(data);
  }

  if (type === 'supplier') {
    return orderMapperSupplier(data);
  }

  return data;
};

export const orderMapperCustomerOrders = (type, data) => {
  if (!data || !data.data) {
    return;
  }
  if (type === 'new') {
    return orderMapperCustomer(data);
  }

  if (type === 'archive') {
    // data.data = data.data.map((item) => ({
    //   ...item,
    //   status: item.status === 'new' ? 'not_answered' : item.status,
    // }));
    return data;
    // return orderMapperCustomer(data);
  }

  if (type === 'in_progress') {
    data.data = data.data.map((item) => ({
      ...item,
      status: item.status === 'new' ? 'in_progress' : item.status,
    }));
    return data;
  }

  return data;
};

export const orderSupplierArchiveMapper = (data) => {
  if (!data || !data.data) {
    return data;
  }

  data.data = data.data.map((item) => ({
    ...item,
    status: item.status === 'in_progress' ? 'declined' : item.status,
  }));

  return data;
};
