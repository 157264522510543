import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Icon from '../ui/Icon';
import NavList from '../NavList/NavList';
import NotificationPopup from '../NotificationPopup/NotificationPopup';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../store/slice/authSlice';
import { clearUnreadCount } from '../../store/slice/notificationSlice';
import { FormattedMessage } from 'react-intl';
import LangSwitcher from '../ui/LangSwitcher';

const SideBar = ({ menuExpand, menuExpandChange, items, langs }) => {
  const [showAlert, setShowAlert] = useState(false);
  const notificationMeta = useSelector(
    (state) => state.notifications.unreadListMeta
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logOut = () => {
    dispatch(clearUnreadCount());
    dispatch(logout()).then((res) => {
      if (res && res.meta.requestStatus === 'fulfilled') {
        navigate('/');
      }
    });
  };

  const getCount = () => notificationMeta && notificationMeta.total;

  return (
    <div className="sidebar">
      <div className="sidebar__header">
        <Icon icon="logo-light" />
      </div>
      <div className="sidebar__list-wrapper">
        <div className="sidebar__list">
          <div className="sidebar__list-item notification">
            <div
              className="item__inner"
              onClick={() => setShowAlert(!showAlert)}
            >
              <span className="icon">
                <Icon icon="notification" size="21px" />
              </span>
              <span>
                <FormattedMessage
                  id="page.layout.menu.notifications"
                  defaultMessage="Notifications"
                />
              </span>
              <span className="notifications-count">
                {getCount() > 0 && (
                  <div>{getCount() < 99 ? getCount() : 99}</div>
                )}
              </span>
              <span className="notification-mobile">
                {getCount() > 0 && (
                  <div>{getCount() < 99 ? getCount() : 99}</div>
                )}
              </span>
            </div>
            {showAlert && (
              <NotificationPopup onClose={() => setShowAlert(false)} />
            )}
          </div>
          <div className="sidebar__list-item nav-menu">
            <NavList
              items={items}
              collapseAllSubList={menuExpand}
              onSubmenuExpand={() => menuExpandChange(true)}
            />
          </div>
          <div className="sidebar__list-item">
            <LangSwitcher
              items={langs}
              collapseAllSubList={menuExpand}
              onSubmenuExpand={() => menuExpandChange(true)}
            />
            <div className="item__inner" onClick={() => logOut()}>
              <span className="icon">
                <Icon icon="login" size="21px" />
              </span>
              <span>
                <FormattedMessage
                  id="page.layout.menu.logOut"
                  defaultMessage="Log Out"
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SideBar.propTypes = {
  menuExpand: PropTypes.bool,
  menuExpandChange: PropTypes.func,
  items: PropTypes.array.isRequired,
  langs: PropTypes.array.isRequired,
};

export default SideBar;
