import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import FormInput from '../../../components/ui/FormInput/FormInput';
import Button, {
  BUTTON_SIZE,
  BUTTON_TYPE,
} from '../../../components/ui/Button/Button';
import Icon from '../../../components/ui/Icon';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { login } from '../../../store/slice/authSlice';
import { getProfile } from '../../../store/slice/personalInfoSlice';
import LoadedContent from '../../../components/LoadedContent';
import { getCompany } from '../../../api/company';
import { FormattedMessage, useIntl } from 'react-intl';

const SignInPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useIntl();

  const { control, handleSubmit, setError, formState } = useForm({
    reValidateMode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
    },
  });
  const { errors } = formState;

  const onSubmit = (data) => {
    setIsLoading(true);
    const postData = {
      email: data.email.toLowerCase(),
      password: data.password,
    };

    dispatch(login(postData))
      .unwrap()
      .then((res) => {
        localStorage.removeItem('role');
        localStorage.removeItem('companyType');
        localStorage.removeItem('companySpec');
        dispatch(getProfile())
          .unwrap()
          .then((res) => {
            if (res.companyId) {
              getCompany(res.companyId)
                .then((res) => {
                  if (res && res.data && res.data.data) {
                    localStorage.setItem('companyType', res.data.data.type);
                    localStorage.setItem('companySpec', res.data.data.spec);

                    if (res.role === 'super admin') {
                      navigate('/all-companies/customers');
                    } else {
                      if (res.data.data.type === 'supplier') {
                        navigate('/supplier/my-orders');
                      } else {
                        navigate('/customer/my-orders');
                      }
                    }
                  }
                })
                .catch(() => {});
            } else {
              if (res.role === 'super admin') {
                navigate('/all-companies/customers');
              } else {
                navigate('/my-company/company-info');
              }
            }
          })
          .catch((er) => {
            setError('password', {
              type: 'custom',
              message: er || 'Try please later!',
            });
            localStorage.removeItem('token');
            localStorage.removeItem('role');
            localStorage.removeItem('refreshToken');
            setIsLoading(false);
          })
          .finally(() => {});
      })
      .catch((er) => {
        setIsLoading(false);
        if (typeof er !== 'string') {
          return;
        }

        let localized;

        if (er === 'The user credentials were incorrect.') {
          localized = intl.formatMessage({
            id: 'input.error.authError',
            defaultMessage: 'The user credentials were incorrect.',
          });
        }

        setError('email', {
          type: 'custom',
          message: localized || er,
        });
        setError('password', {
          type: 'custom',
          message: localized || er,
        });
      });
  };

  useEffect(() => {
    // const title = intl.formatMessage({
    //   id: 'auth.signIn.title',
    //   defaultMessage: 'Sign in',
    // });
    document.title = 'Gjutfrossa';
  }, []);

  return (
    <div className="sign-in">
      <div className="sign-in__header">
        <Link to="/sign-in">
          <Icon icon="logo-light" />
        </Link>
      </div>

      <div className="sign-in__logo">
        <div className="sign-in__logo-wrapper">
          <Icon icon="logo-big" />
        </div>
      </div>
      <div className="sign-in__content">
        <LoadedContent loader={isLoading} />
        <div className="sign-in__form">
          <form
            className="sign-in__form-inner"
            onSubmit={handleSubmit(onSubmit)}
          >
            <span className="sign-in__form-title">
              <FormattedMessage
                id="auth.signIn.title"
                defaultMessage="Log In"
              />
            </span>
            <FormInput
              control={control}
              label={intl.formatMessage({
                id: 'auth.signIn.form.mail',
                defaultMessage: 'E-mail',
              })}
              name="email"
              id="email"
              type="email"
              errors={errors.email}
              placeholder={intl.formatMessage({
                id: 'auth.signIn.form.mailPlaceholder',
                defaultMessage: 'Enter your e-mail',
              })}
              disabled={isLoading}
              rules={{
                required: (
                  <FormattedMessage
                    id="auth.signIn.form.required"
                    defaultMessage="Field is required"
                  />
                ),
              }}
            />
            <FormInput
              control={control}
              label={intl.formatMessage({
                id: 'auth.signIn.form.password',
                defaultMessage: 'Password',
              })}
              name="password"
              id="password"
              type="password"
              errors={errors.password}
              placeholder={intl.formatMessage({
                id: 'auth.signIn.form.passwordPlaceholder',
                defaultMessage: 'Enter your password',
              })}
              disabled={isLoading}
              rules={{
                required: (
                  <FormattedMessage
                    id="auth.signIn.form.required"
                    defaultMessage="Field is required"
                  />
                ),
              }}
            />
            <div className="sign-in__form-btn">
              <Button
                text={intl.formatMessage({
                  id: 'auth.signIn.btn',
                  defaultMessage: 'Log In',
                })}
                size={BUTTON_SIZE.MEDIUM}
                type={BUTTON_TYPE.PRIMARY}
                disabled={isLoading}
              />
              <Link to="/forgot-password">
                <span className="link">
                  <FormattedMessage
                    id="auth.signIn.forgotPassword"
                    defaultMessage="Forgot Password?"
                  />
                </span>
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignInPage;
