import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';
import useScrollBlock from '../../../utils/useScrollBlock';
import { useOnClickOutside } from '../../../utils/useClickOutside';
import ReactDOM from 'react-dom';
import { useMediaQuery } from 'react-responsive';

const Actions = ({ actions }) => {
  const [isListShow, setIsListShow] = useState(false);
  const [blockScroll, allowScroll] = useScrollBlock();
  const contentRef = useRef(null);
  const componentMounted = useRef(true);

  useOnClickOutside(
    contentRef,
    () => {
      setIsListShow(false);
    },
    componentMounted
  );

  useEffect(() => {
    if (isListShow) {
      blockScroll();
    } else {
      allowScroll();
    }
  }, [isListShow]);

  useEffect(() => {
    return () => {
      componentMounted.current = false;
    };
  }, []);

  if (!actions.length) {
    return null;
  }

  const isMobileOrTablet = useMediaQuery({
    query: '(max-width: 900px) ',
  });

  return (
    <div className="actions">
      <div className="actions-btn" onClick={() => setIsListShow(!isListShow)}>
        <Icon icon="more" size="15px" />
      </div>
      {isListShow &&
        isMobileOrTablet &&
        ReactDOM.createPortal(
          <>
            <div className="actions__bg"></div>
            <div className="actions__list" ref={contentRef}>
              {actions.map((item, index) => (
                <div
                  key={index}
                  className="actions__list-item"
                  onClick={() => {
                    item.action();
                    setIsListShow(false);
                  }}
                >
                  <div className="actions__list-btn">
                    <Icon icon={item.icon} size="16px" />
                  </div>
                  <span className="actions__list-desc">{item.text}</span>
                </div>
              ))}
            </div>
          </>,
          document.getElementById('portal-action')
        )}
      {isListShow && !isMobileOrTablet && (
        <>
          <div className="actions__bg"></div>
          <div className="actions__list" ref={contentRef}>
            {actions.map((item, index) => (
              <div
                key={index}
                className="actions__list-item"
                onClick={() => {
                  item.action();
                  setIsListShow(false);
                }}
              >
                <div className="actions__list-btn">
                  <Icon icon={item.icon} size="16px" />
                </div>
                <span className="actions__list-desc">{item.text}</span>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

Actions.propTypes = {
  actions: PropTypes.array.isRequired,
};

export default Actions;
