/* eslint-disable no-unused-vars */

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';
import Icon from '../Icon';
import { useOnClickOutside } from '../../../utils/useClickOutside';
import TimePickerControls from './TimePickerControls';
const timeRegex = /^(0[0-9]|1[0-9]|2[0-3]|[0-9]):([0-5][0-9]|[0-59])$/;
const hhRegex = /^(0[0-9]|1[0-9]|2[0-3])$/;
const mmRegex = /^([0-5][0-9])$/;

const TimePicker = (props) => {
  const { field } = useController(props);
  const [isFocus, setIsFocus] = useState(false);
  const [isSublistShow, setSublistShow] = useState(false);
  const { label, id, placeholder, value, disabled, onFocus, errors, rules } =
    props;

  const [houres, setHoures] = useState();
  const [min, setMin] = useState();

  const contentRef = useRef(null);
  const hhRef = useRef(null);
  const mmRef = useRef(null);
  const componentMounted = useRef(null);

  const iconsWrap = () => {
    let className = 'timepicker__input-wrap';

    if (errors && !isSublistShow) {
      className += ' errors';
    }
    return className;
  };

  const onInputBlur = (e) => {
    const value = e.target.value;
    const isValid = timeRegex.test(value);

    if (!isValid) {
      field.onChange(`${formatValue(houres)}:${formatValue(min)}`);
    } else {
      initTimeState(value);
    }
  };

  useOnClickOutside(
    contentRef,
    () => {
      setSublistShow(false);
      setIsFocus(false);
    },
    componentMounted
  );

  useEffect(() => {
    window.addEventListener('keyup', tabFocuseHandler);

    if (!field.value) {
      setHoures(0);
      setMin(0);
      return;
    }

    const isValid = timeRegex.test(field.value);
    if (isValid) {
      initTimeState(field.value);
    } else {
      setHoures(0);
      setMin(0);
    }

    return () => {
      componentMounted.current = false;
      window.removeEventListener('keyup', tabFocuseHandler);
    };
  }, []);

  useEffect(() => {
    if (isSublistShow) {
      field.onChange(`${formatValue(houres)}:${formatValue(min)}`);
    }
  }, [houres, min]);

  const formatValue = (val) => (val < 10 ? `0${val}` : `${val}`);

  const initTimeState = (value) => {
    const hh = value.split(':')[0];
    const mm = value.split(':')[1];
    setHoures(hh[0] === '0' ? Number(hh[1]) : Number(hh));
    setMin(mm[0] === '0' ? Number(mm[1]) : Number(mm));
  };

  const tabFocuseHandler = (e) => {
    const code = e.keyCode ? e.keyCode : e.which;
    if (code === 9) {
      if (
        document.activeElement.id === id ||
        document.activeElement.id === 'timepicker-hh-input' ||
        document.activeElement.id === 'timepicker-mm-input' ||
        document.activeElement.id === 'timepicker-hh-dec' ||
        document.activeElement.id === 'timepicker-hh-inc' ||
        document.activeElement.id === 'timepicker-mm-dec' ||
        document.activeElement.id === 'timepicker-mm-inc'
      ) {
        return false;
      } else {
        setSublistShow(false);
        setIsFocus(false);
      }
    }
  };

  return (
    <div
      className={!disabled ? 'timepicker' : 'timepicker disabled'}
      ref={contentRef}
    >
      <div className="timepicker__label-wrap">
        {label && (
          <label
            htmlFor={id}
            className={errors ? 'timepicker__label error' : 'timepicker__label'}
          >
            {label}
            {rules && rules.required ? <span className="required">*</span> : ''}
          </label>
        )}
      </div>
      <div className={isFocus ? ` focused` : ``}>
        <div className={iconsWrap()}>
          <div className="icon">
            <Icon icon="time" size="16px" />
          </div>
          <input
            {...field}
            id={id}
            type="text"
            inputMode="decimal"
            placeholder={placeholder}
            disabled={disabled}
            onFocus={(e) => {
              setIsFocus(true);
              setSublistShow(true);
              if (onFocus) {
                onFocus();
              }
            }}
            onBlur={(e) => {
              onInputBlur(e);
              field.onBlur();
            }}
            className={errors ? 'error' : ''}
            value={value || (field.value === null ? '' : field.value)}
          />
          {isSublistShow && (
            <TimePickerControls
              houres={houres}
              min={min}
              setHoures={setHoures}
              setMin={setMin}
              formatValue={formatValue}
              hhRef={hhRef}
              mmRef={mmRef}
            />
          )}
        </div>
      </div>
      {errors && <p className="error">{errors.message}</p>}
    </div>
  );
};

TimePicker.propTypes = {
  text: PropTypes.string,
  label: PropTypes.string.isRequired,
  id: PropTypes.string,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  onFocus: PropTypes.func,
  errors: PropTypes.object,
  rules: PropTypes.object,
  control: PropTypes.object.isRequired,
};

export default TimePicker;
