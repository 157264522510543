import React from 'react';
import PropTypes from 'prop-types';

const AboutCard = ({ title, data, actions }) => {
  return (
    <div className="about-card">
      <div className="about-card__title">
        {title}
        {actions}
      </div>
      <div className="about-card__content">
        {data &&
          data.map((item, index) => (
            <div className="about-card__content-item" key={index}>
              <div className="about-card__content-name">{item.title}</div>
              <div className="about-card__content-desc">{item.value}</div>
            </div>
          ))}
      </div>
    </div>
  );
};

AboutCard.defaultProps = {
  onSuccess: () => {},
  actions: null,
};

AboutCard.propTypes = {
  data: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  onSuccess: PropTypes.func,
  actions: PropTypes.node,
};

export default AboutCard;
